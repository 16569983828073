<h1 mat-dialog-title>
  <span>{{'common.add_image' | translate}}</span>
</h1>
<div mat-dialog-content>

  <mat-form-field appearance="outline" class="w-100 mt-4 mb-4" *ngIf="data.showType">
    <mat-label>{{'common.image_type' | translate}}</mat-label>
    <mat-select [(ngModel)]="data.type">
      <mat-option [value]="imageType.PICKUP">{{'common.pickup' | translate}}</mat-option>
      <mat-option [value]="imageType.DELIVERY">{{'common.delivery' | translate}}</mat-option>
      <mat-option [value]="imageType.GENERAL">{{'common.general' | translate}}</mat-option>
    </mat-select>
  </mat-form-field>

  <button class="btn-primary green w-100" (click)="fileUpload.click()" [disabled]="isLoading">
    {{'common.upload' | translate}}
  </button>

  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

  <!-- <pre>{{data | json}}</pre> -->
  <button class="btn-primary dark w-100 mt-2" mat-dialog-close="">{{'common.close' | translate}}</button>
</div>
