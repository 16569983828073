<h1 mat-dialog-title class="fs-3 text-center mb-2">
  {{'common.edit_price' | translate}}
</h1>
<div mat-dialog-content>
  <table *ngIf="!!data && data" style="width: 100%" class="table table-striped">
    <thead>
      <td translate>common.position</td>
      <td translate>common.sum</td>
    </thead>

    <tbody>

      <tr>
        <td translate>common.transport_price</td>
        <td>
          {{ownOrganization?.default_currency}}
          <input type="number" [(ngModel)]="data.base_price" (keyup)="onChange()" >
        </td>
      </tr>

      <tr *ngFor="let option of data.options">
        <td>{{option.name}}</td>
        <td>
          {{ownOrganization?.default_currency}}
          <input type="number" [(ngModel)]="option.price" (keyup)="onChange()">
        </td>
      </tr>
      <tr>
        <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.excl_vat' | translate}})</td>
        <td style="font-weight: bold;">{{ownOrganization?.default_currency}} {{data.total_price | number: '1.2-2'}}</td>
      </tr>

    </tbody>
  </table>

  <!-- <pre>{{data | json}}</pre> -->
</div>

<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" [mat-dialog-close]="data">{{'common.save' | translate}}</button>
</div>