import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { IDetailedTour, ITourShipment, TourService, TourStatus } from '../../services/tour.service';
import { IVehicle } from '../../services/fleet.service';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { IUser } from '../../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { CarrierShipmentsService } from '../../services/carrier-shipments.service';
// import { } from 'googlemaps';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { downloadFile, secondsToHumanTime } from '../../utils';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from '../../services/organizations.service';
import { IOrganization } from '../../interfaces/organization.interface';


export const removeDuplicates = (objects: any[]): any[] => {
  const seen = new Set<string>();
  return objects.filter(obj => {
    const signature = JSON.stringify(obj);
    if (seen.has(signature)) {
      return false;
    }
    seen.add(signature);
    return true;
  });
}

// export interface IStop {
//   name: string,
//   location: string,
//   zip_code: string,
//   company: string,
//   address: string
// }


@Component({
  selector: 'app-tour-editor',
  templateUrl: './tour-editor.component.html',
  styleUrls: ['./tour-editor.component.scss']
})
export class TourEditorComponent implements OnInit {
  form: FormGroup = new FormGroup({
    date: new FormControl(''),
    weight: new FormControl(0),
    status: new FormControl(null),
    ground_spaces: new FormControl(0),
    vehicle: new FormControl(null),
    driver: new FormControl(null),
  });

  editable = true;

  tourStatus = TourStatus

  tourUUID = ''
  tour: IDetailedTour | undefined = undefined;
  // stops: IStop[] = [];
  isLoading: boolean = false;

  ownOrganization: IOrganization | null = null;

  @ViewChild('map', { static: true }) mapElement: any;

  // map: google.maps.Map | null = null;
  // directionsService = new google.maps.DirectionsService;
  // directionsDisplay = new google.maps.DirectionsRenderer;

  legs: any[] = []

  constructor(
    private route: ActivatedRoute,
    public carrierShipmentsService: CarrierShipmentsService,
    private tourService: TourService,
    private toastrService: ToastrService,

    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
    private organizationService: OrganizationsService,
  ) { }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    this.editable = this.route.snapshot.data['editable'];

    this.route.params.subscribe(async (params) => {
      if (!!params && params['tourUUID']) {
        this.tourUUID = params['tourUUID']
        await this.refresh();
        // this.initMap();
      }
    })
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.tour = await lastValueFrom(this.tourService.getTour(this.tourUUID))
      this.updateFields()
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  updateFields(){
    if (this.tour) {
      this.tour.driving_duration_display = secondsToHumanTime(this.tour.driving_duration)
      this.form.patchValue(this.tour);
      // this.calculateStops()
    }
  }

  // initMap() {
  //   const mapProperties = {
  //     center: new google.maps.LatLng(46.7099435, 8.4829874),
  //     zoom: 7,
  //     scrollwheel: false,
  //     mapTypeId: google.maps.MapTypeId.ROADMAP
  //   };
  //   this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);

  //   this.directionsDisplay.setMap(this.map);
  // }

  // calculateStops() {
  //   const addresses: IStop[] = []

  //   if (this.tour && this.tour.shipments) {
  //     for (let index = 0; index < this.tour?.shipments?.length; index++) {
  //       const shipment = this.tour?.shipments[index];
  //       addresses.push({
  //         location: shipment.pickup_location,
  //         zip_code: shipment.pickup_zip,
  //         company: shipment.pickup_company,
  //         name: shipment.pickup_name,
  //         address: shipment.pickup_address,
  //       })
  //       addresses.push({
  //         location: shipment.delivery_location,
  //         zip_code: shipment.delivery_zip,
  //         company: shipment.delivery_company,
  //         name: shipment.delivery_name,
  //         address: shipment.delivery_address,
  //       })
  //     }
  //   }

  //   this.stops = removeDuplicates(addresses);

  //   // this.calculateAndDisplayRoute()
  // }

  async downloadLoadList() {
    if (this.tour?.uuid){
      try {
        this.spinnerService.show()
        const response = await lastValueFrom(this.tourService.loadListPDF(this.tour.uuid));
        const blob = new Blob([response], { 'type': 'application/pdf' });
        downloadFile(blob, `load_list_${this.tour.id}.pdf`);
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide()
      }
    }
  }

  async save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }

    try {
      const data = this.form.value
      if (data.date && (data.date instanceof Date)) {
        data.date = data.date.toISOString().split('T')[0]
      }

      this.tour = await lastValueFrom(this.tourService.updateTour(this.tourUUID, this.form.value));
      this.translate.get('toastr.updated').subscribe((translatedMessage: string) => {
        this.toastrService.success(translatedMessage);
      });
      this.refresh();
    } catch (error) {
      console.error(error)
    }
  }

  async onCloseTour() {
    try {
      this.tour = await lastValueFrom(this.tourService.closeTour(this.tourUUID));
          this.translate.get('toastr.tour_planned_driver_can_see').subscribe((translatedMessage: string) => {
            this.toastrService.success(translatedMessage);
          });
      this.updateFields();
    } catch (error) {
      console.error(error)
    }
  }

  async onOptimizeTour() {
    try {
      this.tour = await lastValueFrom(this.tourService.optimizeTour(this.tourUUID));
      this.translate.get('toastr.tour_optimized').subscribe((translatedMessage: string) => {
        this.toastrService.success(translatedMessage);
      });
      this.updateFields();
    } catch (error) {
      console.error(error)
    }
  }

  onSelectedVehicle(event: IVehicle) {
    this.form.patchValue({ vehicle: event.id })
  }

  // calculateAndDisplayRoute() {
  //   const wayPoints: google.maps.DirectionsWaypoint[] = [];

  //   if (this.stops.length < 2) return

  //   const origin = `${this.stops[0].address}, ${this.stops[0].location}, Schweiz`
  //   // const destination = `${this.stops[this.stops.length - 1].address}, ${this.stops[this.stops.length - 1].location}, Schweiz`
  //   const destination = origin;

  //   for (let index = 1; index < this.stops.length; index++) {
  //     const el = this.stops[index];
  //     if (index < 8) {
  //       wayPoints.push({ location: `${el.address}, ${el.location}, Schweiz` })
  //     }
  //   }

  //   this.directionsService.route({
  //     origin: origin,
  //     destination: destination,
  //     waypoints: wayPoints,
  //     optimizeWaypoints: true,
  //     travelMode: google.maps.TravelMode.DRIVING
  //   }, (response, status) => {
  //     if (status === 'OK') {
  //       this.directionsDisplay.setDirections(response);
  //       this.legs = response.routes[0].legs
  //     } else {
  //       window.alert('Directions request failed due to ' + status);
  //     }
  //   });
  // }

  calculateDrivingTimeDuration() {
    let total_duration = 0
    let total_distance = 0

    this.legs.forEach(el => {
      total_distance += el.distance.value / 1000
      total_duration += el.duration.value
    });

    if (this.tour) {
      this.tour.driving_duration = total_duration
      this.tour.driving_distance = total_distance
    }
  }

  onSelectedUser(user: IUser) {
    if (this.tour) {
      this.tour.driver = user.id
      this.tour.driver_object = user
    }
    this.form.patchValue({ driver: user.id })
  }

  async onRemoveShipmentFromTour(shipment: ITourShipment) {
    if (confirm(`Wollen Sie die Sendung ${shipment.number} aus der Tour entfernen?`)) {
      await lastValueFrom(this.carrierShipmentsService.removeShipmentsFromTour(this.tourUUID, [shipment.uuid]));
      this.translate.get('toastr.tour_updated').subscribe((translatedMessage: string) => {
        this.toastrService.success(translatedMessage);
      });
      this.refresh();
    }
  }

  async dropStop(event: any) {
    if (this.tour && this.tour.order){
      console.log(this.tour.order)
      moveItemInArray(this.tour.order, event.previousIndex, event.currentIndex);
      console.log(this.tour.order)
      this.tour = await lastValueFrom(this.tourService.updateTour(this.tourUUID, this.tour));
      this.updateFields()
    }
  }

  alert() {
    this.translate.get('common.drag_and_drop_alert').subscribe((res: string) => {
      alert(res);
    })
  }
}
