
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as Sentry from '@sentry/browser';

@Injectable({ providedIn: 'root' })
export class OnlineService {

  onlineMode = new BehaviorSubject<boolean>(false);

  constructor() {
    console.log('starting OnlineService')
    if (navigator.onLine) {
      console.log('online');
      this.onlineMode.next(true);
      Sentry.setTag('onlineMode', true);
    } else {
      console.log('offline');
      this.onlineMode.next(false);
      Sentry.setTag('onlineMode', false);
    }
    window.addEventListener('offline', (e) => {
      console.log('offline');
      this.onlineMode.next(false);
      Sentry.setTag('onlineMode', false);
    });
    window.addEventListener('online', (e) => {
      console.log('online');
      this.onlineMode.next(true);
      Sentry.setTag('onlineMode', true);
    });
  }
}
