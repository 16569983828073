import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { IDetailedTour } from '../../services/tour.service';
import { IVehicle } from '../../services/fleet.service';


@Component({
  selector: 'app-tour-editor-modal',
  templateUrl: './tour-editor-modal.component.html',
  styleUrls: ['./tour-editor-modal.component.scss']
})
export class TourEditorModalComponent implements OnInit {

  form: FormGroup = new FormGroup({
    date: new FormControl(''),
    weight: new FormControl(0),
    ground_spaces: new FormControl(0),
    vehicle: new FormControl(null),
  });

  constructor(
    public dialogRef: MatDialogRef<TourEditorModalComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: IDetailedTour,
  ) { }

  async ngOnInit() {
    this.form.patchValue(this.data)
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.dialogRef.close(this.form.value)
  }

  onSelectedVehicle(event: IVehicle) {
    this.form.patchValue({ vehicle: event.id})
  }
}
