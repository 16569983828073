import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { IApiToken } from '../interfaces/token.interface';

export interface IUser {
  id?: number;
  uuid?: string;
  first_name: string,
  last_name: string,
  username?: string,
  email: string,
  phone: string,

  avatardocument?: string;
  avatardocument_object?: {
    file: string | null;
    id: number;
    thumbnail: string | null;
    name: string
  };

  permission_pipeline: boolean;
  permission_dispatch: boolean;
  permission_invoicing: boolean;
  permission_client_management: boolean;
  permission_empties_management: boolean;
  permission_warehouse_management: boolean;
  permission_activity: boolean;
  permission_export: boolean;
  permission_user_management: boolean;
  permission_settings: boolean;
  permission_org_management: boolean;
  permission_see_pricing: boolean;
  permission_market_place: boolean;
  permission_alerts_place: boolean;
  permission_new_shipments: boolean;

  permission_shipper_panel: boolean;
  permission_carrier_panel: boolean;
  permission_driver_panel: boolean;

  preference_show_shipments_to_pickup: boolean;
  preference_show_shipments_in_vehicle: boolean;
  preference_show_shipments_to_deliver: boolean;

  preference_favorite_panel: 'carrier' | 'shipper' | 'driver' | '';

  preference_show_market_place: boolean
  preference_show_facturation: boolean
  preference_show_activity: boolean
}

export interface IDetailedUser extends IUser {
  id: number,
  uuid: string,
  created_at: Date,
  updated_at: Date,
  last_login: Date,
}


const CARGOFLOW_USER_PREFERENCES = 'CARGOFLOW_USER_PREFERENCES';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  ownUser$ = new BehaviorSubject<IDetailedUser | null>(null);

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) { }

  initService() {
    this.loadCachedPreferences();
    this.getOwnUser().subscribe()
  }

  loadCachedPreferences(): void {
    const cache = localStorage.getItem(CARGOFLOW_USER_PREFERENCES);
    if (!!cache) {
      this.ownUser$.next(JSON.parse(cache));
    }
  }

  cacheUserPreferences(user: IDetailedUser): void {
    localStorage.setItem(CARGOFLOW_USER_PREFERENCES, JSON.stringify(user));
  }

  public getOwnUser(): Observable<IDetailedUser> {
    return this.http
      .get<IDetailedUser>(this._urls.OWN_USER)
      .pipe(
        tap(
          (response) => {
            this.ownUser$.next(response);
            this.cacheUserPreferences(response);
          },
          (error) => {
            console.error(error);
          }
        ),
        map((response) => response),
      );
  }

  public updateOwnUser(user: any): Observable<any> {
    return this.http.patch<any>(this._urls.OWN_USER, user)
  }

  public updateOwnAvatar(data: any): Observable<any> {
    return this.http.post<any>(this._urls.OWN_AVATAR, data)
  }

  public getUserKeys(): Observable<IApiToken[]> {
    return this.http.get<IApiToken[]>(this._urls.OWN_KEYS);
  }

  public deleteUserKey(keyUUID: string): Observable<any> {
    return this.http.delete<any[]>(this._urls.OWN_KEYS_BY_UUID(keyUUID));
  }

  public createKey(data: any): Observable<IApiToken> {
    return this.http.post<IApiToken>(this._urls.OWN_KEYS, data);
  }

  public updateKey(keyUUID: string, data: IApiToken): Observable<IApiToken> {
    return this.http.patch<IApiToken>(this._urls.OWN_KEYS_BY_UUID(keyUUID), data);
  }

  public createPushToken(data: any): Observable<any> {
    return this.http.post<any>(this._urls.OWN_PUSH_TOKENS, data);
  }

  public getUserPushTokens(): Observable<IApiToken[]> {
    return this.http.get<IApiToken[]>(this._urls.OWN_PUSH_TOKENS);
  }

  public deleteUserPushToken(token: string): Observable<IApiToken> {
    return this.http.delete<IApiToken>(this._urls.OWN_PUSH_TOKEN(token));
  }

  public getOrgUsers(): Observable<IDetailedUser[]> {
    return this.http.get<IDetailedUser[]>(this._urls.USERS);
  }

  public getOrgUser(userUUID: string): Observable<IDetailedUser> {
    return this.http.get<IDetailedUser>(this._urls.USERS_BY_UUID(userUUID));
  }

  public deleteUser(userUUID: string): Observable<any> {
    return this.http.delete<any>(this._urls.USERS_BY_UUID(userUUID))
  }

  public createUser(user: IUser): Observable<any> {
    return this.http.post<any>(this._urls.USERS, user)
  }

  public updateUser(userUUID: string, user: IDetailedUser): Observable<any> {
    return this.http.patch<any>(this._urls.USERS_BY_UUID(userUUID), user)
  }

  public updateUserPassword(user: IDetailedUser, password: string): Observable<any> {
    return this.http.patch<any>(this._urls.USER_PASSWORD_BY_UUID(user.uuid), password)
  }

}
