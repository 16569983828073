import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { IClient, ClientsService } from 'projects/webapp/app/services/clients.service';
import { IShipment, ShipmentsService } from '../../services/shipments.service';
import { CarrierShipmentsService } from '../../services/carrier-shipments.service';


@Component({
  selector: 'app-client-shipments',
  templateUrl: './client-shipments.component.html',
  styleUrls: ['./client-shipments.component.scss']
})
export class ClientShipmentsComponent implements OnInit {

  @Input() client: IClient | null = null;
  shipments: IShipment [] = []
  isLoading: boolean  = false;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public dialog: MatDialog,
    private carrierShipmentsService: CarrierShipmentsService,
  ) {
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true
      if (this.client?.uuid){
        this.shipments = await lastValueFrom(this.carrierShipmentsService.getClientShipments(this.client.uuid))
        console.log(this.shipments)
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

}
