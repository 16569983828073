<div *ngIf="isLoading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading && !token" class="mt-5 p-4 w-100 text-center">
  <div class="alert alert-danger" role="alert">
    SiteKey not found. Please contact our support for further information.
  </div>
</div>

<div *ngIf="!isLoading && token">
  <div class="container">

    <h1 class="text-center mb-5">
      <span>
        {{'common.record_shipment' | translate}}
      </span>
      <span>
        - {{clientName}}
      </span>
    </h1>

    <div class="row mb-5">
      <div class="col-sm-6 mb-3 mb-sm-0">
        <app-location-form [shipmentForm]="draftShipmentForm" [type]="'pickup'"></app-location-form>
      </div>
      <div class="col-sm-6">
        <app-location-form [shipmentForm]="draftShipmentForm" [type]="'delivery'"></app-location-form>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <ng-container *ngFor="let packetForm of packetItemForms.controls; index as index">
          <div class="mb-5">
            <app-shipment-packets [index]="index" [packetForm]="packetForm"
              [number_of_packets]="packetItemForms.controls.length"
              (deletePacket)="removePacketItem($event)"></app-shipment-packets>
          </div>
        </ng-container>
      </div>
      <div class="text-center mb-2">
        <button class="co-btn-flat-primary" (click)="addPacketItem()">
          {{'carrier_dashboard.add_another_cargo' | translate}}
        </button>
      </div>
    </div>

    <app-extras-form [shipmentForm]="draftShipmentForm"></app-extras-form>

    <div class="mb-5 d-flex justify-content-between">
      <div>
        {{'common.price' | translate}}:
        <span *ngIf="isLoadingPricing">
          <div class="spinner-border spinner-border-sm" role="status">
          </div>
        </span>
        <span *ngIf="!!price && !isLoadingPricing">


          {{ price.total_price | number: '1.2-2'}} Fr.
          <span class="glyphicon glyphicon-pencil"></span>
          <button type="button" class="btn btn-default btn-sm" (click)="openModal(pricing)">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle"
              viewBox="0 0 16 16">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path
                d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z">
              </path>
            </svg>

          </button>
        </span>
        <span *ngIf="!price && !isLoadingPricing">
          -
        </span>
      </div>

      <div>

        <button class="btn btn-secondary me-2" (click)="resetForm()">
          {{'common.reset' | translate}}
        </button>

        <button class="btn btn-primary" (click)="openModal(confirmation)">
          {{'common.next' | translate}}
        </button>
      </div>
    </div>

    <!-- <pre>{{draftShipmentForm.value | json}}</pre> -->

  </div>

  <br>
  <div>
    <div class="pt-6 text-center" *ngIf="tenantName">Licensed to {{tenantName}}</div>

    <div class="text-center">Powered by <a href="https://cargoflow.ch/" style="text-decoration: underline;"
        target="_blank">Cargoflow</a></div>

    <div class="text-center">
      <small class="text-center">v{{versions.date | date: 'yyyyMMdd'}}-{{versions.hash}}</small>
    </div>

  </div>
  <br>

</div>


<ng-template #pricing>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'carrier_dashboard.price_overview' | translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <pre>{{price | json}}</pre> -->
    <table *ngIf="!!price && price" style="width: 100%" class="table table-striped">
      <thead>
        <td>{{'common.position' | translate}}</td>
        <td>{{'common.sum' | translate}}</td>
      </thead>

      <tbody>

        <tr>
          <td>{{'common.transport_price' | translate}}</td>
          <td>CHF {{price.base_price | number: '1.2-2'}}</td>
        </tr>

        <tr *ngFor="let option of price.options">
          <td>{{option.name}}</td>
          <td>CHF {{option.price | number: '1.2-2'}}</td>
        </tr>
        <tr>
          <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.excl_vat' | translate}})</td>
          <td style="font-weight: bold;">CHF {{price.total_price | number: '1.2-2'}}</td>
        </tr>

        <tr>
          <td>{{'common.value_added_tax' | translate}}</td>
          <td>CHF {{price.total_vat | number: '1.2-2'}}</td>
        </tr>

        <tr>
          <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.incl_vat' | translate}})</td>
          <td style="font-weight: bold;">CHF {{price.total_price_with_vat | number: '1.2-2'}}</td>
        </tr>

      </tbody>
    </table>
    <button class="btn btn-primary w-100" (click)="modalRef?.hide()">{{'common.close' | translate}}</button>
  </div>
</ng-template>

<ng-template #confirmation>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{'common.shipment_overview' | translate}}</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <!-- <pre>{{price | json}}</pre> -->

    <form [formGroup]="draftShipmentForm">
      {{'common.from' | translate}}:
      <div class="mb-3">
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Adresse"
          [formControlName]="'pickup' + '_address_complete'" readonly="true">
      </div>
      {{'common.to' | translate}}
      <div class="mb-3">
        <input type="text" class="form-control" id="exampleFormControlInput1" placeholder="Adresse"
          [formControlName]="'delivery' + '_address_complete'" readonly="true">
      </div>
    </form>

    {{'common.at_a_price_of' | translate}}:
    <div class="mb-3">
      <strong>{{'carrier_dashboard.shipment_price' | translate}} ({{'common.excl_vat' | translate}}): CHF {{price?.total_price | number: '1.2-2'}}</strong>
      <br>
      <strong>{{'carrier_dashboard.shipment_price' | translate}} ({{'common.incl_vat' | translate}}): CHF {{price?.total_price_with_vat | number: '1.2-2'}}</strong>
    </div>

    <div class="mt-4 mb-4">
      <small>
        Nach dem abschicken werden wir Ihre Anfrage sofort bearbeiten. Dabei erhalten Sie eine E-Mail mit der
        Auftragsbestätigung.
      </small>
    </div>

    <button class="btn btn-primary w-100" (click)="sendRequest()" [disabled]="isLoadingProcessing">
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
        *ngIf="isLoadingProcessing"></span>
      <span *ngIf="!isLoadingProcessing">
        Sendung übermitteln
      </span>
      <span *ngIf="isLoadingProcessing">
        {{'common.in_process' | translate}}
      </span>
    </button>
  </div>
</ng-template>

<ng-template #successModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Erfolgreich übermittelt</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="mt-4 mb-4">
      <small>
        Ihre Sendung wurde übermittelt. Sie erhalten bald eine E-Mail von uns.
      </small>

    </div>
    <div class="mt-4 mb-4">
      <small *ngIf="shipment">
        Die Sendungsnummer ist {{shipment.number | shipmentNumberPipe}}, diese können sie auch hier verfolgen <a
          href="{{environment.uiDomain}}/public/shipment-tracking/{{shipment.tracking_uuid}}"
          target="_blank">{{environment.uiDomain}}/public/shipment-tracking/{{shipment.tracking_uuid}}.</a>
      </small>
    </div>

    <button class="btn btn-primary w-100" (click)="closeSuccess()">
      {{'common.close' | translate}}
    </button>
  </div>
</ng-template>
