import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum ShipmentMarketStatus {
  UNKNOWN = 0,
  OPEN = 1,
  CLOSED = 2,
}

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'marketStatusPipe' })
export class MarketStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(status: number | string): string {
    switch (status) {
      case ShipmentMarketStatus.UNKNOWN:
        return this.translate.instant('common.unknown');
      case ShipmentMarketStatus.OPEN:
        return this.translate.instant('common.in_progress');
      case ShipmentMarketStatus.CLOSED:
        return this.translate.instant('common.completed');
      default:
        return '-'
    }
  }
}
