import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { versions } from 'projects/versions';
import { SwUpdate } from '@angular/service-worker';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  versions = versions;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    private title: Title,
    private translate: TranslateService,
    private updates: SwUpdate,
  ) {
    this.translate.get('about_title').subscribe(val=>{
      this.title.setTitle(val);
    })
  }

  async ngOnInit() {
  }

  async checkUpdates() {
    if (this.updates.isEnabled) {
      const hasUpdate = await this.updates.checkForUpdate();
      if (hasUpdate) {
        if (confirm('There is a new version of this app, you want to refresh now?') === true) {
          this.updates.activateUpdate().then(() => {
            document.location.reload();
          });
        } else {
          console.log('not performing update');
        }
      } else {
        alert('You are running the latest version');
      }
    }
  }
}
