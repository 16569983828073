<mat-card>
    <mat-card-title>{{'common.email_validation' | translate}}</mat-card-title>
    <mat-card-content>
        <div class="pt-2">

            <div class="alert alert-info">
                {{'common.email_is_being_validated_please_wait' | translate}}
            </div>

            <div style="display: flex; justify-content: center;">
                <small style="color: gray;">v{{versions.hash}} - {{versions.date | date: "yyyy-MM-dd HH:mm"}}</small>
            </div>

        </div>
    </mat-card-content>
</mat-card>