import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { ClientEditorComponent } from 'projects/webapp/app/components/client-editor/client-editor.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { IClient, ClientsService } from 'projects/webapp/app/services/clients.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-client-overview',
  templateUrl: './client-overview.component.html',
  styleUrls: ['./client-overview.component.scss']
})
export class ClientsOverviewComponent implements OnInit {

  clients: IClient[] = [];

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public dialog: MatDialog,
    private router: Router,
    private title: Title,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.client_overview').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    await this.refresh();
  }

  async refresh() {
    try {
      this.spinnerService.show();
      this.clients = await lastValueFrom(this.clientService.getClients())
      console.log(this.clients)
    } catch (error) {
      console.error(error);
    } finally {
      this.spinnerService.hide();
    }
  }

  editClient(client: IClient) {
    this.dialog.open(ClientEditorComponent, { data: client }).afterClosed().subscribe(async val => {
      if (!!val && client.uuid) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.client_edited'));

        await lastValueFrom(this.clientService.updateClient(client.uuid, val));
        this.toastrService.success(successMessage);
        this.refresh()
      }
    })
  }

  onAddClient() {
    const client: IClient = {
      company: '',
      first_name: '',
      last_name: '',
      address: '',
      zip_code: '',
      location: '',
      description: '',
      phone: '',
      email: '',
    }
    this.dialog.open(ClientEditorComponent, { data: client }).afterClosed().subscribe(async val => {
      if (!!val) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.client_created'));

        await this.clientService.createClient(val).toPromise();
        this.toastrService.success(successMessage);
        this.refresh()
      }
    })
  }

  async deleteClient(client: IClient) {
    const confirmationMessage = await lastValueFrom(this.translateService.get('toastr.confirm_client_deletion'));

    if (confirm(confirmationMessage) && client.uuid) {
      await lastValueFrom(this.clientService.deleteClient(client.uuid))
      this.refresh();
    }
  }

  openDetailsClient(client: IClient) {
    this.router.navigate([`/main/carrier/clients/${client.uuid}/details`])
  }
}
