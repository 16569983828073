<div class="container">

    <div class="my-4 text-center w-100" style="width: 100%; overflow-x: auto; white-space: nowrap; min-height: 60px; padding-top: 20px; scrollbar-width: none;">

        <!-- <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/pricing" routerLinkActive="active-btn">{{'common.prices' | translate}}</a>
        <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/surcharges" routerLinkActive="active-btn">{{'common.surcharges' | translate}}</a> -->

        <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/organization" routerLinkActive="active-btn">{{'common.organization' | translate}}</a>
        <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/users" routerLinkActive="active-btn">{{'common.users' | translate}}</a>

        <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/fleet" routerLinkActive="active-btn">{{'carrier_dashboard.user-editor-fleet.main-title' | translate}}</a>
        <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/hubs" routerLinkActive="active-btn">{{'common.hubs' | translate}}</a>

        <!-- <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/api" routerLinkActive="active-btn">{{'common.interface' | translate}}</a>
        <a class="light-btn primary-hover-animation mx-2" routerLink="/main/carrier/settings/plugin" routerLinkActive="active-btn">{{'common.plugin' | translate}}</a> -->

    </div>
    <router-outlet></router-outlet>

</div>
