import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { versions } from 'projects/versions';


@Injectable()
export class AppVersionInterceptor implements HttpInterceptor {
  constructor(
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestWithVersion = request.clone({
      setHeaders: {
        'x-app-name': 'webapp',
        'x-app-build-date': versions.date,
        'x-app-hash': versions.hash
      }
    });
    return next.handle(requestWithVersion);
  }
}
