<mat-form-field appearance="outline" style="width: 100%" *ngIf="!!possibleOrganizations && possibleOrganizations.length > 0">
    <mat-label>{{'common.organization' | translate}}</mat-label>
    <input type="text" matInput [(ngModel)]="selectedOrganization" [matAutocomplete]="auto" [formControl]="organizationFormControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
        (optionSelected)='organizationSelected($event.option.value)'>
        <mat-option *ngFor="let option of filteredOrganizations" [value]="option">
            {{getOrganizationName(option)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>
