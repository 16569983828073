import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IDetailedTour, TourService, TourStatus } from '../../services/tour.service';


@Component({
  selector: 'app-tour-selector',
  templateUrl: './tour-selector.component.html',
  styleUrls: ['./tour-selector.component.scss']
})
export class TourSelectorComponent implements OnInit {

  @Input() filter: TourStatus[] = []

  @Input() selectedTour: IDetailedTour | undefined = undefined;

  possibleTours: IDetailedTour[] = [];
  filteredTours: IDetailedTour[] = []
  userFormControl = new FormControl('')

  @Output() onSelectedTour = new EventEmitter<IDetailedTour>()

  constructor(
    public tourService: TourService,
    public dialogRef: MatDialogRef<TourSelectorComponent>,
  ) {
    this.userFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe(val => {
      this.filteredTours = val;
    });
  }

  async ngOnInit() {
    // HACK:
    if (this.filter.includes(TourStatus.IN_PROGRESS) ){
      this.possibleTours = await lastValueFrom(this.tourService.getOpenTours())
    } else {
      this.possibleTours = await lastValueFrom(this.tourService.getTours())
    }
  }

  getTitle(user: IDetailedTour) {
    console.log(`getTitle(${user?.uuid}`)
    const filtered = this.possibleTours.find(p => p.uuid === user?.uuid)
    if (filtered) {
      return `${filtered.vehicle_object?.number} ${filtered.date}`
    }
    return '';
  }

  private _filter(value: string): IDetailedTour[] {
    console.log(value)
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.possibleTours.filter(tour => {
        return tour.vehicle_object?.number.toLowerCase().includes(filterValue)
      });
    }
    return []
  }

  selected(client: IDetailedTour) {
    this.onSelectedTour.emit(client);
  }

  getOptionName(tour: IDetailedTour) {
    return `${tour.id}: ${tour.vehicle_object?.number} ${tour.date}`
  }
}
