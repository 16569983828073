<div class="container-fluid">
    <mat-card class="mt-3" *ngIf="client">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'common.client' | translate}}:
                    <span *ngIf="client?.company; else noCompany">{{client.company}}</span>
                    <ng-template #noCompany>{{client.first_name}} {{client.last_name}}</ng-template>
                </h1>

            </div>
            <div>
                <nav class="nav nav-pills nav-justified d-flex justify-content-around my-3">
                    <a class="btn-primary dark primary-hover-animation w-25" [routerLinkActive]="['active']"
                        routerLink="/main/carrier/clients/{{client.uuid}}/details">{{'common.details' | translate}}</a>
                    <a class="btn-primary dark primary-hover-animation w-25" [routerLinkActive]="['active']"
                        routerLink="/main/carrier/clients/{{client.uuid}}/shipments">{{'common.shipments' | translate}}</a>
                    <a class="btn-primary dark primary-hover-animation w-25" [routerLinkActive]="['active']"
                        routerLink="/main/carrier/clients/{{client.uuid}}/integrations">{{'common.integrations' | translate}}</a>
                </nav>
                <app-client-shipments [client]="client"></app-client-shipments>

            </div>
        </mat-card-content>
    </mat-card>
</div>