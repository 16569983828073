import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientsService, IClient } from '../../services/clients.service';


@Component({
  selector: 'app-client-selector-modal',
  templateUrl: './client-selector.component.html',
})
export class ClientSelectorModalComponent {
  
  constructor(
    public clientsService: ClientsService,
    public dialogRef: MatDialogRef<ClientSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IClient,
    ) {
    }

    onSelectedClient(event: IClient) {
      this.data = event;
    }
}
