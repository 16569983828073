<div class="container">

    <mat-card class="mt-3">
        <mat-card-content>

            <div style="display: flex; flex-direction: row; justify-content: space-between;">

                <div class="d-flex gap-3">
                    <h1>{{'common.user_details' | translate}}</h1>
                    <a routerLink="/main/carrier/settings/users" mat-icon-button color="primary" (click)="refresh()">
                        <mat-icon>keyboard_backspace</mat-icon>
                    </a>
                </div>
                <div>

                    <button mat-icon-button color="primary" (click)="refresh()">
                        <mat-icon>refresh</mat-icon>
                    </button>
                </div>
            </div>

            <mat-tab-group mat-stretch-tabs="true" mat-align-tabs="center" class="mt-1 mb-1">
                <mat-tab [label]="'common.details' | translate">

                    <ul class="co-simple-list mt-3" *ngIf="user">
                        <li><strong>{{'common.first_name' | translate}}:</strong> {{user.first_name}}</li>
                        <li><strong>{{'common.last_name' | translate}}:</strong> {{user.last_name}}</li>
                        <hr>
                        <li><strong>{{'common.phone' | translate}}: </strong> <a href="tel:{{user.phone}}">{{user.phone}}</a></li>
                        <hr>
                        <li><strong>{{'common.created' | translate}}:</strong> {{user.created_at | humanDatePipe}}</li>
                        <li><strong>{{'common.last_updated' | translate}}:</strong> {{user.updated_at | humanDatePipe}}</li>
                        <li><strong>{{'common.insured' | translate}}:</strong> {{user.last_login | humanDatePipe}}</li>
                    </ul>
                    <!-- <pre>{{user | json}}</pre> -->

                </mat-tab>
                <mat-tab [label]="'common.settings' | translate">
                    <div *ngIf="user" class="pt-2">
                        <app-user-editor-form [user]="user" (onFormSubmit)="onFormSubmit($event)"></app-user-editor-form>
                    </div>
                </mat-tab>
                <mat-tab [label]="'common.shipments' | translate">
                    <div style="display: flex; flex-direction: row; justify-content: end;">
                        <div>
                            <button mat-icon-button color="primary" (click)="refresh()">
                                <mat-icon>refresh</mat-icon>
                            </button>
                        </div>
                    </div>

                    <div *ngIf="!!userShipments && userShipments.length == 0" class="alert alert-info mt-3">
                        {{'common.no-shipments' | translate}}
                    </div>

                    <table *ngIf="!!userShipments && userShipments.length > 0" style="width: 100%"
                        class="table table-striped">
                        <thead class="thead-light">
                            <td>{{'common.number' | translate}}</td>
                            <td>{{'common.status'| translate}}</td>
                            <td>{{'common.location'| translate}}</td>
                        </thead>

                        <tbody>

                            <tr *ngFor="let shipment of userShipments">
                                <td>
                                    <a [routerLink]="'/main/carrier/shipments/' + shipment.uuid">
                                        {{shipment.number | shipmentNumberPipe}}
                                    </a>
                                </td>
                                <td>
                                    {{shipment.status | shipmentStatusPipe }}
                                </td>
                                <td>
                                    {{shipment.current_loc | shipmentCurrentLocationStatusPipe }}
                                </td>

                            </tr>

                        </tbody>
                    </table>

                    <!-- <pre>{{clientShipments | json}}</pre> -->
                </mat-tab>

            </mat-tab-group>

        </mat-card-content>
    </mat-card>

</div>
