<h1 mat-dialog-title class="fs-3 text-center mb-2">
  {{'shipment_edit.surcharge.edit' | translate }}
</h1>
<div mat-dialog-content>
  <table *ngIf="!!data && data" style="width: 100%" class="table table-striped">
    <thead>
      <td>{{'common.position'| translate}}</td>
      <td>{{'common.amount'| translate}}</td>
    </thead>

    <tbody>

      <tr>
        <td>
          <input [(ngModel)]="data.name">
        </td>
        <td>{{ownOrganization?.default_currency}}
          <input type="number" [(ngModel)]="data.price" (keyup)="onChange()">
        </td>
      </tr>

      <tr>
        <td>{{ 'common.transport_price' | translate }}</td>
        <td>{{ownOrganization?.default_currency}} {{data.transaction.base_price | pricePipe}}
        </td>
      </tr>

      <tr *ngFor="let option of data.transaction.options">
        <td>{{option.name}}</td>
        <td>{{ownOrganization?.default_currency}}
          {{option.price | pricePipe}}
        </td>
      </tr>
      <tr>
        <td style="font-weight: bold;">{{'carrier_dashboard.shipment_price' | translate}} ({{'common.excl_vat' | translate}})</td>
        <td style="font-weight: bold;">{{ownOrganization?.default_currency}} {{total_price | number: '1.2-2'}}</td>
      </tr>

    </tbody>
  </table>

  <!-- <pre>{{data | json}}</pre> -->
</div>

<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close'| translate}}</button>
  <button class="btn-primary green primary-hover-animation" [mat-dialog-close]="data">{{'common.save'|translate}}</button>
</div>
