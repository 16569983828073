import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SiteKeyEditorComponent } from 'projects/webapp/app/components/site-key-editor/site-key-editor.component';
import { environment } from 'projects/webapp/app/environments/environment';
import { ISiteKey, OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-settings-platform-pricing',
  templateUrl: './settings-platform-pricing.component.html',
  styleUrls: ['./settings-platform-pricing.component.scss']
})
export class SettingsPlatformPricingComponent implements OnInit {
 
  constructor(
    public organizationService: OrganizationsService,
    public usersService: UsersService,
    public dialog: MatDialog,
  ) {}

  async ngOnInit() {
  }
  
 

}
