import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmbedUrlConstantsService } from './embed-url-constants.service';


@Injectable({ providedIn: 'root' })
export class EmbedTrackTraceService {

  public siteKey = '';

  constructor(
    private http: HttpClient,
    private urls: EmbedUrlConstantsService,
  ) {}

  public search_shipment_by_number(search: any): Observable<any> {
    return this.http.post<any>(this.urls.SEARCH_SHIPMENT, search );
  }
 
}
