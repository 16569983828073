import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminUrlConstantsService } from './admin-url-constants.service';
import { Params } from '@angular/router';
import { IAdminOrganization, IOrganization } from '../interfaces/organization.interface';
import { IAdminDetailedUser } from '../interfaces/user.interface';
import { IAdminShipmentAuction } from '../interfaces/auction.interface';
import { IAdminShipmentTransaction } from '../interfaces/transaction.interface';


@Injectable({ providedIn: 'root' })
export class AdminService {

  constructor(
    private http: HttpClient,
    private urls: AdminUrlConstantsService,
  ) { }

  getDashboardNumbers(): Observable<any> {
    return this.http.get<any>(this.urls.DASHBOARD_NUMBERS);
  }

  getOrganizations(): Observable<never[]> {
    return this.http.get<any>(this.urls.ORGANIZATIONS);
  }

  getOrganization(organizationUUID: string): Observable<IAdminOrganization> {
    return this.http.get<IAdminOrganization>(this.urls.ORGANIZATIONS_BY_UUID(organizationUUID));
  }

  updateOrganization(organizationUUID: string, data: IAdminOrganization): Observable<IAdminOrganization> {
    return this.http.patch<IAdminOrganization>(this.urls.ORGANIZATIONS_BY_UUID(organizationUUID), data);
  }

  getComments(organizationUUID: string, params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.urls.COMMENTS_FOR_ORGANIZATIONS_BY_UUID(organizationUUID), { params });
  }

  addComment(organizationUUID: string, data: Params = {}): Observable<any> {
    return this.http.post<any>(this.urls.COMMENTS_FOR_ORGANIZATIONS_BY_UUID(organizationUUID), data );
  }

  getUsers(params: Params = {}): Observable<IAdminDetailedUser[]> {
    return this.http.get<IAdminDetailedUser[]>(this.urls.USERS, { params });
  }

  getUser(userUUID: string): Observable<IAdminDetailedUser> {
    return this.http.get<IAdminDetailedUser>(this.urls.USERS_BY_UUID(userUUID));
  }

  updateUser(userUUID: string, data: IAdminDetailedUser): Observable<IAdminDetailedUser> {
    return this.http.patch<IAdminDetailedUser>(this.urls.USERS_BY_UUID(userUUID), data);
  }

  impersonateOrganizationUser(organizationUUID: string, ): Observable<any> {
    return this.http.post<any>(this.urls.IMPERSONATE_ORGANIZATIONS_BY_UUID(organizationUUID), {});
  }

  getShipment(shipmentUUID: string): Observable<any> {
    return this.http.get<any>(this.urls.SHIPMENTS_BY_UUID(shipmentUUID));
  }

  getShipments(): Observable<never[]> {
    return this.http.get<any>(this.urls.SHIPMENTS);
  }

  searchShipments(params: Params) {
    return this.http.get<any>(this.urls.SHIPMENT_SEARCH, { params })
  }

  getAuctions(params: Params = {}): Observable<IAdminShipmentAuction[]> {
    return this.http.get<IAdminShipmentAuction[]>(this.urls.AUCTIONS, { params });
  }

  getTransactions(params: Params = {}): Observable<IAdminShipmentTransaction[]> {
    return this.http.get<IAdminShipmentTransaction[]>(this.urls.TRANSACTIONS, { params });
  }

  getActivity(params: Params = {}): Observable<any[]> {
    return this.http.get<any[]>(this.urls.EVENTS, { params });
  }


}
