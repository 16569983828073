import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IUser } from '../../services/users.service';
import { AuthService } from '../../services/auth.service';
import { IHub } from '../../services/organizations.service';

 
 

@Component({
  selector: 'app-hub-editor',
  templateUrl: './hub-editor.component.html',
  styleUrls: ['./hub-editor.component.scss']
})
export class HubEditorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HubEditorComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: IHub,
  ) { }

  async ngOnInit() {
  }
 
}
