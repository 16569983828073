<h1 mat-dialog-title>
  <h3  >Tours for all your vehicles</h3>
</h1>
<div mat-dialog-content>
  <div class="mt-2">

    <form [formGroup]="form">

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.date' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

    </form>

  </div>

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button mat-button color="primary" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>
