import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { AdvertisementsService } from '../../services/advertisement.service';

export interface IAdvertisementAddImageData {
  advertisementUUID: string
}

@Component({
  selector: 'app-advertisement-add-image-modal',
  templateUrl: './advertisement-add-image-modal.component.html',
  styleUrls: ['./advertisement-add-image-modal.component.scss']
})
export class AdvertisementAddImageModalComponent implements OnInit {
  isLoading = false;

  constructor(
    public dialogRef: MatDialogRef<AdvertisementAddImageModalComponent>,
    private advertisementsService: AdvertisementsService,
    @Inject(MAT_DIALOG_DATA) public data: IAdvertisementAddImageData,
  ) { }

  async ngOnInit() { }

  async onFileSelected(event: any) {
    console.log('onFileSelected');
    try {
      this.isLoading = true;
      const file: File = event.target.files[0];
      if (file && this.data.advertisementUUID) {
        const fileName = file.name;
        const formData = new FormData();
        formData.append("file", file);
        formData.append('name', fileName);
        const result = await lastValueFrom(this.advertisementsService.addImage(this.data.advertisementUUID, formData));
        console.log(result);
        this.isLoading = false;
        if (this.dialogRef) {
          this.dialogRef.close(true)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }
}
