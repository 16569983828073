import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { formatRelative, subDays } from 'date-fns'
import { IShipment, ShipmentsService } from 'projects/webapp/app/services/shipments.service';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-admin-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class AdminActivityComponent implements OnInit {
  public isLoading = false;
  events: any[] = []

  constructor(
    private title: Title,
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.translate.get('common.admin_activity').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
    this.refresh()
  }

  async refresh() {
    this.spinnerService.show()
    this.events = await lastValueFrom(this.adminService.getActivity())
    this.spinnerService.hide()
  }

}
