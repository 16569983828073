import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { IUser, UsersService } from 'projects/webapp/app/services/users.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  ownUser: IUser | null = null;

  tokens: any[]= [];

  form = new FormGroup({
    username: new FormControl(''),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    email: new FormControl(''),
    phone: new FormControl('', Validators.required),
    avatardocument: new FormControl(''),

    preference_show_shipments_to_pickup: new FormControl(true, Validators.required),
    preference_show_shipments_in_vehicle: new FormControl(true, Validators.required),
    preference_show_shipments_to_deliver: new FormControl(true, Validators.required),

    preference_show_market_place: new FormControl(true, Validators.required),
    preference_show_facturation: new FormControl(true, Validators.required),
    preference_show_activity: new FormControl(true, Validators.required),

  });
  userName: string = '';

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    private title: Title,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.translate.get('carrier_dashboard.profile.page_title').subscribe(val =>{
      this.title.setTitle(val)
    })
    this.userName = this.authService.getUsername() || ''
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
    this.spinnerService.show()
    this.ownUser = await this.usersService.getOwnUser().toPromise() as any;
    if (this.ownUser){

      if (!!this.ownUser.avatardocument_object && this.ownUser.avatardocument_object.file){
        this.ownUser.avatardocument = this.ownUser.avatardocument_object.file;
      }
      this.form.patchValue(this.ownUser);
    }

    this.tokens = await lastValueFrom(this.usersService.getUserPushTokens())

    this.spinnerService.hide()
  }

  async submit() {
    const response = await this.usersService.updateOwnUser(this.form.value).toPromise()
    console.log(response);
    this.translate.get('toastr.profile_updated').subscribe((translatedMessage: string) => {
      this.toastrService.success(translatedMessage);
    });
    await this.refresh()
  }

}
