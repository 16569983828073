export const isDatesValidByFilter = (shipment: any, filtersValue: any): boolean => {
  const pickupDate = shipment.pickup_date ? new Date(formatDate(shipment.pickup_date)) : null;
  const pickupDateStart = filtersValue.pickupDateStart ? new Date(filtersValue.pickupDateStart) : null;
  const pickupDateEnd = filtersValue.pickupDateEnd ? new Date(filtersValue.pickupDateEnd) : null;
  if (!isDatesValidByRange(pickupDate, pickupDateStart, pickupDateEnd)) return false;

  const deliveryDate = shipment.delivery_date ? new Date(formatDate(shipment.delivery_date)) : null;
  const deliveryDateStart = filtersValue.deliveryDateStart ? new Date(filtersValue.deliveryDateStart) : null;
  const deliveryDateEnd = filtersValue.deliveryDateEnd ? new Date(filtersValue.deliveryDateEnd) : null;
  return isDatesValidByRange(deliveryDate, deliveryDateStart, deliveryDateEnd);
}

export const hasTourByFilter = (shipment: any, filtersValue: any): boolean => {
  if (filtersValue.hasNoTour) {
    return shipment.assigned_tour_object == undefined;
  }
  return true;
}

export const formatDate = (date: Date | string ): string => {
  // const pipe = new
  if (date instanceof Date) {
    return date.toISOString().split('T')[0]
  }
  // return '';
  return date
}
export const isDatesValidByRange = (date: Date | null, dateStart: Date | null, dateEnd: Date | null): boolean => {
  // console.log('----')

  let date_normalized = null;
  if (date) {
    // console.log('date: ' + date)
    date_normalized = getDateWithoutTimezone(date);
  }

  let date_start_normalized = null;
  if (dateStart) {
    // console.log('dateStart: ' + dateStart)
    date_start_normalized = getDateWithoutTimezone(dateStart);
  }

  let date_end_normalized = null;
  if (dateEnd) {
    // console.log('dateEnd: ' + dateEnd)
    date_end_normalized = getDateWithoutTimezone(dateEnd);
  }

  if (!date_normalized) return false;
  if (!date_end_normalized && !date_start_normalized) return true;
  if (date_start_normalized && date_start_normalized > date_normalized) return false;
  if (date_end_normalized && date_end_normalized < date_normalized) return false;
  if (date_start_normalized == date_end_normalized && date_normalized == date_start_normalized) {
    return true;
  }

  return true;
}

export const getDateWithoutTimezone = (date: any) => {
  let normalizedDate = null;

  const date_start_year = date.getFullYear();
  const date_start_month = date.getMonth();
  const date_start_day = date.getDate();
  // console.log(`${date_start_year}-${date_start_month}-${date_start_day}`)
  normalizedDate = new Date(Date.UTC(date_start_year, date_start_month, date_start_day)).toISOString().split('T')[0];

  // console.log('normalizedDate: ' + normalizedDate)
  normalizedDate = normalizedDate ? Date.parse(normalizedDate) : null;
  return normalizedDate;
}
