import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../environments/environment';



export interface IMapboxLocation {
  attribution: string;
  features: IMapboxLocationFeature[];
  query: string[];
  type: string;
}

export interface IMapboxLocationFeature {
  bbox: number[];
  center: [number, number];
  context: { id: string; text: string; wikidata: string; short_code?: string }[];
  geometry: { type: string; coordinates: [number, number] };
  id: string;
  place_name: string;
  place_type: [string];
  properties: { wikidata?: string };
  relevance: number;
  text: string;
  type: string;
}

@Injectable({
  providedIn: 'root'
})
export class MapBoxService {

  constructor(
    private http: HttpClient,
  ) {
  }

  public getLocationByLocationNameAndPostCode(address: string, postCode: string): Observable<IMapboxLocation> {
    const params = {
      access_token: environment.mapboxToken,
      country: 'ch',
      postcode: postCode,
      types: 'place'
    };
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${address}.json`;
    return this.http.get<IMapboxLocation>(url, { params });
  }
 

}
