import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicShipmentsService } from 'projects/webapp/app/services/public-shipments.service';
import { MatDialog } from '@angular/material/dialog';
import { IDetailedShipment } from '../../interfaces/shipment.interface';
import { PanelType } from '../../interfaces/panel-types.interface';


@Component({
  selector: 'app-shipment-address',
  templateUrl: './shipment-address.component.html',
  styleUrls: ['./shipment-address.component.scss']
})
export class ShipmentAddressComponent implements OnInit {

  @Input() shipment: IDetailedShipment | null = null;
  @Input() editable: boolean = true
  @Input() type: PanelType = 'carrier';
  isLoading = false;

  constructor(
    public publicShipmentsService: PublicShipmentsService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
  }

  openInGoogleMaps(arg: 'pickup' | 'delivery') {
    let url = ''
    if (arg == 'pickup') {
      url = 'http://maps.google.com/?q=' + `${this.shipment?.pickup_address}, ${this.shipment?.pickup_zip} ${this.shipment?.pickup_location}`;
    } else {
      url = 'http://maps.google.com/?q=' + `${this.shipment?.delivery_address}, ${this.shipment?.delivery_zip} ${this.shipment?.delivery_location}`;
    }
    window.open(url, '_blank');
  }

}
