import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { lastValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { InvoiceStatus } from 'projects/webapp/app/interfaces/invoice.interface';
import { InvoicesService } from 'projects/webapp/app/services/invoices.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';


@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class FacturationComponent implements OnInit {
  isLoading = true;
  invoiceStatus = InvoiceStatus

  clientShipments: {
    client_object: {
      uuid: string;
      name?: string;
      company: string;
      first_name: string
      last_name: string;
    },
    client_total_price: number;
    shipments: IShipment[]
  }[] = [];

  shipments: IShipment[]= []

  ownOrganization: IOrganization | null = null;

  constructor(
    public spinnerService: NgxSpinnerService,
    private title: Title,
    private carrierShipmentsService: CarrierShipmentsService,
    private toastrService: ToastrService,
    private invoiceService: InvoicesService,
    private translate: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.translate.get('carrier_dashboard.invoicing').subscribe((res: string) => {
      this.title.setTitle(`Cargoflow: ${res}`);
    });
  }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    await this.refresh()
  }

  async refresh(){
    this.shipments = await lastValueFrom(this.carrierShipmentsService.getShipmentsReadyForInvoicing())
    this.sortByClient()
  }

  // onAddItem() {
  //   this.router.navigate(['/main/carrier/facturation/invoice-editor'])
  // }

  sortByClient() {
    // make client shipments empty
    this.clientShipments = []
    // iterate over the list of shipments
    this.shipments.forEach(el => {
      console.log(el)
      // if we have already this shipments client in the array => add this shipment to it
      const found = this.clientShipments.find(p => p.client_object.uuid === el.client_object?.uuid)
      if (found) {
        found.shipments.push(el);
        found.client_total_price += el.total_price;
      } else {
        if (el.client_object) {
          this.clientShipments.push({
            client_object: el.client_object,
            shipments: [el],
            client_total_price: el.total_price
          });
        }
      }
    });
  }

  async invoiceClient(client: any) {
    const confirmationMessage = await lastValueFrom(this.translate.get('common.create_invoice_client', { name: client.company }));
    const successMessage = await lastValueFrom(this.translate.get('common.invoice_created'));

    if (confirm(confirmationMessage)){
      await lastValueFrom(this.invoiceService.createInvoiceForShipments(client.uuid));
      this.toastrService.success(successMessage);
      await this.refresh();
    }
  }

}
