import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-change-language-modal',
  templateUrl: './change-language-modal.component.html',
  styleUrls: ['./change-language-modal.component.scss']
})
export class ChangeLanguageModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ChangeLanguageModalComponent>,
    public toastrService: ToastrService,
    public translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  async ngOnInit() {
  }

  changeLanguage() {
    this.toastrService.info('reloading...');
    this.toastrService.info(this.translate.instant('common.language_changed'));
    setTimeout(() => {
      document.location.reload();
    }, 800);
  }
}
