import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { OrganizationsService } from '../../services/organizations.service';
import { IOrganization } from '../../interfaces/organization.interface';


@Component({
  selector: 'app-organization-selector',
  templateUrl: './organization-selector.component.html',
  styleUrls: ['./organization-selector.component.scss']
})
export class OrganizationSelectorComponent implements OnInit {

  @Input() selectedOrganization: IOrganization | undefined | null = undefined;
  @Input() selectedOrganizationUUID: string | undefined | null = null;
  @Input() filterOrganization: 'carrier' | 'shipper' | null = null
  @Input() withSelf = true

  possibleOrganizations: IOrganization[] = [];
  filteredOrganizations: IOrganization[] = []
  organizationFormControl = new FormControl('')

  @Output() onSelectedOrganization = new EventEmitter<IOrganization>()

  constructor(
    public organizationsService: OrganizationsService
  ) {
    this.organizationFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe(val => {
      this.filteredOrganizations = val;
    });
  }

  async ngOnInit() {
    this.possibleOrganizations = await lastValueFrom(this.organizationsService.getOrganizations({
      type: this.filterOrganization,
      withSelf: this.withSelf
    }))
    if (!!this.selectedOrganizationUUID) {
      this.selectedOrganization = this.possibleOrganizations.find((c) => c.uuid === this.selectedOrganizationUUID)
      this.onSelectedOrganization.emit(this.selectedOrganization)
    }
  }

  getTitle(organization: IOrganization) {
    console.log(`getTitle(${organization?.uuid}`)
    const filteredOrganization = this.possibleOrganizations.find(p => p.uuid === organization?.uuid)
    if (filteredOrganization) {
      if (!!filteredOrganization.company_name) {
        return filteredOrganization.company_name
      }
      return `${filteredOrganization.first_name} ${filteredOrganization.last_name}`
    }
    return '';
  }

  private _filter(value: string): IOrganization[] {
    console.log(value)
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.possibleOrganizations.filter(organization => {
        return (!!organization.company_name && organization.company_name.toLowerCase().includes(filterValue))
      });
    }
    return []
  }

  organizationSelected(organization: IOrganization) {
    this.onSelectedOrganization.emit(organization);
  }

  getOrganizationName(organization: IOrganization) {
    let name = `${organization.first_name} ${organization.last_name}`
    if (organization.company_name) {
      name = `${organization.company_name}`
    }

    if (organization.location) {
      name = name + ` von ${organization.location}`
    }
    return name;
  }
}
