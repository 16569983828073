<div class="text-center mt-5" *ngIf="!isLoading && shipments.length == 0">
    <div class="alert alert-info">{{'common.no_shipments_yet' | translate}}</div>
</div>

<table class="table table-custom" style="width: 100%" *ngIf="!!shipments && shipments.length > 0">
    <thead>
        <td>{{'common.number' | translate}}</td>
        <td>{{'common.from' | translate}}</td>
        <td >{{'common.to' | translate}}</td>
        <td>{{'common.status' | translate}}</td>
    </thead>

    <tbody>
        <tr *ngFor="let shipment of shipments">
            <td [attr.data-label]="'common.number' | translate">
                <a routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                    {{shipment.number | shipmentNumberPipe }}
                </a>
            </td>
            <td [attr.data-label]="'common.from' | translate">
                {{shipment.pickup_address }}
                <br>
                {{shipment.pickup_zip }} {{shipment.pickup_location }}
                <br>
                {{shipment.pickup_date }}
            </td>

            <td [attr.data-label]="'common.to' | translate">
                {{shipment.delivery_address }}
                <br>
                {{shipment.delivery_zip }} {{shipment.delivery_location }}
                <br>
                {{shipment.delivery_date }}
            </td>
            <td [attr.data-label]="'common.status' | translate">
                {{shipment.status | shipmentStatusPipe}}
            </td>
        </tr>
    </tbody>
</table>
