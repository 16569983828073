<h1 mat-dialog-title>
  <span *ngIf="!data.token">{{'common.create_sitekey' | translate}}</span>
  <span *ngIf="!!data.token">{{'common.edit_sitekey' | translate}}</span>
</h1>
<div mat-dialog-content>

  <mat-form-field appearance="outline" style="width: 100%" class="mt-2">
    <mat-label>{{'common.name' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.name" required>
  </mat-form-field>

  <div>
    <mat-checkbox [(ngModel)]="data.active">
      {{'common.active' | translate}}
    </mat-checkbox>
  </div>

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" [mat-dialog-close]="data" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>