<div class="container">

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'common.pickup_information' | translate}}</h1>
            </div>
            <form [formGroup]="shipmentForm" (ngSubmit)="save()">

                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" [id]="type + '_company'"
                            [formControlName]="type + '_company'">
                        <label [for]="type + '_company'" class="form-label" translate>common.firma</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" [id]="type + '_name'"
                            [formControlName]="type + '_name'">
                        <label [for]="type + '_name'" class="form-label">{{'common.name' | translate}}</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" [id]="type + '_address'"
                            [formControlName]="type + '_address'">
                        <label [for]="type + '_address'" class="form-label" translate>common.address</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" [id]="type + '_zip'" [formControlName]="type + '_zip'">
                        <label [for]="type + '_zip'" class="form-label">{{'common.postcode' | translate}}</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" [id]="type + '_location'"
                            [formControlName]="type + '_location'">
                        <label [for]="type + '_location'" class="form-label" translate>common.locality</label>
                    </div>
                </div>

                <div class="mb-3">
                    <div class="form-floating">
                        <input class="form-control" type="text" [id]="type + '_reference'"
                            [formControlName]="type + '_reference'">
                        <label [for]="type + '_reference'" class="form-label">{{'common.reference' | translate}}</label>
                    </div>
                </div>

                <div class="mb-3">
                    <input class="form-control" type="date" placeholder="Abholdatum"
                        [formControlName]="type + '_date'" />
                </div>

                <div class="mb-3">
                    <select class="form-select">
                        <option value="1" selected>{{'carrier_dashboard.during_the_day' | translate}}</option>
                        <option value="2">{{'carrier_dashboard.until_midday' | translate}}</option>
                        <option value="3">{{'carrier_dashboard.in_the_afternoon' | translate}}</option>
                    </select>
                </div>

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="notification_{{type}}"
                        [formControlName]="type + '_notification'">
                    <label class="form-check-label" for="notification_{{type}}">
                        {{'common.notification' | translate}}
                    </label>
                </div>

                <div class="mb-3" *ngIf="shipmentForm.value.pickup_notification">
                    <div class="form-floating">
                        <input class="form-control" type="tel" id="call_notification_{{type}}"
                            [formControlName]="type + '_notification_call'">
                        <label [for]="type + '_notification_call'" class="form-label">{{'common.phone' | translate}}</label>
                    </div>
                </div>

            </form>
            <!-- <app-location-form [draftShipmentForm]="shipmentForm" [type]="'pickup'"></app-location-form> -->

            <div class="mt-3 d-flex">
                <button class="btn-primary green primary-hover-animation ms-auto" (click)="save()">{{'common.save_and_back' | translate}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>
