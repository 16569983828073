import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';

 

@Component({
  selector: 'app-org-overview',
  templateUrl: './org-overview.component.html',
  styleUrls: ['./org-overview.component.scss']
})
export class OrgOverviewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<OrgOverviewComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: {
      uuid: string
      company_name: string
      first_name: string
      last_name: string
      created_at: string
    },
  ) {
    this.data.created_at = data.created_at.split('T')[0]
   }

  async ngOnInit() {
  }
 
}
