import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IShipmentAddImageData, ShipmentAddImageModalComponent } from 'projects/webapp/app/components/shipment-add-image-modal/shipment-add-image-modal.component';
import { ImageType } from 'projects/webapp/app/interfaces/shipment.interface';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { lastValueFrom } from 'rxjs';


export const generateShipmentPickupInfoFormGroup = () => {
  return new FormGroup({
    pickup_signature_name: new FormControl(''),
    pickup_comment: new FormControl(''),
  });
}

@Component({
  selector: 'app-shipment-do-pickup',
  templateUrl: './shipment-do-pickup.component.html',
  styleUrls: ['./shipment-do-pickup.component.scss']
})
export class ShipmentDoPickupComponent implements OnInit {

  public isLoading = false;
  public shipmentUUID: string | null = null;
  public shipment: IShipment | null = null;

  public pickupInfoForm = generateShipmentPickupInfoFormGroup()

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private carrierShipmentsService: CarrierShipmentsService,
    private toastrService: ToastrService,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.pickup_shipment').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(async (val) => {
      if (!!val && val['shipmentUUID']) {
        this.shipmentUUID = val['shipmentUUID']
        this.refresh();
      }
    })
  }

  async refresh() {
    if (this.shipmentUUID) {
      this.shipment = await lastValueFrom(this.carrierShipmentsService.getShipment(this.shipmentUUID))
    }
  }

  onAddImage() {
    const data: IShipmentAddImageData = {
      panelType: 'carrier',
      type: ImageType.PICKUP,
      showType: false,
      shipmentUUID: this.shipmentUUID || ''
    }
    this.dialog.open(ShipmentAddImageModalComponent, {
      data
    }).afterClosed().subscribe(async val => {
      if (!!val) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.picture_saved'));
        this.toastrService.success(successMessage)
      }
    })
  }

  async save() {
    if (this.shipmentUUID) {
      try {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_pickedup'));
        this.spinnerService.show('do-pickup')
        await lastValueFrom(this.carrierShipmentsService.pickupShipment(this.shipmentUUID, this.pickupInfoForm.value))
        this.toastrService.success(successMessage)
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide('do-pickup')
      }
    }
    this.router.navigate(['/main/driver/dashboard'])
  }
}
