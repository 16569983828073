import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { formatDistance, formatRelative, subDays } from 'date-fns'
import { de } from 'date-fns/locale'
import { UserCardComponent } from 'projects/webapp/app/components/user-card/user-card.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { EventType, EventsService, IEvent } from 'projects/webapp/app/services/events.service';
import { TranslateService } from '@ngx-translate/core';

formatRelative(subDays(new Date(), 3), new Date())

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {

  activityEvents: any[] = [];
  EventType = EventType;


  constructor(
    public authService: AuthService,
    public eventsService: EventsService,
    private title: Title,
    private dialog: MatDialog,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
  ) {
    this.translate.get('common.user_activity').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.refresh();
  }

  refresh() {
    this.spinnerService.show();
    this.eventsService.getLastActivityEvents().subscribe(val => {
      this.activityEvents = val;
      this.activityEvents.forEach(el =>{
        el['updated_at_human'] = formatDistance(new Date(el['updated_at']), new Date(), { locale: de, addSuffix: true })
      })

      console.log(this.activityEvents)
      this.spinnerService.hide();
      this.initEvents();
    });
  }

  navigateToDetails(event: IEvent) {
    switch(event.type){
      case EventType.LOGIN:
      case EventType.RESET_PASSWORD:
      case EventType.UPDATE_AVATAR:
        // this.router.navigate(['/main/carrier/projects/' + event.entity_uuid])
        this.dialog.open(UserCardComponent, { data: event.author_object })
        break;

      case EventType.CREATE_PROJECT:
      case EventType.UPDATE_PROJECT:
        this.router.navigate(['/main/carrier/projects/' + event.entity_uuid])
        break;

      case EventType.CREATE_PROJECT_DOCUMENT:
      case EventType.DELETE_PROJECT_DOCUMENT:
      case EventType.UPDATE_PROJECT_SHARING:
        this.router.navigate(['/main/carrier/projects/' + event.entity_uuid])
        break;

      default:
        console.warn('could not forward user to the item')
        console.warn(event)
    }
  }

  private initEvents(): void {

    // this.events = []
    this.activityEvents.forEach(el => {

      let description = ''

      if (!!el.author_object) {
        description = `Von: ${el.author_object?.first_name} ${el.author_object?.last_name}`
      }

      // this.events.push({
      //   id: el.id,
      //   title: el.type_name,
      //   description: description,
      //   timestamp: new Date(el.updated_at),
      // })
    })
  }

}
