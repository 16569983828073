import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ShipmentTransactionKind } from '../interfaces/shipment.interface';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'transactionTypePipe' })
export class TransactionTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(status: number | string): string {
    switch (status) {
      case ShipmentTransactionKind.UNKNOWN:
        console.warn('this should not be possible')
        return '';
      case ShipmentTransactionKind.AUCTION:
        return this.translate.instant('common.auction')
      case ShipmentTransactionKind.BUYER_SET:
        return this.translate.instant('common.scheduled_by_buyer')
      case ShipmentTransactionKind.SELLER_SET:
        return this.translate.instant('common.scheduled_by_seller');
      case ShipmentTransactionKind.OWN_CLIENT:
        return this.translate.instant('common.set_by_buyer');
      default:
        return '-'
    }
  }
}
