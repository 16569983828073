import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { IInvoice } from '../interfaces/invoice.interface';



@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  private _httpOptions = {
    responseType: 'arraybuffer' as 'json',
  };
  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public InvoicePDF(invoiceUUID: string): Observable<any> {
    return this.http.get<any>(this._urls.INVOICE_PDF_BY_UUID(invoiceUUID), this._httpOptions)
  }

  public manualInvoice(data: IInvoice): Observable<IInvoice> {
    return this.http.post<IInvoice>(this._urls.DOCUMENTS_INVOICE_MANUAL_INVOICE, data)
  }

  public getInvoices(params: any): Observable<IInvoice[]> {
    return this.http.get<IInvoice[]>(this._urls.INVOICES, { params })
  }

  getInvoice(invoiceUUID: string): Observable<IInvoice> {
    return this.http.get<IInvoice>(this._urls.INVOICES_BY_UUID(invoiceUUID))
  }

  public updateInvoice(invoiceUUID: string, data: IInvoice): Observable<IInvoice> {
    return this.http.patch<IInvoice>(this._urls.INVOICES_BY_UUID(invoiceUUID), data)
  }

  public deleteInvoice(invoiceUUID: string): Observable<IInvoice> {
    return this.http.delete<IInvoice>(this._urls.INVOICES_BY_UUID(invoiceUUID))
  }

  public createInvoiceForShipments(clientUUID: string) {
    return this.http.post<IInvoice>(this._urls.CLIENT_INVOICE_READY_SHIPMENTS(clientUUID), {})
  }

}
