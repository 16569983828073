import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IUser } from '../../services/users.service';
import { AuthService } from '../../services/auth.service';

export interface InputData extends IUser {
  editMode: 'create' | 'update'
}


@Component({
  selector: 'app-user-password-editor',
  templateUrl: './user-password-editor.component.html'
})
export class UserPasswordEditorComponent implements OnInit {

  form: FormGroup = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(20),
      Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{6,}$')
    ]),
  });

  showSuccessMessage = false;

  constructor(
    public dialogRef: MatDialogRef<UserEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUser,
  ) { }

  async ngOnInit() {
  }

  onSubmit() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      this.dialogRef.close(this.form.value)
    } else {
      console.log(this.form.errors)
    }
  }
}


@Component({
  selector: 'app-user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss']
})
export class UserEditorComponent implements OnInit {

  form: FormGroup = new FormGroup({
    first_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    last_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    email: new FormControl(''),
    phone: new FormControl('', Validators.required),

    permission_pipeline: new FormControl(false),
    permission_dispatch: new FormControl(false),
    permission_invoicing: new FormControl(false),
    permission_client_management: new FormControl(false),
    permission_empties_management: new FormControl(false),
    permission_warehouse_management: new FormControl(false),
    permission_activity: new FormControl(false),
    permission_export: new FormControl(false),
    permission_user_management: new FormControl(false),
    permission_org_management: new FormControl(false),
    permission_settings: new FormControl(false),
    permission_see_pricing: new FormControl(false),
    permission_alerts_place: new FormControl(false),
    permission_market_place: new FormControl(false),
    permission_new_shipments: new FormControl(false),

    permission_shipper_panel: new FormControl(false),
    permission_carrier_panel: new FormControl(false),
    permission_driver_panel: new FormControl(false),
  });

  constructor(
    public dialogRef: MatDialogRef<UserEditorComponent>,
    public authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: InputData,
  ) { }

  async ngOnInit() {
    this.form.patchValue(this.data)
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.dialogRef.close(this.form.value)
  }

}
