<div class="container-fluid">

    <mat-card class="mt-3">
        <mat-card-content>

            <div class="alert alert-info bg-green-light" style="border: none;" >
                {{'common.you_can_integrate_cargoflow' | translate}}
                {{'common.this_allows_your_existing_customers' | translate}}
                <br>
                <br>
                {{'common.your_customers_and_their_employees' | translate}}
                <br>
                <br>
                {{'common.you_can_create_a_new_key' | translate}}
                {{'common.the_cargoflow_team_can_do_this' | translate}}
            </div>

            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h3> </h3>
                <button class="d-flex" color="primary" mat-icon-button (click)="openSiteKeyEditor()">
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <table *ngIf="!!siteKeys && siteKeys.length" style="width: 100%" class="table table-custom">
                <thead>
                    <td>{{'common.name' | translate}}</td>
                    <td>URL</td>
                </thead>

                <tbody>
                    <tr *ngFor="let sitekey of siteKeys">
                        <td [attr.data-label]="'common.name' | translate">{{sitekey.name}}</td>
                        <td data-label="URL">
                            {{getIframeCode(sitekey)}}
                        </td>
                        <td>
                            <div class="d-flex justify-content-around justify-content-md-end">
                                <button mat-icon-button (click)="updateSiteKeyEditor(sitekey)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <a mat-icon-button color="primary" class="d-flex"
                                    href="{{environment.uiDomain}}/embed/big-wizard?siteKey={{sitekey.token}}"
                                    target="_blank">
                                    <mat-icon>open_in_new</mat-icon>
                                </a>
                                <button color="primary" mat-icon-button (click)="deleteSiteKey(sitekey)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <div class="alert alert-info bg-green-light" style="border: none;" *ngIf="!siteKeys || siteKeys.length == 0">
                {{'common.no_sitekeys_for_your' | translate}} {{'common.organization' | translate}}
            </div>
        </mat-card-content>
    </mat-card>

</div>