<mat-card class="mt-3">
  <mat-card-content>
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h5>{{'common.meta_data' | translate}}</h5>
    </div>

    <div *ngIf="meta_data">
      <pre>{{meta_data | json}}</pre>
    </div>
  </mat-card-content>
</mat-card>
