<h1 mat-dialog-title>
  <span>{{'common.set_password' | translate}}</span>
</h1>
<div mat-dialog-content style="min-height: 20vh">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <p>
      <mat-form-field>
        <input type="text" matInput placeholder="Passwort" formControlName="password">
        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('required')">
          {{'common.field_required' | translate}}
        </mat-error>
        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('minlength')">
          <span>{{'common.min_length' | translate}}
          </span>{{form.get('password')?.errors?.["minlength"]?.requiredLength}}.
        </mat-error>
        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('maxlength')">
          <span>{{'common.max_length' | translate}}
          </span>{{form.get('password')?.errors?.["maxlength"]?.requiredLength}}.
        </mat-error>
        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('pattern')">
          {{'common.password_requirements' | translate}}
        </mat-error>
      </mat-form-field>
    </p>

  </form>

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.cancel' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" (click)="onSubmit()" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>
