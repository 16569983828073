<h1 mat-dialog-title>
  <span *ngIf="!!shipment.uuid">{{'common.marketplace_shipment' | translate}} {{shipment.number | shipmentNumberPipe}}</span>
</h1>
<div mat-dialog-content>

  <div class="mt-2">
    <div class="row">
      <div class="col-6">
        <mat-card class="mt-3 ">
          <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <h5 style="word-break: break-all;">{{'common.pickup_information' | translate}}</h5>

            </div>

            <p>{{'common.pickup_date' | translate}}: {{shipment.pickup_date}}</p>
            <hr>

            <ul class="co-simple-list">
              <li class="co-unreadable-text" *ngIf="!!shipment.pickup_company">{{'common.firma' | translate}}: {{shipment.pickup_company}}</li>
              <li class="co-unreadable-text">{{shipment.pickup_name}}</li>
              <li class="co-unreadable-text">{{shipment.pickup_address}}</li>
              <li>{{shipment.pickup_zip}} {{shipment.pickup_location}}</li>
            </ul>

            <div *ngIf="shipment.pickup_reference">
              <hr>
              <ul class="co-simple-list">
                <li class="co-unreadable-text">{{'common.pickup_reference' | translate}}: {{shipment.pickup_reference}}</li>
              </ul>
            </div>

            <div class="alert alert-info" *ngIf="shipment.pickup_notification">
              <a href="tel:{{shipment.pickup_phone}}">
                {{'common.phone' | translate}}: {{shipment.pickup_phone}}
              </a>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-6">
        <mat-card class="mt-3">
          <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
              <h5 style="word-break: break-all;">{{'common.delivery_information' | translate}}</h5>
            </div>

            <p>{{'common.delivery_date' | translate}}: {{shipment.delivery_date}}</p>
            <hr>
            <ul class="co-simple-list">
              <li class="co-unreadable-text" *ngIf="!!shipment.delivery_company">{{'common.firma' | translate}}: {{shipment.delivery_company}}</li>
              <li class="co-unreadable-text">{{shipment.delivery_name}}</li>
              <li class="co-unreadable-text">{{shipment.delivery_address}}</li>
              <li>{{shipment.delivery_zip}} {{shipment.delivery_location}}</li>
            </ul>

            <div *ngIf="shipment.delivery_reference">
              <hr>
              <ul class="co-simple-list">
                <li class="co-unreadable-text">{{'common.delivery_reference' | translate}}: {{shipment.delivery_reference}}</li>
              </ul>
            </div>

            <div class="alert alert-info" *ngIf="shipment.delivery_notification">
              <a href="tel:{{shipment.delivery_phone}}">
                {{'common.phone' | translate}}: {{shipment.delivery_phone}}
              </a>
            </div>

          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <mat-card class="mt-3">
      <mat-card-content>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h5>{{'common.cargoes' | translate}}</h5>

        </div>

        <ul class="co-simple-list">
          <li *ngIf="shipment.insurance">{{'common.insured' | translate}}: {{shipment.insurance | yesNoPipe}}</li>
          <!-- <li>{{'common.goods_value' | translate}}: CHF {{shipment.value}} </li> -->
          <li>{{'common.total_weight' | translate}}: {{shipment.total_weight}}kg </li>
        </ul>
        <hr>

        <table *ngIf="!!shipment.packets && shipment.packets.length > 0" style="width: 100%"
          class="table table-striped">
          <thead>
            <td translate>common.name</td>
            <td>{{'common.type' | translate}}</td>
            <td translate>common.quantity</td>
            <td>{{'common.mass' | translate}} (cm)</td>
            <td>{{'common.total_weight' | translate}} (kg)</td>
          </thead>

          <tbody>
            <tr *ngFor="let packet of shipment.packets">
              <td >

                <span class="co-unreadable-text">
                  {{packet.name}}
                </span>


              </td>
              <td>{{packet.type | cargoItemName}}</td>
              <td>{{packet.count}}</td>
              <td>L{{packet.length}}xB{{packet.width}}xH{{packet.height}}</td>
              <td>{{packet.weight}}</td>
            </tr>
          </tbody>
        </table>

      </mat-card-content>
    </mat-card>

    <mat-card class="mt-3" *ngIf="shipment.empties">
      <mat-card-content>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h5>{{'common.empty_containers' | translate}}</h5>

        </div>

        <div class="alert alert-info" role="alert" *ngIf="shipment.empties">
          {{'common.empty_container_exchange_ordered' | translate}}
        </div>

        <table style="width: 100%" class="table table-striped" *ngIf="shipment.empties">
          <thead>
            <!-- <td></td> -->
            <td>{{'common.pallets' | translate}}</td>
            <td>{{'common.frames' | translate}}</td>
            <td>{{'common.lids' | translate}}</td>
          </thead>

          <tbody>
            <tr>
              <!-- <td>Beauftragt</td> -->
              <td>{{shipment.empties_eur_palets}}</td>
              <td>{{shipment.empties_sbb_frames}}</td>
              <td>{{shipment.empties_sbb_covers}}</td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>


    <mat-card class="mt-3">
      <mat-card-content>
        <div class="alert alert-info">
          {{'common.the_price_for_this_shipment_is_currently' | translate}} {{shipment.market_price | number: '1.2-2' }} Fr.
          {{'common.this_price_is_a_flat_rate_between_you_and_the_shipper' | translate}}
          {{'common.if_you_accept_the_shipment_you_must_also_perform_it' | translate}}
          {{'common.please_note_that_cargoflow_will_charge' | translate}}
          <br>
          {{'common.you_can_view_the_details_after_shipment_acceptance' | translate}}
        </div>
      </mat-card-content>
    </mat-card>

    <!-- <pre>{{shipment | json}}</pre> -->
  </div>

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" (click)="takeShipment()">{{'common.accept_shipment' | translate}}</button>
</div>
