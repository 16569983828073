import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { IClient } from 'projects/webapp/app/services/clients.service';
import { UsersService } from '../../services/users.service';
import { OrganizationsService } from '../../services/organizations.service';
import { MatDialog } from '@angular/material/dialog';
import { ApiKeyEditorComponent } from '../api-key-editor/api-key-editor.component';
import { deepCopy } from '../../utils';
import { IApiToken, TokenType } from '../../interfaces/token.interface';
import { environment } from '../../environments/environment';


@Component({
  selector: 'app-client-wizards',
  templateUrl: './client-wizards.component.html',
  styleUrls: ['./client-wizards.component.scss']
})
export class ClientWizardsComponent implements OnInit {

  @Input() client: IClient | null = null;
  wizardKeys: IApiToken[] = []
  isLoading: boolean = false;
  environment = environment

  constructor(
    private organizationService: OrganizationsService,
    public dialog: MatDialog,
    public usersService: UsersService,
  ) { }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true
      if (this.client?.uuid) {
        this.wizardKeys = await lastValueFrom(this.organizationService.getClientKeys(this.client.uuid, { type: TokenType.WIZARD}))
        console.log(this.wizardKeys)
      } else {
        this.wizardKeys = await this.usersService.getUserKeys().toPromise() as any;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  onAddKey() {
    const data: Partial<IApiToken> = {
      purpose: '',
      active: true,
      type: TokenType.WIZARD
    }

    if (!!this.client && !!this.client.uuid) {
      data.client_object = {
        uuid: this.client.uuid,
        name: ''
      }
    }

    this.dialog.open(ApiKeyEditorComponent, { data }).afterClosed().subscribe(async val => {
      if (!!val) {
        await this.usersService.createKey(val).toPromise();
        this.refresh();
      }
    })
  }

  onEditKey(apiToken: IApiToken) {
    const data = deepCopy(apiToken)
    this.dialog.open(ApiKeyEditorComponent, { data }).afterClosed().subscribe(async (val: IApiToken) => {
      if (!!val) {
        await this.usersService.updateKey(val.token, val).toPromise();
        this.refresh();
      }
    })
  }

  async onDeleteKey(data: IApiToken) {
    if (confirm('Sind Sie sicher, dass Sie den Schlüssel löschen wollen?')) {
      await this.usersService.deleteUserKey(data.token).toPromise();
      this.refresh();
    }
  }

}
