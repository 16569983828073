import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvoiceStatus, IInvoice } from 'projects/webapp/app/interfaces/invoice.interface';

 
@Component({
  selector: 'app-invoice-edit-modal',
  templateUrl: './invoice-edit-modal.component.html',
  styleUrls: ['./invoice-edit-modal.component.scss']
})
export class InvoiceEditModalComponent implements OnInit {

  invoiceStatus = InvoiceStatus
  keys = Object.keys(this.invoiceStatus).filter(k => !isNaN(Number(k))).map(el => parseInt(el, 10));

  constructor(
    public dialogRef: MatDialogRef<InvoiceEditModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IInvoice,
  ) { }

  async ngOnInit() {
  }
}
