import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';

@Component({
  selector: 'app-settings-platform-surcharges',
  templateUrl: './settings-platform-surcharges.component.html',
  styleUrls: ['./settings-platform-surcharges.component.scss']
})
export class SettingsPlatformSurchargesComponent implements OnInit {

  constructor(
    public organizationService: OrganizationsService,
    public usersService: UsersService,
    public dialog: MatDialog,
  ) {}

  async ngOnInit() {
  }
 

}
