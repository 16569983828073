import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({
    name: 'enumIntToDescription',
})
export class EnumIntToDescriptionPipe implements PipeTransform {
    // enum object on which you want this pipe to work
    transform(value: number | string | any, _enum: any): any {
        return Object.values(_enum)[value];
    }
}