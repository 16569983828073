<div *ngIf="isLoading">
  <div class="spinner-border" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>

<div *ngIf="!isLoading && !siteKey" class="mt-5 p-4 w-100 text-center">
  <div class="alert alert-danger" role="alert">
    SiteKey not found. Please contact our support for further information.
  </div>
</div>

<div *ngIf="!isLoading && siteKey">
  <section>
    <div class="div-block">
      <div class="kachel">
        <div id="w-node-dbf486f7-84e0-546a-3719-ca0d44829387-87b30012"
          class="w-layout-layout quick-stack wf-layout-layout">
          <div id="w-node-dbf486f7-84e0-546a-3719-ca0d44829388-87b30012" class="w-layout-cell cell-11">
            <h2 class="heading-3">{{'common.transport_calculator' | translate}}</h2>
            <p class="paragraph-3">{{'common.capture_the_data_and_get_the_price_without_delay' | translate}}</p>
            <div class="form-block w-form">

              <form id="wf-form-Transport-Rechner"
              name="wf-form-Transport-Rechner" data-name="Transport Rechner"
                method="get" class="form" data-wf-page-id="6501adf589f411c287b30012"
                data-wf-element-id="b1e85295-6de5-6544-97f5-61b24223f8bb"
                [formGroup]="draftShipmentForm"
                >
                <div class="abholort"><label for="Abholort" class="field-label">{{'common.pick-up-location' | translate}}</label><input type="text"
                    class="text-field w-input" maxlength="256" name="Abholort" data-name="Abholort" placeholder=""
                    id="Abholort"></div>
                <div class="lieferort"><label for="Lieferort" class="field-label">{{'common.delivery_location' | translate}}</label><input type="text"
                    class="text-field w-input" maxlength="256" name="Lieferort" data-name="Lieferort" placeholder=""
                    id="Lieferort" required=""></div>
                <div class="gewicht"><label for="Gewicht" class="field-label">{{'common.weight' | translate}}</label><input type="text"
                    class="text-field w-input" maxlength="256" name="Gewicht" data-name="Gewicht" placeholder="kg"
                    id="Gewicht" required=""></div>
                <div class="masse">
                  <div id="w-node-aa7cc7db-5bd3-ce02-e14c-15590f631705-87b30012"
                    class="w-layout-layout quick-stack-2 wf-layout-layout">
                    <div id="w-node-aa7cc7db-5bd3-ce02-e14c-15590f631706-87b30012" class="w-layout-cell">
                      <h1 class="field-label-copy">{{'common.length' | translate}}</h1><input type="text" class="text-field w-input"
                        maxlength="256" name="L-nge" data-name="Länge" placeholder="cm" id="L-nge" required="">
                    </div>
                    <div id="w-node-aa7cc7db-5bd3-ce02-e14c-15590f631707-87b30012" class="w-layout-cell">
                      <h1 class="field-label-copy">{{'common.width' | translate}}</h1><input type="text" class="text-field w-input"
                        maxlength="256" name="Breite" data-name="Breite" placeholder="cm" id="Breite" required="">
                    </div>
                    <div id="w-node-fe9dc9b9-e3f4-3079-07c3-04e2eccfadf2-87b30012" class="w-layout-cell">
                      <h1 class="field-label-copy">{{'common.height' | translate}}</h1><input type="text" class="text-field w-input"
                        maxlength="256" name="H-he" data-name="Höhe" placeholder="cm" id="H-he" required="">
                    </div>
                  </div>
                </div>
                <div class="preis">
                  <div id="w-node-_80ff49ab-2858-4bcb-b0c9-b4c6f8525cf4-87b30012"
                    class="w-layout-layout quick-stack-3 wf-layout-layout">
                    <div class="w-layout-cell cell-12">
                      <h1 class="field-label-copy">{{'common.price' | translate}}:</h1>
                    </div>
                    <div class="w-layout-cell cell-13">
                      <h2 *ngIf="!price?.total_price_with_vat">- CHF {{'common.incl_vat' | translate}}</h2>
                      <h2 *ngIf="price?.total_price_with_vat">{{price?.total_price_with_vat}} CHF {{'common.incl_vat' | translate}}</h2>
                    </div>
                  </div>
                </div><input type="submit" value="Transport buchen" data-wait="bitte warten"
                  class="submit-button w-button">
              </form>
              <div class="w-form-done">
                <div>Thank you! Your submission has been received!</div>
              </div>
              <div class="w-form-fail">
                <div>Oops! Something went wrong while submitting the form.</div>
              </div>
            </div>
            <a href="http://www.cargoflow.ch" target="_blank" class="cargoflow-link w-inline-block"><img
                src="images/Cargoflow-C-1.png" loading="lazy" sizes="32px"
                srcset="images/Cargoflow-C-1-p-500.png 500w, images/Cargoflow-C-1-p-800.png 800w, images/Cargoflow-C-1-p-1080.png 1080w, images/Cargoflow-C-1.png 1518w"
                alt="" class="image-2"></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- <div>
    <div class="pt-6 text-center" *ngIf="tenantName">Licensed to {{tenantName}}</div>

    <div class="text-center">Powered by <a href="https://cargoflow.ch/" style="text-decoration: underline;"
        target="_blank">Cargoflow</a></div>

    <div class="text-center">
      <small class="text-center">v{{versions.date | date: 'yyyyMMdd'}}-{{versions.hash}}</small>
    </div>
  </div> -->
  <!-- <br> -->

</div>
