import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { Params } from '@angular/router';
import { IDispatchInfo } from '../components/dispatch-modal/dispatch-modal.component';
import { ICarrierMarketShipment, ICarrierShipment, IShipmentTransaction, IShipperMarketShipment, ShipmentImage } from '../interfaces/shipment.interface';
import { IShipmentComment } from '../interfaces/shipment-comment.interface';


export enum CurrentLocationStatus {
  UNKNOWN = 0,
  AT_PICKUP_SITE = 10,
  AT_PICKUP_TRUCK = 11,
  AT_HUB = 12,
  AT_DELIVERY_TRUCK = 13,
  AT_DELIVERY_SITE = 14,
}

export enum ShipmentTaskStatus {
  PICKUP_VIA_HUB = 20,
  PICKUP_AND_DELIVERY_DIRECTLY = 21,
  DELIVERY_VIA_HUB = 22,
  DELIVERY_DIRECTLY = 23,
}

export enum ShipmentStatus {
  UNKNOWN = 0,
  CREATED = 1,
  APPROVED = 2,

  DISPATCHED = 7,

  IN_HUB = 8,

  IN_PICKUP = 6,
  PICKED_UP = 9,

  IN_DELIVERED = 3,
  DELIVERED = 4,

  CANCELLED = 5,
}


export enum ShipmentPriceMode {
  UNKNOWN = 0,
  AUCTION = 3,
  BUYER_SET = 4,
  SELLER_SET = 5,
}


export enum ShipmentEventType {
  UNKNOWN = 0,
  SHIPMENT_CREATED = 1,
  SHIPMENT_ACCEPTED = 4,
  SHIPMENT_PICKED_UP = 2,
  SHIPMENT_ARRIVED_AT_HUB = 3,
  SHIPMENT_DISPATCHED = 5,
  SHIPMENT_DATA_UPDATED = 6,
  SHIPMENT_DELIVERED = 7,
  SHIPMENT_CANCELLED = 8,
  SHIPMENT_INVOICED = 9,
  SHIPMENT_PRICE_CHANGED = 10,
  SHIPMENT_RELEASED_FOR_INVOICING = 11,
  SHIPMENT_SOLD = 12
}

export interface IShipmentEvent {
  created_at: any;
  updated_at: string | undefined;
  uuid: string;
  type: ShipmentEventType,
  author_object: {
    first_name: string;
    last_name: string;
  }
}


export interface IShipment {
  invoice_object: {
    uuid: string;
    number: string;
  };

  invoice: number;

  task: ShipmentTaskStatus;

  assigned_driver: number | null;
  assigned_driver_object: {
    uuid: string
    first_name: string
    last_name: string;
  } | null;


  assigned_hub: number | null;
  assigned_hub_object: {
    uuid: string
    name: string
    address: string;
    location: string;
  } | null;

  tracking_uuid: string;

  client: number | null;
  client_object: {
    uuid: string;
    name?: string;
    company: string;
    first_name: string
    last_name: string;
  } | null;

  sender?: number | null;
  sender_object?: {
    uuid: string;
    name?: string;
    company_name: string;
    first_name: string
    last_name: string;
  } | null;

  organization_object?: {
    uuid: string;
    name?: string;
    company_name: string;
    first_name: string
    last_name: string;
  } | null;

  // manual_price: boolean

  number: string;

  pickup_company: string;
  pickup_name: string;
  pickup_address: string;
  pickup_zip: string;
  pickup_location: string;
  pickup_reference: string;
  pickup_date: string;
  pickup_notification: boolean
  pickup_phone: string;

  delivery_company: string;
  delivery_name: string;
  delivery_address: string;
  delivery_zip: string;
  delivery_location: string;
  delivery_reference: string;
  delivery_date: string;
  delivery_notification: boolean
  delivery_phone: string;

  id?: number;
  uuid?: string;

  created_at?: string;
  updated_at?: string;

  packets: {
    height: number,
    width: number,
    length: number,
    weight: number
    count: number
    type: number


    name: string
  }[];

  total_weight: number

  status: ShipmentStatus,
  current_loc: CurrentLocationStatus,
  ready_to_invoice: boolean

  dangerous_goods: boolean,
  dangerous_points: number,
  dangerous_amount: number,
  dangerous_un_number: number,

  empties: boolean,
  empties_eur_palets: number,
  empties_sbb_frames: number,
  empties_sbb_covers: number,
  insurance: boolean,

  price_mode: ShipmentPriceMode | string,

  value: number,

  // TODO: deprecated
  base_price: number,
  options: {
    name: string;
    price: number
  }[];
  total_price: number;
  total_vat: number;
  total_price_with_vat: number;

  events?: IShipmentEvent[]
  comments?: IShipmentComment[]
}

export interface IPickupInfo {
  pickup_signature_name?: string | null;
  pickup_comment?: string | null;
}

export interface IDeliverInfo {
  deliver_signature_name?: string | null
  deliver_comment?: string | null
}



@Injectable({
  providedIn: 'root'
})
export class ShipmentsService {

  constructor(
    protected http: HttpClient,
    protected _urls: UrlConstantsService,
  ) {
  }

}
