import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { generateShipmentCargoItemsFormGroup } from 'projects/shared/generators';
import { lastValueFrom } from 'rxjs';
import { generatePacketFormGroup } from '../../embed/big-wizard/wizard.component';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shipment-edit-cargo-items',
  templateUrl: './shipment-edit-cargo-items.component.html',
  styleUrls: ['./shipment-edit-cargo-items.component.scss']
})
export class ShipmentEditCargoItemsComponent implements OnInit {

  public isLoading = false;
  public shipmentUUID: string | null = null;

  public shipmentForm = generateShipmentCargoItemsFormGroup()
  public packetItemForms: FormArray = this.shipmentForm.get('packets') as FormArray;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private carrierShipmentsService: CarrierShipmentsService,
    private translate: TranslateService,
  ) {
    this.translate.get('common.edit_freight_items').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(async (val) => {
      if (!!val && val['shipmentUUID']) {
        this.shipmentUUID = val['shipmentUUID']
        this.refresh();
      }
    })
  }

  async refresh() {
    if (this.shipmentUUID) {
      const response = await lastValueFrom(this.carrierShipmentsService.getShipment(this.shipmentUUID)) as any
      // this.shipmentForm.patchValue({ ...response })
      this.removePacketItem(0)
      response.packets.forEach((element: any) => {
        const cargoItemsCount = this.packetItemForms.controls.length;
        const cargoItemForm = generatePacketFormGroup();
        cargoItemForm.patchValue(element)
        this.packetItemForms.insert(cargoItemsCount, cargoItemForm);
      });
    }
  }

  async save() {
    if (this.shipmentUUID) {
      await lastValueFrom(this.carrierShipmentsService.updateShipment(this.shipmentUUID, this.shipmentForm.value as any))
      this.router.navigate(['/main/carrier/shipments/' + this.shipmentUUID])
    }
  }

  public addPacketItem(): void {
    const cargoItemsCount = this.packetItemForms.controls.length;
    const cargoItemForm = generatePacketFormGroup();
    this.packetItemForms.insert(cargoItemsCount, cargoItemForm);
  }

  public removePacketItem(index: number): void {
    this.packetItemForms.removeAt(index);
  }

}
