<div class="container">

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'carrier_dashboard.shipment-search.main_title' | translate}} </h1>
            </div>

            <form class="search-form" [formGroup]="searchForm">

                <div class="mb-3">
                    <input type="text" class="form-control" id="exampleFormControlInput1" formControlName="search">
                </div>

                <div class="spinner-border" role="status" *ngIf="isLoading">
                </div>

            </form>

            <div *ngIf="searchForm.value.search != '' && !foundShipments || foundShipments?.length === 0"
                class="alert alert-info w-100">
                {{'carrier_dashboard.shipment-search.no_shipment' | translate}}
            </div>

            <table class="table table-striped" style="width: 100%"
                *ngIf="!!foundShipments && foundShipments.length > 0">
                <thead>
                    <tr>
                        <th scope="col">{{'common.number' | translate}} / Status</th>
                        <th scope="col">{{'common.pick-up-location' | translate}} </th>
                        <th scope="col">{{'common.delivery_location' | translate}}</th>
                        <th scope="col">{{'common.client' | translate}} </th>
                        <th scope="col">{{'common.sender' | translate}} </th>
                        <th scope="col">{{'common.carrier' | translate}} </th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let foundShipment of foundShipments">
                        <td>
                            <ul class="co-simple-list">
                                <li>
                                    <a routerLink="/main/admin/shipments/{{foundShipment.uuid}}">
                                        {{ foundShipment.number | shipmentNumberPipe}}
                                    </a>
                                </li>
                                <li>{{ foundShipment.status | shipmentStatusPipe}}</li>
                            </ul>
                        </td>
                        <td>
                            <ul class="co-simple-list">
                                <li>{{ foundShipment.pickup_company}}</li>
                                <li>{{ foundShipment.pickup_name}}</li>
                                <li>{{ foundShipment.pickup_address}}</li>
                                <li>{{ foundShipment.pickup_location}}</li>
                            </ul>
                        </td>
                        <td>
                            <ul class="co-simple-list">
                                <li>{{ foundShipment.delivery_company}}</li>
                                <li>{{ foundShipment.delivery_name}}</li>
                                <li>{{ foundShipment.delivery_address}}</li>
                                <li>{{ foundShipment.delivery_location}}</li>
                            </ul>
                        </td>
                        <td>
                            <pre>{{foundShipment.client_object?.name}}</pre>
                        </td>
                        <td>
                            <pre>{{foundShipment?.sender_object?.name}}</pre>
                        </td>
                        <td>
                            <pre>{{foundShipment?.organization_object?.name}}</pre>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- <pre>{{foundShipments | json}}</pre> -->
        </mat-card-content>
    </mat-card>
</div>
