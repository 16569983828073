import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { IDetailedTour } from '../../services/tour.service';
import { IVehicle } from '../../services/fleet.service';

@Component({
  selector: 'app-tour-date-picker-modal',
  templateUrl: './tour-date-picker-modal.component.html',
  styleUrls: ['./tour-date-picker-modal.component.scss']
})
export class TourDatePickerModalComponent implements OnInit {

  form: FormGroup = new FormGroup({
    date: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<TourDatePickerModalComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {date: string},
  ) { }

  async ngOnInit() {
    this.form.patchValue(this.data)
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.dialogRef.close(this.form.value)
  }

  onSelectedVehicle(event: IVehicle) {
    this.form.patchValue({ vehicle: event.id})
  }
}
