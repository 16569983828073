import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SiteKeyEditorComponent } from 'projects/webapp/app/components/site-key-editor/site-key-editor.component';
import { environment } from 'projects/webapp/app/environments/environment';
import { ISiteKey, OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-settings-cargoflow-for-you',
  templateUrl: './settings-cargoflow-for-you.component.html',
  styleUrls: ['./settings-cargoflow-for-you.component.scss']
})
export class CargoFlowForYouComponent implements OnInit {

  siteKeys: ISiteKey[] = []
  environment = environment
  constructor(
    public organizationService: OrganizationsService,
    public usersService: UsersService,
    public dialog: MatDialog,
  ) { }

  async ngOnInit() {
    this.refreshSiteKeys();
  }

  async refreshSiteKeys() {
    this.siteKeys = await lastValueFrom(this.organizationService.getSiteKeys())
  }

  getIframeCode(sitekey: ISiteKey) {
    return `<iframe id="cargoflow" src="${environment.uiDomain}/embed/big-wizard?siteKey=${sitekey.token}" width="100%" height="1000px"></iframe>`
  }

  openSiteKeyEditor() {
    const data: Partial<ISiteKey> = {

    }
    this.dialog.open(SiteKeyEditorComponent, { data }).afterClosed().subscribe(async val => {
      console.log(val)
      if (!!val) {
        await lastValueFrom(this.organizationService.createSiteKey(val));
        this.refreshSiteKeys()
      }
    })
  }

  updateSiteKeyEditor(data: ISiteKey) {
    this.dialog.open(SiteKeyEditorComponent, { data }).afterClosed().subscribe(async val => {
      if (!!val) {
        await lastValueFrom(this.organizationService.updateSiteKeys(val.id, val))
        this.refreshSiteKeys()
      }
    })
  }

  async deleteSiteKey(siteKey: ISiteKey) {
    if (confirm('Wollen Sie wirklich diesen Schlüssel löschen?') && siteKey?.id) {
      await lastValueFrom(this.organizationService.deleteSiteKey(siteKey.id));
      this.refreshSiteKeys();
    }
  }

}
