import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-shipment-meta-data',
  templateUrl: './shipment-meta-data.component.html',
  styleUrls: ['./shipment-meta-data.component.scss']
})
export class ShipmentMetaDataComponent implements OnInit {

  @Input() meta_data: any = undefined;

  constructor(
  ) { }

  async ngOnInit() {
  }
}
