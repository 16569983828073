import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ICarrierMarketShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { OrganizationOverviewModalComponent } from '../organization-overview-modal/organization-overview-modal.component';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';

@Component({
  selector: 'app-market-place-shipment-card',
  templateUrl: './market-place-shipment-card.component.html',
  styleUrls: ['./market-place-shipment-card.component.scss']
})
export class MarketPlaceShipmentCardComponent implements OnInit {
  @Input() shipment: ICarrierMarketShipment | null = null;
  @Output() onShipmentOpenDetails: EventEmitter<ICarrierMarketShipment> = new EventEmitter()

  ownOrganization: IOrganization | null = null;

  constructor(
    public dialog: MatDialog,
    private organizationService: OrganizationsService,
  ) { }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
  }

  openShipmentDetails() {
    if (this.shipment){
      this.onShipmentOpenDetails.emit(this.shipment);
    }
  }

  openOrdererDetails(){
    if (this.shipment){
      this.dialog.open(OrganizationOverviewModalComponent, { data: this.shipment.orderer })
    }
  }
}
