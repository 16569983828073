import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { FileHandle } from 'projects/webapp/app/services/dragDrop.directive';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { TranslateService } from '@ngx-translate/core';

// https://stackoverflow.com/questions/21928083/iban-validation-check
/*
 * Returns 1 if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 */
export function isValidIBANNumber(input: any) {
  var CODE_LENGTHS = {
    AD: 24, AE: 23, AT: 20, AZ: 28, BA: 20, BE: 16, BG: 22, BH: 22, BR: 29,
    CH: 21, CR: 21, CY: 28, CZ: 24, DE: 22, DK: 18, DO: 28, EE: 20, ES: 24,
    FI: 18, FO: 18, FR: 27, GB: 22, GI: 23, GL: 18, GR: 27, GT: 28, HR: 21,
    HU: 28, IE: 22, IL: 23, IS: 26, IT: 27, JO: 30, KW: 30, KZ: 20, LB: 28,
    LI: 21, LT: 20, LU: 20, LV: 21, MC: 27, MD: 24, ME: 22, MK: 19, MR: 27,
    MT: 31, MU: 30, NL: 18, NO: 15, PK: 24, PL: 28, PS: 29, PT: 25, QA: 29,
    RO: 24, RS: 22, SA: 24, SE: 24, SI: 19, SK: 24, SM: 27, TN: 24, TR: 26,
    AL: 28, BY: 28, EG: 29, GE: 22, IQ: 23, LC: 32, SC: 31, ST: 25,
    SV: 28, TL: 23, UA: 29, VA: 22, VG: 24, XK: 20
  };
  var iban = String(input).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
    code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
    digits;
  // check syntax and length
  const code_lengths = CODE_LENGTHS as any
  if (!code || iban.length !== code_lengths[code[1]]) {
    return false;
  }
  // rearrange country code and check digits, and convert chars to ints
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter: any) => {
    return (letter.charCodeAt(0) - 55) as any;
  });
  // final check
  return mod97(digits) === 1;
}

export function mod97(string: any) {
  var checksum = string.slice(0, 2), fragment;
  for (var offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

export function IBANValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    const IBANValid = isValidIBANNumber(value)
    return (IBANValid || value == '' || !value) ? null : { 'iban_number': { value: control.value } };
  };
}


@Component({
  selector: 'app-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class OrganizationDetailsComponent implements OnInit {

  ownOrganization: IOrganization | null = null;
  ownOrganizationLogo: any = null;

  files: FileHandle[] = [];

  organizationForm = new FormGroup({
    company_name: new FormControl('', Validators.required),

    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),

    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),

    address: new FormControl('', Validators.required),
    zip: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),

    vat_number: new FormControl(''),
    default_currency: new FormControl(''),
    iban_number: new FormControl('', IBANValidator()),

    have_empties_management: new FormControl(false, Validators.required),
    have_warehouse_management: new FormControl(false, Validators.required),
    have_market_place: new FormControl(false, Validators.required),
  });

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    private organizationService: OrganizationsService,
    public dialog: MatDialog,
    private title: Title,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.translate.get('carrier_dashboard.organization_details.page_title').subscribe(val => {
      this.title.setTitle(val)
    })
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
    this.spinnerService.show()
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    if (!!this.ownOrganization) {
      this.organizationForm.patchValue(this.ownOrganization);
    }

    this.ownOrganizationLogo = await lastValueFrom(this.organizationService.getLogo());

    this.spinnerService.hide()
  }

  async submitOrganization() {
    this.organizationForm.markAllAsTouched()
    if (this.organizationForm.valid) {
      const successMessage = await lastValueFrom(this.translate.get('toastr.organization_updated'));

      const response = await this.organizationService.updateOwnOrganization(this.organizationForm.value as IOrganization).toPromise()
      console.log(response);
      this.toastrService.success(successMessage);
      await this.refresh()
    }
  }

  filesDropped(files: FileHandle[] | any): void {
    this.files = files;

    if (this.files) {

      this.files.forEach(async file => {
        const fileName = file.name || '';
        const formData = new FormData();
        let blob = await fetch(file.data).then(r => r.blob());
        console.log(blob)
        formData.append("file", blob as any);
        formData.append('name', fileName);
        const result = await lastValueFrom(this.organizationService.updateLogo(formData));

        console.log(result);
        this.ownOrganizationLogo = result

      });
    }
  }
  async onFileSelected(event: any) {
    const file: File = event.target.files[0];

    if (file) {

      const fileName = file.name;

      const formData = new FormData();

      formData.append("file", file);
      formData.append("name", fileName);

      const result = await lastValueFrom(this.organizationService.updateLogo(formData));
      this.ownOrganizationLogo = result
    }
  }



}
