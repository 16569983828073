<mat-form-field appearance="outline" style="width: 100%" *ngIf="!!possibleUsers && possibleUsers.length > 0">
    <mat-label translate>common.driver</mat-label>
    <input type="text" matInput [(ngModel)]="selectedUser" [matAutocomplete]="auto" [formControl]="userFormControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
        (optionSelected)='userSelected($event.option.value)'>
        <mat-option *ngFor="let option of filteredUsers" [value]="option">
            {{getUserName(option)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<!-- <div class="alert alert-info" role="alert" style="width: 100%" *ngIf="!possibleUsers || possibleUsers.length == 0">
    Noch keine Kunden. Sie können neue Kunden in der <a routerLink="/main/carrier/clients">Kundenverwaltung</a> {{'common.create' | translate}}
</div> -->