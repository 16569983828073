import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IHub, OrganizationsService } from '../../services/organizations.service';


@Component({
  selector: 'app-hub-selector',
  templateUrl: './hub-selector.component.html',
  styleUrls: ['./hub-selector.component.scss']
})
export class HubSelectorComponent implements OnInit {

  @Input() selectedHub: IHub | null = null;

  possibleHubs: IHub[] = [];
  filteredHubs: IHub[] = []
  userFormControl = new FormControl('')

  @Output() onSelectedHub = new EventEmitter<IHub>()

  constructor(
    public organizationsService: OrganizationsService,
  ) {
    this.userFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe(val => {
      this.filteredHubs = val;
    });
  }

  async ngOnInit() {
    this.possibleHubs = await lastValueFrom(this.organizationsService.getHubs())
  }

  getTitle(user: IHub) {
    console.log(`getTitle(${user?.uuid}`)
    const filteredHub = this.possibleHubs.find(p => p.uuid === user?.uuid)
    if (filteredHub) {
      return `${filteredHub.name} ${filteredHub.address} ${filteredHub.location}`
    }
    return '';
  }

  private _filter(value: string): IHub[] {
    console.log(value)
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.possibleHubs.filter(hub => {
        return hub.name.toLowerCase().includes(filterValue) || hub.address.toLowerCase().includes(filterValue) || hub.location.toLowerCase().includes(filterValue)
      });
    }
    return []
  }

  userSelected(client: IHub) {
    this.onSelectedHub.emit(client);
  }

  getHubName(hub: IHub) {
    return `${hub.name} ${hub.address} ${hub.location}`
  }
}
