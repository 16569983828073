<h1 mat-dialog-title class="fs-3 mb-2">
    <span *ngIf="!data.uuid" translate>carrier_dashboard.add_customer</span>
    <span *ngIf="!!data.uuid" translate>carrier_dashboard.edit_customer</span>
</h1>
<div mat-dialog-content>
    <form [formGroup]="form" class="pt-4">
        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.firma</mat-label>
            <input matInput formControlName="company">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.first_name</mat-label>
            <input matInput formControlName="first_name">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.last_name</mat-label>
            <input matInput formControlName="last_name">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.address</mat-label>
            <input matInput formControlName="address">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.zip_code</mat-label>
            <input matInput formControlName="zip_code">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.locality</mat-label>
            <input matInput formControlName="location">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.phone</mat-label>
            <input matInput formControlName="phone">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.mail</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>

        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label translate>common.remark</mat-label>
            <textarea matInput formControlName="description"></textarea>
        </mat-form-field>
    </form>

    <!-- <pre>data: {{form.value | json}}</pre> -->

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
    <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.cancel' | translate}}</button>
    <button class="btn-primary green primary-hover-animation" cdkFocusInitial (click)="onSubmit()">{{'common.save' | translate}}</button>
</div>
