<h1 mat-dialog-title>
  <span *ngIf="!data.uuid">{{'common.add_user' | translate}}</span>
  <span *ngIf="!!data.uuid">{{'common.edit_user' | translate}}</span>
</h1>
<div mat-dialog-content>
  <div class="mt-2">

    <form [formGroup]="form">

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>common.first_name</mat-label>
        <input matInput formControlName="first_name">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>common.last_name</mat-label>
        <input matInput formControlName="last_name">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>common.mail</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>common.phone</mat-label>
        <input matInput formControlName="phone">
      </mat-form-field>

      <!-- <div style="display: flex;flex-direction: column;"> -->
      <div *ngIf="data.editMode === 'update' && data.preference_favorite_panel == 'driver'">

        <mat-checkbox class="ps-1" formControlName="permission_pipeline">{{'common.access_to_approval_pipeline' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_dispatch">{{'common.access_to_dispatch_pipeline' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_invoicing">{{'common.access_to_invoices' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1"
          formControlName="permission_client_management">{{'common.access_to_customer_management' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1"
          formControlName="permission_empties_management">{{'common.access_to_empty_container_management' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1"
          formControlName="permission_warehouse_management">{{'common.access_to_warehouse_management' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_activity">{{'common.access_to_activity_dashboard' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_export">{{'common.access_to_export' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_user_management"
          *ngIf="data.uuid !== authService.getUserUUID()">{{'common.access_to_user_management' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1"
          formControlName="permission_org_management">{{'common.access_to_organization_management' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_settings">{{'common.access_to_settings' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1"
          formControlName="permission_see_pricing">{{'carrier_dashboard.user-editor-permission.prices-visible' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_alerts_place">{{'common.access_to_warning_pipeline' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_market_place">{{'common.access_to_marketplace' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_new_shipments">{{'common.access_to_shipment_capture' |
          translate}}</mat-checkbox>

        <mat-checkbox class="ps-1" formControlName="permission_shipper_panel">{{'common.access_to_shipper_view' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_carrier_panel">{{'common.access_to_dispatcher_view' |
          translate}}</mat-checkbox>
        <mat-checkbox class="ps-1" formControlName="permission_driver_panel">{{'common.access_to_driver_view' |
          translate}}</mat-checkbox>
      </div>

      <!-- </div> -->

    </form>

    <!-- <pre>{{data | json}}</pre> -->
    <div *ngIf="!data.uuid" class="alert alert-info mt-4">
      {{'common.you_can_also_adjust_all_user_details_later' | translate}}
    </div>

  </div>

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.cancel' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>
