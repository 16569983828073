import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IClient } from '../../services/clients.service';
import { IApiToken } from '../../interfaces/token.interface';

@Component({
  selector: 'app-api-key-editor',
  templateUrl: 'api-key-editor.component.html'
})
export class ApiKeyEditorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ApiKeyEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IApiToken,
  ) { }

  async ngOnInit() {
  }

  onSelectedClient(event: IClient) {
    this.data.client = event.id
  }
}
