import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ShipmentPriceMode } from '../services/shipments.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'priceModePipe' })
export class PriceModePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(type: any): string {
    switch (type) {
      case ShipmentPriceMode.UNKNOWN:
        return this.translate.instant('common.unknown');
      case ShipmentPriceMode.BUYER_SET:
        return this.translate.instant('common.flat_rate_from_buyer');
      case ShipmentPriceMode.SELLER_SET:
        return this.translate.instant('common.flat_rate_from_seller');
      case ShipmentPriceMode.AUCTION:
        return this.translate.instant('common.auction');
    }

    return '-'
  }
}
