import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';

export interface IVehicle {
  id?: string;
  uuid?: string;
  number: string;
  model: string;
  max_weight: number;
  max_ground_spaces: number;
  description: string
}


@Injectable({
  providedIn: 'root'
})
export class FleetService {

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getVehicles(): Observable<IVehicle[]> {
    return this.http.get<IVehicle[]>(this._urls.VEHICLES)
  }

  public getVehicle(vehicleUUID: string): Observable<any> {
    return this.http.get<any>(this._urls.VEHICLES_BY_UUID(vehicleUUID))
  }

  public updateVehicle(vehicleUUID: string, client: IVehicle): Observable<any> {
    return this.http.patch<any>(this._urls.VEHICLES_BY_UUID(vehicleUUID), client)
  }

  public createVehicle(client: IVehicle): Observable<any> {
    return this.http.post<any>(this._urls.VEHICLES, client)
  }

  public deleteVehicle(vehicleUUID: string): Observable<any> {
    return this.http.delete<any>(this._urls.VEHICLES_BY_UUID(vehicleUUID))
  }

}
