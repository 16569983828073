<div class="container-fluid">
    <mat-card class="mt-4" *ngIf="invoice">
        <mat-card-content>

            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'common.invoice' | translate}}: {{invoice.number}}</h1>
            </div>
            <!-- <pre>{{invoice | json}}</pre> -->

            <h4>{{'common.details' | translate}}:</h4>
            <ul class="co-simple-list">
                <li>{{'common.number' | translate}}: {{invoice.number}}</li>
                <li>{{'common.client' | translate}}: <a
                        routerLink="/main/carrier/clients/{{invoice.client_object?.uuid}}/details">{{invoice.client_object?.name}}</a>
                </li>
                <li>{{'common.invoice_date' | translate}}: {{invoice.invoice_date}} </li>
                <li>{{'common.payable_by' | translate}}: {{invoice.pay_until_date}} </li>
                <li>{{'common.total_amount' | translate}}: {{ownOrganization?.default_currency}} {{invoice.total_price_without_vat | pricePipe}} </li>
                <li>{{'common.total_amount' | translate}} ({{'common.incl_vat' | translate}}): {{ownOrganization?.default_currency}} {{invoice.total_price_with_vat | pricePipe}} </li>
                <li>{{'common.status' | translate}}: {{invoice.status | invoiceStatusPipe}} </li>
            </ul>

            <h4>{{'common.shipments' | translate}}:</h4>
            <ul class="co-simple-list">
                <li *ngFor="let shipment of invoice.shipments ">{{'common.number' | translate}}: <a
                        routerLink="/main/carrier/shipments/{{shipment.uuid}}">{{shipment.number |
                        shipmentNumberPipe}}</a></li>
            </ul>


        </mat-card-content>
    </mat-card>
</div>
