import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { IShipment } from './shipments.service';

@Injectable({
  providedIn: 'root'
})
export class PublicShipmentsService {
  private _httpOptions = {
    responseType: 'arraybuffer' as 'json',
  };

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getPublicShipmentDetails(tracking_uuid: string): Observable<Partial<IShipment>> {
    return this.http.get<IShipment>(this._urls.PUBLIC_SHIPMENT_DETAILS(tracking_uuid))
  }

  public addPublicShipmentComment(tracking_uuid: string, content: string): Observable<any> {
    return this.http.post<any>(this._urls.PUBLIC_SHIPMENT_COMMENT(tracking_uuid), {content})
  }

  public getDeliverySlip(tracking_uuid: string) {
    return this.http.get<any>(this._urls.PUBLIC_DELIVERY_SLIP_SHIPMENTS_BY_TRACKING_UUID(tracking_uuid), this._httpOptions)
  }

}
