import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IFilterProperty, IFilterOutput } from './filter.interface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-filtered-tags',
  templateUrl: './filtered-tags.component.html',
  styleUrls: ['./filtered-tags.component.scss']
})
export class FilteredTagsComponent implements OnInit {

  debugging = false;

  @Input() sortingOptions: any = [
    { name: this.translate.instant('common.pickup_date'), value: 'pickup_date'},
    { name: this.translate.instant('common.delivery_date'), value: 'delivery_date'},
    { name: this.translate.instant('common.pickup_postcode'), value: 'pickup_zip'},
    { name: this.translate.instant('common.delivery_postcode'), value: 'delivery_zip'},
    { name: this.translate.instant('common.creation_date'), value: 'created_at'},
    { name: this.translate.instant('common.last_updated'), value: 'updated_at' },
    { name: this.translate.instant('common.total_weight'), value: 'total_weight'},
  ];

  @Input() searchHistoryEntries: any = [
    { name: 'pickup date' },
    { name: 'delivery date' },
    { name: 'pickup zip' },
    { name: 'delivery zip' },
    { name: 'creating date' },
    { name: 'updated date' },
  ];

  @Input() hasSelectionAll: boolean = true;
  @Input() hasHistory: boolean = false;

  @Input() filterGroups: { filterTags: IFilterProperty[] }[] = []

  @Input() filterProperties: IFilterProperty[] = []

  @Output() onUpdateFilterEvent: EventEmitter<IFilterOutput> = new EventEmitter()
  @Output() onChangeSelectionEvent: EventEmitter<boolean> = new EventEmitter()
  @Output() onChangeSortingEvent: EventEmitter<any> = new EventEmitter()

  showFirstFilterList = false;
  showSecondFilterList = false;
  showThirdFilterList = false;

  lastProperty: IFilterProperty | null = null;

  @ViewChild('FirstFilterList') firstFilterList: ElementRef | undefined;
  @ViewChild('SecondFilterList') secondFilterList: ElementRef | undefined;
  @ViewChild('ThirdFilterList') thirdFilterList: ElementRef | undefined;

  constructor(
    private eRef: ElementRef,
    private translate: TranslateService
  ) {
  }

  async ngOnInit() {
  }

  @HostListener('document:click', ['$event'])
  clickout(event: any) {
    if (!this.eRef.nativeElement.contains(event.target) && !this.isClickInsideFirstDiv(event)) {
        this.showFirstFilterList = false;
    }
  }

  isClickInsideFirstDiv(event: any) {
    return this.firstFilterList ? this.firstFilterList.nativeElement.contains(event.target) : false;
  }

  isClickInsideSecondDiv(event: any) {
    return this.secondFilterList ? this.secondFilterList.nativeElement.contains(event.target) : false;
  }

  isClickInsideThirdDiv(event: any) {
    return this.thirdFilterList ? this.thirdFilterList.nativeElement.contains(event.target) : false;
  }

  onSelectFilterProperty(property: IFilterProperty) {
    this.filterGroups.push({ filterTags: [property] })
    this.lastProperty = property;
    // TODO show other drop downs
    this.showFirstFilterList = false;
    this.showSecondFilterList = true;
  }

  onSelectSecondProperty(property: IFilterProperty) {
    this.filterGroups[this.filterGroups.length - 1].filterTags.push(property)
    this.showSecondFilterList = false;
    this.showThirdFilterList = true;
  }

  onSelectThirdProperty(property: IFilterProperty) {
    this.filterGroups[this.filterGroups.length - 1].filterTags.push(property)
    this.showThirdFilterList = false;
    this.onUpdateFiltering()
  }

  deleteFilterGroup(filterGroup: any) {
    this.filterGroups = this.filterGroups.filter(el => el != filterGroup);
    this.onUpdateFiltering()
  }

  deleteFilterGroups() {
    this.filterGroups = []
    this.showFirstFilterList = false;
    this.showSecondFilterList = false;
    this.showThirdFilterList = false;
    this.onUpdateFiltering()
  }

  newSearchInput(event: KeyboardEvent) {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      if (this.showFirstFilterList) {
        this.showFirstFilterList = false;
      } else if (this.showSecondFilterList) {
        this.showSecondFilterList = false;
      } else if (this.showThirdFilterList) {
        this.showThirdFilterList = false;
      } else if (this.filterGroups.length >= 1) {
        this.filterGroups.pop();
      }
    }
  }

  onChangeSelection(event: any) {
    console.log(event)
    console.log(event.target.checked)
    this.onChangeSelectionEvent.emit(event.target.checked)
  }

  onChangeSorting(event: any) {
    console.log(event.target.value); // logs the value of the selected option
    this.onChangeSortingEvent.emit(event.target.value)
  }

  onUpdateFiltering() {
    this.onUpdateFilterEvent.emit({
      filters: this.filterGroups
    })
  }

}
