import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { formatDistance } from 'date-fns';
import { de, enGB, pl } from 'date-fns/locale';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'humanDatePipe' })
export class HumanDatePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(date?: string | Date, addSuffix = true): string {
    try {
      if (!!date) {
        const currentLang = this.translate.currentLang;
        let locale;

        switch (currentLang) {
          case 'de':
            locale = de;
            break;
          case 'en':
            locale = enGB;
            break;
          case 'pl':
            locale = pl;
            break;
        }

        return formatDistance(new Date(date), new Date(), {
          locale: locale,
          addSuffix: true,
        });
      }
      return '-';
    } catch (error) {
      console.error(error);
      return '-';
    }
  }
}
