import { Component } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-language-changer',
  templateUrl: './language-changer.component.html',
  styleUrl: './language-changer.component.scss'
})
export class LanguageChangerComponent {
  constructor(
    public languageService: LanguageService
  ) { }

}
