<h1 mat-dialog-title>
  <span>{{'common.add_image' | translate}}</span>
</h1>
<div mat-dialog-content>

  <button mat-flat-button color="primary" class="w-100" (click)="fileUpload.click()" [disabled]="isLoading">
    {{'common.upload' | translate}}
  </button>

  <input type="file" class="file-input" (change)="onFileSelected($event)" #fileUpload>

  <!-- <pre>{{data | json}}</pre> -->
</div>

<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">{{'common.close' | translate}}</button>
</div>
