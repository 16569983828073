import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { versions } from 'projects/versions';
import { LicenseModalComponent } from 'projects/webapp/app/components/license-modal/license-modal.component';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { ShipmentsService } from 'projects/webapp/app/services/shipments.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-carrier-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class CarrierDashboardComponent implements OnInit {

  versions = versions;
  isLoading = false;

  dashboardNumbers: {
    shipments_for_approval: number,
    shipments_in_market_place: number,
    shipments_in_alert: number,
    shipments_to_pickup: number,
    shipments_to_deliver: number,
    shipments_in_vehicle: number,
  } | null = null;

  constructor(
    private router: Router,
    private title: Title,
    private carrierShipmentsService: CarrierShipmentsService,
    public organizationsService: OrganizationsService,
    public usersService: UsersService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.translate.get('title_cargoflow_dashboard').subscribe(val=>{
      this.title.setTitle(val);
    })
  }

  async ngOnInit() {
    this.refresh()
  }


  async refresh() {
    try {
      this.isLoading = true;
      this.dashboardNumbers = await lastValueFrom(this.carrierShipmentsService.getDashboardNumbers())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

  navigateToPipeline() {
    if (!this.organizationsService.ownOrg$.value?.admin_permission_software_license) {
      this.dialog.open(LicenseModalComponent,
        { disableClose: true })
      return
    }
    this.router.navigate(['/main/carrier/pipeline-release']);
  }

  navigateToAlerts() {
    if (!this.organizationsService.ownOrg$.value?.admin_permission_software_license) {
      this.dialog.open(LicenseModalComponent,
        { disableClose: true })
      return
    }
    this.router.navigate(['/main/carrier/alerts']);
  }

  navigateToMarketPlace() {
    this.router.navigate(['/main/carrier/market-place'])
  }
}
