import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { versions } from 'projects/versions';
import { AuthService, OrganizationType } from 'projects/webapp/app/services/auth.service';
import { LanguageService } from 'projects/webapp/app/services/language.service';
import { UsersService } from 'projects/webapp/app/services/users.service';


@Component({
  selector: 'app-panel-chooser',
  templateUrl: './panel-chooser.component.html',
  styleUrls: ['./panel-chooser.component.scss']
})
export class PanelChooserComponent implements OnInit {

  versions = versions;
  isLoading = false;
  savePreference = true;

  constructor(
    private router: Router,
    private title: Title,
    private usersService: UsersService,
    public authService: AuthService,
    public languageService: LanguageService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.cockpit_selection').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  ngOnInit() {
    // 1. check if org has global preference
    // 2. then check if the user has a preference
    // 3. if no org pref and no user pref, check their permissions
    if (this.authService.hasOrganizationTypePreference() == OrganizationType.CARRIER){
      console.log('no option for carrier panel -> go to shipper panel')
      this.goTo('carrier')
      return;
    }

    if (this.authService.hasOrganizationTypePreference() == OrganizationType.SHIPPER) {
      console.log('no option for carrier panel -> go to shipper panel')
      this.goTo('shipper')
      return;
    }

    const preference_favorite_panel = this.usersService.ownUser$.value?.preference_favorite_panel
    if (preference_favorite_panel) {
      this.goTo(preference_favorite_panel)
      return;
    }

    // if (!this.usersService.ownUser$.value?.permission_shipper_panel) {
    //   console.log('no permission for shipper panel -> go to carrier panel')
    //   this.goTo('carrier')
    //   return;
    // }

    // if (!this.usersService.ownUser$.value?.permission_carrier_panel) {
    //   console.log('no permission for carrier panel -> go to shipper panel')
    //   this.goTo('shipper')
    //   return;
    // }

  }

  goTo(type: 'shipper' | 'carrier' | 'driver' | 'admin') {
    this.router.navigate([`/main/${type}/dashboard`])
  }

  logout(){
    this.authService.logout(true)
    this.router.navigate([`/`])
  }

  onLanguageSelect(arg0: string) {
    this.languageService.changeLanguage(arg0)
  }
}
