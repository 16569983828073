import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-public-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss',
  ]
})
export class PublicHeaderComponent implements OnInit, OnDestroy {
  // private subscription: Subscription;
  public currentPath = '';
  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.currentPath = location.pathname;
    console.log('Current Path:', this.currentPath);
    // this.subscription = this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe((event: any) => {
    //     // Handle the path change event
    //     this.currentPath = event.url;
    //     console.log('Current Path:', this.currentPath);
    //   });
  }

  ngOnDestroy(): void {
    // if (this.subscription) {
    //   this.subscription.unsubscribe();
    // }
  }
}
