import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientUrlConstantsService {

  environment = environment
  baseUrl = this.environment.baseUrl

  public CALCULATE_PRICE = `${this.environment.baseUrl}/api/v1/client/calculate-price`;
  public VERIFY_SITEKEY = `${this.environment.baseUrl}/api/v1/client/verify-token`;
  public REQUEST_SHIPMENT = `${this.environment.baseUrl}/api/v1/client/request-shipment`;
  public SEARCH_SHIPMENT = `${this.environment.baseUrl}/api/v1/client/search-shipment`;

  constructor() { }
}
