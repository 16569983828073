import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';

export interface IMessage {
  id?: number,
  uuid: string,
  created_at: Date,
  updated_at: Date,

  title: string,
  description: string,
  needs_read_confirmation: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getMessages(): Observable<IMessage[]> {
    return this.http.get<IMessage[]>(this._urls.MESSAGES)
  }

  public markAsViewed(messageUUID: string): Observable<unknown> {
    return this.http.post<unknown>(this._urls.MESSAGES_BY_ID(messageUUID), {})
  }

  public markAsRead(messageUUID: string): Observable<unknown> {
    return this.http.delete<any>(this._urls.MESSAGES_BY_ID(messageUUID))
  }

}
