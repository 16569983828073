import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { versions } from 'projects/versions';
import { IAdminShipmentAuction } from 'projects/webapp/app/interfaces/auction.interface';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-admin-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss']
})
export class AdminAuctionsComponent implements OnInit {
  isLoading = true;
  versions = versions;
  items: IAdminShipmentAuction[] = []

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.items = await lastValueFrom(this.adminService.getAuctions())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }
}
