import { Component } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'projects/webapp/app/services/auth.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent {
  form: FormGroup = new FormGroup({
    username: new FormControl(''),
  });

  showSuccessMessage = false;

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    console.log('ForgetPasswordComponent')
  }

  onSubmit() {
    this.authService.forgotPassword(this.form.value).subscribe((res: any) => {
      if (res) {
        // this.router.navigate(['/']);
        this.showSuccessMessage = true;
      } else {
        // this.user.username = '';
        // this.user.password = '';
        this.form.patchValue({ password: '' })
      }
    });
  }
}
