import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDetailedTour, TourService, TourStatus } from '../../services/tour.service';


@Component({
  selector: 'app-tour-selector-modal',
  template: `
  <h1 mat-dialog-title>
    <span>{{'common.dispatch' | translate}}</span>
  </h1>
  <div mat-dialog-content>
    <div class="mt-2">
      <app-tour-selector [filter]="data.filter" (onSelectedTour)="onSelectedTour($event)"></app-tour-selector>
    </div>
  </div>
  <div mat-dialog-actions style="display: flex; justify-content: space-evenly">
      <button mat-button color="secondary" mat-dialog-close="">{{'common.cancel' | translate}}</button>
  </div>
  `
})
export class TourSelectorModalComponent {

  constructor(
    public tourService: TourService,
    public dialogRef: MatDialogRef<TourSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { filter: TourStatus[] },
  ) {
  }

  onSelectedTour(option: IDetailedTour) {
    this.dialogRef.close(option)
  }

}
