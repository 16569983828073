import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { formatRelative, subDays } from 'date-fns'
import { IShipment, ShipmentsService } from 'projects/webapp/app/services/shipments.service';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

formatRelative(subDays(new Date(), 3), new Date())

@Component({
  selector: 'app-admin-shipment-search',
  templateUrl: './shipment-search.component.html',
  styleUrls: ['./shipment-search.component.scss']
})
export class AdminShipmentSearchComponent implements OnInit {
  foundShipments: Partial<IShipment>[] = [];
  public isLoading = false;
  public searchForm: FormGroup = new FormGroup({ search: new FormControl('') });

  constructor(
    private title: Title,
    private adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
  ) {
  }

  async ngOnInit() {
    this.searchForm.patchValue({ search: this.route.snapshot.queryParams['search'] ?? '' });
    this.onSearch();

    this.searchForm.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(400))
      .subscribe((value) => {
        console.log(value)
        this.router.navigate([], {
          queryParams: {
            search: value.search
          }, replaceUrl: true
        });
        this.onSearch();
      });
  }

  async onSearch() {
    this.isLoading = true;
    this.title.setTitle(`Cargoflow: Admin: Search ${this.searchForm.value.search}`);
    this.foundShipments = await lastValueFrom(this.adminService.searchShipments({ search: this.searchForm.value.search }))
    this.isLoading = false;
  }

}
