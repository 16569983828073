import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';


@Component({
  selector: 'app-organization-overview-modal',
  templateUrl: './organization-overview-modal.component.html',
  styleUrls: ['./organization-overview-modal.component.scss']
})
export class OrganizationOverviewModalComponent implements OnInit {

  ownOrganization: IOrganization | null = null;

  constructor(
    public dialogRef: MatDialogRef<OrganizationOverviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
  }

}
