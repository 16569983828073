import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ClientSelectorModalComponent } from 'projects/webapp/app/components/client-selector-modal/client-selector.component';
import { ShipmentCommentModal } from 'projects/webapp/app/components/shipment-comment-modal.component';
import { UserSelectorModalComponent } from 'projects/webapp/app/components/user-selector-modal/user-selector.component';
import { environment } from 'projects/webapp/app/environments/environment';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { IShipmentComment } from 'projects/webapp/app/interfaces/shipment-comment.interface';
import { IDetailedShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { IClient } from 'projects/webapp/app/services/clients.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IShipment, ShipmentStatus } from 'projects/webapp/app/services/shipments.service';
import { ShipperShipmentsService } from 'projects/webapp/app/services/shipper-shipments.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-shipper-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class ShipperShipmentDetailsComponent implements OnInit {

  isLoading = false;
  shipmentUUID: string | null = null;
  shipment: IDetailedShipment | null = null;

  hasShare = false;
  environment = environment;
  privateShipmentComments: IShipmentComment[] = []
  publicShipmentComments: IShipmentComment[] = []

  shipmentStatus = ShipmentStatus

  ownOrganization: IOrganization | null = null;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private shipperShipmentsService: ShipperShipmentsService,
    public usersService: UsersService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.translateService.get('common.dispach_details').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  ngOnInit() {
    this.fetchOrganization();

    if (navigator.canShare as any) {
      this.hasShare = true;
    }

    console.log('DashboardComponent')
    this.route.params.subscribe(params => {
      if (!!params && params['shipmentUUID']) {
        this.shipmentUUID = params['shipmentUUID'];
        this.refreshShipment();
      }
    })
  }

  async fetchOrganization() {
    try {
      const organization = await this.organizationService.getOwnOrganization().toPromise();
      this.ownOrganization = organization || null; // Handle undefined case
    } catch (error) {
      console.error('Error fetching own organization', error);
      this.ownOrganization = null; // Ensure ownOrganization is set to null on error
    }
  }

  async refreshShipment() {
    if (this.shipmentUUID) {
      this.refreshShipmentCore()
      this.refreshPrivateComments()
      this.refreshPublicComments()
    }
  }

  async refreshShipmentCore() {
    const errorMessage = await lastValueFrom(this.translateService.get('toastr.shipment_not_found'));

    try {
      this.shipment = await lastValueFrom(this.shipperShipmentsService.getShipment(this.shipmentUUID as any));
    } catch (error) {
      console.error(error)
      this.toastrService.error(errorMessage)
    }
  }

  async refreshPrivateComments() {
    // this.privateShipmentComments = await lastValueFrom(this.shipperShipmentsService.getPrivateCommentsShipment(this.shipmentUUID as any))
  }

  async refreshPublicComments() {
    this.publicShipmentComments = await lastValueFrom(this.shipperShipmentsService.getPublicCommentsShipment(this.shipmentUUID as any))
  }

  // changeClient() {
  //   this.dialog.open(ClientSelectorModalComponent).afterClosed().subscribe(async (val: IClient | null) => {
  //     if (!!val && val.id && this.shipment?.uuid) {
  //       console.log(val);
  //       this.shipment.client = val.id;
  //       await lastValueFrom(this.shipperShipmentsService.updateShipment(this.shipment.uuid, this.shipment));
  //       this.refreshShipmentCore()
  //     }
  //   })
  // }

  editCurrentDriver() {
    const data = this.shipment?.assigned_driver_object
    this.dialog.open(UserSelectorModalComponent, { data }).afterClosed().subscribe(async (val) => {
      if (!!val && this.shipment?.uuid) {
        this.shipment.assigned_driver = val.id;
        await lastValueFrom(this.shipperShipmentsService.updateShipment(this.shipment.uuid, this.shipment))
        this.refreshShipmentCore();
      }
    })
  }

  toBeImplemented() {
    alert('coming soon...')
  }

  // onAddPrivateComment() {
  //   this.dialog.open(ShipmentCommentModal).afterClosed().subscribe(async val => {
  //     if (!!val && this.shipment?.uuid) {
  //       await lastValueFrom(this.shipperShipmentsService.addPrivateCommentShipment(this.shipment?.uuid, val));
  //       this.refreshPrivateComments()
  //     }
  //   })
  // }

  onAddPublicComment() {
    this.dialog.open(ShipmentCommentModal).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        await lastValueFrom(this.shipperShipmentsService.addPublicCommentShipment(this.shipment?.uuid, val));
        this.refreshPublicComments()
      }
    })
  }

  onUpdateComment(comment: IShipmentComment) {
    const data = comment.content;
    this.dialog.open(ShipmentCommentModal, { data }).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        comment.content = val;
        await lastValueFrom(this.shipperShipmentsService.updateCommentsShipment(this.shipment?.uuid, comment.uuid, comment))
        this.refreshPrivateComments();
        this.refreshPublicComments();
      }
    })
  }

  async onDeleteComment(comment: IShipmentComment) {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_commentary_cancelation'));

    if (confirm(confirmMessage) && this.shipment?.uuid) {
      await lastValueFrom(this.shipperShipmentsService.deleteCommentsShipment(this.shipment?.uuid, comment.uuid));
      this.refreshPrivateComments();
      this.refreshPublicComments();
    }
  }

  openInGoogleMaps(arg: 'pickup' | 'delivery') {
    let url = ''
    if (arg == 'pickup') {
      url = 'http://maps.google.com/?q=' + `${this.shipment?.pickup_address}, ${this.shipment?.pickup_zip} ${this.shipment?.pickup_location}`;
    } else {
      url = 'http://maps.google.com/?q=' + `${this.shipment?.delivery_address}, ${this.shipment?.delivery_zip} ${this.shipment?.delivery_location}`;
    }
    window.open(url, '_blank');
  }

  share() {
    if (navigator.share) {
      navigator
        .share({
          title: `Sendung ${this.shipment?.number}`,
          url: `${environment.uiDomain}/public/shipment-tracking/${this.shipment?.tracking_uuid}`,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    }
  }

  async cancelShipment() {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_shipment_deletion'));
    const infoMessage = await lastValueFrom(this.translateService.get('toastr.shipment_cancelled'));
    if (confirm(confirmMessage) && this.shipmentUUID) {
      await lastValueFrom(this.shipperShipmentsService.cancelShipment(this.shipmentUUID));
      this.toastrService.info(infoMessage)
      this.refreshShipment();
    }
  }
}
