import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IUser } from '../../services/users.service';
import { AuthService } from '../../services/auth.service';
import { AdvertisementImage, AdvertisementsService, IAdvertisement } from '../../services/advertisement.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-advertisement-details',
  templateUrl: './advertisement-details.component.html',
  styleUrls: ['./advertisement-details.component.scss']
})
export class AdvertisementDetailsComponent implements OnInit {

  images: AdvertisementImage[] = []

  constructor(
    public dialogRef: MatDialogRef<AdvertisementDetailsComponent>,
    public authService: AuthService,
    private advertisementsService: AdvertisementsService,
    @Inject(MAT_DIALOG_DATA) public data: IAdvertisement,
  ) { }

  async ngOnInit() {
    if (this.data.uuid) {
      try {
        this.images = await lastValueFrom(this.advertisementsService.getImages(this.data.uuid))
      } catch (error) {
        console.error(error)
      }
    }
  }

}
