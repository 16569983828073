<div class="container">
    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>Dock & Yard Management</h1>
            </div>

            <div class="text-center mt-5">
                <div class="alert alert-info">coming soon...</div>
            </div>

        </mat-card-content>
    </mat-card>
</div>