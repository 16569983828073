import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDetailedTour } from '../../services/tour.service';


@Component({
  selector: 'app-tour-card',
  templateUrl: './tour-card.component.html',
  styleUrls: ['./tour-card.component.scss']
})
export class TourCardComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TourCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDetailedTour,
  ) { }

  async ngOnInit() {
  }

}
