<div class="container mb-5 pt-5" style="min-height: 90vh;">

    <div *ngIf="!isLoading && shipment">

        <div class="d-flex justify-content-between" style="align-items: center;">
            <h1>{{'common.shipment' | translate}}: {{shipment.number | shipmentNumberPipe}}</h1>
            <div class="alert alert-info">
                <strong>
                    {{'common.status' | translate}}: {{shipment.status | shipmentStatusPipe}}
                </strong>
            </div>
        </div>

        <mat-card class="mt-3">
            <mat-card-content>
                <div #mapElement class="map" id="map" class="match-parent"></div>
            </mat-card-content>
        </mat-card>

        <mat-card class="mt-3">
            <mat-card-content>
                <div class="row text-center" style="align-items: center;">
                    <!-- <div class="col-3">
                        <img src="assets/shipment-tracking/icons8-lkw-100.png" style="height: 40px;" class="mx-1">
                    </div> -->
                    <div class="col-4">
                        <strong>
                            {{numberOfCargoItems}} {{'common.cargoes' | translate}}
                        </strong>
                    </div>
                    <div class="col-4">
                        <strong>
                            {{shipment.total_weight}} Kg
                        </strong>
                    </div>
                    <div class="col-4">
                        <button class="btn btn-outline-primary" (click)="notImplementedYet()">{{'common.delivery_slip' | translate}}</button>
                    </div>
                    <!-- <div class="col-4">
                        <button class="btn btn-outline-primary" (click)="notImplementedYet()">Nachricht</button>
                    </div> -->
                </div>
            </mat-card-content>
        </mat-card>

        <div class="row" style="display: flex; align-items: stretch">
            <div class="col-6">
                <mat-card class="mt-3">
                    <mat-card-content>
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <h1>{{'common.from' | translate}}</h1>
                        </div>

                        <div>
                            <ul class="co-simple-list">
                                <li>{{shipment.pickup_company}}</li>
                                <li>{{shipment.pickup_name}}</li>
                                <li>{{shipment.pickup_address}}</li>
                                <li>{{shipment.pickup_zip}} {{shipment.pickup_location}}</li>
                            </ul>
                        </div>
                    </mat-card-content>
                </mat-card>

            </div>
            <div class="col-6">
                <mat-card class="mt-3">
                    <mat-card-content>
                        <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <h1 translate>common.to</h1>
                        </div>

                        <div>

                            <ul class="co-simple-list">
                                <li>{{shipment.delivery_company}}</li>
                                <li>{{shipment.delivery_name}}</li>
                                <li>{{shipment.delivery_address}}</li>
                                <li>{{shipment.delivery_zip}} {{shipment.delivery_location}}</li>
                            </ul>
                        </div>
                    </mat-card-content>
                </mat-card>

            </div>
        </div>

        <app-shipment-events *ngIf="shipment.events" [events]="shipment.events"> </app-shipment-events>

        <mat-card class="mt-3">
            <mat-card-content>
                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                    <h1>{{'common.comments' | translate}}</h1>

                    <button mat-icon-button (click)="onAddPublicComment()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>


                <div class="alert alert-info" *ngIf="shipment.comments?.length == 0">
                    {{'common.no_comments_yet' | translate}}
                </div>

                <div>
                    <ul class="co-simple-list">
                        <div class="alert alert-light" *ngFor="let comment of shipment.comments">

                            <span *ngIf="comment.author_object">
                                {{comment.author_object.first_name}} {{comment.author_object.last_name}}
                                ({{comment.author_object.organization}})
                            </span>
                            <span>
                                {{comment.updated_at | humanDatePipe}}:
                            </span>
                            <span>
                                {{comment.content}}
                            </span>

                        </div>
                    </ul>

                </div>

                <!-- <app-shipment-tracking-timeline></app-shipment-tracking-timeline> -->
            </mat-card-content>
        </mat-card>

    </div>

    <div *ngIf="!isLoading && !shipment" class="alert alert-info mt-4 text-center">
        {{'common.no_shipment_found_with_this_tracking_code' | translate}}
    </div>
    <!-- <pre>{{isLoading | json}}</pre>
    <pre>{{shipment | json}}</pre> -->
</div>

<footer>
    <div class="text-center mt-4 mb-4">

        <div class="px-5 py-2"><a href="https://stats.uptimerobot.com/43xPOhzOrx" target="_blank"
                class="text-base text-gray-500 hover:text-gray-900">{{'common.status' | translate}}</a>
        </div>

        <div class="text-center">Powered by <a href="https://cargoflow.ch/"
                style="text-decoration: underline;" target="_blank">Cargoflow</a></div>

        <!-- <p class="mt-8 text-center text-base text-gray-500">© 2023 Neptun Design GmbH. All rights
            reserved.
        </p> -->
    </div>
</footer>
