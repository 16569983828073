import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DispatchModalComponent, IDispatchInfo } from 'projects/webapp/app/components/dispatch-modal/dispatch-modal.component';
import { IFilterOutput, IFilterProperty } from 'projects/webapp/app/components/filtered-tags/filter.interface';
import { TourCardComponent } from 'projects/webapp/app/components/tour-card/tour-card.component';
import { TourSelectorModalComponent } from 'projects/webapp/app/components/tour-selector/tour-selector-modal.component';
import { ICarrierShipment, IDispatchShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { FleetService } from 'projects/webapp/app/services/fleet.service';
import { IShipment, ShipmentStatus, ShipmentTaskStatus } from 'projects/webapp/app/services/shipments.service';
import { IDetailedTour, ITour, TourService, TourStatus } from 'projects/webapp/app/services/tour.service';
import { deepCopy, getUniqueObjects } from 'projects/webapp/app/utils';
import { hasTourByFilter, isDatesValidByFilter } from 'projects/webapp/app/utils-shipment';
import { lastValueFrom } from 'rxjs';
import { getMetaDataShipmentFilters } from './shipment-filter-builder';
import { onSorting } from 'projects/webapp/app/components/filtered-tags/filters';
import { TranslateService } from '@ngx-translate/core';
import { TourDatePickerModalComponent } from 'projects/webapp/app/components/tour-date-picker-modal/tour-date-picker-modal.component';


export interface IShipmentsFilter {
  pickupPLZStart: number;
  pickupPLZEnd: number;
  deliveryPLZStart: number;
  deliveryPLZEnd: number;
  pickupDateStart: Date;
  pickupDateEnd: Date;
  deliveryDateStart: Date;
  deliveryDateEnd: Date;
}

@Component({
  selector: 'app-pipeline-dispatch',
  templateUrl: './pipeline-dispatch.component.html',
  styleUrls: ['./pipeline-dispatch.component.scss']
})
export class PipelineDispatchComponent implements OnInit {

  shipmentsForDispatch: IDispatchShipment[] = [];
  isLoading = false;
  shipmentStatus = ShipmentStatus

  tours: IDetailedTour[] = [];

  selectedShipments: IDispatchShipment[] = []
  public filteredShipments: IDispatchShipment[] = [];

  defaultProperties: IFilterProperty[] = [
    {
      title: 'an Tour zugewiesen',
      key: 'has_tour',
      operators: [
        {
          key: 'operator',
          title: '=',
          value: true,
        },
        {
          key: 'operator',
          title: '!=',
          value: false,
        },
      ],
      values: [
        {
          key: 'values',
          title: 'Ja',
          value: true
        },
        {
          key: 'values',
          title: 'Nein',
          value: false,
        },
      ]
    },
  ]

  filterProperties: IFilterProperty[] = deepCopy(this.defaultProperties)

  constructor(
    public carrierShipmentsService: CarrierShipmentsService,
    public fleetService: FleetService,
    public tourService: TourService,
    public dialog: MatDialog,
    public toastrService: ToastrService,
    public translate: TranslateService,
    public spinnerService: NgxSpinnerService,
  ) {
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.shipmentsForDispatch = await lastValueFrom(this.carrierShipmentsService.getShipmentsForDispatch())
      this.filteredShipments = this.shipmentsForDispatch;
      // TODO: add signal to update the filters to reset them...
      this.tours = await lastValueFrom(this.tourService.getOpenTours())

      this.createFilters()

    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  createToursForVehicles() {
    const data = {
      date: new Date(),
    }
    this.dialog.open(TourDatePickerModalComponent, {
      data,
      width: '90vw',
    }).afterClosed().subscribe(async (val: Partial<ITour>) => {
      if (!!val) {
        try {
          this.spinnerService.show();
          const possibleVehicles = await lastValueFrom(this.fleetService.getVehicles())
          for (let index = 0; index < possibleVehicles.length; index++) {
            const vehicle = possibleVehicles[index];
            const data: any = {
              date: val.date.toISOString().split('T')[0],
              weight: 0,
              ground_spaces: 0,
              turnover: 0,
              profit: 0,
              number_of_stops: 0,
              driving_duration: 0,
              driving_distance: 0,
              vehicle: vehicle.id,
            }

            await lastValueFrom(this.tourService.createTour(
              data
            ))
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    });
  }

  createFilters() {
    const clients: any[] = []
    this.filterProperties = deepCopy(this.defaultProperties)

    for (let index = 0; index < this.filteredShipments.length; index++) {
      const element = this.filteredShipments[index];
      if (element.client_object && element.client_object.name && element.client) {
        clients.push(
          {
            title: element.client_object?.name,
            key: `${element.client}`,
            value: `${element.client}`
          })
      }
    }

    const cleanUpClients = getUniqueObjects(clients);

    this.filterProperties.forEach(shipment => {
      if (shipment.key == 'client') {
        shipment.values = cleanUpClients
      }
    });

    console.log(this.filterProperties)

    this.filterProperties.push({
      title: 'Kunde',
      key: 'client',
      operators: [
        {
          key: 'operator',
          title: '='
        },
        {
          key: 'operator',
          title: '!='
        },
      ],
      values: cleanUpClients
    })

    try {
      this.filterProperties = [...this.filterProperties, ...getMetaDataShipmentFilters(this.filteredShipments)]
    } catch (error) {
      console.error(error)
    }
  }

  // TODO: move this stuff to the tour editor
  dispatchShipment(shipment: IShipment) {
    const data: IDispatchInfo = {
      user: shipment.assigned_driver_object as any,
      hub: null,
      mode: ShipmentTaskStatus.PICKUP_AND_DELIVERY_DIRECTLY,
      current_loc: shipment.current_loc
    }
    console.log(data)
    this.dialog.open(DispatchModalComponent, { data, minWidth: '80vw' }).afterClosed().subscribe(async (val) => {
      if (!!val && shipment.uuid) {
        const infoMessage = await lastValueFrom(this.translate.get('toastr.shipment_deleted'));

        console.log(val)
        await lastValueFrom(this.carrierShipmentsService.dispatchShipment(shipment.uuid, val));
        this.toastrService.info(infoMessage);
        this.refresh();
      }
    })
  }

  public applyFilters(filtersValue: IShipmentsFilter | any): void {
    console.log(`filtering:`)
    console.log(filtersValue)
    this.filteredShipments = this.shipmentsForDispatch.filter((shipment) => {

      const dateFilter = isDatesValidByFilter(shipment, filtersValue);
      const hasTourFilter = hasTourByFilter(shipment, filtersValue);
      // return value && dateFilter;
      return dateFilter && hasTourFilter;
    });
  }

  onShowTourDetails(tour: ITour) {
    this.dialog.open(TourCardComponent, { data: tour })
  }

  onToggleSelection(event: any, shipment: ICarrierShipment): void {
    // const inputElement = event.target as HTMLInputElement;
    console.log(event.checked)
    console.log(shipment)

    const index = this.selectedShipments.findIndex(s => s.uuid === shipment.uuid);

    if (index !== -1) {
      this.selectedShipments = this.selectedShipments.filter(s => s.uuid !== shipment.uuid);
    } else {
      this.selectedShipments = [...this.selectedShipments, shipment];
    }
  }

  isShipmentChecked(shipment: ICarrierShipment) {
    return this.selectedShipments.find(s => s.uuid === shipment.uuid) ? true : false;
  }

  dispatchToTour() {
    const data = { filter: [TourStatus.IN_PROGRESS] }
    this.dialog.open(TourSelectorModalComponent, { data }).afterClosed().subscribe(async (val: IDetailedTour) => {
      if (!!val && val.uuid) {
        console.log(`want to add ${this.selectedShipments.length} to the tour ${val.vehicle_object?.number}`);
        await lastValueFrom(this.carrierShipmentsService.assignShipmentsToTour(val.uuid, this.selectedShipments));
        this.toastrService.info(this.translate.instant('toastr.tour_updated'));
        this.refresh();
      }
    })
  }

  onChangeSelectionEvent(state: boolean) {
    console.log(state)
    if (state) {
      this.selectedShipments = this.filteredShipments;
    } else {
      this.selectedShipments = [];
    }
  }

  onChangeFilterEvent(filterOutput: IFilterOutput) {
    console.log(filterOutput)
    this.filteredShipments = this.shipmentsForDispatch;

    for (let index = 0; index < filterOutput.filters.length; index++) {
      const element = filterOutput.filters[index];
      this.filteredShipments = this.filteredShipments.filter(shipment => {
        // Special case meta data
        if (element.filterTags[0].title.includes('Meta data:')) {
          if (!!shipment.meta_data[element.filterTags[0].key]) {
            if (element.filterTags[1].title == '=') {
              if (shipment.meta_data[element.filterTags[0].key] == element.filterTags[2].value) {
                return true;
              }
              return false;
            } else if (element.filterTags[1].title == '!=') {
              if (shipment.meta_data[element.filterTags[0].key] != element.filterTags[2].value) {
                return true;
              }
              return false;
            }
            console.error('we should not arrive here')
            return false
          }
          // Special case has tour
        } else if (element.filterTags[0].key == 'has_tour') {
          if (element.filterTags[1].title == '=') {
            return !!shipment.assigned_tour_object == element.filterTags[2].value
          } else if (element.filterTags[1].title == '!=') {
            return !!shipment.assigned_tour_object != element.filterTags[2].value
          }
        } else {
          // check if shipment has this key
          if (shipment.hasOwnProperty(element.filterTags[0].key)) {
            const thisShipment: any = shipment
            if (element.filterTags[1].title == '=') {
              if (thisShipment[element.filterTags[0].key] == element.filterTags[2].value) {
                return true;
              }
              return false;
            } else if (element.filterTags[1].title == '!=') {
              if (thisShipment[element.filterTags[0].key] != element.filterTags[2].value) {
                return true;
              }
              return false;
            }
          } else {
            return false
          }
        }
        return false
      })
    }
  }

  onChangeSorting(option: string) {
    console.log(option);
    onSorting(option, this.filteredShipments);
  }
}
