<div class="row mb-5">
  <div class="col-12">
    <div class="co-card">
      <div class="p-4">
        <h5 class="card-title" translate>common.extras</h5>
        <form [formGroup]="shipmentForm">

          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="empties" formControlName="empties">
            <label class="form-check-label" for="empties" translate>
              carrier_dashboard.loading_equipment_exchange
            </label>
          </div>

          <div [hidden]="!shipmentForm.value.empties">
            <div class="input-group mb-3">
              <div class="form-floating">
                <input type="number" id="length" class="form-control" formControlName="empties_eur_palets" min="0"
                  [ngClass]="{'is-valid': shipmentForm.get('empties_eur_palets')?.touched && shipmentForm.get('empties_eur_palets')?.valid, 'is-invalid': shipmentForm.get('empties_eur_palets')?.touched && shipmentForm.get('empties_eur_palets')?.invalid}">
                <label for="length" class="form-label" translate>carrier_dashboard.euro_pallets</label>
              </div>

              <div class="form-floating">
                <input type="number" id="width" class="form-control" formControlName="empties_sbb_frames" min="0"
                  [ngClass]="{'is-valid': shipmentForm.get('empties_sbb_frames')?.touched && shipmentForm.get('empties_sbb_frames')?.valid, 'is-invalid': shipmentForm.get('empties_sbb_frames')?.touched && shipmentForm.get('empties_sbb_frames')?.invalid}">
                <label for="width" class="form-label" translate>carrier_dashboard.sbb_frames</label>
              </div>

              <div class="form-floating">
                <input type="number" id="height" class="form-control" formControlName="empties_sbb_covers" min="0"
                  [ngClass]="{'is-valid': shipmentForm.get('empties_sbb_covers')?.touched && shipmentForm.get('empties_sbb_covers')?.valid, 'is-invalid': shipmentForm.get('empties_sbb_covers')?.touched && shipmentForm.get('empties_sbb_covers')?.invalid}">
                <label for="height" class="form-label" translate>carrier_dashboard.sbb_lids</label>
              </div>
            </div>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="dangerous_goods"
              formControlName="dangerous_goods">
            <label class="form-check-label" for="dangerous_goods" translate>
              carrier_dashboard.hazardous_materials
            </label>
          </div>

          <div [hidden]="!shipmentForm.value.dangerous_goods">
            <div class="input-group mb-3">
              <div class="form-floating">
                <input type="number" id="length" class="form-control" formControlName="dangerous_un_number">
                <label for="length" class="form-label">UN {{'common.number' | translate}}</label>
              </div>

              <div class="form-floating">
                <input type="number" id="width" class="form-control" formControlName="dangerous_amount">
                <label for="width" class="form-label" translate>common.amount</label>
              </div>

              <div class="form-floating">
                <input type="number" id="height" class="form-control" formControlName="dangerous_points">
                <label for="height" class="form-label" translate>carrier_dashboard.hazardous_materials_points</label>
              </div>
            </div>
          </div>

          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="insurance" formControlName="insurance">
            <label class="form-check-label" for="insurance" translate>
              carrier_dashboard.transport_insurance
            </label>
          </div>

          <div class="mb-3" [hidden]="!shipmentForm.value.insurance">
            <div class="form-floating">
              <input class="form-control" type="number" id="value" formControlName="value">
              <label for="value" class="form-label" translate>common.goods_value</label>
            </div>
          </div>

          <div class="d-flex justify-content-start">
            <div class="form-check me-4">
              <input class="form-check-input" type="checkbox" value="" id="pickup_crane_needed"
                formControlName="pickup_crane_needed">
              <label class="form-check-label" for="pickup_crane_needed" translate>
                carrier_dashboard.crane_at_pickup
              </label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="delivery_crane_needed"
                formControlName="delivery_crane_needed">
              <label class="form-check-label" for="delivery_crane_needed" translate>
                carrier_dashboard.crane_at_delivery
              </label>
            </div>
          </div>

          <div class="d-flex justify-content-start">
            <div class="form-check me-4">
              <input class="form-check-input" type="checkbox" value="" id="pickup_access_restriction"
                formControlName="pickup_access_restriction">
              <label class="form-check-label" for="pickup_access_restriction" translate>
                carrier_dashboard.access_restriction_at_pickup
              </label>
            </div>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="delivery_access_restriction"
                formControlName="delivery_access_restriction">
              <label class="form-check-label" for="delivery_access_restriction" translate>
                carrier_dashboard.access_restriction_at_delivery
              </label>
            </div>

          </div>
        </form>

      </div>
    </div>
  </div>
</div>
