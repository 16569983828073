<mat-card>
  <mat-card-content>
    <div class="login-container">

      <app-language-changer></app-language-changer>

      <hr>

      <div class="button mt-2" *ngIf="authService.isAuthenticatedPlatformAdmin()">
        <button (click)="goTo('admin')" class="btn-primary green w-100" [disabled]="isLoading" style="width: 100%;">
          {{'panel_chooser.as_admin_login' | translate}}
        </button>
      </div>

      <div class="button mt-2">
        <button (click)="goTo('carrier')"class="btn-primary green w-100" [disabled]="isLoading" style="width: 100%;">
          {{'panel_chooser.login_as_dispatcher' | translate}}
        </button>
      </div>

      <div class="button mt-2">
        <button (click)="goTo('driver')" class="btn-primary green w-100" [disabled]="isLoading" style="width: 100%;">
          {{'panel_chooser.login_as_driver' | translate}}
        </button>
      </div>

      <div class="button mt-2">
        <button (click)="goTo('shipper')" class="btn-primary green w-100" [disabled]="isLoading"
          style="width: 100%;">
          {{'panel_chooser.login_as_sender'| translate}}
        </button>
      </div>

      <div class="text-center mt-2">
        <div class="form-check d-flex justify-content-center gap-2">
          <input class="form-check-input" type="checkbox" value="" id="save_preference" [(ngModel)]="savePreference">
          <label class="form-check-label" for="save_preference">
            {{'panel_chooser.save_choice'| translate}}
          </label>
        </div>
      </div>

      <div class="button mt-4">
        <button (click)="logout()" class="btn-primary light" [disabled]="isLoading"
          style="width: fit-content; border: 1px solid #DCDDDD;">{{'panel_chooser.logout'| translate}}</button>
      </div>

      <hr style="color: grey;margin: 20px;">
      <div style="display: flex; justify-content: center;">
        <small style="color: #2432394c;">v{{versions.hash}} - {{versions.date | date: "yyyy-MM-dd HH:mm"}}</small>
      </div>
    </div>

  </mat-card-content>
</mat-card>
