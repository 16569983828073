import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { InvoiceStatus } from '../interfaces/invoice.interface';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'invoiceStatusPipe' })
export class InvoiceStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(status: InvoiceStatus | string): string {
    if (typeof (status) === 'string' ) {
      status = parseInt(status, 10)
    }
    switch (status) {
      case InvoiceStatus.DRAFT:
        return this.translate.instant('common.draft');
      case InvoiceStatus.SENT:
        return this.translate.instant('common.sent');
      case InvoiceStatus.PAID:
        return this.translate.instant('common.paid');
      case InvoiceStatus.CANCELLED:
        return this.translate.instant('common.cancelled');
      default:
        return `-`;
    }
  }

}
