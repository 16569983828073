<div class="container-fluid">

  <div class="d-flex justify-content-between">
    <h1>{{'common.auctions' | translate}}</h1>
    <a mat-icon-button [routerLink]="[]" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </a>
  </div>

  <div>
    <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
      <thead>
        <td>{{'common.created' | translate}}</td>
        <td>{{'common.shipment' | translate}}</td>
        <td>{{'common.seller' | translate}}</td>
        <td>{{'common.status' | translate}}</td>
        <td>{{'common.type' | translate}}</td>
        <td>{{'common.price' | translate}}</td>
        <td>Views</td>
        <td>{{'common.until' | translate}}</td>
      </thead>

      <tbody>

        <tr *ngFor="let item of items">
          <td>{{item.created_at | humanDatePipe}}</td>

          <td>
            <a routerLink="/main/admin/shipments/{{item.shipment_object.uuid}}">
              {{item.shipment_object.formatted_number}}
            </a>
          </td>
          <td><span *ngIf="!!item.seller_object">
              <a routerLink="/main/admin/organizations/{{item.seller_object.uuid}}">
                {{item.seller_object.name}}
              </a>
            </span>
          </td>

          <td>
            {{item.market_status | marketStatusPipe }}
          </td>

          <td>
            {{item.price_mode | priceModePipe }}
          </td>

          <td>
            {{item.current_price | pricePipe }}
          </td>

          <td>
            {{item.view_logs?.length }}
          </td>
          <td>
            {{item.end_date | humanDatePipe }}
          </td>


        </tr>
      </tbody>
    </table>
  </div>
  <!-- <pre>{{items | json}}</pre> -->
  <!-- <mat-card class="mt-3">
        <mat-card-content>
            <pre>{{items | json}}</pre>
        </mat-card-content>
    </mat-card> -->

</div>
