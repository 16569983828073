<h1 mat-dialog-title>
  <h3 *ngIf="!data.uuid">{{'common.add_tour' | translate}}</h3>
  <h3 *ngIf="!!data.uuid">{{'common.edit_tour' | translate}}</h3>
</h1>
<div mat-dialog-content>
  <div class="mt-2">

    <form [formGroup]="form">

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.date' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <app-vehicle-selector [selectedVehicle]="data.vehicle_object" (onSelectedVehicle)="onSelectedVehicle($event)"></app-vehicle-selector>

    </form>

  </div>

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>
