import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientsService, IClient } from '../../services/clients.service';


@Component({
  selector: 'app-client-editor',
  templateUrl: './client-editor.component.html',
  styleUrls: ['./client-editor.component.scss']
})
export class ClientEditorComponent implements OnInit {

  form: FormGroup = new FormGroup({
    company: new FormControl('', [
      Validators.required,
    ]),
    first_name: new FormControl(''),
    last_name: new FormControl(''),
    address: new FormControl('', [
      Validators.required,
    ]),
    zip_code: new FormControl('', [
      Validators.required,
    ]),
    location: new FormControl('', [
      Validators.required,
    ]),

    phone: new FormControl('', [
    ]),
    email: new FormControl('', [
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,10}$")
    ]),
    description: new FormControl('', [
    ]),
  });

  constructor(
    public clientsService: ClientsService,
    public dialogRef: MatDialogRef<ClientEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IClient,
  ) {
  }

  async ngOnInit() {
    this.form.patchValue(this.data)
  }

  onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.dialogRef.close(this.form.value)
    }
  }

}
