import { Component, OnInit, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AdvertisementDetailsComponent } from 'projects/webapp/app/components/advertisement-details/advertisement-details.component';
import { AdvertisementEditorComponent } from 'projects/webapp/app/components/advertisement-editor/advertisement-editor.component';
import { AdvertisementsService, IAdvertisement } from 'projects/webapp/app/services/advertisement.service';
import { deepCopy } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit {

  ads: IAdvertisement[] = []
  isLoading = false;

  constructor(
    private title: Title,
    private advertisementsService: AdvertisementsService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private translate: TranslateService,
    @Optional() private angularFireAnalytics: AngularFireAnalytics,
  ) { }

  async ngOnInit() {
    this.translate.get('carrier_dashboard.ads.main_title').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
    // this.title.setTitle(`Cargoflow: Inserate`);
    this.refresh();
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true
      this.ads = await lastValueFrom(this.advertisementsService.getAds({}))
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  add() {
    const data: IAdvertisement = {
      title: '',
      description: '',
      phone: '',
      email: '',
      location: '',
      tags: [],
      is_active: true
    }
    this.dialog.open(AdvertisementEditorComponent, {
      data,
      width: '90vw',
    }).afterClosed().subscribe(async val => {
      if (!!val) {
        try {
          this.angularFireAnalytics?.logEvent(`add_ads`)
          await lastValueFrom(this.advertisementsService.addAdvertisement(val))
          this.translate.get('toastr.ad_published').subscribe((translatedMessage: string) => {
            this.toastrService.success(translatedMessage);
          });
          this.refresh()
        } catch (error) {
          console.error(error)
        }
      }
    })
  }

  onOpenDetails(ad: IAdvertisement) {
    this.dialog.open(AdvertisementDetailsComponent, {
      data: ad,
      width: '90vw',
    });
  }

  onOpenEdit(ad: IAdvertisement) {
    const data = deepCopy(ad)
    this.dialog.open(AdvertisementEditorComponent, {
      data,
      width: '90vw',
    }).afterClosed().subscribe(async val => {
      if (!!val && ad.uuid) {
        try {
          await lastValueFrom(this.advertisementsService.updateAdvertisement(ad.uuid, val))
          this.translate.get('toastr.ad_updated').subscribe((translatedMessage: string) => {
            this.toastrService.success(translatedMessage);
          });
          this.refresh()
        } catch (error) {
          console.error(error)
        }
      }
    })
  }
}
