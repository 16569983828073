<h1 mat-dialog-title>
  <h3 *ngIf="!data.uuid">{{'common.add_advert' | translate}}</h3>
  <h3 *ngIf="!!data.uuid">{{'common.edit_advert' | translate}}</h3>
</h1>
<div mat-dialog-content>
  <div class="mt-2">

    <form [formGroup]="form">

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.title' | translate}}</mat-label>
        <input matInput formControlName="title">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>carrier_dashboard.user-editor-description</mat-label>
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="10" formControlName="description"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>common.locality</mat-label>
        <input matInput formControlName="location">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>common.phone</mat-label>
        <input matInput formControlName="phone">
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label translate>common.mail</mat-label>
        <input matInput formControlName="email">
      </mat-form-field>

      <!-- <app-advertisement-images></app-advertisement-images> -->

      <mat-checkbox class="ps-1" formControlName="is_active">{{'common.is_active' | translate}}</mat-checkbox>
      <div *ngIf="data.uuid">
        <br>
        <button (click)="addImage()" class="btn-primary dark gap-2">{{'common.add_image' | translate}} <mat-icon>perm_media</mat-icon></button>
        <br>
        <div class="row">
          <div *ngFor="let image of images" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
            <div class="w-100" style="border: 1px solid black; border-radius: 5px; padding: 5px; margin: 2px">
              <img [src]="image.file" width="100%" />
              <small style="word-break: break-all;">{{image.name}}</small>
              <a class="delete d-flex ms-auto" mat-icon-button [routerLink]="[]" (click)="deleteImage(image.uuid)"><mat-icon>delete</mat-icon></a>
            </div>
          </div>
        </div>

        <div class="alert alert-info" *ngIf="!images || images.length == 0">{{'common.no_images_yet' | translate}} </div>
      </div>

    </form>

    <!-- <pre>{{data | json}}</pre> -->
    <div *ngIf="!data.uuid" class="alert alert-info mt-4">
      {{'common.you_can_also_adjust_details_and_add_images_later' | translate}}
    </div>

  </div>

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button class="btn-primary light" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button mat-button class="btn-primary green" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>
