<h1 mat-dialog-title>
  <span *ngIf="!data.token">{{'common.create_key' | translate}}</span>
  <span *ngIf="!!data.token">{{'common.edit_key' | translate}}</span>
</h1>
<div mat-dialog-content class="pt-3">

  <app-client-selector [selectedClientUUID]="data.client_object?.uuid" (onSelectedClient)="onSelectedClient($event)"></app-client-selector>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'common.purpose' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.purpose" required>
  </mat-form-field>

  <div>
    <mat-checkbox [(ngModel)]="data.active">
      {{'common.active' | translate}}
    </mat-checkbox>
  </div>

  <!-- <pre>{{data | json}}</pre> -->

</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" [mat-dialog-close]="data" cdkFocusInitial>{{'common.save' | translate}}</button>
</div>