import { Component, OnInit } from '@angular/core';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  shipments: Partial<IShipment>[] = []
  constructor(

    private carrierShipmentsService: CarrierShipmentsService,
  ) {
  }

  async ngOnInit() {
    this.shipments = await lastValueFrom(this.carrierShipmentsService.getShipmentsForAlerts())
  }
}
