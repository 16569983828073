<!-- <div>
    <div class="crop-actions">
        <button style="margin-right: 1rem"  mat-flat-button (click)="reset()">
            Reset
        </button>
        <button color="primary" mat-flat-button (click)="crop()">
            Ok
        </button>
    </div>
</div> -->


<h1 mat-dialog-title>
    <span>{{ title }}</span>
</h1>
<div mat-dialog-content>
    <img id="image" height="500" [src]="sanitizedUrl">
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
    <button class="btn-primary light primary-hover-animation" (click)="reset()">
        {{ reset_string }}
    </button>
    <button class="btn-primary green primary-hover-animation" (click)="crop()">
        {{ save }}
    </button>
</div>