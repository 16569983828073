import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { generateShipmentEmptiesFormGroup } from 'projects/shared/generators';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-shipment-edit-empties',
  templateUrl: './shipment-edit-empties.component.html',
  styleUrls: ['./shipment-edit-empties.component.scss']
})
export class ShipmentEditEmptiesComponent implements OnInit {
  public isLoading = false;
  public shipmentUUID: string | null = null;

  public shipmentForm = generateShipmentEmptiesFormGroup()

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private carrierShipmentsService: CarrierShipmentsService,
    private translate: TranslateService,
  ) {
    this.translate.get('common.edit_empty_containers_from_consignment').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(async (val) => {
      if (!!val && val['shipmentUUID']) {
        this.shipmentUUID = val['shipmentUUID']
        this.refresh();
      }
    })
  }

  async refresh() {
    if (this.shipmentUUID) {
      const response = await lastValueFrom(this.carrierShipmentsService.getShipment(this.shipmentUUID)) as any
      this.shipmentForm.patchValue({ ...response })
    }
  }

  async save() {
    if (this.shipmentUUID) {
      await lastValueFrom(this.carrierShipmentsService.updateShipment(this.shipmentUUID, this.shipmentForm.value as any))
      this.router.navigate(['/main/carrier/shipments/' + this.shipmentUUID])
    }
  }
}
