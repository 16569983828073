import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'yesNoPipe' })
export class YesNoPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(status: boolean): string {
    if (status) {
      return this.translate.instant('common.yes');
    }
    return this.translate.instant('common.no');
  }
}
