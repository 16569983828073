<div style="text-align: center;">
    <ul>
        <li>online: {{stateToEmoji(isOnline)}}</li>
        <li>camera support: {{stateToEmoji(hasCameraSupport)}}</li>
        <li>location support: {{stateToEmoji(hasLocationSupport)}}</li>
        <li>notification support: {{stateToEmoji(hasNotificationSupport)}}</li>
        <li>app version: {{ versions.date | date: 'dd.MM.yyyy HH:mm:ss' }} | {{versions.hash}}</li>
    </ul>
    <br>
    <hr>
    <h3>List of Cameras</h3>
    <select id="videoSource"></select>
    <br>
    <hr>
    <h3>Driver Location</h3>
    <br>
    <!-- TODO: add map here -->
    <pre>{{currentPosition | json}}</pre>
    <br>
    <hr>
</div>