import { IDispatchShipment } from "projects/webapp/app/interfaces/shipment.interface";

export const getMetaDataShipmentFilters = (shipments: IDispatchShipment[]) => {
  const properties: any[] = []
  const metaKeyValuesRaw: any = {}

  for (let index = 0; index < shipments.length; index++) {
    const element = shipments[index];

    if (element.meta_data && Object.keys(element.meta_data).length > 0) {
      for (let key in element.meta_data) {
        if (element.meta_data.hasOwnProperty(key)) {
          console.log(`key: ${key}`);
          if (metaKeyValuesRaw.hasOwnProperty(key)) {
            metaKeyValuesRaw[key]['values'].push(element.meta_data[key])
          } else {
            metaKeyValuesRaw[key] = {
              values: [
                element.meta_data[key]
              ]
            }
          }
          metaKeyValuesRaw[key]['values'] = [...new Set(metaKeyValuesRaw[key]['values'])];
        }
      }
    }
  }

  console.log(metaKeyValuesRaw)

  for (let key in metaKeyValuesRaw) {
    if (metaKeyValuesRaw.hasOwnProperty(key)) {

      for (let index = 0; index < metaKeyValuesRaw[key].values.length; index++) {
        let element = metaKeyValuesRaw[key].values[index];
        metaKeyValuesRaw[key].values[index] = {
          "key": "values",
          "title": element,
          "value": element
        }
      }

      properties.push({
        title: `Meta data: ${key}`,
        key,
        operators: [
          {
            key: 'operator',
            title: '='
          },
          {
            key: 'operator',
            title: '!='
          },
        ],
        values: metaKeyValuesRaw[key].values
      })
    }
  }

  return properties
}
