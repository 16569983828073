import { Component, Input, OnInit } from '@angular/core';
import { IDetailedTour, TourService, TourStatus } from '../../services/tour.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';
import { UserSelectorModalComponent } from '../user-selector-modal/user-selector.component';
import { CarrierShipmentsService } from '../../services/carrier-shipments.service';
import { TranslateService } from '@ngx-translate/core';
import { IOrganization } from '../../interfaces/organization.interface';
import { OrganizationsService } from '../../services/organizations.service';

@Component({
  selector: 'app-vehicle-card',
  templateUrl: './vehicle-card.component.html',
  styleUrls: ['./vehicle-card.component.scss']
})
export class VehicleCardComponent implements OnInit {

  @Input() tour: IDetailedTour | null = null;
  tourStatus = TourStatus;

  ownOrganization: IOrganization | null = null;

  constructor(
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private tourService: TourService,
    private carrierShipmentsService: CarrierShipmentsService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) { }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
  }

  assignDriver(tour: IDetailedTour) {
    // alert('Todo')
    const data = this.tour?.driver_object
    this.dialog.open(UserSelectorModalComponent, { data }).afterClosed().subscribe(async (val) => {
      if (!!val && this.tour?.uuid) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.tour_updated'));

        this.tour = await lastValueFrom(this.tourService.updateTour(this.tour.uuid, { ...tour, driver: val.id }));
        this.toastrService.success(successMessage)
      }
    })
  }

  async onCloseTour() {
    try {
      if (this.tour?.uuid) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.tour_planned'));

        this.tour = await lastValueFrom(this.tourService.closeTour(this.tour?.uuid));
        this.toastrService.success(successMessage)
        // this.updateFields();
      }
    } catch (error) {
      console.error(error)
    }
  }


}
