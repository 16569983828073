<div class="container">

  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1>Activity</h1>
      </div>


      <table class="table table-striped" style="width: 100%" *ngIf="!!events && events.length > 0">
        <thead>
          <tr>
            <th scope="col">When</th>
            <th scope="col">Who</th>
            <th scope="col">What</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let event of events">
            <td>
              {{event.updated_at | humanDatePipe}}
            </td>
            <td>
              <a *ngIf="event.author_object" routerLink="/main/admin/users/{{event.author_object.uuid}}">
                {{event.author_object.first_name}}
                {{event.author_object.last_name}}
              </a>
              <span *ngIf="event.author_organization">
                (org:
                <a routerLink="/main/admin/organizations/{{event.author_organization.uuid}}">
                  {{event.author_organization.name}}
                </a>
                )
              </span>
            </td>
            <td>
              {{event.type_name}}
            </td>
          </tr>
        </tbody>
      </table>

      <!-- <pre>{{foundShipments | json}}</pre> -->
    </mat-card-content>
  </mat-card>
</div>
