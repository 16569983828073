<h1 mat-dialog-title>
  <span translate>carrier_dashboard.licence_required</span>
</h1>
<div mat-dialog-content class="pt-2">

  <div *ngIf="!requestDone">

    <div class="alert alert-info" translate>
      carrier_dashboard.licence_request_no_obligation
    </div>

    <mat-form-field appearance="outline" style="width: 100%">
      <mat-label translate>carrier_dashboard.your_phone_number</mat-label>
      <input matInput [(ngModel)]="phoneNumber" required>
    </mat-form-field>


    <div class="d-flex justify-content-center mt-4 mb-4">
      <button class="btn btn-primary" (click)="save()" [disabled]="phoneNumber == '' || isLoading">
        <span *ngIf="!isLoading" translate>
          carrier_dashboard.request_licence
        </span>
        <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span *ngIf="isLoading" class="sr-only" translate>common.loading</span>
      </button>
    </div>
  </div>

  <div *ngIf="requestDone" class="alert alert-success" translate>carrier_dashboard.enquiry_sent</div>
</div>

<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" (click)="cancel()">{{'common.close' | translate}}</button>
  <!-- <button mat-button color="primary" cdkFocusInitial (click)="save()">Veröffentlichen</button> -->
</div>