import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicShipmentsService } from 'projects/webapp/app/services/public-shipments.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { AdminShipmentsService } from '../../services/admin-shipments.service';
import { PanelType } from '../../interfaces/panel-types.interface';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from '../../services/users.service';
import { IShipmentTransaction, ShipmentTransactionKind } from '../../interfaces/shipment.interface';
import { ShipmentEditPricingModalComponent } from '../shipment-edit-pricing-modal/shipment-edit-pricing-modal.component';
import { lastValueFrom } from 'rxjs';
import { ShipmentEditSurchargeModalComponent } from '../shipment-edit-surcharge-modal/shipment-edit-surcharge-modal.component';
import { IEditSurcharge, ISurcharge } from '../../interfaces/surcharge.interface';
import { CarrierShipmentsService } from '../../services/carrier-shipments.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from '../../services/organizations.service';
import { IOrganization } from '../../interfaces/organization.interface';


@Component({
  selector: 'app-shipment-pricing',
  templateUrl: './shipment-pricing.component.html',
  styleUrls: ['./shipment-pricing.component.scss']
})
export class ShipmentPricingComponent implements OnInit {

  @Input() shipmentUUID: string | null = null;
  @Input() panelType: PanelType = 'carrier';

  transactions: IShipmentTransaction[] = []

  isLoading = false;
  shipmentTransactionKind = ShipmentTransactionKind
  ownOrganization: IOrganization | null = null;

  constructor(
    public publicShipmentsService: PublicShipmentsService,
    private spinnerService: NgxSpinnerService,
    public route: ActivatedRoute,
    private carrierShipmentsService: CarrierShipmentsService,
    private adminShipmentsService: AdminShipmentsService,
    public usersService: UsersService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
  }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    this.refreshPricing();
  }

  async refreshPricing() {
    if (this.shipmentUUID) {
      try {
        this.spinnerService.show('shipmentPricing')
        if (this.panelType == 'carrier') {
          this.transactions = await lastValueFrom(this.carrierShipmentsService.getShipmentTransaction(this.shipmentUUID))
        }
        if (this.panelType == 'admin') {
          this.transactions = await lastValueFrom(this.adminShipmentsService.getShipmentTransaction(this.shipmentUUID))
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide('shipmentPricing')
      }
    }
  }

  openEditPricingModal(transaction: IShipmentTransaction) {
    const data = {
      base_price: transaction.base_price,
      options: transaction?.options,
    }
    this.dialog.open(ShipmentEditPricingModalComponent, { data }).afterClosed().subscribe(async val => {
      if (!!val && this.shipmentUUID && transaction) {
        console.log(val)
        transaction.base_price = val.base_price
        transaction.options = val.options
        // TODO: add event to change price
        await lastValueFrom(this.carrierShipmentsService.updateShipmentTransaction(this.shipmentUUID, transaction.uuid, transaction))
      }
    })
  }

  openAddSurchargeModal(transaction: IShipmentTransaction) {
    const data: IEditSurcharge = {
      name: '',
      price: 0,
      transaction: transaction
    }
    
    this.dialog.open(ShipmentEditSurchargeModalComponent, { data }).afterClosed().subscribe(async (val) => {
      if (!!val && this.shipmentUUID && transaction) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_cancelled'));

        console.log(val)
        await lastValueFrom(this.carrierShipmentsService.addShipmentTransactionSurcharge(this.shipmentUUID, transaction.uuid, val))
        this.toastrService.success(successMessage)
        this.refreshPricing();
      }
    })
  }
}
