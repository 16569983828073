<h1 mat-dialog-title>
  <span *ngIf="data.uuid">{{'common.tour_details' | translate}}</span>
</h1>
<div mat-dialog-content>
  <div class="pt-2">

    <div class="row">
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>{{'common.id' | translate}}</mat-label>
        <input matInput [(ngModel)]="data.id" readonly>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>{{'common.date' | translate}}</mat-label>
        <input matInput [(ngModel)]="data.date" readonly>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>{{'common.travel_time' | translate}}</mat-label>
        <input matInput [(ngModel)]="data.driving_duration" readonly>
      </mat-form-field>
    <mat-form-field appearance="outline" class="col-6">
      <mat-label>{{'common.travel_time' | translate}}</mat-label>
      <input matInput [(ngModel)]="data.driving_distance" readonly>
    </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>{{'common.stops' | translate}}</mat-label>
        <input matInput [(ngModel)]="data.number_of_stops" readonly>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>{{'common.weight' | translate}}</mat-label>
        <input matInput [(ngModel)]="data.weight" readonly>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label translate>common.ground_spaces</mat-label>
        <input matInput [(ngModel)]="data.ground_spaces" readonly>
      </mat-form-field>
    </div>

    <div class="row">
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>{{'common.revenue' | translate}}</mat-label>
        <input matInput [(ngModel)]="data.turnover" readonly>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-6">
        <mat-label>{{'common.profit' | translate}}</mat-label>
        <input matInput [(ngModel)]="data.profit" readonly>
      </mat-form-field>
    </div>

    <!-- <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'common.vehicle' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.vehicle_object.number" readonly>
  </mat-form-field> -->

    <!-- <pre>{{data | json}}</pre> -->
  </div>

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">{{'common.close' | translate}}</button>
</div>
