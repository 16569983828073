import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IShipperMarketShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { ShipperShipmentsService } from 'projects/webapp/app/services/shipper-shipments.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-shipper-market-place',
  templateUrl: './market-place.component.html',
  styleUrls: ['./market-place.component.scss']
})
export class ShipperMarketPlaceComponent implements OnInit {
  shipments: IShipperMarketShipment[] = []
  isLoading = false;

  constructor(
    private shipperShipmentsService: ShipperShipmentsService,
    public dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.shipments = await lastValueFrom(this.shipperShipmentsService.getOpenMarketShipments())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }

}
