<h1 mat-dialog-title>
  <span>{{'common.select_customer' | translate}}</span>
</h1>
<div mat-dialog-content>
  <div class="mt-2">
    <app-client-selector (onSelectedClient)="onSelectedClient($event)"></app-client-selector>
  </div>
</div>
<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button class="btn-primary green primary-hover-animation" cdkFocusInitial [mat-dialog-close]="data">{{'common.save' | translate}}</button>
</div>
