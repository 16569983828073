import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { ClientEditorComponent } from 'projects/webapp/app/components/client-editor/client-editor.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { IClient, ClientsService } from 'projects/webapp/app/services/clients.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

  client: IClient | null = null;
  clientUUID: string | null = null;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private title: Title,
    private toastrService: ToastrService,
    private spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.client_details').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(async (params) => {
      if (!!params && params['clientUUID']) {
        this.clientUUID = params['clientUUID']
        await this.refresh();
      }
    })
  }

  async refresh() {
    try {
      this.spinnerService.show();
      if (this.clientUUID){
        this.client = await lastValueFrom(this.clientService.getClient(this.clientUUID))
        console.log(this.client)
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinnerService.hide();
    }
  }

  editClient(client: IClient ) {
    this.dialog.open(ClientEditorComponent, { data: client }).afterClosed().subscribe(async val => {
      if (!!val && client.uuid) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.client_edited'));

        await lastValueFrom(this.clientService.updateClient(client.uuid, val));
        this.toastrService.success(successMessage);
        this.refresh()
      }
    })
  }

  async deleteClient(client: IClient) {
    const confirmationMessage = await lastValueFrom(this.translateService.get('toastr.confirm_client_deletion'));

    if (confirm(confirmationMessage) && client.uuid) {
      await lastValueFrom(this.clientService.deleteClient(client.uuid))
      this.refresh();
    }
  }

}
