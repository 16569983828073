<div style="border: none;" class="alert alert-info bg-green-light">
    {{'common.you_can_store_your_rates_here' | translate}}
    {{'common.these_prices_apply_to_customers_who_do_not_have_special_prices' | translate}}
    {{'common.if_your_pricing_model_is_more_specific_contact_us' | translate}}
    <br><br>
    {{'common.special_prices_for_a_specific_customer' | translate}}
</div>

<form [formGroup]="pricingForm" (submit)="updateSurcharges()">
    <div class="mb-3">
        <div class="form-floating">
            <select class="form-select" id="platform_calculator" formControlName="platform_calculator">
                <!-- <option value="0">DEFAULT</option> -->
                <!-- <option value="1">GU</option> -->
                <option value="2">{{'common.pallet_price_list' | translate}}</option>
            </select>
            <label for="platform_calculator" class="form-label">{{'common.platform_price_calculator' | translate}}</label>
        </div>
    </div>

    <div class="mb-3" *ngIf="pricingForm.value.platform_calculator == 1">
        <div class="form-floating">
            <input class="form-control" type="number" id="platform_gu_discount_percent"
                formControlName="platform_gu_discount_percent">
            <label for="platform_gu_discount_percent" class="form-label">GU Rabatt in % ({{'common.for_example' | translate}} -50 {{'common.for' | translate}} 50%
                Rabatt)</label>
        </div>
    </div>

    <div class="mb-3" *ngIf="pricingForm.value.platform_calculator == 2">
        <div
            *ngFor="let platform_pallet_price_item_form of getPalletPriceForm(); index as index">
            <form [formGroup]="platform_pallet_price_item_form" class="mb-1">
                <div class="form-floating">
                    <input class="form-control" type="number" id="price"
                        formControlName="price">
                    <label for="price" class="form-label">{{'common.pallet_price' | translate}} {{index + 1}} in CHF</label>
                </div>
            </form>
            <!-- <pre>{{platform_pallet_price_item_form.value | json}}</pre> -->
        </div>

    </div>

    <!-- <pre>{{pricingForm.value.platform_pallet_price_list | json}}</pre> -->
    <!-- <pre>{{palletPriceForms.controls | json}}</pre> -->
    <button class="green-btn primary-hover-animation" type="submit">{{'common.save' | translate}}</button>
</form>
