import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';

export interface IAdvertisement {

  id?: number;
  uuid?: string;

  created_at?: string;
  updated_at?: string;

  title: string,
  description: string;
  location: string;
  tags: string[];
  is_active: any;

  phone: string;
  email: string;

  organization?: number;
  organization_name?: string;
}


export interface AdvertisementImage {
  "id": number,
  "uuid": string,
  "file": string,
  "shipment": string,
  "thumbnail": string | null,
  "name": string
}


@Injectable({
  providedIn: 'root'
})
export class AdvertisementsService {

  private _httpOptions = {
    responseType: 'arraybuffer' as 'json',
  };

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getAds(params: any): Observable<IAdvertisement[]> {
    return this.http.get<IAdvertisement[]>(this._urls.ADVERTISEMENTS, { params })
  }

  public addAdvertisement(advertisement: IAdvertisement): Observable<IAdvertisement> {
    return this.http.post<IAdvertisement>(this._urls.ADVERTISEMENTS, advertisement)
  }

  public updateAdvertisement(advertisementUUID: string, advertisement: IAdvertisement): Observable<IAdvertisement> {
    return this.http.patch<IAdvertisement>(this._urls.ADVERTISEMENTS_BY_UUID(advertisementUUID), advertisement)
  }

  addImage(advertisementUUID: string, data: any) {
    return this.http.post<any>(this._urls.ADVERTISEMENT_BY_UUID_IMAGES(advertisementUUID), data)
  }

  getImages(advertisementUUID: string) {
    return this.http.get<AdvertisementImage[]>(this._urls.ADVERTISEMENT_BY_UUID_IMAGES(advertisementUUID))
  }

  deleteImage(advertisementUUID: string, imageUUID: string) {
    return this.http.delete<any>(this._urls.ADVERTISEMENT_BY_UUID_IMAGES_BY_UUID(advertisementUUID, imageUUID))
  }

  // public InvoicePDF(invoiceUUID: string): Observable<any> {
  //   return this.http.get<any>(this._urls.INVOICE_PDF_BY_UUID(invoiceUUID), this._httpOptions)
  // }

  // public manualInvoice(data: IInvoice): Observable<IInvoice> {
  //   return this.http.post<IInvoice>(this._urls.DOCUMENTS_INVOICE_MANUAL_INVOICE, data)
  // }



  // getInvoice(invoiceUUID: string): Observable<IInvoice> {
  //   return this.http.get<IInvoice>(this._urls.INVOICES_BY_UUID(invoiceUUID))
  // }

  // public updateInvoice(invoiceUUID: string, data: IInvoice): Observable<IInvoice> {
  //   return this.http.patch<IInvoice>(this._urls.INVOICES_BY_UUID(invoiceUUID), data)
  // }

  // public deleteInvoice(invoiceUUID: string): Observable<IInvoice> {
  //   return this.http.delete<IInvoice>(this._urls.INVOICES_BY_UUID(invoiceUUID))
  // }

  // public createInvoiceForShipments(clientUUID: string) {
  //   return this.http.post<IInvoice>(this._urls.CLIENT_INVOICE_READY_SHIPMENTS(clientUUID), {})
  // }

}
