import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'app-shipment-comment',
    template: `
  <h1 class="fs-3 text-center mt-2" mat-dialog-title>
  <span>{{'common.comment' | translate}}</span>
  </h1>
  <div mat-dialog-content>

    <div class="form-group mt-4 mb-4">
      <!-- <label for="exampleInputEmail1">Neuer {{'common.comment' | translate}}</label> -->
      <input type="email" class="form-control" id="exampleInputEmail1" [(ngModel)]="data">
    </div>
  
  </div>
  <div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
    <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
    <button class="btn-primary green primary-hover-animation" [mat-dialog-close]="data">{{'common.save' | translate}}</button>
  </div>
  `,
})
export class ShipmentCommentModal {
    constructor(
        public dialogRef: MatDialogRef<ShipmentCommentModal>,
        @Inject(MAT_DIALOG_DATA) public data: string,
    ) {
    }
}