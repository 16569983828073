import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { versions } from 'projects/versions';
import { environment } from 'projects/webapp/app/environments/environment';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  isLoading = true;
  versions = versions;
  environment = environment;

  keyNumbers: {
    users_login_today: number,
    private_shipments_today: number,
    market_place_shipments_today: number,
    market_place_turnover_today: number,
    market_place_profit_today: number,

    users_login_yesterday: number,
    private_shipments_yesterday: number,
    market_place_shipments_yesterday: number,
    market_place_turnover_yesterday: number,
    market_place_profit_yesterday: number,

  } | null = null

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.keyNumbers = await lastValueFrom(this.adminService.getDashboardNumbers());
      console.log(this.keyNumbers)
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }
}
