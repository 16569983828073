import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { IUser, UsersService } from '../../services/users.service';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss']
})
export class UserSelectorComponent implements OnInit {

  @Input() selectedUser: IUser | null | undefined = null;

  possibleUsers: IUser[] = [];
  filteredUsers: IUser[] = []
  userFormControl = new FormControl('')

  @Output() onSelectedUser = new EventEmitter<IUser>()

  constructor(
    public usersService: UsersService,
  ) {
    this.userFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe(val => {
      this.filteredUsers = val;
    });
  }

  async ngOnInit() {
    this.possibleUsers = await lastValueFrom(this.usersService.getOrgUsers())
  }

  getTitle(user: IUser) {
    console.log(`getTitle(${user?.uuid}`)
    const filteredClient = this.possibleUsers.find(p => p.uuid === user?.uuid)
    if (filteredClient) {
      return `${filteredClient.first_name} ${filteredClient.last_name}`
    }
    return '';
  }

  private _filter(value: string): IUser[] {
    console.log(value)
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.possibleUsers.filter(user => {
        return user.first_name.toLowerCase().includes(filterValue) || user.last_name.toLowerCase().includes(filterValue)
      });
    }
    return []
  }

  userSelected(user: IUser) {
    this.onSelectedUser.emit(user);
  }

  getUserName(user: IUser) {
    return `${user.first_name} ${user.last_name}`
  }
}
