<div class="container">

    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'common.empty_containers' | translate}}</h1>
            </div>

            <form [formGroup]="shipmentForm">

                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="empties" formControlName="empties">
                    <label class="form-check-label" for="empties">
                        {{'common.empty_container_exchange_ordered' | translate}}
                    </label>
                </div>

                <div class="input-group mb-3">
                    <div class="form-floating">
                        <input type="number" id="length" class="form-control" formControlName="empties_eur_palets" 
                            min="0">
                        <label for="length" class="form-label">{{'carrier_dashboard.euro_pallets' | translate}}</label>
                    </div>

                    <!-- <div class="form-floating">
                        <input type="number" id="width" class="form-control" formControlName="empties_sbb_frames" 
                            min="0">
                        <label for="width" class="form-label">{{'carrier_dashboard.sbb_frames' | translate}}</label>
                    </div>

                    <div class="form-floating">
                        <input type="number" id="height" class="form-control" formControlName="empties_sbb_covers"
                            min="0">
                        <label for="height" class="form-label">{{'carrier_dashboard.sbb_lids' | translate}}</label>
                    </div> -->
                </div>
            </form>

            <div class="mt-3 d-flex float-right">
                <button class="btn-primary green primary-hover-animation" (click)="save()">{{'common.save_and_back' | translate}}</button>
            </div>
        </mat-card-content>
    </mat-card>
</div>