<h1 mat-dialog-title>
  <span *ngIf="!!data.uuid">{{'common.organization' | translate}}</span>
</h1>
<div mat-dialog-content>

  <div class="mt-2">
    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.company_name; else useName">
      <mat-label translate>common.name</mat-label>
      <input matInput [(ngModel)]="data.company_name" readonly>
    </mat-form-field>

    <ng-template #useName>
      <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.first_name">
        <mat-label translate>common.name</mat-label>
        <input matInput [(ngModel)]="data.first_name" readonly>
      </mat-form-field>

      <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.last_name">
        <mat-label translate>common.name</mat-label>
        <input matInput [(ngModel)]="data.last_name" readonly>
      </mat-form-field>
    </ng-template>

    <mat-form-field appearance="outline" style="width: 100%" *ngIf="data.last_name">
      <mat-label>{{'common.on_the_platform_since' | translate}}:</mat-label>
      <input matInput [(ngModel)]="data.created_at" readonly>
    </mat-form-field>

    <!-- <pre>{{data | json}}</pre> -->
  </div>

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">{{'common.close' | translate}}</button>
</div>