<div class="filter-bar d-none d-md-flex">
  <div class="selecting">

    <input type="checkbox" *ngIf="hasSelectionAll" (change)="onChangeSelection($event)">
    <select *ngIf="hasHistory">
      <option *ngFor="let entry of searchHistoryEntries">{{entry.name}}</option>
    </select>

  </div>
  <div class="filtering">
    <div class="filterGroup" *ngFor="let filterGroup of filterGroups">
      <div class="filterTag" *ngFor="let filterTag of filterGroup.filterTags; index as i">
        {{filterTag.title}}
        <button (click)="deleteFilterGroup(filterGroup)" class="cleanButton" *ngIf="i == 2">x</button>
      </div>
    </div>

    <div class="fill-width">
      <div class="input-container">
        <input type="text" class="input-text" (focus)="showFirstFilterList=true" (keydown)="newSearchInput($event)"
          placeholder="Search or filter results...">

        <div *ngIf="showFirstFilterList" class="dropdown-list" #FirstFilterList>
          <ul>
            <li *ngFor="let filterProperty of filterProperties" class="filterItem"
              (click)="onSelectFilterProperty(filterProperty)">{{filterProperty.title}}</li>
          </ul>
        </div>

        <div *ngIf="showSecondFilterList && lastProperty" class="dropdown-list" #SecondFilterList>
          <ul>
            <li *ngFor="let operator of lastProperty.operators" class="filterItem"
              (click)="onSelectSecondProperty(operator)">{{operator.title}}</li>
          </ul>
        </div>

        <div *ngIf="showThirdFilterList && lastProperty" class="dropdown-list" #ThirdFilterList>
          <ul>
            <li *ngFor="let values of lastProperty.values" class="filterItem" (click)="onSelectThirdProperty(values)">
              {{values.title}}</li>
          </ul>
        </div>

      </div>
      <div class="cleanUpDiv">
        <button (click)="deleteFilterGroups()" class="cleanButton" *ngIf="filterGroups.length >= 1">x</button>
      </div>
    </div>
  </div>
  <div class="sorting">
    <select (change)="onChangeSorting($event)" class="sorting-option">
      <option *ngFor="let sortingOption of sortingOptions" [value]="sortingOption.value">{{sortingOption.name}}</option>
    </select>
  </div>
</div>


<div style="margin-top: 150px" *ngIf="debugging">

  <small>filterProperties</small>
  <pre>{{filterProperties | json}}</pre>

  <small>showFirstFilterList</small>
  <pre>{{showFirstFilterList}}</pre>

  <small>showSecondFilterList</small>
  <pre>{{showSecondFilterList}}</pre>

  <small>showThirdFilterList</small>
  <pre>{{showThirdFilterList}}</pre>

  <hr>

  <small>filterGroups</small>
  <pre>{{filterGroups | json}}</pre>

</div>
