import { DecimalPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'pricePipe' })
export class PricePipe implements PipeTransform {
  constructor() { }

  transform(value: any) {
    try {
      const decimalPipe = new DecimalPipe('de-CH');
      return decimalPipe.transform(value, '1.2-2');
    } catch (error) {
      console.error(error)
      return value;
    }
  }
}
