import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientsService } from '../../services/clients.service';
import { IUser } from '../../services/users.service';
import { IHub } from '../../services/organizations.service';
import { CurrentLocationStatus, ShipmentStatus, ShipmentTaskStatus } from '../../services/shipments.service';


export interface IDispatchInfo {
  hub: IHub | null,
  user: IUser | null,
  mode: ShipmentTaskStatus,
  current_loc: CurrentLocationStatus
}


@Component({
  selector: 'app-dispatch-modal',
  templateUrl: './dispatch-modal.component.html',
})
export class DispatchModalComponent {

  shipmentStatus = ShipmentStatus
  shipmentTaskStatus = ShipmentTaskStatus
  currentLocationStatus = CurrentLocationStatus

  constructor(
    public clientsService: ClientsService,
    public dialogRef: MatDialogRef<DispatchModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDispatchInfo,
  ) {
  }

  onSelectedHub(event: IHub) {
    this.data.hub = event;
  }

  onSelectedUser(event: IUser) {
    this.data.user = event;
  }

}
