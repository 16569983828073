<form [formGroup]="form">

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.first_name</mat-label>
    <input matInput formControlName="first_name">
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.last_name</mat-label>
    <input matInput formControlName="last_name">
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.mail</mat-label>
    <input matInput formControlName="email">
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.phone</mat-label>
    <input matInput formControlName="phone">
  </mat-form-field>

    <mat-checkbox class="ps-1" formControlName="permission_pipeline">{{'carrier_dashboard.user-editor-permission.access_to_release_pipeline' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_dispatch">{{'carrier_dashboard.user-editor-permission.access_to_disposition_pipeline' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_invoicing">{{'carrier_dashboard.user-editor-permission.access_to_invoices' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_client_management">{{'carrier_dashboard.user-editor-permission.access_to_client_management' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_empties_management">{{'carrier_dashboard.user-editor-permission.access_to_empties_management' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_warehouse_management">{{'carrier_dashboard.user-editor-permission.access_to_warehouse_management' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_activity">{{'carrier_dashboard.user-editor-permission.access_to_activity-display' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_export">{{'carrier_dashboard.user-editor-permission.access_to_export' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_user_management" *ngIf="user && user.uuid !== authService.getUserUUID()">{{'common.access_to_user_management' | translate}}</mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_org_management">{{'carrier_dashboard.user-editor-permission.access_to_organization_administration' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_settings">{{'carrier_dashboard.user-editor-permission.access_to_settings' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_see_pricing">{{'carrier_dashboard.user-editor-permission.prices-visible' | translate}} {{'carrier_dashboard.user-editor-permission.prices-visible' | translate}}</mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_alerts_place">{{'carrier_dashboard.user-editor-permission.access_to-alert-pipeline' | translate}} </mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_market_place">{{'carrier_dashboard.user-editor-permission.access_to_the_marketplace' | translate}}</mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_new_shipments">{{'carrier_dashboard.user-editor-permission.access_to_shipment_booking' | translate}}</mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_shipper_panel">{{'carrier_dashboard.user-editor-permission.access_to_shipper_view' | translate}}</mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_carrier_panel">{{'carrier_dashboard.user-editor-permission.access_to_dispatcher_view' | translate}}</mat-checkbox>
    <mat-checkbox class="ps-1" formControlName="permission_driver_panel">{{'carrier_dashboard.user-editor-permission.access_to_drivers_app' | translate}}</mat-checkbox>
  <!-- </div> -->

</form>

<!-- <pre>{{user | json}}</pre> -->
<!-- <pre>{{form.value | json}}</pre> -->
<button class="btn-primary green primary-hover-animation ms-auto" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button>
