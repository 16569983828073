import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { Params } from '@angular/router';
import { IDeliverInfo, IPickupInfo, IShipment, ShipmentsService } from './shipments.service';
import { IShipmentComment } from '../interfaces/shipment-comment.interface';
import { ICarrierMarketShipment, ICarrierShipment, IShipmentTransaction, ShipmentImage } from '../interfaces/shipment.interface';
import { IDispatchInfo } from '../components/dispatch-modal/dispatch-modal.component';


export interface IImportInformation {
  shipments: ICarrierShipment[],
  created_at: string,
}


@Injectable({ providedIn: 'root' })
export class CarrierShipmentsService extends ShipmentsService {

  private _httpOptions = {
    responseType: 'arraybuffer' as 'json',
  };

  constructor(
    protected override http: HttpClient,
    protected override _urls: UrlConstantsService,
  ) {
    super(http, _urls);
  }

  getTempImportShipments(): Observable<IImportInformation> {
    return this.http.get<any>(this._urls.CARRIER_IMPORT_SHIPMENTS, {})
  }

  importShipmentsFile(formData: FormData): Observable<IImportInformation> {
    return this.http.post<any>(this._urls.CARRIER_UPLOAD_IMPORT_SHIPMENTS_FILE, formData)
  }

  getDeliverySlip(shipmentUUID: string): Observable<any> {
    return this.http.get<any>(this._urls.CARRIER_DELIVERY_SLIP_SHIPMENTS_BY_UUID(shipmentUUID), this._httpOptions)
  }

  getLabel(shipmentUUID: string): Observable<any> {
    return this.http.get<any>(this._urls.CARRIER_LABEL_SHIPMENTS_BY_UUID(shipmentUUID), this._httpOptions)
  }

  getShipments(): Observable<IShipment[]> {
    return this.http.get<IShipment[]>(this._urls.CARRIER_SHIPMENTS)
  }

  getCarrierShipmentsInMarketPlace(params: Params): Observable<ICarrierMarketShipment[]> {
    return this.http.get<ICarrierMarketShipment[]>(this._urls.CARRIER_SHIPMENTS_MARKET_PLACE, { params })
  }

  public getShipmentsForApproval(): Observable<ICarrierShipment[]> {
    return this.http.get<ICarrierShipment[]>(this._urls.CARRIER_SHIPMENTS_FOR_APPROVAL)
  }

  getShipmentsForDispatch(): Observable<ICarrierShipment[]> {
    return this.http.get<ICarrierShipment[]>(this._urls.CARRIER_SHIPMENTS_FOR_DISPATCH)
  }

  getShipmentsAssigned(params: Params): Observable<ICarrierShipment[]> {
    return this.http.get<ICarrierShipment[]>(this._urls.CARRIER_SHIPMENTS_ASSIGNED, { params })
  }

  public getShipmentsForInvoicing(): Observable<IShipment[]> {
    return this.http.get<IShipment[]>(this._urls.CARRIER_SHIPMENTS_FOR_INVOICING)
  }

  public getShipmentsReadyForInvoicing(): Observable<IShipment[]> {
    return this.http.get<IShipment[]>(this._urls.CARRIER_SHIPMENTS_IN_INVOICING)
  }

  public getShipmentsForAlerts(): Observable<IShipment[]> {
    return this.http.get<IShipment[]>(this._urls.CARRIER_SHIPMENTS_FOR_ALERTS)
  }

  public getShipment(shipmentUUID: string): Observable<ICarrierShipment> {
    return this.http.get<ICarrierShipment>(this._urls.CARRIER_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  takeShipmentFromMarketPlace(shipmentUUID: string): Observable<ICarrierMarketShipment> {
    return this.http.post<ICarrierMarketShipment>(this._urls.CARRIER_SHIPMENTS_TAKE_BY_UUID(shipmentUUID), {})
  }

  makeShipmentInvoiceReady(shipmentUUID: string): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_SHIPMENTS_MAKE_INVOICE_READY_BY_UUID(shipmentUUID), {})
  }

  addMarketPlaceView(shipmentUUID: string): Observable<ICarrierMarketShipment> {
    return this.http.post<ICarrierMarketShipment>(this._urls.CARRIER_SHIPMENTS_VIEW_BY_UUID(shipmentUUID), {})
  }

  public calculatePriceForShipment(shipment: IShipment): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_SHIPMENTS_PRICING, shipment)
  }

  public pickupShipment(shipmentUUID: string, shipment: IPickupInfo): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_DO_PICKUP_SHIPMENTS_BY_UUID(shipmentUUID), shipment)
  }

  public deliverShipment(shipmentUUID: string, shipment: IDeliverInfo): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_DO_DELIVER_SHIPMENTS_BY_UUID(shipmentUUID), shipment)
  }

  public setAtHubShipment(shipmentUUID: string, shipment: IPickupInfo): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_DO_SET_AT_HUB_SHIPMENTS_BY_UUID(shipmentUUID), shipment)
  }

  public createShipment(shipment: IShipment): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_SHIPMENTS, shipment)
  }

  public updateShipment(shipmentUUID: string, shipment: IShipment): Observable<any> {
    return this.http.patch<any>(this._urls.CARRIER_SHIPMENTS_BY_UUID(shipmentUUID), shipment)
  }

  public acceptShipment(shipmentUUID: string): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_RELEASE_SHIPMENTS_BY_UUID(shipmentUUID), {})
  }

  public sellShipment(shipmentUUID: string, data: any): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_SELL_SHIPMENTS_BY_UUID(shipmentUUID), data)
  }

  public cancelShipment(shipmentUUID: string): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_CANCEL_SHIPMENTS_BY_UUID(shipmentUUID), {})
  }

  public getShipmentTransaction(shipmentUUID: string): Observable<any> {
    return this.http.get<any>(this._urls.CARRIER_PRICE_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  public updateShipmentTransaction(shipmentUUID: string, transactionUUID: string, transaction: IShipmentTransaction): Observable<any> {
    return this.http.patch<any>(this._urls.CARRIER_PRICE_TRANSACTION_SHIPMENTS_BY_UUID(shipmentUUID, transactionUUID), transaction)
  }

  public addShipmentTransactionSurcharge(shipmentUUID: string, transactionUUID: string, data: any): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_PRICE_TRANSACTION_SURCHARGE_SHIPMENTS_BY_UUID(shipmentUUID, transactionUUID), data)
  }

  public getShipmentImages(shipmentUUID: string): Observable<ShipmentImage[]> {
    return this.http.get<ShipmentImage[]>(this._urls.CARRIER_IMAGES_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  public addShipmentImage(shipmentUUID: string, data: any): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_IMAGES_SHIPMENTS_BY_UUID(shipmentUUID), data)
  }

  public addPrivateCommentShipment(shipmentUUID: string, content: string): Observable<IShipmentComment> {
    return this.http.post<IShipmentComment>(this._urls.CARRIER_PRIVATE_COMMENT_SHIPMENTS_BY_UUID(shipmentUUID), { content })
  }

  public addPublicCommentShipment(shipmentUUID: string, content: string): Observable<IShipmentComment> {
    return this.http.post<IShipmentComment>(this._urls.CARRIER_COMMENT_SHIPMENTS_BY_UUID(shipmentUUID), { content })
  }

  public getPrivateCommentsShipment(shipmentUUID: string): Observable<IShipmentComment[]> {
    return this.http.get<IShipmentComment[]>(this._urls.CARRIER_PRIVATE_COMMENT_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  public getPublicCommentsShipment(shipmentUUID: string): Observable<IShipmentComment[]> {
    return this.http.get<IShipmentComment[]>(this._urls.CARRIER_COMMENT_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  public deleteCommentsShipment(shipmentUUID: string, commentUUID: string): Observable<IShipmentComment[]> {
    return this.http.delete<IShipmentComment[]>(this._urls.CARRIER_COMMENT_BY_UUID_SHIPMENTS_BY_UUID(shipmentUUID, commentUUID))
  }

  public updateCommentsShipment(shipmentUUID: string, commentUUID: string, comment: IShipmentComment): Observable<IShipmentComment[]> {
    return this.http.patch<IShipmentComment[]>(this._urls.CARRIER_COMMENT_BY_UUID_SHIPMENTS_BY_UUID(shipmentUUID, commentUUID), comment)
  }

  public getDashboardNumbers(): Observable<any> {
    return this.http.get<any>(this._urls.CARRIER_SHIPMENTS_FOR_DASHBOARD)
  }

  searchShipments(params: Params) {
    return this.http.get<any>(this._urls.CARRIER_SHIPMENTS_SEARCH, { params })
  }

  assignDriver(shipmentUUID: string, userUUID: string) {
    return this.http.post<IShipmentComment>(this._urls.CARRIER_DRIVER_SHIPMENTS_BY_UUID(shipmentUUID), { userUUID })
  }

  dispatchShipment(shipmentUUID: string, val: IDispatchInfo): Observable<unknown> {
    return this.http.post<any>(this._urls.CARRIER_DISPATCH_SHIPMENTS_BY_UUID(shipmentUUID), val)
  }

  getClientShipments(clientUUID: string): Observable<IShipment[]> {
    return this.http.get<IShipment[]>(this._urls.CARRIER_SHIPMENTS, { params: { clientUUID } })
  }

  assignShipmentsToTour(tourUUID: string, val: any): Observable<unknown> {
    return this.http.post<any>(this._urls.CARRIER_TOURS_SHIPMENTS(tourUUID), val)
  }

  removeShipmentsFromTour(tourUUID: string, val: any): Observable<unknown> {
    return this.http.delete<any>(this._urls.CARRIER_TOURS_SHIPMENTS(tourUUID), {params: val})
  }
}
