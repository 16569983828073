import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { CurrentLocationStatus, ShipmentStatus, ShipmentTaskStatus } from '../services/shipments.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'shipmentStatusPipe' })
export class ShipmentStatusPipe implements PipeTransform {
  constructor (private translate: TranslateService) {}

  transform(status: any): string {
    switch (status) {
      case ShipmentStatus.UNKNOWN:
        return this.translate.instant('common.unknown');
      case ShipmentStatus.CREATED:
        return this.translate.instant('common.created')
      case ShipmentStatus.APPROVED:
        return this.translate.instant('common.accepted');
      case ShipmentStatus.DISPATCHED:
        return this.translate.instant('common.dispatched');
      case ShipmentStatus.IN_HUB:
        return this.translate.instant('common.at_hub');
      // case ShipmentStatus.DISPATCHED_FOR_PICKUP:
      //   return 'Disponiert für Abholung'
      // case ShipmentStatus.DISPATCHED_FOR_DELIVERY:
      //   return 'Disponiert für Zustellung'
      case ShipmentStatus.PICKED_UP:
        return this.translate.instant('common.picked_up');
      case ShipmentStatus.IN_DELIVERED:
        return this.translate.instant('common.out_for_delivery');
      case ShipmentStatus.DELIVERED:
        return this.translate.instant('common.shipped');
      case ShipmentStatus.CANCELLED:
        return this.translate.instant('common.cancelled');
      case ShipmentStatus.IN_PICKUP:
        return this.translate.instant('common.out_for_pickup');
    }
    return '-'
  }
}


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'shipmentCurrentLocationStatusPipe' })
export class ShipmentCurrentLocationStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(status: any): string {
    switch (status) {
      case CurrentLocationStatus.UNKNOWN:
        return this.translate.instant('common.unknown');
      case CurrentLocationStatus.AT_PICKUP_SITE:
        return this.translate.instant('common.at_pickup_location');
      case CurrentLocationStatus.AT_PICKUP_TRUCK:
        return this.translate.instant('common.in_pickup_vehicle');
      case CurrentLocationStatus.AT_HUB:
        return this.translate.instant('common.at_hub');
      case CurrentLocationStatus.AT_DELIVERY_TRUCK:
        return this.translate.instant('common.in_delivery_vehicle');
      case CurrentLocationStatus.AT_DELIVERY_SITE:
        return this.translate.instant('common.at_delivery_location');
    }
    return '-'
  }
}


@Injectable({ providedIn: 'root' })
  @Pipe({ name: 'shipmentTaskStatusPipe' })
export class ShipmentTaskStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(status: any): string {
    switch (status) {
      case ShipmentTaskStatus.PICKUP_VIA_HUB:
        return this.translate.instant('common.pickup_via_hub');
      case ShipmentTaskStatus.PICKUP_AND_DELIVERY_DIRECTLY:
        return this.translate.instant('common.pickup_and_direct_delivery');
      case ShipmentTaskStatus.DELIVERY_VIA_HUB:
        return this.translate.instant('common.delivery_via_hub');
      case ShipmentTaskStatus.DELIVERY_DIRECTLY:
        return this.translate.instant('common.direct_delivery');
    }
    return '-'
  }
}
