import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IEditSurcharge } from '../../interfaces/surcharge.interface';
import { IOrganization } from '../../interfaces/organization.interface';
import { OrganizationsService } from '../../services/organizations.service';


@Component({
  selector: 'app-shipment-edit-surcharge-modal',
  templateUrl: './shipment-edit-surcharge-modal.component.html',
  styleUrls: ['./shipment-edit-surcharge-modal.component.scss']
})
export class ShipmentEditSurchargeModalComponent implements OnInit {

  ownOrganization: IOrganization | null = null;
  total_price = 0

  constructor(
    public dialogRef: MatDialogRef<ShipmentEditSurchargeModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IEditSurcharge,
    private organizationService: OrganizationsService,
  ) { }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    this.onChange();
  }

  onChange() {
    console.log('onchange')
    this.total_price = 0;
    this.total_price = this.data.transaction.total_price;
    this.total_price += this.data.price
  }
}
