import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientsService } from '../../services/clients.service';
import { IUser } from '../../services/users.service';


@Component({
  selector: 'app-user-selector-modal',
  templateUrl: './user-selector.component.html',
})
export class UserSelectorModalComponent {

  constructor(
    public clientsService: ClientsService,
    public dialogRef: MatDialogRef<UserSelectorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IUser,
  ) {
  }

  onSelectedUser(event: IUser) {
    this.data = event;
  }
}
