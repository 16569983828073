import { Component, OnInit, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MarketPlaceShipmentOverviewOverviewComponent } from 'projects/webapp/app/components/market-place-shipment-overview/market-place-shipment-overview.component';
import { OrgOverviewComponent } from 'projects/webapp/app/components/org-overview/org-overview.component';
import { ICarrierMarketShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-market-place',
  templateUrl: './market-place.component.html',
  styleUrls: ['./market-place.component.scss']
})
export class MarketPlaceComponent implements OnInit {
  shipments: ICarrierMarketShipment[] = []
  isLoading = false;

  constructor(
    private carrierShipmentsService: CarrierShipmentsService,
    private dialog: MatDialog,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private organizationsService: OrganizationsService,
    private translateService: TranslateService,
    @Optional() private angularFireAnalytics: AngularFireAnalytics,
  ) { }

  async ngOnInit() {
    this.refresh();
  }

  async refresh() {
    try {
      this.isLoading = true
      this.spinnerService.show()
      // only load shipments if organization can view shipments
      if (this.organizationsService.ownOrg$.value && this.organizationsService.ownOrg$.value.admin_permission_market_place_view) {
        this.shipments = await lastValueFrom(this.carrierShipmentsService.getCarrierShipmentsInMarketPlace({}))
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
      this.spinnerService.hide()
    }
  }

  openSenderDetails(shipment: ICarrierMarketShipment) {
    this.dialog.open(OrgOverviewComponent, { data: shipment.sender_object })
  }

  openShipmentDetails(shipment: ICarrierMarketShipment) {
    this.dialog.open(MarketPlaceShipmentOverviewOverviewComponent, {
      data: shipment,
      width: '90vw',
      // height: '90vh'
    }).afterClosed().subscribe(async val => {
      if (!!val) {
        this.angularFireAnalytics?.logEvent(`try_accept_shipment`)
        const alertMessage = await lastValueFrom(this.translateService.get('toastr.message_to_activate_marketplace'));
        const confirmationMessage = await lastValueFrom(this.translateService.get('toastr.client_edited'));

        if (this.organizationsService.ownOrg$.value && !this.organizationsService.ownOrg$.value.admin_permission_market_place_take) {
          alert(alertMessage)
          return;
        }

        if (confirm(confirmationMessage) && shipment.uuid) {
          try {
            const successMessage = await lastValueFrom(this.translateService.get('toastr.client_edited'));

            this.angularFireAnalytics?.logEvent(`accept_shipment`)
            await lastValueFrom(this.carrierShipmentsService.takeShipmentFromMarketPlace(shipment.uuid))
            this.toastrService.success(successMessage)
            this.refresh()
          } catch (error) {
            console.error(error)
          }
        }
      }
    })
  }
}
