import { Component, OnInit, Optional } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SwUpdate } from '@angular/service-worker';
import { versions } from 'projects/versions';
import { environment } from './environments/environment';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  usingAsInstalledApp = false;

  constructor(
    @Optional() private angularFireAnalytics: AngularFireAnalytics,
    private updates: SwUpdate,
    languageService: LanguageService
  ) {
    languageService.init()
  }

  public ngOnInit(): void {
    if (this.checkIfInstalledWebApp()) {
      this.angularFireAnalytics?.logEvent(
        `using_installed_web_app`, {
        app_name: 'webapp',
        app_version: versions.date
      }
      );
    }

    if (environment.envName !== 'local_dev') {
      this.handleUpdates()
    }
  }

  private handleUpdates() {
    const location = window.location.pathname
    console.log(`location: ${location}`)

    if (this.updates.isEnabled) {
      this.updates.versionUpdates.subscribe(evt => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            this.angularFireAnalytics?.logEvent(`update_available`);

            if (!location.includes('/main')) {
              document.location.reload();
            } else {
              if (confirm('Es ist eine neue Version der Applikation verfügbar, jetzt updaten?') === true) {
                this.updates.activateUpdate().then(() => {
                  this.angularFireAnalytics?.logEvent(`performing_update`);
                  document.location.reload();
                });
              }
            }
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            break;
        }
      })
    }
    this.updates.unrecoverable.subscribe((event) => {
      console.error('unrecoverable', event);
      console.error(event.reason);
    });
  }

  private checkIfInstalledWebApp() {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      this.usingAsInstalledApp = true;
      return true;
    }
    this.usingAsInstalledApp = false;
    return false;
  }


}
