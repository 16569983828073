import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-form',
  templateUrl: './location-form.component.html',
  styleUrls: ['./location-form.component.scss']
})
export class LocationFormComponent implements OnInit {
  @Input() type: 'pickup' | 'delivery' = 'pickup';

  @Input() shipmentForm : any;

  constructor(
  ) {  }

  ngOnInit() { }

}
