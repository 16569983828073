<div class="container-fluid" *ngIf="organization">

  <div class="d-flex justify-content-between">
    <h1>{{'common.organization' | translate}}: {{organization.name}}</h1>
    <div>

      <a mat-icon-button [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
      <a mat-icon-button href="{{environment.apiDomain}}/admin/accounts/organization/{{organization.id}}/change/"
        target="_blank">
        <mat-icon>edit</mat-icon>
      </a>
    </div>
  </div>

  <hr>

  <button class="btn btn-primary" (click)="impersonateOrganization()">impersonate</button>

  <hr>

  <div>
    <h2>{{'common.details' | translate}}</h2>
    <ul class="co-simple-list">
      <li>company_name: {{organization.company_name}}</li>
      <li>first_name: {{organization.first_name}}</li>
      <li>last_name: {{organization.last_name}}</li>

      <li>email: {{organization.email}}</li>

      <li>phone: {{organization.phone}}</li>
      <li>address: {{organization.address}}</li>
      <li>location: {{organization.zip}} {{organization.location}}</li>

      <li>created_at: {{organization.created_at | humanDatePipe}}</li>
      <li>updated_at: {{organization.updated_at | humanDatePipe}}</li>

    </ul>

    <!-- <pre>{{organization | json}}</pre> -->

  </div>

  <hr>

  <div style="display: flex; flex-direction: column;">

    <mat-checkbox [(ngModel)]="organization.is_active"
      (change)="onChangeOrgProperty('is_active', $event)">is_active</mat-checkbox>
    <mat-checkbox [(ngModel)]="organization.admin_permission_software_license"
      (change)="onChangeOrgProperty('admin_permission_software_license', $event)">admin_permission_software_license</mat-checkbox>

    <mat-checkbox [(ngModel)]="organization.admin_permission_assign_carrier"
      (change)="onChangeOrgProperty('admin_permission_assign_carrier', $event)">admin_permission_assign_carrier</mat-checkbox>

    <mat-checkbox [(ngModel)]="organization.admin_permission_market_place_view"
      (change)="onChangeOrgProperty('admin_permission_market_place_view', $event)">admin_permission_market_place_view</mat-checkbox>
    <mat-checkbox [(ngModel)]="organization.admin_permission_market_place_take"
      (change)="onChangeOrgProperty('admin_permission_market_place_take', $event)">admin_permission_market_place_take</mat-checkbox>
  </div>


  <hr>

  <div>
    <h2>Users</h2>

    <table *ngIf="!!users && users.length" style="width: 100%" class="table table-striped">
      <thead>
        <td>{{'common.name' | translate}}</td>
        <td>Username</td>
        <td>Email</td>
        <td>Phone</td>
        <td>Updated_at</td>
        <td>Last_Login</td>
      </thead>

      <tbody>

        <tr *ngFor="let item of users">
          <td>
            <a routerLink="/main/admin/users/{{item.uuid}}">
              {{item.first_name}} {{item.last_name}}
              <span class="badge bg-secondary" *ngIf="item.is_hidden">HIDDEN</span>
            </a>
          </td>
          <td>{{item.username}}</td>
          <td>{{item.email}}</td>
          <td>{{item.phone}}</td>
          <td>{{item.updated_at | humanDatePipe}}</td>
          <td>{{item.last_login | humanDatePipe}}</td>
        </tr>

      </tbody>
    </table>
  </div>

  <hr>


  <div>
    <h2>Admin Kommentare</h2>

    <textarea [(ngModel)]="newComment" style="width: 100%;" cols="10"></textarea>
    <br>
    <button (click)="onAddNewComment()" class="btn btn-primary" [disabled]="newComment === ''">Add Comment</button>

    <div class="alert alert-info mt-2 mb-2" *ngIf="comments.length == 0">{{'common.no_comments_yet' | translate}}</div>

    <table *ngIf="!!comments && comments.length" style="width: 100%" class="table table-striped">
      <thead>
        <td>Who</td>
        <td>When</td>
        <td>What</td>
      </thead>

      <tbody>

        <tr *ngFor="let item of comments">
          <td>{{item.author_object?.first_name}} {{item.author_object?.last_name}}</td>
          <td>{{item.updated_at | humanDatePipe}}</td>
          <td>{{item.content}}</td>
          <!-- <td>{{item.email}}</td> -->
          <!-- <td>{{item.last_login | humanDatePipe}}</td> -->
        </tr>

      </tbody>
    </table>

  </div>
</div>
