import { NgModule } from '@angular/core';
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
  RecaptchaV3Module,
  RECAPTCHA_V3_SITE_KEY,
} from 'ng-recaptcha';

@NgModule({
  declarations: [],
  imports: [RecaptchaModule, RecaptchaFormsModule, RecaptchaV3Module],
  exports: [RecaptchaModule, RecaptchaFormsModule, RecaptchaV3Module],
  providers: [
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6Ld0Pi4nAAAAAM0GQ4RVPKSbLFwj-lfoGSgca2MB' },
  ],
})
export class CaptchaModule { }
