<div class="container-fluid" style="margin-bottom: 100px;">
  <div class="d-flex flex-column flex-md-row justify-content-between">
    <h1 class="mt-2">{{'common.id' | translate}} {{tour?.id}}</h1>
    <div class="d-flex gap-3 justify-content-end align-items-md-center">
      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>

      <!-- <a class="btn-primary green primary-hover-animation" routerLink="/main/carrier/tours">
        {{'common.back_to_tours' | translate}}
        <mat-icon>360</mat-icon>
      </a> -->

      <button class="btn-primary green primary-hover-animation" (click)="downloadLoadList()">
        {{'common.download_pdf' | translate}}
        <mat-icon>download</mat-icon>
      </button>

      <button mat-icon-button color="primary" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>

  <div class="mt-2">
    <!-- <pre>{{tour | son}}</pre> -->

    <div class="pt-2" *ngIf="tour">
      <h3>{{'common.details' | translate}}</h3>

      <div class="container details-container">

        <div class="row gap-4">
          <!-- <mat-form-field appearance="outline" class="col-6">
            <mat-label>{{'common.date' | translate}}</mat-label>
            <input matInput [(ngModel)]="tour.date" readonly>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-6">
            <mat-label>{{'common.stops' | translate}}</mat-label>
            <input matInput [(ngModel)]="tour.number_of_stops" readonly>
          </mat-form-field> -->
          <div class="col d-flex gap-2">
            <span class="d-flex gap-1"><mat-icon>date_range</mat-icon> {{'common.date' | translate}}:</span>
            <span>{{tour.date}}</span>
          </div>
          <div class="col d-flex gap-2">
            <span class="d-flex gap-1"><mat-icon>remove_circle</mat-icon> {{'common.stops' | translate}}:</span>
            <span>{{tour.number_of_stops}}</span>
          </div>
        </div>

        <div class="row gap-4">
          <!-- <mat-form-field appearance="outline" class="col-6">
            <mat-label>{{'common.weight' | translate}}</mat-label>
            <input matInput [(ngModel)]="tour.weight" readonly>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-6">
            <mat-label>{{'common.ground_spaces' | translate}}</mat-label>
            <input matInput [(ngModel)]="tour.ground_spaces" readonly>
          </mat-form-field> -->
          <div class="col d-flex gap-2">
            <span class="d-flex gap-1"><mat-icon>fitness_center</mat-icon> {{'common.weight' | translate}}:</span>
            <span>{{tour.weight}} kg</span>
          </div>
          <div class="col d-flex gap-2">
            <span class="d-flex gap-1"><mat-icon>space_bar</mat-icon> {{'common.ground_spaces' | translate}}:</span>
            <span>{{tour.ground_spaces}}</span>
          </div>
        </div>

        <div class="row gap-4">
          <!-- <mat-form-field appearance="outline" class="col-6">
            <mat-label>{{'common.revenue' | translate}}</mat-label>
            <input matInput [(ngModel)]="tour.turnover" readonly>
          </mat-form-field>
          <mat-form-field appearance="outline" class="col-6">
            <mat-label>{{'common.profit' | translate}}</mat-label>
            <input matInput [(ngModel)]="tour.profit" readonly>
          </mat-form-field> -->
          <div class="col d-flex gap-2">
            <span class="d-flex gap-1"><mat-icon>attach_money</mat-icon> {{'common.revenue' | translate}}:</span>
            <span>{{tour.turnover}} {{ownOrganization?.default_currency}}</span>
          </div>
          <div class="col d-flex gap-2">
            <span class="d-flex gap-1"><mat-icon>attach_money</mat-icon> {{'common.profit' | translate}}:</span>
            <span>{{tour.profit}} {{ownOrganization?.default_currency}}</span>
          </div>
        </div>

      </div>
    </div>

    <hr>

    <form [formGroup]="form" *ngIf="tour && editable">
      <h3>{{'common.editing' | translate}}</h3>

      <mat-form-field appearance="outline" style="width: 100%">
        <mat-label>{{'common.date' | translate}}</mat-label>
        <input matInput [matDatepicker]="picker" formControlName="date">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <app-vehicle-selector [selectedVehicle]="tour.vehicle_object"
        (onSelectedVehicle)="onSelectedVehicle($event)"></app-vehicle-selector>

      <div>
        <app-user-selector [selectedUser]="tour.driver_object"
          (onSelectedUser)="onSelectedUser($event)"></app-user-selector>
      </div>

      <div>
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-label>{{'common.status' | translate}}</mat-label>
          <mat-select formControlName="status">
            <mat-option [value]="tourStatus.IN_PROGRESS">
              {{tourStatus.IN_PROGRESS | tourStatusPipe}}
            </mat-option>

            <mat-option [value]="tourStatus.PLANNED" [disabled]="true">
              {{tourStatus.PLANNED | tourStatusPipe}}
            </mat-option>

            <mat-option [value]="tourStatus.CLOSED" [disabled]="true">
              {{tourStatus.CLOSED | tourStatusPipe}}
            </mat-option>

          </mat-select>
        </mat-form-field>
      </div>

      <div class="d-flex align-items-end flex-column">
        <button class="btn-primary green primary-hover-animation" (click)="save()" cdkFocusInitial>{{'common.save' |
          translate}}</button>
      </div>

      <div class="alert alert-info mt-3"
        *ngIf="tour.status == tourStatus.IN_PROGRESS || tour.status == tourStatus.UNKNOWN">
        <div class="d-flex align-items-center flex-column">
          <button mat-flat-button class="btn-primary green primary-hover-animation" (click)="onCloseTour()"
            [disabled]="!tour.driver_object">{{'common.complete_tour' | translate}}</button>
        </div>
      </div>

    </form>

    <hr>


    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h3>
        {{'common.travel_time_distance' | translate}}
      </h3>
      <div style="display: flex; align-items: center;">
        <button mat-icon-button color="primary" (click)="alert()">
          <mat-icon>info</mat-icon>
        </button>
      </div>
    </div>

    <div class="pt-2" *ngIf="tour">

      <div class="row">
        <mat-form-field appearance="outline" class="col-6">
          <mat-label>{{'common.optimal_travel_time' | translate}}</mat-label>
          <input matInput [(ngModel)]="tour.driving_duration_display" readonly>
        </mat-form-field>
        <mat-form-field appearance="outline" class="col-6">
          <mat-label>{{'common.optimal_travel_distance' | translate}} ({{'common.km' | translate}})</mat-label>
          <input matInput [(ngModel)]="tour.driving_distance" readonly>
        </mat-form-field>

      </div>
      <div class="row px-3" *ngIf="tour && editable">
        <div class="alert alert-info mt-3 " *ngIf="!tour.is_optimized">
          <div class="d-flex align-items-center flex-column">
            <button mat-flat-button class="btn-primary green primary-hover-animation" (click)="onOptimizeTour()"
              [disabled]="tour.is_optimized">{{'common.optimize_calculate' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
    <h4>
      {{'common.stations' | translate}}
    </h4>
    <div cdkDropList class="example-list" *ngIf="!!tour && tour.order && tour.order.length > 0"
      (cdkDropListDropped)="dropStop($event)">
      <div class="grid-row">
        <div class="grid-cell">
        </div>

        <div class="grid-cell">
          common.address
        </div>
      </div>

      <div class="grid-row" *ngFor="let stop of tour.order, index as i" cdkDrag>
        <div class="grid-cell" style="display: flex; flex-direction: row;">
          <div class="drag-index">{{i+1}}</div>
          <div class="w-10 drag-icon">
            <i class="material-icons" cdkDragHandle>reorder</i>
          </div>
        </div>

        <div class="grid-cell">
          {{stop}}
        </div>

      </div>
    </div>

    <div *ngIf="!!tour && !!tour.order && tour.order.length == 0" class="alert alert-info">
      {{'common.no_shipments_in_this_tour' | translate}}</div>
    <!--

    <hr>

    <h3>Karte</h3>
    <button (click)="calculateAndDisplayRoute()">calculate</button>
    <div #map style="width:100%;height:400px"></div>

    <table class="table table-striped" style="width: 100%" *ngIf="!!legs && legs.length > 0">
      <thead>
        <td>#</td>
        <td>{{'common.from' | translate}}</td>
        <td>{{'common.to' | translate}}</td>
        <td>Dauer</td>
        <td>Distanz</td>
      </thead>

      <tbody>
        <tr *ngFor="let leg of legs; index as index">
          <td>
            {{index + 1}}
          </td>
          <td>
            {{leg.start_address}}
          </td>
          <td>
            {{leg.end_address}}
          </td>
          <td>
            {{leg.duration.text}}
          </td>
          <td>
            {{leg.distance.text}}
          </td>
        </tr>
      </tbody>
    </table> -->
    <!-- <pre>{{legs | json}}</pre> -->

    <hr>

    <h3>{{'common.shipments' | translate}}</h3>

    <!-- TODO add button to download deliverey slips here -->

    <div *ngIf="!!tour && !!tour.shipments && tour.shipments.length == 0" class="alert alert-info">
      {{'common.no_shipments_in_this_tour' | translate}}</div>

    <div *ngIf="!!tour && !!tour.shipments && tour.shipments.length > 0" class="container-fluid">
      <div class="scrollable-list">
        <table class="table table-custom" style="width: 100%;">
          <thead>
            <td>{{'common.number' | translate}}</td>
            <td>{{'common.from' | translate}}</td>
            <td>{{'common.to' | translate}}</td>
            <td>{{'common.weight' | translate}}</td>
            <td>{{'common.status' | translate}}</td>
            <td></td>
          </thead>

          <tbody>
            <tr *ngFor="let shipment of tour.shipments">
              <td [attr.data-label]="'common.number' | translate">
                <a routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                  {{shipment.number | shipmentNumberPipe }}
                </a>
              </td>
              <td [attr.data-label]="'common.from' | translate">

                {{shipment.pickup_zip }} {{shipment.pickup_location }}
                <br>
                {{shipment.pickup_date }}
              </td>

              <td [attr.data-label]="'common.to' | translate">
                {{shipment.delivery_zip }} {{shipment.delivery_location }}
                <br>
                {{shipment.delivery_date }}
              </td>
              <td [attr.data-label]="'common.weight' | translate">
                {{shipment.total_weight | number: '1.2-2' }} Kg
              </td>
              <td [attr.data-label]="'common.status' | translate">
                {{shipment.status | shipmentStatusPipe }}
              </td>
              <td>
                <button class="delete-btn" mat-icon-button (click)="onRemoveShipmentFromTour(shipment)" *ngIf="editable">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>



  </div>
</div>