import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { IPrice } from 'projects/shared/price.interface';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-shipment-registration',
  templateUrl: './shipment-registration.component.html',
  styleUrls: ['./shipment-registration.component.scss']
})
export class ShipmentRegistrationComponent implements OnInit {
  price: IPrice | null = null;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public carrierShipmentsService: CarrierShipmentsService,
    public toastrService: ToastrService,
    public router: Router,
    public spinnerService: NgxSpinnerService,
    private translate: TranslateService,
  ) {
  }

  async ngOnInit() {
  }

  async onShipmentRequest(event: any) {
    try {
      this.spinnerService.show()
      const response = await lastValueFrom(this.carrierShipmentsService.createShipment(event));
      this.translate.get('toastr.shipment_created').subscribe((translatedMessage: string) => {
        this.toastrService.success(translatedMessage);
      });
      this.router.navigate(['/main/carrier/shipments/' + response.uuid])
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

  async onShipmentPriceRequest(event: any) {
    const response = await lastValueFrom(this.carrierShipmentsService.calculatePriceForShipment(event));
    this.price = response;
  }

}
