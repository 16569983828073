import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-surcharge-pricing-form',
  templateUrl: './surcharge-pricing-form.component.html',
  styleUrls: ['./surcharge-pricing-form.component.scss']
})
export class SurchargePricingFormComponent implements OnInit {

  surchargesForm: FormGroup = new FormGroup({
    empties_exchange_price_pallet: new FormControl('', []),
    empties_exchange_price_frame: new FormControl('', []),
    empties_exchange_price_cover: new FormControl('', []),
    fuel_surcharge_percent: new FormControl('', []),
    congestion_surcharge_percent: new FormControl('', []),
    insurance_surcharge_min: new FormControl('', []),
    insurance_surcharge_percent: new FormControl('', []),
    phone_notification: new FormControl('', [])
  });

  constructor(
    public organizationService: OrganizationsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit() {
    const response  = await lastValueFrom(this.organizationService.getPricing())
    this.surchargesForm.patchValue(response)
  }

  async updateSurcharges() {
    this.surchargesForm.markAllAsTouched()
    if (this.surchargesForm.valid) {
      const successMessage = await lastValueFrom(this.translateService.get('toastr.saved'));
      const response = await lastValueFrom(this.organizationService.updatePricing(this.surchargesForm.value));
      this.toastrService.success(successMessage);
    }
  }
}
