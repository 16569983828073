
<div class="container mt-4 mb-4">
    <div style="display: flex; justify-content: space-between;">
        <h5>Admin Dashboard</h5>
        <div>
            <div class="spinner-grow" role="status" *ngIf="isLoading">
            </div>
            <a mat-icon-button color="primary" [routerLink]="[]" (click)="refresh()">
                <mat-icon>refresh</mat-icon>
            </a>
        </div>
    </div>
</div>

<div class="container-fluid">

    <mat-card class="mt-3">
        <mat-card-content *ngIf="keyNumbers">
            <h1>Yesterday</h1>
            <ul class="co-simple-list">
                <li>Users Login: {{keyNumbers.users_login_yesterday}}</li>
                <li>Private shipments: {{keyNumbers.private_shipments_yesterday}}</li>
                <li>Marketplace shipments: {{keyNumbers.market_place_shipments_yesterday}}</li>
                <li>Marketplace turnover: {{keyNumbers.market_place_turnover_yesterday | pricePipe}} Fr.</li>
                <li>Marketplace profit: {{keyNumbers.market_place_profit_yesterday | pricePipe}} Fr.</li>
            </ul>
        </mat-card-content>
    </mat-card>

    <mat-card class="mt-3">
        <mat-card-content *ngIf="keyNumbers">
            <h1>Today</h1>
            <ul class="co-simple-list">
                <li>Users Login: {{keyNumbers.users_login_today}}</li>
                <li>Private shipments: {{keyNumbers.private_shipments_today}}</li>
                <li>Marketplace shipments: {{keyNumbers.market_place_shipments_today}}</li>
                <li>Marketplace turnover: {{keyNumbers.market_place_turnover_today | pricePipe}} Fr.</li>
                <li>Marketplace profit: {{keyNumbers.market_place_profit_today | pricePipe}} Fr.</li>
            </ul>
        </mat-card-content>
    </mat-card>
    <div class="d-flex justify-content-center mt-4">
        <a class="btn btn-primary mx-1" routerLink="/main/admin/organizations">{{'carrier_dashboard.organizations' | translate}}</a>
        <a class="btn btn-primary mx-1" routerLink="/main/admin/users">Users</a>
        <a class="btn btn-primary mx-1" routerLink="/main/admin/shipments">Shipments</a>
        <a class="btn btn-primary mx-1" href="{{environment.apiDomain}}/admin" target="_blank">Django Admin</a>

    </div>
</div>
