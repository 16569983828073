<div style="display: flex; flex-direction: row; justify-content: space-between;">

    <h3></h3>
    <div class="d-flex">
        <button class="d-flex" mat-icon-button color="primary" (click)="onAddKey()" style="margin-right: 15px;">
            <mat-icon>add</mat-icon>
        </button>
        <button class="d-flex" mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>


<div *ngIf="!isLoading">

    <div class="alert alert-info bg-green-light" style="border: none;" *ngIf="client">{{'common.your_customers_can_submit_shipments' | translate}}</div>

    <div class="alert alert-info bg-green-light" style="border: none;" *ngIf="!client">{{'common.you_can_create_API_keys_here' | translate}}</div>

    <table *ngIf="!!apiKeys && apiKeys.length" style="width: 100%" class="table table-custom">
        <thead>
            <td>{{'common.keys' | translate}}</td>
            <td>{{'common.creation_date' | translate}}</td>
            <td translate>common.client</td>
            <td>{{'common.usage' | translate}}</td>
            <td translate>common.active</td>
            <td></td>
        </thead>

        <tbody>
            <tr *ngFor="let key of apiKeys">

                <td [attr.data-label]="'common.keys' | translate">{{key.token}}</td>
                <td [attr.data-label]="'common.creation_date' | translate">{{key.created_at | date: 'yyyy-MM-dd HH:mm'}}</td>
                <td [attr.data-label]="'common.client' | translate">
                    <span *ngIf="key.client_object; else noClient">
                        {{key.client_object.name }}
                    </span>
                    <ng-template #noClient>-</ng-template>
                </td>
                <td [attr.data-label]="'common.usage' | translate">{{key.purpose}}</td>
                <td [attr.data-label]="'common.active' | translate">{{key.active | yesNoPipe}}</td>

                <td class="d-flex justify-content-around justify-content-md-end">

                    <button mat-icon-button (click)="onEditKey(key)">
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-icon-button (click)="onDeleteKey(key)">
                        <mat-icon>delete</mat-icon>
                    </button>

                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="text-center mt-5" *ngIf="!isLoading && apiKeys.length == 0">
    <div class="alert alert-warning">{{'common.no_API_keys_yet' | translate}}</div>
</div>


<!-- <pre>{{client | json}}</pre> -->