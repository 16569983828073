import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrganizationsService } from '../../services/organizations.service';
import { IOrganization } from '../../interfaces/organization.interface';


@Component({
  selector: 'app-shipment-edit-pricing-modal',
  templateUrl: './shipment-edit-pricing-modal.component.html',
  styleUrls: ['./shipment-edit-pricing-modal.component.scss']
})
export class ShipmentEditPricingModalComponent implements OnInit {

  ownOrganization: IOrganization | null = null;

  constructor(
    public dialogRef: MatDialogRef<ShipmentEditPricingModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private organizationService: OrganizationsService,
  ) { }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    this.onChange();
  }

  onChange() {
    console.log('onchange')
    this.data.total_price = this.data.base_price;
    this.data.options.forEach((el: any) => {
      this.data.total_price += el.price
    });
  }
}
