import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { InvoicesService } from 'projects/webapp/app/services/invoices.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { IInvoice } from 'projects/webapp/app/interfaces/invoice.interface';
import { TranslateService } from '@ngx-translate/core';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';


@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnInit {

  isLoading = true;

  invoiceUUID = '';
  invoice: IInvoice | null = null;

  ownOrganization: IOrganization | null = null;

  constructor(
    public clientsService: ClientsService,
    public usersService: UsersService,
    public spinnerService: NgxSpinnerService,
    private title: Title,
    private invoicesService: InvoicesService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.translate.get('common.invoice_editor').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    this.route.params.subscribe(async params => {
      console.log(params)
      this.invoiceUUID = params['invoiceUUID'];
      if (!!params && params['invoiceUUID']) {
        await this.refresh();
      }
    });
  }

  async refresh() {
    const invoice = await lastValueFrom(this.invoicesService.getInvoice(this.invoiceUUID));
    console.log(invoice);
    this.invoice = invoice;
  }

}
