<h1 mat-dialog-title>
  <span *ngIf="data">{{data.title}}</span>
</h1>
<div mat-dialog-content *ngIf="data">
  <div [innerHTML]="data.description"></div>
</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <div>
    <mat-checkbox [(ngModel)]="do_not_show_again" color="primary">{{'common.do_not_show_this_message_again' | translate}}</mat-checkbox>
    <div style="display:flex; flex-direction:row; justify-content: center; align-items:center">
      <button class="co-btn-flat-primary" (click)="close()">{{'common.close' | translate}}</button>
    </div>
  </div>


</div>
