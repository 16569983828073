import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TourStatus } from '../services/tour.service';
import { TranslateService } from '@ngx-translate/core';


@Injectable({ providedIn: 'root' })
@Pipe({ name: 'tourStatusPipe' })
export class TourStatusPipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(status: number | string): string {
    switch (status) {
      case TourStatus.UNKNOWN:
        return this.translate.instant('common.unknown');;
      case TourStatus.IN_PROGRESS:
        return this.translate.instant('common.planned');
      case TourStatus.PLANNED:
        return this.translate.instant('common.scheduled');
      case TourStatus.CLOSED:
        return this.translate.instant('common.completed');
      default:
        return '-'
    }
  }
}
