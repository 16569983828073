<div class="container-fluid">

  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h1>Tours archived</h1>
    <div style="display: flex; align-items: center;">
      <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
      <button mat-icon-button color="primary" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </button>

      <a mat-icon-button color="primary" routerLink="/main/carrier/all-tours" *ngIf="!onlyForThisDriver">
        <mat-icon>archive</mat-icon>
      </a>


    </div>
  </div>

  <div class="text-center mt-5" *ngIf="!isLoading && filteredItems.length == 0">
    <div class="alert alert-info">No archived tours</div>
  </div>

  <div *ngIf="!!filteredItems && filteredItems.length > 0" class="container-fluid">
    <div class="scrollable-list">
      <table class="table table-custom" style="width: 100%;">
        <thead>
          <!-- <td *ngIf="!onlyForThisDriver"></td> -->
          <td>{{'common.id' | translate}}</td>
          <td>{{'common.status' | translate}}</td>
          <td>{{'common.date' | translate}}</td>
          <td>{{'common.vehicle' | translate}}</td>
          <td>{{'common.weight' | translate}}</td>
          <td>{{'carrier_dashboard.number_of_slots' | translate}}</td>
          <td>{{'carrier_dashboard.number_of_stops' | translate}}</td>
          <td>{{'carrier_dashboard.travel_time' | translate}}</td>
          <td *ngIf="!onlyForThisDriver">{{'common.revenue' | translate}}</td>
          <td *ngIf="!onlyForThisDriver">{{'common.profit' | translate}}</td>
          <td></td>
        </thead>

        <tbody>
          <tr *ngFor="let tour of filteredItems">
            <!-- <td *ngIf="!onlyForThisDriver">
              <mat-checkbox (change)="onToggleSelection($event, tour)"
                [checked]="isShipmentChecked(tour)"></mat-checkbox>
            </td> -->
            <td [attr.data-label]="'common.id' | translate">
              {{tour.id }}
            </td>
            <td [attr.data-label]="'common.status' | translate">
              {{tour.status | tourStatusPipe }}
            </td>
            <td [attr.data-label]="'common.date' | translate">
              {{tour.date | date: 'dd-MM-yyyy'}}
            </td>
            <td [attr.data-label]="'common.vehicle' | translate">
              {{tour.vehicle_object?.number}}
            </td>
            <td [attr.data-label]="'common.weight' | translate">
              {{tour.weight | number: "1.0-2"}} Kg
            </td>

            <td [attr.data-label]="'carrier_dashboard.number_of_slots' | translate">
              {{tour.ground_spaces | number: "1.2-2"}}
            </td>

            <td [attr.data-label]="'carrier_dashboard.number_of_stops' | translate">
              {{tour.number_of_stops}}
            </td>

            <td [attr.data-label]="'carrier_dashboard.travel_time' | translate">
              {{tour.driving_duration }}
            </td>

            <td [attr.data-label]="'common.revenue' | translate" *ngIf="!onlyForThisDriver">
              {{tour.turnover | number: "1.2-2"}}
            </td>

            <td [attr.data-label]="'common.profit' | translate" *ngIf="!onlyForThisDriver">
              {{tour.profit | number: "1.2-2" }}
            </td>

            <td class="d-flex">
              <a mat-icon-button color="primary" routerLink="/main/driver/tours/{{tour.uuid}}"
                *ngIf="onlyForThisDriver">
                <mat-icon>details</mat-icon>
              </a>

              <a mat-icon-button color="primary" routerLink="/main/carrier/tours/{{tour.uuid}}"
                *ngIf="!onlyForThisDriver">
                <mat-icon>edit</mat-icon>
              </a>

              <button class="delete" mat-icon-button color="primary" (click)="onDelete(tour)" *ngIf="!onlyForThisDriver">
                <mat-icon>delete</mat-icon>
              </button>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
