import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-shipment-packets',
  templateUrl: './shipment-packets.component.html',
  styleUrls: ['./shipment-packets.component.scss']
})
export class ShipmentPacketsComponent implements OnInit {

  @Input() packetForm: any;
  @Input() index: number = 0;
  @Input() number_of_packets: number = 0;

  @Output() deletePacket = new EventEmitter<number>()

  constructor() {
  }

  ngOnInit() {
  }

  remove() {
    this.deletePacket.emit(this.index);
  }
}
