import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { formatRelative, subDays } from 'date-fns'
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { FormGroup, FormControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, lastValueFrom } from 'rxjs';
import { ShipperShipmentsService } from 'projects/webapp/app/services/shipper-shipments.service';
import { TranslateService } from '@ngx-translate/core';

formatRelative(subDays(new Date(), 3), new Date())

@Component({
  selector: 'app-shipper-shipment-search',
  templateUrl: './shipment-search.component.html',
  styleUrls: ['./shipment-search.component.scss']
})
export class ShipperSearchComponent implements OnInit {
  foundShipments: Partial<IShipment>[] = [];
  public isLoading = false;
  public searchForm: FormGroup = new FormGroup({ search: new FormControl('') });

  constructor(
    private title: Title,
    private shipperShipmentsService: ShipperShipmentsService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.search_s').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.searchForm.valueChanges
      .pipe(distinctUntilChanged(), debounceTime(400))
      .subscribe((value) => {
        this.onSearch();
      });
  }
  
  async onSearch() {
    this.isLoading = true;
    this.foundShipments = await lastValueFrom(this.shipperShipmentsService.searchShipments({ search: this.searchForm.value.search }))
    this.isLoading = false;
  }

}
