<div class="page-container pb-4 mb-5">

    <div class="container mb-5">

        <mat-card class="mt-4 mb-4">
            <mat-card-content>

                <div style="display: flex; flex-direction: row; justify-content: space-between;">
                    <h1>{{'carrier_dashboard.user-editor-hub.main_title' | translate}}</h1>

                    <div class="d-flex">
                        <button class="d-flex" mat-icon-button color="primary" (click)="onAdd()">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button class="d-flex" mat-icon-button color="primary" (click)="refresh()">
                            <mat-icon>refresh</mat-icon>
                        </button>
                    </div>
                </div>

                <table class="table table-custom" style="width: 100%" *ngIf="!!hubs && hubs.length > 0">
                    <thead>
                        <td class="ps-2" scope="col">{{'common.id' | translate}}</td>
                        <td scope="col">{{'carrier_dashboard.user-editor-hub.hub_name' | translate}}</td>
                        <td scope="col">{{'common.address' | translate}}</td>
                        <td scope="col">{{'common.location' | translate}}</td>
                        <td></td>
                    </thead>

                    <tbody>
                        <tr *ngFor="let hub of hubs">
                            <td [attr.data-label]="'common.id' | translate">{{ hub.id }}</td>
                            <td [attr.data-label]="'carrier_dashboard.user-editor-hub.hub_name' | translate">{{ hub.name
                                }}</td>
                            <td [attr.data-label]="'common.address' | translate">{{ hub.address}}</td>
                            <td [attr.data-label]="'common.location' | translate">{{ hub.location }}</td>

                            <td class="d-flex justify-content-around justify-content-md-end">
                                <button mat-icon-button (click)="onEdit(hub)">
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button mat-icon-button (click)="onDelete(hub)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="alert alert-info bg-green-light my-2" role="alert" style="width: 100%; border: none;"
                    *ngIf="!hubs || hubs.length == 0">
                    {{'carrier_dashboard.user-editor-hub.no_hub' | translate}}
                </div>
            </mat-card-content>
        </mat-card>

    </div>

</div>