import { IClient } from "../services/clients.service";

export enum InvoiceStatus {
    DRAFT,
    SENT,
    PAID,
    CANCELLED
}

export interface IInvoice {
    shipments: {
        number: string,
        uuid: string
    }[];
    id?: number;
    uuid?: string;

    created_at?: string;
    updated_at?: string;

    number: string,

    client: number;
    client_object?: IClient,

    invoice_type: "collective_invoice",
    language: "de",
    invoice_date: string,
    pay_until_date: string;

    status: InvoiceStatus;

    total_price_without_vat: string,
    total_vat_price: string,
    total_price_with_vat: string,
}


export interface IManualInvoice {
    "items": {
      "title": string;
      "description": string;
      "price": number;
    }[],
    debtor_name: string;
    debtor_address: string;
    debtor_zip: number,
    debtor_city: string;
    debtor_country: 'CH',
    debtor_country_code: 'CH',

    creditor_name: string;
    creditor_address: string;
    creditor_zip: string;
    creditor_city: string;
    creditor_country: "CH";
    creditor_iban_number: string;
    creditor_vat_number: string;
}

