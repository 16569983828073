<mat-form-field appearance="outline" style="width: 100%" *ngIf="!!possibleHubs && possibleHubs.length > 0">
    <mat-label translate>common.hub</mat-label>
    <input type="text" matInput [(ngModel)]="selectedHub" [matAutocomplete]="auto" [formControl]="userFormControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
        (optionSelected)='userSelected($event.option.value)'>
        <mat-option *ngFor="let option of filteredHubs" [value]="option">
            {{getHubName(option)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<!-- <div class="alert alert-info" role="alert" style="width: 100%" *ngIf="!possibleHubs || possibleHubs.length == 0">
    Noch keine Kunden. Sie können neue Kunden in der <a routerLink="/main/carrier/clients">Kundenverwaltung</a> {{'common.create' | translate}}
</div> -->