import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AdvertisementsService, IAdvertisement } from 'projects/webapp/app/services/advertisement.service';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-advertisement-card',
  templateUrl: './advertisement-card.component.html',
  styleUrls: ['./advertisement-card.component.scss']
})
export class AdvertisementCardComponent implements OnInit {
  @Input() advertisement: IAdvertisement | null = null;
  @Input() allowEdit: boolean = true;

  @Output() onOpenDetails: EventEmitter<IAdvertisement> = new EventEmitter()
  @Output() onOpenEdit: EventEmitter<IAdvertisement> = new EventEmitter()

  images: any[] = []

  constructor(
    public authService: AuthService,
    private advertisementsService: AdvertisementsService,
  ) { }

  async ngOnInit() {
    this.refreshImages();
  }

  async refreshImages() {
    if (this.advertisement?.uuid) {
      this.images = await lastValueFrom(this.advertisementsService.getImages(this.advertisement.uuid))
    }
  }

  openDetails() {
    if (this.advertisement) {
      this.onOpenDetails.emit(this.advertisement);
    }
  }

  openEdit() {
    if (this.advertisement) {
      this.onOpenEdit.emit(this.advertisement);
    }
  }
}
