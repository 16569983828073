import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { AdvertisementAddImageModalComponent, IAdvertisementAddImageData } from '../advertisement-add-image-modal/advertisement-add-image-modal.component';
import { AdvertisementsService, IAdvertisement } from '../../services/advertisement.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-advertisement-editor',
  templateUrl: './advertisement-editor.component.html',
  styleUrls: ['./advertisement-editor.component.scss']
})
export class AdvertisementEditorComponent implements OnInit {

  form: FormGroup = new FormGroup({
    title: new FormControl('', [Validators.required, Validators.minLength(2)]),
    description: new FormControl('', [Validators.required, Validators.minLength(2)]),
    email: new FormControl(''),
    location: new FormControl(''),
    phone: new FormControl('', Validators.required),
    is_active: new FormControl(true, Validators.required),
  });

  images: any[] = []

  constructor(
    public dialogRef: MatDialogRef<AdvertisementEditorComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    private advertisementsService: AdvertisementsService,
    @Inject(MAT_DIALOG_DATA) public data: IAdvertisement,
  ) { }

  async ngOnInit() {
    this.form.patchValue(this.data)
    this.refreshImages();
  }

  addImage() {
    this.dialog.open(AdvertisementAddImageModalComponent, { data: { advertisementUUID: this.data.uuid } }).afterClosed().subscribe(async val => {
      if (!!val) {
        this.refreshImages();
      }
    });
  }

  async refreshImages() {
    if (this.data.uuid) {
      this.images = await lastValueFrom(this.advertisementsService.getImages(this.data.uuid))
    }
  }

  async deleteImage(uuid: string) {
    if (this.data.uuid) {
      this.images = await lastValueFrom(this.advertisementsService.deleteImage(this.data.uuid, uuid))
      this.refreshImages()
    }
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.dialogRef.close(this.form.value)
  }

}
