<div class="container-fluid" *ngIf="user">

  <div class="d-flex justify-content-between">
    <h1>User: {{user.first_name}} {{user.last_name}}</h1>

    <div>
      <a mat-icon-button [routerLink]="[]" (click)="refresh()">
        <mat-icon>refresh</mat-icon>
      </a>
      <a mat-icon-button href="{{environment.apiDomain}}/admin/accounts/loginuser/{{user.id}}/change/"
        target="_blank">
        <mat-icon>edit</mat-icon>
      </a>
    </div>
  </div>

  <hr>

  <div>
    <h2>{{'common.details' | translate}}</h2>
    <ul class="co-simple-list">
      <!-- <li>company_name: {{user.company_name}}</li> -->
      <li>first_name: {{user.first_name}}</li>
      <li>last_name: {{user.last_name}}</li>

      <li>username:{{user.username}}</li>
      <li>email: {{user.email}}</li>

      <li>phone: {{user.phone}}</li>
      <li>{{'common.organization' | translate}}: <a
          routerLink="/main/admin/organizations/{{user.organization_object.uuid}}">{{user.organization_object.name}}</a>
      </li>
      <!-- <li>location: {{user.zip}} {{user.location}}</li> -->

      <li>last_login: {{user.last_login | humanDatePipe}}</li>

      <li>created_at: {{user.created_at | humanDatePipe}}</li>
      <li>updated_at: {{user.updated_at | humanDatePipe}}</li>

      <li>uuid: {{user.uuid}}</li>

    </ul>

    <!-- <pre>{{user | json}}</pre> -->

  </div>

  <hr>

  <div style="display: flex; flex-direction: column;">

    <mat-checkbox [(ngModel)]="user.is_active"
      (change)="onChangeOrgProperty('is_active', $event)">is_active</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_pipeline"
      (change)="onChangeOrgProperty('permission_pipeline', $event)">permission_pipeline</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_dispatch"
      (change)="onChangeOrgProperty('permission_dispatch', $event)">permission_dispatch</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_invoicing"
      (change)="onChangeOrgProperty('permission_invoicing', $event)">permission_invoicing</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_client_management"
      (change)="onChangeOrgProperty('permission_client_management', $event)">permission_client_management</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_empties_management"
      (change)="onChangeOrgProperty('permission_empties_management', $event)">permission_empties_management</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_warehouse_management"
      (change)="onChangeOrgProperty('permission_warehouse_management', $event)">permission_warehouse_management</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_activity"
      (change)="onChangeOrgProperty('permission_activity', $event)">permission_activity</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_export"
      (change)="onChangeOrgProperty('permission_export', $event)">permission_export</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_user_management"
      (change)="onChangeOrgProperty('permission_user_management', $event)">permission_user_management</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_settings"
      (change)="onChangeOrgProperty('permission_settings', $event)">permission_settings</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_org_management"
      (change)="onChangeOrgProperty('permission_org_management', $event)">permission_org_management</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_see_pricing"
      (change)="onChangeOrgProperty('permission_see_pricing', $event)">permission_see_pricing</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_market_place"
      (change)="onChangeOrgProperty('permission_market_place', $event)">permission_market_place</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_alerts_place"
      (change)="onChangeOrgProperty('permission_alerts_place', $event)">permission_alerts_place</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_new_shipments"
      (change)="onChangeOrgProperty('permission_new_shipments', $event)">permission_new_shipments</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_shipper_panel"
      (change)="onChangeOrgProperty('permission_shipper_panel', $event)">permission_shipper_panel</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_carrier_panel"
      (change)="onChangeOrgProperty('permission_carrier_panel', $event)">permission_carrier_panel</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.permission_driver_panel"
      (change)="onChangeOrgProperty('permission_driver_panel', $event)">permission_driver_panel</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.preference_show_shipments_to_pickup"
      (change)="onChangeOrgProperty('preference_show_shipments_to_pickup', $event)">preference_show_shipments_to_pickup</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.preference_show_shipments_in_vehicle"
      (change)="onChangeOrgProperty('preference_show_shipments_in_vehicle', $event)">preference_show_shipments_in_vehicle</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.preference_show_shipments_to_deliver"
      (change)="onChangeOrgProperty('preference_show_shipments_to_deliver', $event)">preference_show_shipments_to_deliver</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.preference_show_market_place"
      (change)="onChangeOrgProperty('preference_show_market_place', $event)">preference_show_market_place</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.preference_show_facturation"
      (change)="onChangeOrgProperty('preference_show_facturation', $event)">preference_show_facturation</mat-checkbox>
    <mat-checkbox [(ngModel)]="user.preference_show_activity"
      (change)="onChangeOrgProperty('preference_show_activity', $event)">preference_show_activity</mat-checkbox>
  </div>


</div>
