import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { versions } from 'projects/versions';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-admin-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})
export class AdminShipmentsComponent implements OnInit {
  isLoading = true;
  versions = versions;
  items: any[] = []

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService
  ) { }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.items = await lastValueFrom(this.adminService.getShipments())
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }
}
