import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

export const generatePalletPriceGroup = () => {
  return new FormGroup({
    price: new FormControl(60),
  });
}

@Component({
  selector: 'app-pricing-form',
  templateUrl: './pricing-form.component.html',
  styleUrls: ['./pricing-form.component.scss']
})
export class PricingFormComponent implements OnInit {

  pricingForm: FormGroup = new FormGroup({
    platform_gu_discount_percent: new FormControl(1, []),
    platform_calculator: new FormControl(0, []),
    platform_pallet_price_list: new FormArray([
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup(),
      generatePalletPriceGroup()
    ]),
  });

  public palletPriceForms: FormArray = this.pricingForm.get('platform_pallet_price_list') as FormArray;

  constructor(
    public organizationService: OrganizationsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public toastrService: ToastrService,
    private translateService: TranslateService,
  ) {}

  async ngOnInit() {
    const response = await lastValueFrom(this.organizationService.getPricing())
    this.pricingForm.patchValue(response)
  }

  getPalletPriceForm(){
    return this.palletPriceForms.controls as any
  }

  async updateSurcharges() {
    const successMessage = await lastValueFrom(this.translateService.get('toastr.saved'));
    
    this.pricingForm.markAllAsTouched()
    if (this.pricingForm.valid) {
      const response = await lastValueFrom(this.organizationService.updatePricing(this.pricingForm.value));
      this.toastrService.success(successMessage);
    }
  }
}
