import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';

export interface IClient {
  id?: number;
  uuid?: string;

  name?: string;
  address: string;
  zip_code: string;
  location: string;
  description: string;

  phone: string;
  email: string;

  created_at?: string;
  updated_at?: string;

  first_name: string;
  last_name: string;
  company: string;

  client?: string;
  client_object?: {
    company_name: string;
    first_name: string;
    last_name: string;
    created_at: string;
  } | null;
}


@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getClients(): Observable<IClient[]> {
    return this.http.get<IClient[]>(this._urls.CLIENTS)
  }

  public getClient(clientUUID: string): Observable<any> {
    return this.http.get<any>(this._urls.CLIENTS_BY_UUID(clientUUID))
  }

  public updateClient(clientUUID: string, client: IClient): Observable<any> {
    return this.http.patch<any>(this._urls.CLIENTS_BY_UUID(clientUUID), client)
  }

  public createClient(client: IClient): Observable<any> {
    return this.http.post<any>(this._urls.CLIENTS, client)
  }

  public deleteClient(clientUUID: string): Observable<any> {
    return this.http.delete<any>(this._urls.CLIENTS_BY_UUID(clientUUID))
  }

}
