import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { versions } from 'projects/versions';
import { debounceTime, tap } from 'rxjs/operators';
import { IPrice } from 'projects/shared/price.interface';
import { EmbedWizardService } from 'projects/webapp/app/services/embed-wizard.service';



@Component({
  selector: 'app-embed-small-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class EmbedSmallWizardComponent implements OnInit {

  price: IPrice | null = null;;

  public draftShipmentForm = new FormGroup({

    pickup_zip: new FormControl('', Validators.required),
    pickup_location: new FormControl('', Validators.required),

    delivery_zip: new FormControl('', Validators.required),
    delivery_location: new FormControl('', Validators.required),
    delivery_date: new FormControl('', Validators.required),
  });

  siteKey = '';
  tenantName = '';
  isLoading = true;
  isLoadingPricing = false;
  isLoadingProcessing = false;
  versions = versions;
  initialValue = {}

  currentShipmentKey = 'currentShipment'

  constructor(
    private route: ActivatedRoute,
    private embedWizardService: EmbedWizardService,
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    this.route.queryParams
      .subscribe(async (params) => {
        console.log(params);
        this.siteKey = params['siteKey'];
        console.log(this.siteKey);
        this.isLoading = false;
        if (!!this.siteKey) {
          this.embedWizardService.siteKey = this.siteKey
          try {
            const response = await lastValueFrom(this.embedWizardService.verify_sitekey(this.siteKey));
            console.log(response)
            this.tenantName = response.tenant_name
          } catch (error) {
            console.error(error);
          }
        }
      });

    const val = localStorage.getItem(this.currentShipmentKey);
    if (!!val) {
      console.log('had a current shipment in the session')
      this.draftShipmentForm.patchValue(JSON.parse(val))
      this.initialValue = this.draftShipmentForm.value
      this.fetchPricing()
    }

    this.draftShipmentForm.valueChanges.pipe(
      tap(() => (this.isLoadingPricing = true)),
      debounceTime(300)
    ).subscribe(val => {

      console.log(val)
      localStorage.setItem(this.currentShipmentKey, JSON.stringify(val));
      this.fetchPricing();
      this.initialValue = this.draftShipmentForm.value
    });
  }

  async fetchPricing() {
    try {
      const response = await lastValueFrom(this.embedWizardService.calculate_price(this.draftShipmentForm.value));
      this.price = response;
      this.isLoadingPricing = false;
    } catch (error) {
      console.error(error)
    }
  }

  resetForm() {
    this.draftShipmentForm.reset();
  }

  async sendRequest() {
    console.log('sendRequest')
    this.isLoadingProcessing = true;

    try {
      await lastValueFrom(this.embedWizardService.request_shipment(this.draftShipmentForm.value))
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingProcessing = false;
    }
  }
}
