<div class="container">
  <mat-card class="mt-3">
    <mat-card-content>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h2>{{'common.edit_cargo_pieces' | translate}}</h2>
      </div>

      <div class="row">
        <div class="col-12">
          <ng-container *ngFor="let packetForm of packetItemForms.controls; index as index">
            <div class="mb-5">
              <app-shipment-packets [index]="index" [packetForm]="packetForm"
                [number_of_packets]="packetItemForms.controls.length"
                (deletePacket)="removePacketItem($event)"></app-shipment-packets>
            </div>
          </ng-container>
        </div>
        <div class="text-center mb-2 d-flex justify-content-center">
          <button class="btn-primary dark primary-hover-animation" (click)="addPacketItem()">
            {{'carrier_dashboard.add_another_cargo' | translate}}
            <mat-icon>add_to_photos</mat-icon>
          </button>
        </div>
      </div>

      <!-- <pre>shipmentForm: {{shipmentForm.value | json}}</pre> -->

      <div class="mt-3 d-flex">
        <button class="btn-primary green primary-hover-animation ms-auto" (click)="save()">{{'common.save_and_back' | translate}}</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
