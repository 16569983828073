import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ClientSelectorModalComponent } from 'projects/webapp/app/components/client-selector-modal/client-selector.component';
import { ShipmentCommentModal } from 'projects/webapp/app/components/shipment-comment-modal.component';
import { environment } from 'projects/webapp/app/environments/environment';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { IShipmentComment } from 'projects/webapp/app/interfaces/shipment-comment.interface';
import { ICarrierShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { IClient } from 'projects/webapp/app/services/clients.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { ShipmentStatus, ShipmentsService } from 'projects/webapp/app/services/shipments.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { downloadFile } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class DriverShipmentDetailsComponent implements OnInit {


  isLoading = false;
  shipmentUUID: string | null = null;
  shipment: ICarrierShipment | null = null;

  hasShare = false;
  environment = environment;
  privateShipmentComments: IShipmentComment[] = []
  publicShipmentComments: IShipmentComment[] = []
  shipmentStatus = ShipmentStatus

  ownOrganization: IOrganization | null = null;

  constructor(
    private title: Title,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private carrierShipmentsService: CarrierShipmentsService,
    public usersService: UsersService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.translateService.get('common.dispach_details').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  ngOnInit() {
    this.fetchOrganization();
    
    if (navigator.canShare as any) {
      this.hasShare = true;
    }

    console.log('DashboardComponent')
    this.route.params.subscribe(params => {
      if (!!params && params['shipmentUUID']) {
        this.shipmentUUID = params['shipmentUUID'];
        this.refreshShipment();
      }
    })
  }

  async fetchOrganization() {
    try {
      const organization = await this.organizationService.getOwnOrganization().toPromise();
      this.ownOrganization = organization || null; // Handle undefined case
    } catch (error) {
      console.error('Error fetching own organization', error);
      this.ownOrganization = null; // Ensure ownOrganization is set to null on error
    }
  }

  async refreshShipment() {
    if (this.shipmentUUID) {
      this.refreshShipmentCore()
      this.refreshPrivateComments()
      this.refreshPublicComments()
    }
  }

  async refreshShipmentCore() {
    this.shipment = await lastValueFrom(this.carrierShipmentsService.getShipment(this.shipmentUUID as any));
  }


  async refreshPrivateComments() {
    try {
      this.spinnerService.show('privateShipmentComments')
      this.privateShipmentComments = await lastValueFrom(this.carrierShipmentsService.getPrivateCommentsShipment(this.shipmentUUID as any))
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('privateShipmentComments')
    }
  }

  async refreshPublicComments() {
    try {
      this.spinnerService.show('publicShipmentComments')
      this.publicShipmentComments = await lastValueFrom(this.carrierShipmentsService.getPublicCommentsShipment(this.shipmentUUID as any))
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('publicShipmentComments')
    }
  }

  changeClient() {
    this.dialog.open(ClientSelectorModalComponent).afterClosed().subscribe(async (val: IClient | null) => {
      if (!!val && val.id && this.shipment?.uuid) {
        console.log(val);
        this.shipment.client = val.id;
        await lastValueFrom(this.carrierShipmentsService.updateShipment(this.shipment.uuid, this.shipment));
        this.refreshShipmentCore()
      }
    })
  }


  toBeImplemented() {
    alert('coming soon...')
  }

  onAddPrivateComment() {
    this.dialog.open(ShipmentCommentModal).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        await lastValueFrom(this.carrierShipmentsService.addPrivateCommentShipment(this.shipment?.uuid, val));
        this.refreshPrivateComments()
      }
    })
  }

  onAddPublicComment() {
    this.dialog.open(ShipmentCommentModal).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        await lastValueFrom(this.carrierShipmentsService.addPublicCommentShipment(this.shipment?.uuid, val));
        this.refreshPublicComments()
      }
    })
  }

  onUpdateComment(comment: IShipmentComment) {
    const data = comment.content;
    this.dialog.open(ShipmentCommentModal, { data }).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        comment.content = val;
        await lastValueFrom(this.carrierShipmentsService.updateCommentsShipment(this.shipment?.uuid, comment.uuid, comment))
        this.refreshPrivateComments();
        this.refreshPublicComments();
      }
    })
  }

  async onDeleteComment(comment: IShipmentComment) {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_commentary_cancelation'));

    if (confirm(confirmMessage) && this.shipment?.uuid) {
      await lastValueFrom(this.carrierShipmentsService.deleteCommentsShipment(this.shipment?.uuid, comment.uuid));
      this.refreshPrivateComments();
      this.refreshPublicComments();
    }
  }

  share() {
    if (navigator.share) {
      navigator
        .share({
          title: `Sendung ${this.shipment?.number}`,
          url: `${environment.uiDomain}/public/shipment-tracking/${this.shipment?.tracking_uuid}`,
        })
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
    }
  }

async cancelShipment() {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_shipment_cancelation'));
    const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_cancelled'));

    if (confirm(confirmMessage) && this.shipmentUUID) {
      await lastValueFrom(this.carrierShipmentsService.cancelShipment(this.shipmentUUID));
      this.toastrService.info(successMessage)
      this.refreshShipment();
    }
  }

  async downloadDeliverySlip() {
    if (this.shipmentUUID && this.shipment) {
      try {
        this.spinnerService.show()
        const response = await lastValueFrom(this.carrierShipmentsService.getDeliverySlip(this.shipmentUUID));
        const blob = new Blob([response], { 'type': 'application/pdf' });
        downloadFile(blob, `Lieferschein-${this.shipment.number}.pdf`);
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide()
      }
    }
  }

  async downloadLabel() {
    if (this.shipmentUUID && this.shipment) {
      try {
        this.spinnerService.show()
        const response = await lastValueFrom(this.carrierShipmentsService.getLabel(this.shipmentUUID));
        const blob = new Blob([response], { 'type': 'application/pdf' });
        downloadFile(blob, `Label-${this.shipment.number}.pdf`);
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide()
      }
    }
  }

}
