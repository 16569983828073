<h1 mat-dialog-title>
  <h3>{{data.title}}</h3>
</h1>
<div mat-dialog-content>
  <div class="mt-2">

    <hr>
    <p>{{data.description}}</p>
    <hr>

    <br>
    <br>
    <small style="color: grey;">{{'common.listed_by' | translate}} {{data.organization_name}} {{data.updated_at | humanDatePipe}}</small>
    <br>
    <br>

    <a href="mailto:{{data.email}}" class="btn-primary dark">{{'common.mail' | translate}} {{'common.to_alt' | translate}} {{data.email}} <mat-icon>email</mat-icon></a>
    <br><br>
    <a href="tel:{{data.phone}}" class="btn-primary dark">{{'common.phone' | translate}} {{'common.to_alt' | translate}} {{data.phone}} <mat-icon>phone</mat-icon></a>

    <!-- <pre>{{data | json}}</pre> -->

  </div>

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button class="btn-primary light" mat-dialog-close="">{{'common.close' | translate}}</button>
  <!-- <button mat-button color="primary" (click)="save()" cdkFocusInitial>{{'common.save' | translate}}</button> -->
</div>
