<div *ngIf="tour && tour?.vehicle_object">
  <ul class="w-100" style="color: black;">
    <!-- <li>{{'common.id' | translate}} {{tour.id}}</li> -->
    <!-- TODO add different curencies -->
    <li class="rounded-cont w-50 text-center mb-2 px-2">{{tour.turnover | pricePipe}} {{ownOrganization?.default_currency}}</li>
    <li class="mb-2 d-flex justify-content-between">
      <!-- {{'common.vehicle' | translate}}:  -->
      <span class="fw-bold">{{tour.vehicle_object?.model}}</span>
      <span style="font-size: 14px;">{{tour.vehicle_object?.number}}</span>
    </li>

    <li class="d-flex my-2 justify-content-between" *ngIf="tour.driver_object">
      <div class="d-flex">
        <mat-icon class="">account_box</mat-icon>
        <span class="ms-2">{{tour.driver_object.first_name}} {{tour.driver_object.last_name}}</span>
      </div>
      <a class="d-flex align-items-center" [routerLink]="[]" (click)="assignDriver(tour)"><mat-icon>edit</mat-icon></a>
    </li>

    <!-- TODO link to add driver to this vehicle -->
    <li class="btn-primary yellow my-2 text-center fw-bold" *ngIf="!tour.driver_object">
      <a class="d-flex align-items-center" [routerLink]="[]" (click)="assignDriver(tour)">{{'common.assign_driver' | translate}} <mat-icon>perm_identity</mat-icon></a>
    </li>

    <!-- <li>{{'common.model' | translate}}: {{tour.vehicle_object?.model}}</li> -->
    <li class="d-flex flex-column mb-2">
      <span class="title">{{'common.ground_spaces' | translate}}</span>
      <span style="background-color:#FFF4E5;" class="rounded-cont">{{tour.ground_spaces | number : '1.2-2'}}
        {{'common.from_min' | translate}}
        {{tour.vehicle_object?.max_ground_spaces | number : '1.2-2'}}</span>
    </li>
    <li class="d-flex flex-column mb-2">
      <span class="title">{{'common.weight' | translate}} (kg)</span>
      <span style="background-color:#59e4a83e;" class="rounded-cont">{{tour.weight | number : '1.0-0'}}
        {{'common.from_min' | translate}} {{tour.vehicle_object?.max_weight}}</span>
    </li>
    <li class="d-flex flex-row justify-content-between">
      <div class="d-flex flex-column">
        <span class="title">{{'common.travel_time' | translate}}</span>
        <span>{{tour.driving_duration | timeFormat}}</span>
        <span *ngIf="!tour.driving_duration">0</span>
      </div>
      <div class="d-flex flex-column">
        <span class="title">{{'common.stop' | translate}}</span>
        <span>{{tour.number_of_stops}}</span>
      </div>
      <div class="d-flex flex-column">
        <span class="title">{{'common.travel_distance' | translate}}</span>
        <span>{{tour.driving_distance}} km</span>
      </div>
    </li>
  </ul>

  <div class="my-3" *ngIf="tour.status == tourStatus.IN_PROGRESS || tour.status == tourStatus.UNKNOWN">
    <div class="d-flex align-items-center flex-column">
      <button class="btn-primary green w-100 primary-hover-animation" (click)="onCloseTour()"
        [disabled]="!tour.driver_object">{{'common.complete_plan' | translate}} <mat-icon>thumb_up_alt</mat-icon></button>
    </div>
  </div>

</div>
