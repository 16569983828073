import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BehaviorSubject, Observable, map, tap } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { IApiToken, TokenType } from '../interfaces/token.interface';
import { IOrganization } from '../interfaces/organization.interface';


export interface ISiteKey {
  id?: number;
  name: string;
  token?: string;
  created_at: string;
  updated_at: string;
  active: boolean;
}

export interface IHub {
  id?: number;
  uuid?: string;

  created_at?: string;
  updated_at?: string;

  name: string;
  address: string;
  location: string;

  is_deleted?: boolean;
}


const CARGOFLOW_ORG_PREFERENCES = 'CARGOFLOW_ORG_PREFERENCES';


@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  ownOrg$ = new BehaviorSubject<IOrganization | null>(null);

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  initService() {
    this.loadCachedPreferences();
    this.getOwnOrganization().subscribe()
  }

  loadCachedPreferences(): void {
    const cache = localStorage.getItem(CARGOFLOW_ORG_PREFERENCES);
    if (!!cache) {
      this.ownOrg$.next(JSON.parse(cache));
    }
  }

  cacheOrgPreferences(user: IOrganization): void {
    localStorage.setItem(CARGOFLOW_ORG_PREFERENCES, JSON.stringify(user));
  }

  getPricing() {
    return this.http.get<any>(this._urls.ORGANIZATION_PRICING)
  }

  updatePricing(data: any) {
    return this.http.patch<any>(this._urls.ORGANIZATION_PRICING, data)
  }

  getHubs() {
    return this.http.get<IHub[]>(this._urls.ORGANIZATION_HUBS)
  }

  createHub(data: IHub) {
    return this.http.post<IHub>(this._urls.ORGANIZATION_HUBS, data)
  }

  updateHub(hubUUID: string, data: IHub) {
    return this.http.patch<IHub>(this._urls.ORGANIZATION_HUBS_BY_UUID(hubUUID), data)
  }

  public getOrganizations(params: {} = {}): Observable<IOrganization[]> {
    return this.http.get<IOrganization[]>(this._urls.ORGANIZATIONS, { params })
  }

  public getOwnOrganization(): Observable<IOrganization> {
    return this.http
      .get<IOrganization>(this._urls.OWN_ORGANIZATION)
      .pipe(
        tap(
          (response) => {
            this.ownOrg$.next(response);
            this.cacheOrgPreferences(response);
          },
          (error) => {
            console.error(error);
          }
        ),
        map((response) => response),
      );
  }

  public updateOwnOrganization(organization: IOrganization): Observable<IOrganization> {
    return this.http.patch<IOrganization>(this._urls.OWN_ORGANIZATION, organization)
  }

  getClientKeys(uuid: string, params: { type?: TokenType } = {}): Observable<IApiToken[]> {
    return this.http.get<any>(this._urls.OWN_KEYS, { params: { clientUUID: uuid, ...params } })
  }

  public getLogo(): Observable<any> {
    return this.http.get<any>(this._urls.OWN_ORGANIZATION_LOGO)
  }

  public updateLogo(formData: any): Observable<any> {
    return this.http.post<any>(this._urls.OWN_ORGANIZATION_LOGO, formData)
  }

  getSiteKeys(): Observable<ISiteKey[]> {
    return this.http.get<ISiteKey[]>(this._urls.SITE_KEYS)
  }

  createSiteKey(siteKey: any): Observable<ISiteKey> {
    return this.http.post<ISiteKey>(this._urls.SITE_KEYS, siteKey)
  }

  updateSiteKeys(siteKeyId: number, siteKey: ISiteKey): Observable<ISiteKey> {
    return this.http.patch<ISiteKey>(this._urls.SITE_KEYS_BY_ID(siteKeyId), siteKey)
  }

  deleteSiteKey(siteKeyId: number): Observable<unknown> {
    return this.http.delete<any>(this._urls.SITE_KEYS_BY_ID(siteKeyId))
  }

}
