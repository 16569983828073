import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShipmentPriceMode } from '../../services/shipments.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ShipperShipmentsService } from '../../services/shipper-shipments.service';
import { IOrganization } from '../../interfaces/organization.interface';
import { OrganizationsService } from '../../services/organizations.service';


export interface IShipperPrice {
  price_mode: ShipmentPriceMode | string,
  price: number,
  price_step: number,
  price_max: number,
  end_date: Date,
  end_time: string
}

@Component({
  selector: 'app-tender-pricing-modal',
  templateUrl: './tender-pricing-modal.component.html',
  styleUrls: ['./tender-pricing-modal.component.scss']
})
export class TenderPricingModalComponent implements OnInit {

  tomorrow = new Date()
  isLoadingPricing = false;
  carrier_price = 0
  carrier_price_with_vat = 0

  shipmentPriceMode = ShipmentPriceMode

  form = new FormGroup({
    price: new FormControl(0, Validators.compose([Validators.required, Validators.min(1)])),
    price_step: new FormControl(0, Validators.compose([Validators.required, Validators.min(1)])),
    price_max: new FormControl(0, Validators.compose([Validators.required, Validators.min(1)])),

    price_mode: new FormControl(this.shipmentPriceMode.SELLER_SET, Validators.compose([Validators.required, Validators.min(1)])),

    end_date: new FormControl(this.tomorrow, Validators.compose([Validators.required, Validators.min(1)])),
    carrier_organization: new FormControl(0),
  })

  constructor(
    public dialogRef: MatDialogRef<TenderPricingModalComponent>,
    public shipperShipmentsService: ShipperShipmentsService,
    public organizationsService: OrganizationsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.tomorrow.setDate(new Date().getDate() + 1);

    this.form.patchValue({
      price_mode: this.shipmentPriceMode.SELLER_SET,

      price: 80,
      price_step: 5,
      price_max: 120,

      end_date: this.tomorrow,

      carrier_organization: null
    })
  }

  async ngOnInit() { }

  onSelectedOrganization(event: IOrganization) {
    console.log(event);
    this.form.patchValue({ carrier_organization: event.id })
    this.updateCarrierPricing();
  }

  async updateCarrierPricing() {
    this.isLoadingPricing = true;
    // this.carrier_price = 40;
    const data = {
      ...this.data,
      ...this.form.value
    }
    const response = await lastValueFrom(this.shipperShipmentsService.getPricingForShipmentByOrg(data))
    console.log(response)
    this.carrier_price = response.total_price
    this.carrier_price_with_vat = response.total_price_with_vat
    this.isLoadingPricing = false;
  }

  cancel() {
    this.dialogRef.close()
  }

  save() {
    this.form.markAllAsTouched();
    if (!this.form.valid) {
      return;
    }
    this.dialogRef.close(this.form.value)
  }

}
