        
<mat-card *ngIf="!showSuccessMessage">
    <mat-card-title>{{'login.forgot_password' | translate}}</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field style="width:100%">
                    <input type="text" matInput placeholder="E-Mail" formControlName="username">
                </mat-form-field>
            </p>

            <!-- TODO: add recaptcha -->

            <div class="button">
                <button type="submit" class="btn-primary green w-100">{{'common.request_new_password' | translate}}</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>


<mat-card *ngIf="showSuccessMessage">
    <mat-card-title>{{'common.new_password_requested' | translate}}</mat-card-title>
    <mat-card-content>
        <p>{{'common.check_your_email_for_further_steps' | translate}}</p>
    </mat-card-content>
</mat-card>

