import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShipmentPriceMode } from '../../services/shipments.service';
import { UsersService } from '../../services/users.service';
import { lastValueFrom } from 'rxjs';
import { PublicService } from '../../services/public.service';
import { AuthService } from '../../services/auth.service';
import { OrganizationsService } from '../../services/organizations.service';


export interface IAuctionPrice {
  price_mode: ShipmentPriceMode | string,
  price: number,
  price_step: number,
  price_max: number,
  end_date: Date,
  end_time: string
}


@Component({
  selector: 'app-license-modal',
  templateUrl: './license-modal.component.html',
  styleUrls: ['./license-modal.component.scss']
})
export class LicenseModalComponent implements OnInit {

  phoneNumber = ''
  isLoading = false;

  requestDone = false;

  constructor(
    public dialogRef: MatDialogRef<LicenseModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public publicService: PublicService,
    public authService: AuthService,
    public usersService: UsersService,
    public organizationsService: OrganizationsService,
  ) {
  }

  async ngOnInit() {
  }

  cancel() {
    this.dialogRef.close()
  }

  async save() {
    this.isLoading = true
    await lastValueFrom(this.publicService.contactRequest({
      type: 'license request',
      org: this.organizationsService.ownOrg$.value?.company_name,
      user: this.usersService.ownUser$.value?.username,
      phone: this.phoneNumber
    }))
    this.isLoading = false
    this.requestDone = true;
    // this.dialogRef.close(true);
  }

}
