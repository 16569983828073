<div class="container">
    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>Scanner</h1>
            </div>

            <div class="alert alert-info">coming soon...</div>
        </mat-card-content>
    </mat-card>
</div>