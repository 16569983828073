import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { UsersService } from 'projects/webapp/app/services/users.service';

@Component({
  selector: 'app-shipment-scanner',
  templateUrl: './shipment-scanner.component.html',
  styleUrls: ['./shipment-scanner.component.scss']
})
export class ShipmentScannerComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public carrierShipmentsService: CarrierShipmentsService,
    public toastrService: ToastrService,
    public router: Router
  ) {
  }

  async ngOnInit() {
  }
}
