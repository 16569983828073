import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-extras-form',
  templateUrl: './extras-form.component.html',
  styleUrls: ['./extras-form.component.scss']
})
export class ExtrasFormComponent implements OnInit {
  @Input() shipmentForm : any;

  constructor(
  ) {  }

  ngOnInit() { }

}
