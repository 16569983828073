<div class="container-fluid">

  <div class="d-flex justify-content-between">
    <h1>Users</h1>
    <a mat-icon-button [routerLink]="[]" (click)="refresh()">
      <mat-icon>refresh</mat-icon>
    </a>
  </div>

  <div>
    <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
      <thead>
        <td>{{'common.name' | translate}}</td>
        <td>{{'common.build' | translate}}{{'common.creation_date' | translate}}</td>
        <td>{{'common.insured' | translate}}</td>
        <td>Username</td>
        <td>Phone</td>
        <td>{{'common.organization' | translate}}</td>
      </thead>

      <tbody>

        <tr *ngFor="let item of items">
          <td>
            <a routerLink="/main/admin/users/{{item.uuid}}">
              {{item.first_name }} {{item.last_name}}
            </a>
          </td>
          <td>{{item.created_at | humanDatePipe}}</td>
          <td>{{item.last_login | humanDatePipe}}</td>
          <td>{{item.username}}</td>
          <td>
            <a href="tel:{{item.phone}}">
              {{item.phone}}
            </a>
          </td>
          <td><span *ngIf="!!item.organization_object">
              <a routerLink="/main/admin/organizations/{{item.organization_object.uuid}}">
                {{item.organization_object.name}}
              </a>
            </span></td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- <mat-card class="mt-3">
        <mat-card-content>
            <pre>{{items | json}}</pre>
        </mat-card-content>
    </mat-card> -->

</div>
