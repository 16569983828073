<div class="container-fluid">
    <mat-card class="mt-4">
        <mat-card-content>

            <!-- <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>Neue Rechnung</h1>
            </div> -->

            <div *ngIf="ownOrganization">
                <div class="alert alert-warning" *ngIf="!ownOrganization.vat_number">
                    {{'common.no_vat_number_provided' | translate}}:
                    <a routerLink="/main/carrier/settings/organization">{{'common.organization_settings' | translate}}</a>
                </div>

                <div class="alert alert-warning" *ngIf="!ownOrganization.iban_number">
                    {{'common.no_iban_provided' | translate}}:
                    <a routerLink="/main/carrier/settings/organization">{{'common.organization_settings' | translate}}</a>
                </div>

                <div class="alert alert-warning"
                    *ngIf="!ownOrganization.address || !ownOrganization.zip || !ownOrganization.location">
                    {{'common.no_address_provided' | translate}}:
                    <a routerLink="/main/carrier/settings/organization">{{'common.organization_settings' | translate}}</a>
                </div>
            </div>

            <div class="row">
                <div class="col-12">

                    <div style="display: flex; flex-direction: row; justify-content: space-between;">
                        <h2>{{'common.invoice_data' | translate}}</h2>
                        <div>
                            <button mat-icon-button color="primary"
                                [disabled]="!selectedClient || !ownOrganization || !ownOrganization.iban_number"
                                (click)="downloadInvoice()"><mat-icon>download</mat-icon></button>
                            <button mat-icon-button color="primary"
                                [disabled]="!selectedClient || !ownOrganization || !ownOrganization.iban_number"
                                (click)="saveInvoice()"><mat-icon>save</mat-icon></button>
                        </div>
                    </div>

                    <mat-card>
                        <mat-card-content>

                            <app-client-selector [selectedClient]="selectedClient"
                                (onSelectedClient)="onSelectedClient($event)"></app-client-selector>

                            <!-- <pre>{{selectedClient | json}}</pre> -->

                        </mat-card-content>
                    </mat-card>

                    <mat-card class="mt-4">
                        <mat-card-content>
                            <form [formGroup]="invoiceForm">
                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label>Rechnungsnummer</mat-label>
                                    <input type="text" matInput formControlName="number">
                                </mat-form-field>

                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label>{{'common.invoice_date' | translate}}</mat-label>
                                    <input matInput formControlName="invoice_date" [matDatepicker]="picker">
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>

                                <mat-form-field appearance="outline" style="width: 100%">
                                    <mat-label>{{'common.payable_by' | translate}}</mat-label>
                                    <input matInput formControlName="pay_until_date" [matDatepicker]="until_picker">
                                    <mat-datepicker-toggle matIconSuffix [for]="until_picker"></mat-datepicker-toggle>
                                    <mat-datepicker #until_picker></mat-datepicker>
                                </mat-form-field>
                            </form>
                        </mat-card-content>
                    </mat-card>

                    <!-- <mat-card *ngFor="let item of defaultInvoice.items" class="mt-4">
                        <mat-card-content>
                            <div class="row">
                                <mat-form-field class="col-6">
                                    <mat-label>{{'common.position' | translate}}:</mat-label>
                                    <input matInput [(ngModel)]="item.title" type="text" name="manual-invoice-title"
                                        required>
                                </mat-form-field>
                                <mat-form-field class="col-3">
                                    <mat-label>{{'common.price' | translate}}:</mat-label>
                                    <input matInput [(ngModel)]="item.price" type="number" name="manual-invoice-price"
                                        required>
                                </mat-form-field>
                                <button class="col-3" mat-mini-fab color="warn" (click)="deleteItem(item)"
                                    [disabled]="defaultInvoice.items.length < 2">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </div>
                            <br>
                            <mat-form-field class="col-12">
                                <mat-label>{{'carrier_dashboard.user-editor-description' | translate}} (optional):</mat-label>
                                <textarea matInput [(ngModel)]="item.description" type="text"
                                    name="manual-invoice-description"></textarea>
                            </mat-form-field>
                        </mat-card-content>
                    </mat-card> -->

                    <!-- <div style="text-align: end;" class="mt-2">
                        <button mat-mini-fab color="warn" (click)="addItem()">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div> -->
                    <pre>{{invoiceForm.value | json}}</pre>
                </div>

                <!-- <div class="col-6">
                    <h2>Rechnungsdokument</h2>
                    <mat-card>
                        <mat-card-content>
                            <pdf-viewer [pdfSrc]="pdfSrc" height="calc(100vh - 250px)"></pdf-viewer>
                        </mat-card-content>
                    </mat-card>
                </div> -->
            </div>

        </mat-card-content>
    </mat-card>
</div>