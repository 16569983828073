import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { lastValueFrom, map, startWith } from 'rxjs';
import { FormControl } from '@angular/forms';
import { FleetService, IVehicle } from '../../services/fleet.service';


@Component({
  selector: 'app-vehicle-selector',
  templateUrl: './vehicle-selector.component.html',
  styleUrls: ['./vehicle-selector.component.scss']
})
export class VehicleSelectorComponent implements OnInit {

  @Input() selectedVehicle: IVehicle | undefined = undefined;

  possibleVehicles: IVehicle[] = [];
  filteredVehicles: IVehicle[] = []
  userFormControl = new FormControl('')

  @Output() onSelectedVehicle = new EventEmitter<IVehicle>()

  constructor(
    public fleetService: FleetService,
  ) {
    this.userFormControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    ).subscribe(val => {
      this.filteredVehicles = val;
    });
  }

  async ngOnInit() {
    this.possibleVehicles = await lastValueFrom(this.fleetService.getVehicles())
  }

  getTitle(user: IVehicle) {
    console.log(`getTitle(${user?.uuid}`)
    const filtered = this.possibleVehicles.find(p => p.uuid === user?.uuid)
    if (filtered) {
      return `${filtered.number} ${filtered.description}`
    }
    return '';
  }

  private _filter(value: string): IVehicle[] {
    console.log(value)
    if (typeof (value) === 'string') {
      const filterValue = value.toLowerCase();
      return this.possibleVehicles.filter(user => {
        return user.number.toLowerCase().includes(filterValue) || user.description.toLowerCase().includes(filterValue)
      });
    }
    return []
  }

  selected(client: IVehicle) {
    this.onSelectedVehicle.emit(client);
  }

  getUserName(user: IVehicle) {
    return `${user.number} ${user.description}`
  }
}
