<section class="section wf-section">
    <div class="div-block">
        <div class="kachel">
            <h1 class="heading-3">{{'common.create_account' | translate}}</h1>
            <div class="form-block w-form">
                <form id="email-form" name="email-form" data-name="Email Form" method="get" class="form"
                    data-wf-page-id="64b5a624e94bf56c8bfae507" data-wf-element-id="86e0f79c-eceb-62bd-ed48-fa589d6b90a6"
                    [formGroup]="form" style="padding-top: 15px;">


                    <mat-form-field style="width: 100%" appearance="outline">
                        <mat-label>{{'common.company_name' | translate}}</mat-label>
                        <input type="text" matInput placeholder="Firmenname" formControlName="company_name">
                        <mat-error class="text-danger" *ngIf="form.get('company_name')?.hasError('required')">
                            {{'carrier_dashboard.field_required' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" appearance="outline">
                        <mat-label translate>common.first_name</mat-label>
                        <input type="text" matInput placeholder="Vorname" formControlName="first_name">
                        <mat-error class="text-danger" *ngIf="form.get('first_name')?.hasError('required')">
                            {{'carrier_dashboard.field_required' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" appearance="outline">
                        <mat-label translate>common.last_name</mat-label>
                        <input type="text" matInput placeholder="Nachname" formControlName="last_name">
                        <mat-error class="text-danger" *ngIf="form.get('last_name')?.hasError('required')">
                            {{'carrier_dashboard.field_required' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" style="width: 100%" appearance="outline">
                        <mat-label translate>common.mail</mat-label>
                        <input type="email" matInput placeholder="E-Mail" formControlName="email">
                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('required')">
                            {{'carrier_dashboard.field_required' | translate}}
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('pattern')">
                            {{'common.invalid_email_entry' | translate}}
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('emailTaken')">
                            {{'common.email_already_exists_please_login' | translate}}
                        </mat-error>

                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('userBlocked')">
                            {{'common.user_is_blocked' | translate}}
                        </mat-error>

                        <mat-error class="text-danger" *ngIf="form.get('email')?.hasError('userBlocked')">
                            {{'common.organization_is_blocked' | translate}}
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" style="width: 100%" appearance="outline">
                        <mat-label>{{'common.phone_number' | translate}}</mat-label>
                        <input type="tel" matInput placeholder="Telefonnummer" formControlName="phone">
                        <mat-error class="text-danger" *ngIf="form.get('phone')?.hasError('required')">
                            {{'carrier_dashboard.field_required' | translate}}
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('phone')?.hasError('pattern')">
                            Invalid phone number.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3" style="width: 100%" appearance="outline">
                        <mat-label>Passwort</mat-label>
                        <input type="password" matInput placeholder="Passwort" formControlName="password">
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('required')">
                            {{'carrier_dashboard.field_required' | translate}}
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('minlength')">
                            <span>{{'common.minimum_length_for_this_field_is' | translate}}
                            </span>{{form.get('password')?.errors?.["minlength"]?.requiredLength}}.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('maxlength')">
                            <span>{{'common.maximum_length_for_this_field_is' | translate}}
                            </span>{{form.get('password')?.errors?.["maxlength"]?.requiredLength}}.
                        </mat-error>
                        <mat-error class="text-danger" *ngIf="form.get('password')?.hasError('pattern')">
                            {{'common.password_must_contain_lowercase_uppercase_and_numbers' | translate}}
                        </mat-error>

                    </mat-form-field>

                    <mat-radio-group formControlName="organization_type_preference"
                        style="display: flex;flex-direction: column;">
                        <mat-radio-button value="2">{{'common.i_want_to_transport_shipments' | translate}}</mat-radio-button>
                        <mat-radio-button value="1">{{'common.i_want_to_submit_sell_shipments' | translate}}</mat-radio-button>
                        <mat-radio-button value="3">{{'common.i_want_both' | translate}}</mat-radio-button>
                    </mat-radio-group>

                    <hr>
                    <mat-checkbox formControlName="accept_terms">
                        {{'common.i_have_read_and_agree_to_the' | translate}} <a href="https://www.cargoflow.ch/agbs" target="_blank">AGBs</a> {{'common.read_and_agree' | translate}}
                    </mat-checkbox>

                    <mat-error class="text-danger"
                        *ngIf="form.get('accept_terms')?.touched && form.get('accept_terms')?.hasError('required')">
                        {{'common.you_must_accept_the_cargoflow_terms_and_conditions' | translate}}
                    </mat-error>

                    <p class="paragraph-2">{{'common.you_already_have_an_account' | translate}}: <a routerLink="/login" class="link-4">{{'common.login_now' | translate}}</a>

                        <button (click)="onSubmit()" class="button handy-20top w-button w-100"
                            [disabled]="isLoading">{{'common.register' | translate}}</button>
                </form>
            </div>
        </div>
    </div>
</section>