import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { versions } from 'projects/versions';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  versions = versions;
  isLoading = false;

  failedLogin = false;

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  constructor(
    private authService: AuthService,
    private router: Router,
    private title: Title,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.login').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  ngOnInit() {
    console.log('LoginComponent')
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['/panel-chooser'])
    }
  }

  loading(state: boolean){
    this.isLoading = state;
    if (state) {
      this.spinner.show()
    } else {
      this.spinner.hide()
    }
  }

  onSubmit(type: 'shipper' | 'carrier') {
    this.loading(true);
    this.failedLogin = false;
    this.authService.loginUser(this.form.value.username, this.form.value.password).subscribe((res: any) => {
      this.loading(false);
      if (res) {
        console.log('success -> moving to dashboard')
        // this.router.navigate([`/${type}/dashboard`]);
        this.router.navigate([`/panel-chooser`]);
      } else {
        const warningMessage: any = lastValueFrom(this.translateService.get('toastr.password_username_not_correct'));
        // this.user.username = '';
        // this.user.password = '';
        this.failedLogin = true;
        this.form.patchValue({ password: '' })
        this.toastrService.warning(warningMessage)
      }
    });
  }
}
