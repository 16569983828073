import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicShipmentsService } from 'projects/webapp/app/services/public-shipments.service';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { IShipmentAddImageData, ShipmentAddImageModalComponent } from '../shipment-add-image-modal/shipment-add-image-modal.component';
import { ImageType, ShipmentImage } from '../../interfaces/shipment.interface';
import { MatDialog } from '@angular/material/dialog';
import { AdminShipmentsService } from '../../services/admin-shipments.service';
import { PanelType } from '../../interfaces/panel-types.interface';
import { CarrierShipmentsService } from '../../services/carrier-shipments.service';


@Component({
  selector: 'app-shipment-images',
  templateUrl: './shipment-images.component.html',
  styleUrls: ['./shipment-images.component.scss']
})
export class ShipmentImagesComponent implements OnInit {

  @Input() shipmentUUID: string | null = null;
  @Input() panelType: PanelType = 'carrier';

  isLoading = false;

  shipmentImages: ShipmentImage[] = []
  shipmentImagesAfterType: {
    'pickup': ShipmentImage[],
    'delivery': ShipmentImage[],
    'general': ShipmentImage[],
  } = {
      'pickup': [],
      'delivery': [],
      'general': [],
    }

  constructor(
    public publicShipmentsService: PublicShipmentsService,
    private spinnerService: NgxSpinnerService,
    public route: ActivatedRoute,
    private carrierShipmentsService: CarrierShipmentsService,
    private adminShipmentsService: AdminShipmentsService,
    public dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
    this.refreshImages();
  }

  async refreshImages() {
    try {
      this.spinnerService.show('shipmentImages')
      if (this.panelType === 'carrier') {
        this.shipmentImages = await lastValueFrom(this.carrierShipmentsService.getShipmentImages(this.shipmentUUID as any))
      }
      if (this.panelType === 'admin') {
        this.shipmentImages = await lastValueFrom(this.adminShipmentsService.getShipmentImages(this.shipmentUUID as any))
      }
      this.shipmentImages.forEach(el => {
        if (el.type === ImageType.PICKUP) {
          this.shipmentImagesAfterType.pickup.push(el);
        } else if (el.type === ImageType.DELIVERY) {
          this.shipmentImagesAfterType.delivery.push(el);
        } else {
          this.shipmentImagesAfterType.general.push(el);
        }
      })
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide('shipmentImages')
    }
  }

  onAddImage() {
    if (this.shipmentUUID) {
      const data: IShipmentAddImageData = {
        panelType: this.panelType,
        type: ImageType.GENERAL,
        showType: true,
        shipmentUUID: this.shipmentUUID
      }
      this.dialog.open(ShipmentAddImageModalComponent, {
        data
      }).afterClosed().subscribe(async val => {
        if (!!val) {
          this.refreshImages();
        }
      });
    }
  }
}
