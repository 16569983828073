<div class="container">
    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'common.search' | translate}}</h1>
            </div>
            <!-- <form>
                <input class="w-100 mt-4 mb-4" placeholder="sendungsnummer, abholort etc.">
            </form> -->
            <form class="search-form" [formGroup]="searchForm">
                <!-- <mat-form-field>
                    <input
                        formControlName="search" matInput />
                </mat-form-field> -->
                <div class="mb-3 mx-sm-3 position-relative">
                    <!-- <label for="exampleFormControlInput1" class="form-label">Suche</label> -->
                    <input type="text" class="form-control" id="exampleFormControlInput1" formControlName="search" [placeholder]="'common.main_search_bar' | translate">
                    <mat-icon class="fs-sm-2 absolute">search</mat-icon>
                </div>

                <div class="spinner-border" role="status" *ngIf="isLoading">
                    <!-- <span class="sr-only">Loading...</span> -->
                </div>

            </form>

            <div *ngIf="searchForm.value.search != '' && !foundShipments || foundShipments?.length === 0"
                class="alert alert-info w-100">
                {{'common.no_shipments_found' | translate}}
            </div>

            <table class="table table-custom" style="width: 100%" *ngIf="!!foundShipments && foundShipments.length > 0">
                <thead>
                    <tr>
                        <th scope="col">{{'common.number' | translate}}</th>
                        <th scope="col">{{'common.pick-up-location' | translate}}</th>
                        <th scope="col">{{'common.delivery_location' | translate}}</th>
                        <th scope="col">{{'common.status' | translate}}</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let foundShipment of foundShipments">
                        <td [attr.data-label]="'common.number' | translate">
                            <a routerLink="/main/carrier/shipments/{{foundShipment.uuid}}">
                                <ul class="co-simple-list">
                                    <li>{{ foundShipment.number | shipmentNumberPipe}}</li>
                                </ul>
                            </a>
                        </td>
                        <td [attr.data-label]="'common.pick-up-location' | translate">
                            <ul class="co-simple-list">
                                <li>{{ foundShipment.pickup_company}}</li>
                                <li>{{ foundShipment.pickup_name}}</li>
                                <li>{{ foundShipment.pickup_address}}</li>
                                <li>{{ foundShipment.pickup_location}}</li>
                            </ul>
                        </td>
                        <td [attr.data-label]="'common.delivery_location' | translate">
                            <ul class="co-simple-list">
                                <li>{{ foundShipment.delivery_company}}</li>
                                <li>{{ foundShipment.delivery_name}}</li>
                                <li>{{ foundShipment.delivery_address}}</li>
                                <li>{{ foundShipment.delivery_location}}</li>
                            </ul>
                        </td>
                        <td [attr.data-label]="'common.status' | translate">
                            <ul class="co-simple-list">
                                <li>{{ foundShipment.status | shipmentStatusPipe}}</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- <pre>{{foundShipments | json}}</pre> -->
        </mat-card-content>
    </mat-card>
</div>