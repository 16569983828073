<mat-list>
  <ng-container *ngFor="let item of menu">
    <!-- If the item doesn't have children show it as list item-->
    <ng-container *ngIf="item.subMenu === undefined">
      <mat-list-item *ngIf="item.title" [routerLink]="item.link" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }" (click)="onClickItem(item)">

        <div class="nav-item" style="display: flex; flex-direction: row;">
          <mat-icon [style.color]="item.color" mat-list-icon style="margin-right: 15px"
            *ngIf="(item.needsSubscription && !(organizationsService.ownOrg$ | async)?.admin_permission_software_license) else Subscription">lock</mat-icon>
          <ng-template #Subscription>
            <mat-icon [style.color]="item.color" mat-list-icon style="margin-right: 15px">
              {{ item.icon }}
            </mat-icon>
          </ng-template>
          <div mat-line>
            <small>
              {{ item.title }}
            </small>
          </div>
        </div>


      </mat-list-item>
    </ng-container>

    <!-- If the item has subMenu show it as accordion-->
    <ng-container *ngIf="!!item && !!item.subMenu && item.subMenu.length > 0">
      <mat-expansion-panel [expanded]="item.expanded">
        <mat-expansion-panel-header>
          <mat-panel-title class="d-flex align-items-center">
            <mat-icon [style.color]="item.color" mat-list-icon>
              {{ item.icon }}
            </mat-icon>
            <span mat-line class="list-item">{{ item.title }}</span>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-menu-item [menu]="item.subMenu"></app-menu-item>
      </mat-expansion-panel>
    </ng-container>
  </ng-container>
  <!-- for testing reasons -->
  <app-language-changer></app-language-changer>
</mat-list>
