import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { ShipmentEventType } from '../services/shipments.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'shipmentEventTypePipe' })
export class ShipmentEventTypePipe implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(type: any): string {
    switch(type){
      case ShipmentEventType.SHIPMENT_CREATED:
        return this.translate.instant('common.created');
      case ShipmentEventType.SHIPMENT_ACCEPTED:
        return this.translate.instant('common.accepted');
      case ShipmentEventType.SHIPMENT_PICKED_UP:
        return this.translate.instant('common.picked_up');
      case ShipmentEventType.SHIPMENT_ARRIVED_AT_HUB:
        return this.translate.instant('common.arrived_at_hub');
      case ShipmentEventType.SHIPMENT_DISPATCHED:
        return this.translate.instant('common.dispatched');
      case ShipmentEventType.SHIPMENT_DELIVERED:
        return this.translate.instant('common.delivered');
      case ShipmentEventType.SHIPMENT_CANCELLED:
        return this.translate.instant('common.cancelled');
      case ShipmentEventType.SHIPMENT_INVOICED:
        return this.translate.instant('common.invoiced');
      case ShipmentEventType.SHIPMENT_PRICE_CHANGED:
        return this.translate.instant('common.price_adjusted');
      case ShipmentEventType.SHIPMENT_RELEASED_FOR_INVOICING:
        return this.translate.instant('common.released_for_invoicing');   
      case ShipmentEventType.SHIPMENT_SOLD:
        return this.translate.instant('common.marketplace_transaction');

    }
 
    return '-'
  }
}
