<div mat-dialog-title class="text-center">
  <h3>
    {{data.title}}
  </h3>
</div>
<div mat-dialog-content class="pt-2">

  <!-- <p>{{data.description}}</p> -->
  <div [innerHTML]="data.html"> </div>

</div>

<div class="mb-2" mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" (click)="cancel()">{{'common.close' | translate}}</button>
  <!-- <button mat-button color="primary" cdkFocusInitial (click)="save()">Veröffentlichen</button> -->
</div>
