<form [formGroup]="surchargesForm" (submit)="updateSurcharges()">
    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="empties_exchange_price_pallet"
                formControlName="empties_exchange_price_pallet">
            <label for="empties_exchange_price_pallet" class="form-label">{{'carrier_dashboard.empty_container_exchange' | translate}} {{'carrier_dashboard.euro_pallets' | translate}} ({{'common.for_example' | translate}} 4 CHF)</label>
        </div>
    </div>

    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="empties_exchange_price_frame"
                formControlName="empties_exchange_price_frame">
            <label for="empties_exchange_price_frame" class="form-label">{{'carrier_dashboard.empty_container_exchange' | translate}} {{'carrier_dashboard.sbb_frames' | translate}} ({{'common.for_example' | translate}} 4 CHF)</label>
        </div>
    </div>

    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="empties_exchange_price_cover"
                formControlName="empties_exchange_price_cover">
            <label for="empties_exchange_price_cover" class="form-label">{{'carrier_dashboard.empty_container_exchange' | translate}} {{'carrier_dashboard.sbb_lids' | translate}} ({{'common.for_example' | translate}} 4 CHF)</label>
        </div>
    </div>
    <hr>
    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="fuel_surcharge_percent" formControlName="fuel_surcharge_percent">
            <label for="fuel_surcharge_percent" class="form-label">{{'carrier_dashboard.fuel_surcharge' | translate}} ({{'common.for_example' | translate}} 2 {{'common.for' | translate}} 2%)</label>
        </div>
    </div>

    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="congestion_surcharge_percent" formControlName="congestion_surcharge_percent">
            <label for="congestion_surcharge_percent" class="form-label">{{'carrier_dashboard.congestion_surcharge' | translate}} ({{'common.for_example' | translate}} 2 {{'common.for' | translate}} 2%)</label>
        </div>
    </div>
    <hr>
    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="insurance_surcharge_min"
                formControlName="insurance_surcharge_min">
            <label for="insurance_surcharge_min" class="form-label">{{'carrier_dashboard.insurance_minimum_price' | translate}} ({{'common.for_example' | translate}} 10 CHF)</label>
        </div>
    </div>

    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="insurance_surcharge_percent"
                formControlName="insurance_surcharge_percent">
            <label for="insurance_surcharge_percent" class="form-label">{{'carrier_dashboard.insurance_percentage' | translate}} ({{'common.for_example' | translate}} 2 {{'common.for' | translate}} 2%)</label>
        </div>
    </div>
    <hr>
    <div class="mb-3">
        <div class="form-floating">
            <input class="form-control" type="text" id="phone_notification" formControlName="phone_notification">
            <label for="phone_notification" class="form-label">{{'carrier_dashboard.phone_warning' | translate}} ({{'common.for_example' | translate}} 5 CHF)</label>
        </div>
    </div>


    <button class="green-btn primary-hover-animation" type="submit">{{'common.save' | translate}}</button>
</form>