<div class="page-container pb-4 mb-5">

  <div class="container mb-5">

    <mat-card class="mt-4 mb-4">
      <mat-card-content>

        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          <h1>{{'carrier_dashboard.user-editor-fleet.main-title' | translate}}</h1>

          <button class="green-btn primary-hover-animation d-flex align-items-center gap-2 w-auto" (click)="onCreateNewVehicle()">
            {{'carrier_dashboard.user-editor-fleet.add_new_truck' | translate}}
            <mat-icon>add</mat-icon>
          </button>
        </div>

        <!-- <button mat-flat-button color="primary" (click)="onCreateNewVehicle()"  *ngIf="!!vehicles && vehicles.length == 0"> -->
          <div style="border: none;" class="alert alert-info bg-green-light mt-2" *ngIf="!!vehicles && vehicles.length == 0">
            {{'common.no_vehicles_add_one' | translate}}
          </div>


        <table class="table table-custom" style="width: 100%" *ngIf="!!vehicles && vehicles.length > 0">
          <thead>
            <!-- <td>{{'common.id' | translate}}</td> -->
            <td>{{'common.number' | translate}}</td>
            <td>{{'carrier_dashboard.user-editor-fleet.model' | translate}}</td>
            <td>{{'carrier_dashboard.user-editor-payload' | translate}}</td>
            <td>{{'carrier_dashboard.user-editor-amount_of_pallet-spaces' | translate}} </td>
            <td>{{'carrier_dashboard.user-editor-description' | translate}}</td>
          </thead>

          <tbody>
            <tr *ngFor="let vehicle of vehicles">
              <!-- <td>
                {{vehicle.uuid }}
              </td> -->
              <td [attr.data-label]="'common.number' | translate">
                <div class=" d-flex">
                  <mat-icon class="truck-icon"> local_shipping</mat-icon>
                  {{vehicle.number }}
                </div>
              </td>
              <td [attr.data-label]="'carrier_dashboard.user-editor-fleet.model' | translate">
                {{vehicle.model}}
              </td>
              <td [attr.data-label]="'carrier_dashboard.user-editor-payload' | translate">
                {{vehicle.max_weight }} Kg
              </td>
              <td [attr.data-label]="'carrier_dashboard.user-editor-amount_of_pallet-spaces' | translate">
                {{vehicle.max_ground_spaces | number: "1.2-2" }}
              </td>
              <td [attr.data-label]="'carrier_dashboard.user-editor-description' | translate" class="text-truncate description">
                {{vehicle.description }}
              </td>
              <td class="d-flex justify-content-around justify-content-md-end">
                <button mat-icon-button color="primary" (click)="onEditVehicle(vehicle)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button color="primary" (click)="onDeleteVehicle(vehicle)">
                  <mat-icon>delete</mat-icon>
                </button>

              </td>
            </tr>
          </tbody>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
