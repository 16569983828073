import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { Params } from '@angular/router';
import { IShipment } from './shipments.service';
import { IShipmentComment } from '../interfaces/shipment-comment.interface';
import { IDetailedShipment, IShipperMarketShipment } from '../interfaces/shipment.interface';


export interface IDashboardNumbers {
  shipments_in_market_place: number,
  shipments_to_pickup: number,
  shipments_to_deliver: number,
}

@Injectable({
  providedIn: 'root'
})
export class ShipperShipmentsService {

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  getShipments(): Observable<IDetailedShipment[]> {
    return this.http.get<IDetailedShipment[]>(this._urls.SHIPPER_SHIPMENTS)
  }

  getOpenMarketShipments(): Observable<IShipperMarketShipment[]> {
    return this.http.get<IShipperMarketShipment[]>(this._urls.SHIPPER_SHIPMENTS_MARKET)
  }

  getPricingForShipmentByOrg(data: any) {
    return this.http.post<any>(this._urls.SHIPPER_MARKET_PLACE_ORG_PRICING, data)
  }

  public getShipment(shipmentUUID: string): Observable<IDetailedShipment> {
    return this.http.get<IDetailedShipment>(this._urls.SHIPPER_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  public createShipment(shipment: IShipment): Observable<any> {
    return this.http.post<any>(this._urls.SHIPPER_SHIPMENTS, shipment)
  }

  public updateShipment(shipmentUUID: string, shipment: IShipment): Observable<any> {
    return this.http.patch<any>(this._urls.SHIPPER_SHIPMENTS_BY_UUID(shipmentUUID), shipment)
  }

  public cancelShipment(shipmentUUID: string): Observable<any> {
    return this.http.post<any>(this._urls.SHIPPER_CANCEL_SHIPMENTS_BY_UUID(shipmentUUID), {})
  }

  public addPublicCommentShipment(shipmentUUID: string, content: string): Observable<IShipmentComment> {
    return this.http.post<IShipmentComment>(this._urls.SHIPPER_COMMENT_SHIPMENTS_BY_UUID(shipmentUUID), { content })
  }

  // public getPrivateCommentsShipment(shipmentUUID: string): Observable<IShipmentComment[]> {
  //   return this.http.get<IShipmentComment[]>(this._urls.SHIPPER_PRIVATE_COMMENT_SHIPMENTS_BY_UUID(shipmentUUID))
  // }

  public getPublicCommentsShipment(shipmentUUID: string): Observable<IShipmentComment[]> {
    return this.http.get<IShipmentComment[]>(this._urls.SHIPPER_COMMENT_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  public deleteCommentsShipment(shipmentUUID: string, commentUUID: string): Observable<IShipmentComment[]> {
    return this.http.delete<IShipmentComment[]>(this._urls.SHIPPER_COMMENT_BY_UUID_SHIPMENTS_BY_UUID(shipmentUUID, commentUUID))
  }

  public updateCommentsShipment(shipmentUUID: string, commentUUID: string, comment: IShipmentComment): Observable<IShipmentComment[]> {
    return this.http.patch<IShipmentComment[]>(this._urls.SHIPPER_COMMENT_BY_UUID_SHIPMENTS_BY_UUID(shipmentUUID, commentUUID), comment)
  }

  searchShipments(params: Params) {
    return this.http.get<any>(this._urls.SHIPPER_SHIPMENTS_SEARCH, { params })
  }

}
