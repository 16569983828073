import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-pipeline-invoice',
  templateUrl: './pipeline-invoice.component.html',
  styleUrls: ['./pipeline-invoice.component.scss']
})
export class PipelineInvoiceComponent implements OnInit {

  shipmentsForInvoicing: IShipment[] = []
  isLoading = true;

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public carrierShipmentsService: CarrierShipmentsService,
    public usersService: UsersService,
    public dialog: MatDialog,
    public spinnerService: NgxSpinnerService,
    private title: Title,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.pipeline_accounting').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show();
      this.isLoading = true;
      this.shipmentsForInvoicing = await lastValueFrom(this.carrierShipmentsService.getShipmentsForInvoicing())
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide();
    }
  }

  async sendShipmentToInvoicingSystem(shipment: IShipment) {
    if (shipment && shipment.uuid) {
      const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_relesed_for_invoicing'));

      await lastValueFrom(this.carrierShipmentsService.makeShipmentInvoiceReady(shipment.uuid));
      this.toastrService.success(successMessage)
      this.refresh();
    }
  }

}
