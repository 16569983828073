<mat-card>
    <mat-card-title>Request new password</mat-card-title>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <p>
                <mat-form-field>
                    <input type="password" matInput placeholder="New Password" formControlName="new_password">
                </mat-form-field>
            </p>

            <div class="button">
                <button type="submit" mat-button color="primary">Set new password</button>
            </div>

        </form>
    </mat-card-content>
</mat-card>