import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISiteKey } from '../../services/organizations.service';


@Component({
  selector: 'app-user-api-key-editor',
  templateUrl: 'site-key-editor.component.html'
})
export class SiteKeyEditorComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<SiteKeyEditorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ISiteKey,
  ) { }

  async ngOnInit() {

  }
}


 
 