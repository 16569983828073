import { Component } from "@angular/core";
import { IFilterOutput, IFilterProperty } from "../filtered-tags/filter.interface";
import { onFiltering, onSorting } from "../filtered-tags/filters";

@Component({
  selector: 'app-filtered-list',
  templateUrl: './filtered-list.component.html',
  styleUrls: ['./filtered-list.component.scss']
})
export class FilteredListComponent {

  filterProperties: IFilterProperty[] = []

  selectedItems: any[] = []
  filteredItems: any[] = []
  items: any[] = []

  constructor(
  ) {
  }

  onToggleSelection(event: any, shipment: any): void {
    // const inputElement = event.target as HTMLInputElement;
    console.log(event.checked)
    console.log(shipment)

    const index = this.selectedItems.findIndex(s => s.uuid === shipment.uuid);

    if (index !== -1) {
      this.selectedItems = this.selectedItems.filter(s => s.uuid !== shipment.uuid);
    } else {
      this.selectedItems = [...this.selectedItems, shipment];
    }
  }

  isShipmentChecked(shipment: any) {
    return this.selectedItems.find(s => s.uuid === shipment.uuid) ? true : false;
  }

  onChangeSelectionEvent(state: boolean) {
    console.log(state)
    if (state) {
      this.selectedItems = this.filteredItems;
    } else {
      this.selectedItems = [];
    }
  }

  onChangeFilterEvent(filterOutput: IFilterOutput) {
    this.filteredItems = onFiltering(filterOutput, this.items, this.filteredItems)
  }

  onChangeSorting(option: string) {
    onSorting(option, this.filteredItems);
  }

}
