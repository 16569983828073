import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { IUser } from '../../services/users.service';
import { AuthService } from '../../services/auth.service';


@Component({
  selector: 'app-user-editor-form',
  templateUrl: './user-editor-form.component.html',
  styleUrls: ['./user-editor-form.component.scss']
})
export class UserEditorFormComponent implements OnInit, AfterViewInit {

  @Input() user: IUser | null = null;
  @Output() onFormSubmit = new EventEmitter()

  form: FormGroup = new FormGroup({
    first_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    last_name: new FormControl('', [Validators.required, Validators.minLength(2)]),
    email: new FormControl(''),
    phone: new FormControl('', Validators.required),

    permission_pipeline: new FormControl(false),
    permission_dispatch: new FormControl(false),
    permission_invoicing: new FormControl(false),
    permission_client_management: new FormControl(false),
    permission_empties_management: new FormControl(false),
    permission_warehouse_management: new FormControl(false),
    permission_activity: new FormControl(false),
    permission_export: new FormControl(false),
    permission_user_management: new FormControl(false),
    permission_org_management: new FormControl(false),
    permission_settings: new FormControl(false),
    permission_see_pricing: new FormControl(false),
    permission_alerts_place: new FormControl(false),
    permission_market_place: new FormControl(false),
    permission_new_shipments: new FormControl(false),
    permission_shipper_panel: new FormControl(false),
    permission_carrier_panel: new FormControl(false),
  });

  constructor(
    public authService: AuthService,
  ) { }

  ngOnInit() {
    if (this.user){
      this.form.patchValue(this.user)
    }
  }

  ngAfterViewInit() {
    if (this.user) {
      this.form.patchValue(this.user)
    }
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.onFormSubmit.emit(this.form.value)
  }

}
