import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICarrierMarketShipment } from '../../interfaces/shipment.interface';
import { lastValueFrom } from 'rxjs';
import { CarrierShipmentsService } from '../../services/carrier-shipments.service';


@Component({
  selector: 'app-market-place-shipment-overview',
  templateUrl: './market-place-shipment-overview.component.html',
  styleUrls: ['./market-place-shipment-overview.component.scss']
})
export class MarketPlaceShipmentOverviewOverviewComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MarketPlaceShipmentOverviewOverviewComponent>,
    private carrierShipmentsService: CarrierShipmentsService,
    @Inject(MAT_DIALOG_DATA) public shipment: ICarrierMarketShipment,
  ) {
  }

  async ngOnInit() {
    if (this.shipment.uuid){
      await lastValueFrom(this.carrierShipmentsService.addMarketPlaceView(this.shipment.uuid))
    }
  }

  takeShipment() {
    this.dialogRef.close(true);
  }
}
