import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmbedWizardService } from '../services/embed-wizard.service';
import { ClientWizardService } from '../services/client-wizard.service';


@Injectable()
export class SiteKeyInterceptor implements HttpInterceptor {
  constructor(
    private embedWizardService: EmbedWizardService,
    private clientWizardService: ClientWizardService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestWithVersion = request.clone({
      setHeaders: {
        'x-site-key': this.embedWizardService.siteKey,
        'x-token-key': this.clientWizardService.token,
      }
    });
    return next.handle(requestWithVersion);
  }
}
