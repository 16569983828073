import { Component } from '@angular/core';
import { environment } from 'projects/webapp/app/environments/environment';

@Component({
  selector: 'app-public-footer',
  templateUrl: './footer.component.html',
  styleUrls: [
    './footer.component.scss',
  ]
})
export class PublicFooterComponent {

  env = environment;

  baseUrl = this.env.baseUrl + '/api/v1/schema/swagger-ui/'

  constructor() {}

  openWhatsApp(){}

}
