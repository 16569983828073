import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { versions } from 'projects/versions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, tap } from 'rxjs/operators';
import { IPrice } from 'projects/shared/price.interface';
import { EmbedWizardService } from 'projects/webapp/app/services/embed-wizard.service';


export const generatePacketFormGroup = () => {
  return new FormGroup({
    type: new FormControl(1, Validators.required),
    count: new FormControl(1, Validators.compose([Validators.required, Validators.min(1)])),

    length: new FormControl(120, Validators.compose([Validators.required, Validators.min(1)])),
    width: new FormControl(80, Validators.compose([Validators.required, Validators.min(1)])),
    height: new FormControl(40, Validators.compose([Validators.required, Validators.min(1)])),

    weight: new FormControl(50, Validators.compose([Validators.required, Validators.min(1)])),

    name: new FormControl('', Validators.required),
  });
}

@Component({
  selector: 'app-embed-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss']
})
export class EmbedWizardComponent implements OnInit {

  price: IPrice | null = null;;

  modalRef?: BsModalRef;

  public draftShipmentForm = new FormGroup({
    pickup_company: new FormControl(''),
    pickup_name: new FormControl(''),
    pickup_address: new FormControl('', Validators.required),
    pickup_zip: new FormControl('', Validators.required),
    pickup_location: new FormControl('', Validators.required),
    pickup_notification: new FormControl(false, Validators.required),
    pickup_phone: new FormControl(''),
    pickup_date: new FormControl('', Validators.required),
    pickup_address_complete: new FormControl(''),
    pickup_date_flexible: new FormControl(false, Validators.required),
    pickup_crane_needed: new FormControl(false, Validators.required),
    pickup_access_restriction: new FormControl(false, Validators.required),

    delivery_company: new FormControl(''),
    delivery_name: new FormControl(''),
    delivery_address: new FormControl('', Validators.required),
    delivery_zip: new FormControl('', Validators.required),
    delivery_location: new FormControl('', Validators.required),
    delivery_notification: new FormControl(false, Validators.required),
    delivery_phone: new FormControl(''),
    delivery_date: new FormControl('', Validators.required),
    delivery_address_complete: new FormControl(''),
    delivery_date_flexible: new FormControl(false, Validators.required),
    delivery_crane_needed: new FormControl(false, Validators.required),
    delivery_access_restriction: new FormControl(false, Validators.required),

    packets: new FormArray([generatePacketFormGroup()]),

    dangerous_goods: new FormControl(false, Validators.required),
    dangerous_points: new FormControl(null),
    dangerous_amount: new FormControl(null),
    dangerous_un_number: new FormControl(null),

    empties: new FormControl(false, Validators.required),
    empties_eur_palets: new FormControl(0),
    empties_sbb_frames: new FormControl(0),
    empties_sbb_covers: new FormControl(0),

    insurance: new FormControl(false, Validators.required),
    value: new FormControl(0, Validators.required),

    company_name: new FormControl('', Validators.required),
    first_name: new FormControl('', Validators.required),
    last_name: new FormControl('', Validators.required),
    address: new FormControl('', Validators.required),
    zip: new FormControl('', Validators.required),
    location: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
  });

  public packetItemForms: FormArray = this.draftShipmentForm.get('packets') as FormArray;

  @ViewChild('successModal') successModal!: ElementRef;

  siteKey = '';
  tenantName = '';
  tenantLogo = '';
  isLoading = true;
  isLoadingPricing = false;
  isLoadingProcessing = false;
  versions = versions;
  initialValue = {}

  currentShipmentKey = 'currentShipment'

  constructor(
    private route: ActivatedRoute,
    private embedWizardService: EmbedWizardService,
    private modalService: BsModalService
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    this.route.queryParams
      .subscribe(async (params) => {
        console.log(params);
        this.siteKey = params['siteKey'];
        console.log(this.siteKey);
        this.isLoading = false;
        if (!!this.siteKey) {
          this.embedWizardService.siteKey = this.siteKey
          try {
            const response = await lastValueFrom(this.embedWizardService.verify_sitekey(this.siteKey));
            console.log(response)
            this.tenantName = response.tenant_name
            if (response.preferences.logo_data){
              this.tenantLogo = response.preferences.logo_data.file;
            }
          } catch (error) {
            console.error(error);
          }
        }
      });

    const val = localStorage.getItem(this.currentShipmentKey);
    if (!!val) {
      console.log('had a current shipment in the session')
      this.draftShipmentForm.patchValue(JSON.parse(val))
      this.initialValue = this.draftShipmentForm.value
      this.fillAddress(val);
      this.fetchPricing()
    }

    this.draftShipmentForm.valueChanges.pipe(
      tap(() => (this.isLoadingPricing = true)),
      debounceTime(300)
    ).subscribe(val => {

      this.fillAddress(val);

      console.log(val)
      localStorage.setItem(this.currentShipmentKey, JSON.stringify(val));
      this.fetchPricing();
      this.initialValue = this.draftShipmentForm.value
    });
  }

  private fillAddress(val: any){
    const hasPickupChange = [
      'pickup_company',
      'pickup_name',
      'pickup_address',
      'pickup_zip',
      'pickup_location',
    ].some(key => (this.draftShipmentForm.value as any)[key] != (this.initialValue as any)[key])
    if (hasPickupChange) {
      const line = [val.pickup_company, val.pickup_name, val.pickup_address, val.pickup_location].filter(function (val) {
        return val;
      }).join(', ');
      this.draftShipmentForm.patchValue({ pickup_address_complete: line })
    }

    const hasDeliveryChange = [
      'delivery_company',
      'delivery_name',
      'delivery_address',
      'delivery_zip',
      'delivery_location',
    ].some(key => (this.draftShipmentForm.value as any)[key] != (this.initialValue as any)[key])
    if (hasDeliveryChange) {
      const line = [val.delivery_company, val.delivery_name, val.delivery_address, val.delivery_location].filter(function (val) {
        return val;
      }).join(', ');

      this.draftShipmentForm.patchValue({ delivery_address_complete: line })
    }
  }

  async fetchPricing() {
    try {
      const response = await lastValueFrom(this.embedWizardService.calculate_price(this.draftShipmentForm.value));
      this.price = response;
      this.isLoadingPricing = false;
    } catch (error) {
      console.error(error)
    }
  }

  resetForm() {
    this.draftShipmentForm.reset();
  }

  public addPacketItem(): void {
    const cargoItemsCount = this.packetItemForms.controls.length;
    const cargoItemForm = generatePacketFormGroup();
    this.packetItemForms.insert(cargoItemsCount, cargoItemForm);
  }

  public removePacketItem(index: number): void {
    this.packetItemForms.removeAt(index);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  async sendRequest() {
    console.log('sendRequest')
    this.isLoadingProcessing = true;

    try {
      await lastValueFrom(this.embedWizardService.request_shipment(this.draftShipmentForm.value))
      this.modalRef?.hide()
      this.openModal(this.successModal as any)
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingProcessing = false;
    }
  }

  closeSuccess(){
    this.modalRef?.hide();
    this.draftShipmentForm.reset();
    localStorage.removeItem(this.currentShipmentKey)
  }
}
