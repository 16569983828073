import { Component, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { IClient, ClientsService } from 'projects/webapp/app/services/clients.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { InvoiceStatus, IInvoice } from 'projects/webapp/app/interfaces/invoice.interface';
import { InvoicesService } from 'projects/webapp/app/services/invoices.service';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-accounting-invoice-editor',
  templateUrl: './accounting-invoice-editor.component.html',
  styleUrls: ['./accounting-invoice-editor.component.scss']
})
export class AccountingInvoiceEditorComponent implements OnInit {
  public pdfSrc: Uint8Array | null = null;

  selectedClient: IClient | undefined = undefined;
  ownOrganization: IOrganization | null = null;

  isLoading = true;

  public invoiceForm = new FormGroup({
    status: new FormControl(InvoiceStatus.DRAFT, Validators.required),
    client: new FormControl(0, Validators.required),

    invoice_type: new FormControl('collective_invoice', Validators.required),
    language: new FormControl('de', Validators.required),
    invoice_date: new FormControl('', Validators.required),

    creditor_name: new FormControl("", Validators.required),
    creditor_address: new FormControl("", Validators.required),
    creditor_zip: new FormControl('', Validators.required),
    creditor_city: new FormControl("", Validators.required),
    creditor_iban_number: new FormControl('', Validators.required),
    creditor_vat_number: new FormControl('', Validators.required),
    creditor_country: new FormControl("CH", Validators.required),

    address_line_1: new FormControl('', Validators.required),
    address_line_2: new FormControl('', Validators.required),
    address_line_3: new FormControl('', Validators.required),

    number: new FormControl('', Validators.required),
    price_total: new FormControl(0, Validators.required),
    price_tax: new FormControl(0, Validators.required),
    price_no_tax: new FormControl(0, Validators.required),

    debtor_name: new FormControl('', Validators.required),
    debtor_address: new FormControl("", Validators.required),
    debtor_zip: new FormControl(8000, Validators.required),
    debtor_city: new FormControl('', Validators.required),
    debtor_country: new FormControl('CH', Validators.required),
    debtor_country_code: new FormControl('CH', Validators.required),

    // items: [
    //   {
    //     "title": 'Positionsname',
    //     "description": '',
    //     "price": 0
    //   }
    // ],

    pay_until_date: new FormControl('', Validators.required)
  });

  invoiceUUID = '';

  constructor(
    public clientsService: ClientsService,
    public usersService: UsersService,
    public spinnerService: NgxSpinnerService,
    private organizationService: OrganizationsService,
    private title: Title,
    private invoicesService: InvoicesService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.invoice_editor').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(async params => {
      console.log(params)
      this.invoiceUUID = params['invoiceUUID'];
      if (!!params && params['invoiceUUID']) {
        await this.refresh();
      }
      this.ownOrganization = await lastValueFrom(this.organizationService.getOwnOrganization());
      this.updateCreditor();
    });
  }

  async refresh() {
    const invoice = await lastValueFrom(this.invoicesService.getInvoice(this.invoiceUUID));
    console.log(invoice);
    this.invoiceForm.patchValue(invoice)
    // this.defaultInvoice = invoice;
    if (invoice.client_object) {
      this.selectedClient = invoice.client_object;
      this.updateDebtor(this.selectedClient)
    }
  }

  updateCreditor() {
    // if (this.ownOrganization && this.ownOrganization.address) {
    //   this.defaultInvoice.creditor.name = this.ownOrganization.company_name
    //   this.defaultInvoice.creditor.address = this.ownOrganization.address
    //   this.defaultInvoice.creditor.city = this.ownOrganization.location
    //   this.defaultInvoice.creditor.zip = this.ownOrganization.zip
    //   this.defaultInvoice.creditor.iban_number = this.ownOrganization.iban_number
    //   this.defaultInvoice.creditor.vat_number = this.ownOrganization.vat_number
    // }
  }

  // addItem() {
  //   this.defaultInvoice.items.push({
  //     "title": '',
  //     "description": '',
  //     "price": 0
  //   })
  // }

  // deleteItem(item: any) {
  //   this.defaultInvoice.items = this.defaultInvoice.items.filter(el => el !== item)
  // }

  onSelectedClient(value: IClient) {
    this.selectedClient = value;
    this.invoiceForm.patchValue({client: this.selectedClient.id})
    // this.defaultInvoice.client = this.selectedClient.id;
  }

  updateDebtor(value: IClient) {
    if (value.company) {
      this.invoiceForm.patchValue({debtor_name: value.company})
    } else {
      this.invoiceForm.patchValue({ debtor_name: `${value.first_name} ${value.last_name}` })
    }

    this.invoiceForm.patchValue({debtor_address: value.address });
    this.invoiceForm.patchValue({debtor_zip: parseInt(value.zip_code, 10) });
    this.invoiceForm.patchValue({debtor_city: value.location });

    if (value.company) {
      this.invoiceForm.patchValue({ address_line_1: value.company })
    } else {
      this.invoiceForm.patchValue({ address_line_1: `${value.first_name} ${value.last_name}` })
    }

    this.invoiceForm.patchValue({address_line_2: value.address})
    this.invoiceForm.patchValue({address_line_3: `${value.zip_code} ${value.location}`})
  }

  async downloadInvoice() {
    // console.log('downloadInvoice');
    // try {
    //   this.updateInvoiceFields()
    //   this.spinner.show()
    //   this.pdfSrc = await lastValueFrom(this.invoicesService.manualInvoicePreview(this.invoiceForm.value as any))
    //   if (this.pdfSrc) {
    //     const blob = new Blob([this.pdfSrc], { 'type': 'application/pdf' });
    //     downloadFile(blob, `Rechnung_${this.invoiceForm.value?.number}.pdf`);
    //   }
    // } catch (error) {
    //   console.error(error)
    // } finally {
    //   this.spinner.hide()
    // }
  }

  async saveInvoice() {
    // TODO: validate the form here

    console.log('saveInvoice')
    this.updateInvoiceFields()
    let response: IInvoice;

    // this.invoiceForm.patchValue({ invoice_date: this.invoiceForm.value.invoice_date?.split('T')[0] })
    // this.invoiceForm.patchValue({ pay_until_date: this.invoiceForm.value.pay_until_date?.split('T')[0]})

    if (this.invoiceUUID) {
      response = await lastValueFrom(this.invoicesService.updateInvoice(this.invoiceUUID, this.invoiceForm.value as any));
      console.log(response)
    } else {
      response = await lastValueFrom(this.invoicesService.manualInvoice(this.invoiceForm.value as any));
      console.log(response)
    }
    if (response.uuid) {
      this.invoiceUUID = response.uuid;
      this.router.navigate(['/main/carrier/facturation/invoice-editor/' + response.uuid])
    }
    const successMessage = await lastValueFrom(this.translateService.get('toastr.invoice_saved'));

    this.toastrService.success(successMessage)
  }

  updateInvoiceFields() {
    this.invoiceForm.patchValue({ invoice_date: new Date(this.invoiceForm.value.invoice_date as any).toISOString().split("T")[0]})
    this.invoiceForm.patchValue({ pay_until_date: new Date(this.invoiceForm.value.pay_until_date as any).toISOString().split("T")[0]})
    // this.defaultInvoice.price_total = 0
    // this.defaultInvoice.price_tax = 0
    // this.defaultInvoice.price_no_tax = 0
    // this.defaultInvoice.items.forEach(el => {
    //   const tax_value = 0.077
    //   this.defaultInvoice.price_no_tax += el.price
    //   this.defaultInvoice.price_tax += (el.price * tax_value)
    //   this.defaultInvoice.price_total += (el.price * (1 + tax_value))
    // })
  }
}
