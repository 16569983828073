import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IDetailedUser } from '../../services/users.service';

 
@Component({
  selector: 'app-user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<UserCardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IDetailedUser,
  ) { }

  async ngOnInit() {
  }
 
}
