import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { IVehicle } from './fleet.service';
import { IUser } from './users.service';
import { ShipmentStatus } from './shipments.service';

export enum TourStatus {
  UNKNOWN = 0,
  IN_PROGRESS = 1,
  PLANNED = 2,
  CLOSED = 3,
}

export interface ITourShipment {

  pickup_location: any;
  pickup_zip: any;
  pickup_date: any;
  pickup_company: string,
  pickup_name: string,
  pickup_address: string,

  delivery_company: string,
  delivery_name: string,
  delivery_address: string,
  delivery_date: any;
  delivery_location: any;
  delivery_zip: any;

  id: number;
  uuid: string;
  number: string;
  total_weight: number
  ground_spaces: number
  status: ShipmentStatus
}

export interface ITour {

  date: Date | string | any;
  weight: number;
  ground_spaces: number;

  turnover: number
  profit: number

  // seconds
  driving_duration: number;
  // only UI
  driving_duration_display?: string;

  // km
  driving_distance: number;

  number_of_stops: number;
}


export interface IDetailedTour extends ITour {

  id?: number;
  uuid?: string;

  status: TourStatus;

  vehicle: any;
  vehicle_object?: IVehicle;

  shipments?: ITourShipment[]

  is_optimized: boolean;
  order?: string[];

  driver: any;
  driver_object?: IUser | null;
}


@Injectable({
  providedIn: 'root'
})
export class TourService {

  private _httpOptions = {
    responseType: 'arraybuffer' as 'json',
  };

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getOpenTours(): Observable<IDetailedTour[]> {
    let params = new HttpParams()
      .set('status', TourStatus.UNKNOWN)
      .set('status', TourStatus.IN_PROGRESS)
    return this.http.get<IDetailedTour[]>(this._urls.TOURS, { params })
  }

  loadListPDF(tourUUID: string) {
    return this.http.get<any>(this._urls.TOUR_BY_UUID_LOAD_LIST(tourUUID), this._httpOptions)
  }

  public getTours(): Observable<IDetailedTour[]> {
    return this.http.get<IDetailedTour[]>(this._urls.TOURS)
  }

  public getMyTours(userUUID: string | undefined): Observable<IDetailedTour[]> {
    let params = new HttpParams()
      .set('driver', userUUID || '')
      .set('status', '2')
    return this.http.get<IDetailedTour[]>(this._urls.TOURS, { params })
  }

  public getTour(tourUUID: string): Observable<IDetailedTour> {
    return this.http.get<IDetailedTour>(this._urls.TOURS_BY_UUID(tourUUID))
  }

  public updateTour(tourUUID: string, tour: ITour | IDetailedTour): Observable<any> {
    return this.http.patch<any>(this._urls.TOURS_BY_UUID(tourUUID), tour)
  }

  public createTour(tour: ITour): Observable<any> {
    return this.http.post<any>(this._urls.TOURS, tour)
  }

  public deleteTour(tourUUID: string): Observable<any> {
    return this.http.delete<any>(this._urls.TOURS_BY_UUID(tourUUID))
  }

  public closeTour(tourUUID: string): Observable<any> {
    return this.http.post<any>(this._urls.CLOSE_TOURS_BY_UUID(tourUUID), {})
  }

  public optimizeTour(tourUUID: string): Observable<any> {
    return this.http.post<any>(this._urls.OPTIMIZE_TOURS_BY_UUID(tourUUID), {})
  }

}
