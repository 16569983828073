<!-- <h1 mat-dialog-title>
  {{data.name}}
</h1> -->
<div mat-dialog-content>

  <ul class="co-simple-list">
    <li><b>{{'common.name' | translate}}:</b> {{data.name}}</li>
    <li><b>{{'common.locality' | translate}}:</b> {{data.zip_code}} {{data.location}}</li>
    <li><b>{{'common.phone' | translate}}:</b> {{data.phone}}</li>
    <li><b>{{'common.on_the_platform_since' | translate}}:</b> {{data.created_at | humanDatePipe: false}}</li>
  </ul>

  <!-- <pre>{{data | json}}</pre> -->
</div>

<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button class="btn-primary light primary-hover-animation" mat-dialog-close="">{{'common.close' | translate}}</button>
</div>
