<div class="container-fluid">

    <div class="d-flex justify-content-between">
        <h1>Shipments</h1>
        <a mat-icon-button [routerLink]="[]" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </a>
    </div>

    <div>
        <table *ngIf="!!items && items.length" style="width: 100%" class="table table-striped">
            <thead>
                <td>{{'common.number' | translate}}</td>
                <td>{{'common.build' | translate}} {{'common.creation_date' | translate}}</td>
                <!-- <td>Sender</td>
                <td>Client</td> -->
                <td>{{'common.organization' | translate}}</td>
            </thead>

            <tbody>

                <tr *ngFor="let item of items">
                    <td>
                        <a routerLink="/main/admin/shipments/{{item.uuid}}">
                            {{item.number | shipmentNumberPipe}}
                        </a>
                    </td>
                    <td>{{item.created_at | humanDatePipe}}</td>

                    <!-- <td><span *ngIf="!!item.sender_object">{{item.sender_object.name}}</span></td> -->
                    <!-- <td><span *ngIf="!!item.client_object">{{item.client_object.name}}</span></td> -->
                    <td><span *ngIf="!!item.organization_object">
                            <a routerLink="/main/admin/organizations/{{item.organization_object.uuid}}">
                                {{item.organization_object.name}}
                            </a>
                        </span></td>
                </tr>


            </tbody>
        </table>
    </div>

    <!-- <mat-card class="mt-3">
        <mat-card-content>
            <pre>{{items | json}}</pre>
        </mat-card-content>
    </mat-card> -->


</div>