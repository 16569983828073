<section class="section wf-section">
  <div class="div-block">

    <div class="kachel">
      <div style="height: 46px;" class="container d-flex justify-content-center align-items-center gap-4 mt-4">

        <app-language-changer></app-language-changer>
        
      </div>
      <h1 class="heading-3 text-center mb-4">{{'common.login' | translate}}</h1>
      <div class="form-block w-form">
        <form [formGroup]="form" class="pt-2">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'common.username' | translate}}</mat-label>
            <input type="text" matInput formControlName="username">
          </mat-form-field>

          <mat-form-field class="w-100" appearance="outline">
            <mat-label>{{'common.password'| translate}}</mat-label>
            <input type="password" matInput formControlName="password">
          </mat-form-field>
          <div class="alert alert-warning" *ngIf="failedLogin">
            {{'login.failed_login_text'| translate}}
          </div>

          <div class="mt-2">
            <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>

            <button (click)="onSubmit('carrier')" class="button" *ngIf="!isLoading"
              style="width: 100%;">{{'common.login' | translate}}</button>
          </div>

          <div class="mt-5 text-center">
            <a routerLink="/register">{{'common.register' | translate}}</a>
          </div>
          <br>
          <div class="text-center">
            <a routerLink="/forgot-password">{{'login.forgot_password' | translate}}</a>
          </div>

          <hr style="color: grey;margin: 20px;">
          <div style="display: flex; justify-content: center;">
            <small style="color: #2432394c;">v{{versions.hash}} - {{versions.date | date: "yyyy-MM-dd HH:mm"}}</small>
          </div>

        </form>
      </div>
    </div>
  </div>
</section>
