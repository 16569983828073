import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmbedUrlConstantsService } from './embed-url-constants.service';
import { IPrice } from 'projects/shared/price.interface';


@Injectable({ providedIn: 'root' })
export class EmbedWizardService {

  public siteKey = '';

  constructor(
    private http: HttpClient,
    private urls: EmbedUrlConstantsService,
  ) {}

  public verify_sitekey(sitekey: string): Observable<any> {
    return this.http.post<any>(this.urls.VERIFY_SITEKEY, {sitekey});
  }

  public calculate_price(data: any): Observable<IPrice> {
    return this.http.post<IPrice>(this.urls.CALCULATE_PRICE, data);
  }

  public request_shipment(data: any): Observable<IPrice> {
    return this.http.post<IPrice>(this.urls.REQUEST_SHIPMENT, data);
  }
 
}
