import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ShipmentCommentModal } from 'projects/webapp/app/components/shipment-comment-modal.component';
import { ShipmentEditPricingModalComponent } from 'projects/webapp/app/components/shipment-edit-pricing-modal/shipment-edit-pricing-modal.component';
import { UserSelectorModalComponent } from 'projects/webapp/app/components/user-selector-modal/user-selector.component';
import { environment } from 'projects/webapp/app/environments/environment';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { IShipmentComment } from 'projects/webapp/app/interfaces/shipment-comment.interface';
import { ICarrierShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { ShipmentStatus } from 'projects/webapp/app/services/shipments.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-admin-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class AdminShipmentDetailsComponent implements OnInit {

  isLoading = false;
  shipmentUUID: string | null = null;
  shipment: ICarrierShipment | null = null;

  environment = environment;
  privateShipmentComments: IShipmentComment[] = []
  publicShipmentComments: IShipmentComment[] = []

  shipmentStatus = ShipmentStatus

  ownOrganization: IOrganization | null = null;

  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private carrierShipmentsService: CarrierShipmentsService,
    private adminService: AdminService,
    private dialog: MatDialog,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
    this.translateService.get('common.dispach_details').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    this.route.params.subscribe(params => {
      if (!!params && params['shipmentUUID']) {
        this.shipmentUUID = params['shipmentUUID'];
        this.refreshShipment();
      }
    })
  }

  async refreshShipment() {
    if (this.shipmentUUID) {
      this.refreshShipmentCore()
      // this.refreshPrivateComments()
      // this.refreshPublicComments()
    }
  }

  async refreshShipmentCore() {
    this.shipment = await lastValueFrom(this.adminService.getShipment(this.shipmentUUID as any));
  }

  async refreshPrivateComments() {
    this.privateShipmentComments = await lastValueFrom(this.carrierShipmentsService.getPrivateCommentsShipment(this.shipmentUUID as any))
  }

  async refreshPublicComments() {
    this.publicShipmentComments = await lastValueFrom(this.carrierShipmentsService.getPublicCommentsShipment(this.shipmentUUID as any))
  }

  editShipmentStatus() {
    const data = this.shipment?.status
    // this.dialog.open(ShipmentStatusModal, { data }).afterClosed().subscribe(async (val) => {
    //   if (!!val && this.shipment?.uuid) {
    //     this.shipment.status = val;
    //     await lastValueFrom(this.carrierShipmentsService.updateShipment(this.shipment.uuid, this.shipment))
    //     this.refreshShipmentCore();
    //   }
    // })
  }

  editCurrentDriver() {
    const data = this.shipment?.assigned_driver_object
    this.dialog.open(UserSelectorModalComponent, { data }).afterClosed().subscribe(async (val) => {
      if (!!val && this.shipment?.uuid) {
        this.shipment.assigned_driver = val.id;
        await lastValueFrom(this.carrierShipmentsService.updateShipment(this.shipment.uuid, this.shipment))
        this.refreshShipmentCore();
      }
    })
  }

  toBeImplemented() {
    alert('coming soon...')
  }

  onAddPrivateComment() {
    this.dialog.open(ShipmentCommentModal).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        await lastValueFrom(this.carrierShipmentsService.addPrivateCommentShipment(this.shipment?.uuid, val));
        this.refreshPrivateComments()
      }
    })
  }

  onAddPublicComment() {
    this.dialog.open(ShipmentCommentModal).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        await lastValueFrom(this.carrierShipmentsService.addPublicCommentShipment(this.shipment?.uuid, val));
        this.refreshPublicComments()
      }
    })
  }

  onUpdateComment(comment: IShipmentComment) {
    const data = comment.content;
    this.dialog.open(ShipmentCommentModal, { data }).afterClosed().subscribe(async val => {
      if (!!val && this.shipment?.uuid) {
        comment.content = val;
        await lastValueFrom(this.carrierShipmentsService.updateCommentsShipment(this.shipment?.uuid, comment.uuid, comment))
        this.refreshPrivateComments();
        this.refreshPublicComments();
      }
    })
  }

  async onDeleteComment(comment: IShipmentComment) {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_commentary_cancelation'));

    if (confirm(confirmMessage) && this.shipment?.uuid) {
      await lastValueFrom(this.carrierShipmentsService.deleteCommentsShipment(this.shipment?.uuid, comment.uuid));
      this.refreshPrivateComments();
      this.refreshPublicComments();
    }
  }

  // async onChangePriceMode(event: any) {
  //   console.log(event)
  //   if (this.shipment) {
  //     this.shipment.manual_price = event.checked;
  //     if (this.shipment.uuid) {
  //       await lastValueFrom(this.carrierShipmentsService.updateShipment(this.shipment.uuid, this.shipment))
  //     }
  //   }
  // }

  // openEditPricingModal() {
  //   if (this.shipment) {
  //     const data = {
  //       base_price: this.shipment.base_price,
  //       options: this.shipment?.options,
  //     }
  //     this.dialog.open(ShipmentEditPricingModalComponent, { data }).afterClosed().subscribe(async val => {
  //       if (!!val && this.shipmentUUID && this.shipment) {
  //         console.log(val)
  //         this.shipment.base_price = val.base_price
  //         this.shipment.options = val.options
  //         // TODO: add event to change price
  //         await lastValueFrom(this.carrierShipmentsService.updateShipmentPrice(this.shipmentUUID, this.shipment))
  //       }
  //     })
  //   }
  // }

  async cancelShipment() {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_shipment_cancelation'));
    const successMessage = await lastValueFrom(this.translateService.get('toastr.shipment_cancelled'));

    if (confirm(confirmMessage) && this.shipmentUUID) {
      await lastValueFrom(this.carrierShipmentsService.cancelShipment(this.shipmentUUID));
      this.toastrService.info(successMessage)
      this.refreshShipment();
    }
  }
}
