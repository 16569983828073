<div style="display: flex; flex-direction: row; justify-content: space-between;">

    <h3></h3>
    <div>
        <button mat-icon-button color="primary" (click)="onAddKey()" style="margin-right: 15px;">
            <mat-icon>add</mat-icon>
        </button>
        <button mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>


<div *ngIf="!isLoading">

    <div class="alert alert-info" *ngIf="client">{{'common.your_customers_can_submit_shipments_to_you' | translate}}</div>

    <div class="alert alert-info" *ngIf="!client">{{'common.you_can_create_customer_specific_shipment_capture_pages_here' | translate}}</div>

    <table *ngIf="!!wizardKeys && wizardKeys.length" style="width: 100%" class="table table-striped">
        <thead>
            <td>{{'common.keys' | translate}}</td>
            <td>{{'common.creation_date' | translate}}</td>
            <td translate>common.client</td>
            <td>{{'common.usage' | translate}}</td>
            <td translate>common.active</td>
            <td></td>
        </thead>

        <tbody>
            <tr *ngFor="let key of wizardKeys">

                <td>
                    <a href="{{environment.uiDomain}}/client/wizard?token={{key.token}}" target="_blank">{{environment.uiDomain}}/client/wizard?token={{key.token}}</a>
                </td>
                <td>{{key.created_at | date: 'yyyy-MM-dd HH:mm'}}</td>
                <td>
                    <span *ngIf="key.client_object; else noClient">
                        {{key.client_object.name }}
                    </span>
                    <ng-template #noClient>-</ng-template>
                </td>
                <td>{{key.purpose}}</td>
                <td>{{key.active | yesNoPipe}}</td>

                <td style="display: flex; justify-content: end">

                    <button mat-icon-button (click)="onEditKey(key)">
                        <mat-icon>edit</mat-icon>
                    </button>

                    <button mat-icon-button (click)="onDeleteKey(key)">
                        <mat-icon>delete</mat-icon>
                    </button>

                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="text-center mt-5" *ngIf="!isLoading && wizardKeys.length == 0">
    <div class="alert alert-warning">{{'common.no_URL_for_shipment_capture' | translate}}</div>
</div>


<!-- <pre>{{client | json}}</pre> -->