import { Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { versions } from 'projects/versions';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { debounceTime, tap } from 'rxjs/operators';
import { IPrice } from 'projects/shared/price.interface';
import { ClientWizardService } from 'projects/webapp/app/services/client-wizard.service';
import { ToastrService } from 'ngx-toastr';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { environment } from 'projects/webapp/app/environments/environment';
import { TranslateService } from '@ngx-translate/core';


export const generatePacketFormGroup = () => {
  return new FormGroup({
    type: new FormControl(1, Validators.required),
    count: new FormControl(1, Validators.compose([Validators.required, Validators.min(1)])),

    length: new FormControl(120, Validators.compose([Validators.required, Validators.min(1)])),
    width: new FormControl(80, Validators.compose([Validators.required, Validators.min(1)])),
    height: new FormControl(40, Validators.compose([Validators.required, Validators.min(1)])),

    weight: new FormControl(50, Validators.compose([Validators.required, Validators.min(1)])),

    name: new FormControl('', Validators.required),
  });
}

@Component({
  selector: 'app-client-wizard',
  templateUrl: './client-wizard.component.html',
  styleUrls: ['./client-wizard.component.scss']
})
export class ClientWizardComponent implements OnInit {

  price: IPrice | null = null;;

  modalRef?: BsModalRef;
  environment = environment;

  public draftShipmentForm = new FormGroup({

    pickup_company: new FormControl(''),
    pickup_name: new FormControl(''),
    pickup_address: new FormControl('', Validators.required),
    pickup_zip: new FormControl('', Validators.required),
    pickup_location: new FormControl('', Validators.required),
    pickup_notification: new FormControl(false, Validators.required),
    pickup_phone: new FormControl(''),
    pickup_date: new FormControl('', Validators.required),
    pickup_date_flexible: new FormControl(false, Validators.required),
    pickup_address_complete: new FormControl(''),
    pickup_crane_needed: new FormControl(false, Validators.required),

    delivery_company: new FormControl(''),
    delivery_name: new FormControl(''),
    delivery_address: new FormControl('', Validators.required),
    delivery_zip: new FormControl('', Validators.required),
    delivery_location: new FormControl('', Validators.required),
    delivery_notification: new FormControl(false, Validators.required),
    delivery_phone: new FormControl(''),
    delivery_date: new FormControl('', Validators.required),
    delivery_date_flexible: new FormControl(false, Validators.required),
    delivery_address_complete: new FormControl(''),
    delivery_crane_needed: new FormControl(false, Validators.required),

    packets: new FormArray([generatePacketFormGroup()]),

    dangerous_goods: new FormControl(false, Validators.required),
    dangerous_points: new FormControl(null),
    dangerous_amount: new FormControl(null),
    dangerous_un_number: new FormControl(null),

    empties: new FormControl(false, Validators.required),
    empties_eur_palets: new FormControl(0),
    empties_sbb_frames: new FormControl(0),
    empties_sbb_covers: new FormControl(0),

    insurance: new FormControl(false, Validators.required),
    value: new FormControl(0, Validators.required),

  });

  public packetItemForms: FormArray = this.draftShipmentForm.get('packets') as FormArray;

  @ViewChild('successModal') successModal!: ElementRef;

  token = '';
  tenantName = '';
  clientName = '';
  isLoading = true;
  isLoadingPricing = false;
  isLoadingProcessing = false;
  versions = versions;
  initialValue = {}
  shipment: Partial<IShipment> | null = null;

  currentShipmentKey = 'currentShipment'

  constructor(
    private route: ActivatedRoute,
    private clientWizardService: ClientWizardService,
    private modalService: BsModalService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    this.route.queryParams
      .subscribe(async (params) => {
        console.log(params);
        this.token = params['token'];
        console.log(this.token);
        this.isLoading = false;
        if (!!this.token) {
          this.clientWizardService.token = this.token
          try {
            const response = await lastValueFrom(this.clientWizardService.verify_token(this.token));
            console.log(response)
            this.tenantName = response.tenant_name
            this.clientName = response.client_name
          } catch (error) {
            console.error(error);
          }
        }
      });

    const val = localStorage.getItem(this.currentShipmentKey);
    if (!!val) {
      console.log('had a current shipment in the session')
      this.draftShipmentForm.patchValue(JSON.parse(val))
      this.initialValue = this.draftShipmentForm.value
      this.fillAddress(val);

      this.fetchPricing()
    }

    this.draftShipmentForm.valueChanges.pipe(
      tap(() => (this.isLoadingPricing = true)),
      debounceTime(300)
    ).subscribe(val => {
      this.fillAddress(val);

      console.log(val)
      localStorage.setItem(this.currentShipmentKey, JSON.stringify(val));
      this.fetchPricing();
      this.initialValue = this.draftShipmentForm.value
    });
  }

  private fillAddress(val: any) {
    const hasPickupChange = [
      'pickup_company',
      'pickup_name',
      'pickup_address',
      'pickup_zip',
      'pickup_location',
    ].some(key => (this.draftShipmentForm.value as any)[key] != (this.initialValue as any)[key])
    if (hasPickupChange) {
      const line = [val.pickup_company, val.pickup_name, val.pickup_address, val.pickup_location].filter(function (val) {
        return val;
      }).join(', ');
      this.draftShipmentForm.patchValue({ pickup_address_complete: line })
    }

    const hasDeliveryChange = [
      'delivery_company',
      'delivery_name',
      'delivery_address',
      'delivery_zip',
      'delivery_location',
    ].some(key => (this.draftShipmentForm.value as any)[key] != (this.initialValue as any)[key])
    if (hasDeliveryChange) {
      const line = [val.delivery_company, val.delivery_name, val.delivery_address, val.delivery_location].filter(function (val) {
        return val;
      }).join(', ');

      this.draftShipmentForm.patchValue({ delivery_address_complete: line })
    }
  }

  async fetchPricing() {
    try {
      const response = await lastValueFrom(this.clientWizardService.calculate_price(this.draftShipmentForm.value));
      this.price = response;
      this.isLoadingPricing = false;
    } catch (error) {
      console.error(error)
    }
  }

  resetForm() {
    this.draftShipmentForm.reset();
  }

  public addPacketItem(): void {
    const cargoItemsCount = this.packetItemForms.controls.length;
    const cargoItemForm = generatePacketFormGroup();
    this.packetItemForms.insert(cargoItemsCount, cargoItemForm);
  }

  public removePacketItem(index: number): void {
    this.packetItemForms.removeAt(index);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  async sendRequest() {
    console.log('sendRequest')
    this.draftShipmentForm.markAllAsTouched();
    if (!this.draftShipmentForm.valid) {
      const errorMessage = await lastValueFrom(this.translateService.get('toastr.incomplete_input'));
      console.log(this.draftShipmentForm.errors);
      this.toastrService.error(errorMessage)
      return
    }

    console.log('sendRequest')
    this.isLoadingProcessing = true;

    try {
      const response = await lastValueFrom(this.clientWizardService.request_shipment(this.draftShipmentForm.value))
      this.shipment = response;
      this.modalRef?.hide()
      this.openModal(this.successModal as any)
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingProcessing = false;
    }
  }

  closeSuccess() {
    this.modalRef?.hide();
    localStorage.removeItem(this.currentShipmentKey)
    location.reload()
    // this.draftShipmentForm.reset();
  }
}
