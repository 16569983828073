import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { generateShipmentPickupFormGroup } from 'projects/shared/generators';
import { PanelType } from 'projects/webapp/app/interfaces/panel-types.interface';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { lastValueFrom, take } from 'rxjs';


@Component({
  selector: 'app-shipment-edit-pickup',
  templateUrl: './shipment-edit-pickup.component.html',
  styleUrls: ['./shipment-edit-pickup.component.scss']
})
export class ShipmentEditPickupComponent implements OnInit {
  public isLoading = false;
  public shipmentUUID: string | null = null;
  type = 'pickup'
  public shipmentForm = generateShipmentPickupFormGroup()
  panelType: PanelType = 'carrier'


  constructor(
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private carrierShipmentsService: CarrierShipmentsService,
  ) {
    this.translate.get('common.edit_pickup_information_page_title').subscribe(val=>{
      this.title.setTitle(val)
    })
  }

  async ngOnInit() {
    this.route.data.pipe(take(1)).subscribe(val => {
      this.panelType = val['panelType']
    });

    this.route.params.subscribe(async (val) => {
      if (!!val && val['shipmentUUID']) {
        this.shipmentUUID = val['shipmentUUID']
        this.refresh();
      }
    })
  }

  async refresh() {
    if (this.shipmentUUID) {
      const response = await lastValueFrom(this.carrierShipmentsService.getShipment(this.shipmentUUID)) as any
      this.shipmentForm.patchValue({ ...response })
    }
  }

  async save() {
    if (this.shipmentUUID) {
      await lastValueFrom(this.carrierShipmentsService.updateShipment(this.shipmentUUID, this.shipmentForm.value as any))
      this.router.navigate(['/main/carrier/shipments/' + this.shipmentUUID])
    }
  }
}
