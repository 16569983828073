<footer class="mt-4 mb-4">
        <div class="text-center">
                <div class="px-5 py-2">
                        <a href="https://stats.uptimerobot.com/43xPOhzOrx" target="_blank"
                                class="text-base text-gray-500 hover:text-gray-900">{{'common.status' | translate}}</a>
                </div>

                <p class="mt-8 text-center text-base text-gray-500">© 2023 Neptun Design GmbH. All rights
                        reserved.
                </p>
        </div>
</footer>
<!-- TODO: Add swiss made software here https://www.swissmadesoftware.org/about.html-->
