import { Component, Input, OnInit } from '@angular/core';
import { IShipmentEvent } from '../../services/shipments.service';


@Component({
  selector: 'app-shipment-events',
  templateUrl: './shipment-events.component.html',
  styleUrls: ['./shipment-events.component.scss']
})
export class ShipmentEventsComponent implements OnInit {

  @Input() events: IShipmentEvent[] = [];

  constructor(
  ) { }

  async ngOnInit() {
  }
}
