import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminUrlConstantsService {

  environment = environment
  baseUrl = this.environment.baseUrl

  public DASHBOARD_NUMBERS = `${this.environment.baseUrl}/api/v1/admin/dashboard-numbers`;

  public ORGANIZATIONS = `${this.environment.baseUrl}/api/v1/admin/organizations`;
  public ORGANIZATIONS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/organizations/${uuid}`;
  public IMPERSONATE_ORGANIZATIONS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/organizations/${uuid}/impersonate`;
  public COMMENTS_FOR_ORGANIZATIONS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/organizations/${uuid}/comments`;

  public USERS = `${this.environment.baseUrl}/api/v1/admin/users`;
  public USERS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/users/${uuid}`;

  public SHIPMENTS = `${this.environment.baseUrl}/api/v1/admin/shipments`;
  public SHIPMENTS_BY_UUID = (uuid: string) => `${this.environment.baseUrl}/api/v1/admin/shipments/${uuid}`;

  public AUCTIONS = `${this.environment.baseUrl}/api/v1/admin/auctions`;
  public TRANSACTIONS = `${this.environment.baseUrl}/api/v1/admin/transactions`;

  public EVENTS = `${this.environment.baseUrl}/api/v1/admin/events`;

  public SHIPMENT_SEARCH = `${this.environment.baseUrl}/api/v1/admin/shipment-search`;

  constructor() { }
}
