import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'cargoItemName' })
export class CargoItemName implements PipeTransform {
  constructor(private translate: TranslateService) { }

  transform(type: number): string {
    switch (type) {
      case 1 || '1':
        return this.translate.instant('common.europallet')
      case 2 || '2':
        return this.translate.instant('common.disposable_pallet')
      case 3 || '3':
        return this.translate.instant('common.carton')
      case 4 || '4':
        return this.translate.instant('common.another')
      default:
          return '-'
    }
  }
}
