
export enum TokenType {
    UNKNOWN = 0,
    API = 1,
    WIZARD = 2,
} 

export interface IApiToken {
    purpose: string;
    token: string;
    created_at: string;
    updated_at: string;
    active: boolean;
    client?: number;
    client_object?: {
        uuid: string;
        name: string;
    },
    type: TokenType
}

