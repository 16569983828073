import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth.service';
import { IVehicle } from '../../services/fleet.service';


@Component({
  selector: 'app-vehicle-editor',
  templateUrl: './vehicle-editor.component.html',
  styleUrls: ['./vehicle-editor.component.scss']
})
export class VehicleEditorComponent implements OnInit {

  form: FormGroup = new FormGroup({
    number: new FormControl('',),
    model: new FormControl(''),
    max_weight: new FormControl(0, ),
    max_ground_spaces: new FormControl(0, ),
    description: new FormControl(''),
  });

  constructor(
    public dialogRef: MatDialogRef<VehicleEditorComponent>,
    public authService: AuthService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: IVehicle,
  ) { }

  async ngOnInit() {
    this.form.patchValue(this.data)
  }

  save() {
    this.form.markAllAsTouched()
    if (!this.form.valid) {
      return
    }
    this.dialogRef.close(this.form.value)
  }

}
