<h1 mat-dialog-title>
  <span>{{'common.invoice_status' | translate}}</span>
</h1>
<div mat-dialog-content>

    <mat-form-field appearance="outline" style="width: 100%" class="mt-4">
      <mat-label>{{'common.status' | translate}}</mat-label>
      <mat-select [(ngModel)]="data.status">
        <mat-option *ngFor="let key of keys" [value]="key">
          {{key | invoiceStatusPipe}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  <!-- <pre>{{data | json}}</pre> -->

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">{{'common.close' | translate}}</button>
  <button mat-button color="secondary" [mat-dialog-close]="data">{{'common.save' | translate}}</button>
</div>