import { FormGroup, FormArray, FormControl, Validators, AbstractControl, ValidatorFn } from "@angular/forms";


export const getNextWorkday = (holidays: string[]) => {
  const currentDate = new Date();
  const oneDay = 24 * 60 * 60 * 1000; // One day in milliseconds

  // Skip weekends (Saturday: 6, Sunday: 0) and holidays
  while (currentDate.getDay() === 0 || currentDate.getDay() === 6 || holidays.includes(currentDate.toISOString().split('T')[0])) {
    currentDate.setTime(currentDate.getTime() + oneDay);
  }

  // Find the next workday
  while (currentDate.getDay() === 0 || currentDate.getDay() === 6 || holidays.includes(currentDate.toISOString().split('T')[0])) {
    currentDate.setTime(currentDate.getTime() + oneDay);
  }

  // Format the next workday and the day after that
  const nextWorkday = currentDate.toISOString().split('T')[0];

  currentDate.setTime(currentDate.getTime() + oneDay);
  const dayAfterNext = currentDate.toISOString().split('T')[0];

  return { nextWorkday, dayAfterNext };
}

// const holidays = ["2023-08-01"];
const holidays: string[] = [];

export const getDefaultPickupDate = () => {
  // TODO: + 24h and no weekend
  const { nextWorkday, dayAfterNext } = getNextWorkday(holidays);
  return nextWorkday
  // return new Date().toISOString().split('T')[0]
}

export const getDefaultDeliveryDate = () => {
  // TODO: + 24h and no weekend
  const { nextWorkday, dayAfterNext } = getNextWorkday(holidays);
  return dayAfterNext;
}

export function dateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const today = new Date().getTime();

    if (!(control && control.value)) {
      // if there's no control or no value, that's ok
      return null;
    }

    return null;
  }
}


export const generatePacketFormGroup = () => {
  return new FormGroup({
    type: new FormControl(1, Validators.required),
    count: new FormControl(1, Validators.compose([Validators.required, Validators.min(1)])),

    length: new FormControl(120, Validators.compose([Validators.required, Validators.min(1)])),
    width: new FormControl(80, Validators.compose([Validators.required, Validators.min(1)])),
    height: new FormControl(40, Validators.compose([Validators.required, Validators.min(1)])),

    weight: new FormControl(50, Validators.compose([Validators.required, Validators.min(1)])),

    name: new FormControl('', Validators.required),
  });
}


export const generateShipmentFormGroup = () => {
  return new FormGroup({
    client: new FormControl(''),

    pickup_company: new FormControl(''),
    pickup_name: new FormControl(''),
    pickup_address: new FormControl('', Validators.required),
    pickup_zip: new FormControl('', Validators.required),
    pickup_location: new FormControl('', Validators.required),
    pickup_reference: new FormControl(''),
    pickup_notification: new FormControl(false, Validators.required),
    pickup_phone: new FormControl(''),
    pickup_date: new FormControl(getDefaultPickupDate(), [Validators.required, dateValidator()]),
    pickup_date_flexible: new FormControl(false, Validators.required),
    pickup_crane_needed: new FormControl(false, Validators.required),
    pickup_access_restriction: new FormControl(false, Validators.required),

    delivery_company: new FormControl(''),
    delivery_name: new FormControl(''),
    delivery_address: new FormControl('', Validators.required),
    delivery_zip: new FormControl('', Validators.required),
    delivery_location: new FormControl('', Validators.required),
    delivery_reference: new FormControl(''),
    delivery_notification: new FormControl(false, Validators.required),
    delivery_phone: new FormControl(''),
    delivery_date: new FormControl(getDefaultDeliveryDate(), [Validators.required, dateValidator()]),
    delivery_date_flexible: new FormControl(false, Validators.required),
    delivery_crane_needed: new FormControl(false, Validators.required),
    delivery_access_restriction: new FormControl(false, Validators.required),

    packets: new FormArray([generatePacketFormGroup()]),

    dangerous_goods: new FormControl(false, Validators.required),
    dangerous_points: new FormControl(null),
    dangerous_amount: new FormControl(null),
    dangerous_un_number: new FormControl(null),

    empties: new FormControl(false, Validators.required),
    empties_eur_palets: new FormControl(0),
    empties_sbb_frames: new FormControl(0),
    empties_sbb_covers: new FormControl(0),

    insurance: new FormControl(false, Validators.required),
    value: new FormControl(0, Validators.required),
  });
}


export const generateShipmentPickupFormGroup = () => {
  return new FormGroup({
    pickup_company: new FormControl(''),
    pickup_name: new FormControl(''),
    pickup_address: new FormControl('', Validators.required),
    pickup_zip: new FormControl('', Validators.required),
    pickup_location: new FormControl('', Validators.required),
    pickup_reference: new FormControl(''),
    pickup_notification: new FormControl(false, Validators.required),
    pickup_phone: new FormControl(''),
    pickup_date: new FormControl('', Validators.required),
    // TODO: add missing properties
  });
}

export const generateShipmentDeliveryFormGroup = () => {
  return new FormGroup({
    delivery_company: new FormControl(''),
    delivery_name: new FormControl(''),
    delivery_address: new FormControl('', Validators.required),
    delivery_zip: new FormControl('', Validators.required),
    delivery_location: new FormControl('', Validators.required),
    delivery_reference: new FormControl(''),
    delivery_notification: new FormControl(false, Validators.required),
    delivery_phone: new FormControl(''),
    delivery_date: new FormControl('', Validators.required),
    // TODO: add missing properties
  });
}


export const generateShipmentCargoItemsFormGroup = () => {
  return new FormGroup({
    packets: new FormArray([generatePacketFormGroup()]),
  });
}

export const generateShipmentEmptiesFormGroup = () => {
  return new FormGroup({
    empties: new FormControl(false, Validators.required),
    empties_eur_palets: new FormControl(0),
    empties_sbb_frames: new FormControl(0),
    empties_sbb_covers: new FormControl(0),
  });
}
