<mat-form-field appearance="outline" style="width: 100%" *ngIf="!!possibleVehicles && possibleVehicles.length > 0">
    <mat-label>{{'common.vehicle' | translate}}</mat-label>
    <input type="text" matInput [(ngModel)]="selectedVehicle" [matAutocomplete]="auto" [formControl]="userFormControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
        (optionSelected)='selected($event.option.value)'>
        <mat-option *ngFor="let option of filteredVehicles" [value]="option">
            {{getUserName(option)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<div class="alert alert-info" role="alert" style="width: 100%" *ngIf="!possibleVehicles || possibleVehicles.length == 0">
    {{'common.no_vehicles_yet' | translate}} <a routerLink="/main/carrier/settings/fleet">{{'common.vehicle_management' | translate}}</a> {{'common.create' | translate}}
</div>
