import { IFilterOutput } from "./filter.interface";

export const onSorting = (option: string, items: any[]) => {
  console.log(option);
  items.sort((a, b) => {
    const item_A: any = a;
    const item_B: any = b;
    if (option == 'pickup_date' || option == 'delivery_date') {
      return (new Date(item_A[option]) as any) - (new Date(item_B[option]) as any);
    } else {
      return item_A[option] - item_B[option];
    }
  });
}

export const onFiltering = (filterOutput: IFilterOutput, items: any[], filteredItems: any []) => {
    console.log(filterOutput)
    filteredItems = items;

    for (let index = 0; index < filterOutput.filters.length; index++) {
      const element = filterOutput.filters[index];
      filteredItems = filteredItems.filter(shipment => {
        // Special case meta data
        if (element.filterTags[0].title.includes('Meta data:')) {
          if (!!shipment.meta_data[element.filterTags[0].key]) {
            if (element.filterTags[1].title == '=') {
              if (shipment.meta_data[element.filterTags[0].key] == element.filterTags[2].value) {
                return true;
              }
              return false;
            } else if (element.filterTags[1].title == '!=') {
              if (shipment.meta_data[element.filterTags[0].key] != element.filterTags[2].value) {
                return true;
              }
              return false;
            }
            console.error('we should not arrive here')
            return false
          }
          // Special case has tour
        } else if (element.filterTags[0].key == 'has_tour') {
          if (element.filterTags[1].title == '=') {
            return !!shipment.assigned_tour_object == element.filterTags[2].value
          } else if (element.filterTags[1].title == '!=') {
            return !!shipment.assigned_tour_object != element.filterTags[2].value
          }
        } else {
          // check if shipment has this key
          if (shipment.hasOwnProperty(element.filterTags[0].key)) {
            const thisShipment: any = shipment
            if (element.filterTags[1].title == '=') {
              if (thisShipment[element.filterTags[0].key] == element.filterTags[2].value) {
                return true;
              }
              return false;
            } else if (element.filterTags[1].title == '!=') {
              if (thisShipment[element.filterTags[0].key] != element.filterTags[2].value) {
                return true;
              }
              return false;
            }
          } else {
            return false
          }
        }
        return false
      })
    }
  return filteredItems;
}
