import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDetailedShipment } from 'projects/webapp/app/interfaces/shipment.interface';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { ShipperShipmentsService } from 'projects/webapp/app/services/shipper-shipments.service';
import { lastValueFrom } from 'rxjs';
import { OrganizationOverviewModalComponent } from '../../carrier/organization-overview-modal/organization-overview-modal.component';

@Component({
  selector: 'app-shipper-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipperShipmentListComponent implements OnInit {

  shipments: IDetailedShipment[] = [];
  isLoading = false;

  constructor(
    private shipperShipmentsService: ShipperShipmentsService,
    private dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    this.isLoading = true;
    this.shipments = await lastValueFrom(this.shipperShipmentsService.getShipments())
    this.isLoading = false;
  }

  openBuyerOrgDetails(arg0: any) {
    this.dialog.open(OrganizationOverviewModalComponent, { data: arg0 })
  }
}
