import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { versions } from 'projects/versions';
import { IAdminShipmentTransaction } from 'projects/webapp/app/interfaces/transaction.interface';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-admin-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class AdminTransactionsComponent implements OnInit {
  isLoading = true;
  versions = versions;
  items: IAdminShipmentTransaction[] = []

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService
  ) {
  }

  async ngOnInit() {
    this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.items = await lastValueFrom(this.adminService.getTransactions());
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }
}
