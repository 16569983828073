import { Component, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'projects/about/app/environments/environment';
import { IAdminOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { IAdminDetailedUser } from 'projects/webapp/app/interfaces/user.interface';
import { AdminService } from 'projects/webapp/app/services/admin.service';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { IDetailedUser } from 'projects/webapp/app/services/users.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-admin-organization-details',
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss']
})
export class AdminOrganizationDetailsComponent implements OnInit {
  isLoading = true;

  organizationUUID: string | null = null;
  organization: IAdminOrganization | null = null;
  users: IAdminDetailedUser[] = []
  comments: any[] = []
  newComment = ""

  environment = environment

  constructor(
    private adminService: AdminService,
    private spinnerService: NgxSpinnerService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private organizationsService: OrganizationsService,
    private translateService: TranslateService,
  ) { }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      if (!!params && params['organizationUUID']) {
        this.organizationUUID = params['organizationUUID'];
        this.refresh();
      }
    })
  }

  async refresh() {
    if (this.organizationUUID) {
      try {
        this.spinnerService.show()
        this.isLoading = true;
        this.organization = await lastValueFrom(this.adminService.getOrganization(this.organizationUUID))
        await this.refreshUsers()
        await this.refreshComments()
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide()
        this.isLoading = false;
      }
    }
  }

  async refreshUsers() {
    this.users = await lastValueFrom(this.adminService.getUsers({ organizationUUID: this.organizationUUID }))
  }

  async refreshComments() {
    if (this.organizationUUID) {
      this.comments = await lastValueFrom(this.adminService.getComments(this.organizationUUID))
    }
  }

  async onChangeOrgProperty(property: string, event: MatCheckboxChange) {
    if (this.organizationUUID && this.organization && this.organization.hasOwnProperty(property)) {
      const infoMessage = await lastValueFrom(this.translateService.get('toastr.tour_updated'));

      const a = this.organization as any
      a[property] = event.checked
      await lastValueFrom(this.adminService.updateOrganization(this.organizationUUID, a))
      this.toastrService.info(infoMessage)
      this.refresh()
    }
  }

  async impersonateOrganization() {
    if (this.organizationUUID) {
      await lastValueFrom(this.adminService.impersonateOrganizationUser(this.organizationUUID));
      await lastValueFrom(this.organizationsService.getOwnOrganization())
      this.toastrService.info('impersonated')
    }
  }

  async onAddNewComment() {
    if (this.organizationUUID) {
      await lastValueFrom(this.adminService.addComment(this.organizationUUID, { content: this.newComment }));
      this.newComment = '';
      this.refreshComments()
    }

  }
}
