<div class="container-fluid mt-1">
  <div class="row">
    <div class="col-12">
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <h1>Inserate</h1>
        <div style="display: flex; align-items: center;">
          <mat-spinner [diameter]="20" style="margin: 12px" *ngIf="isLoading"> </mat-spinner>
          <button mat-icon-button color="primary" (click)="refresh()">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="text-center mt-5" *ngIf="!isLoading && ads.length == 0">
        <div class="alert alert-info">{{'common.currently_none' | translate}} {{'carrier_dashboard.ads.main_title' | translate}}...</div>
      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6 col-xl-4" *ngFor="let ad of ads">
      <div class="mb-2">
        <app-advertisement-card [advertisement]="ad" [allowEdit]="false" (onOpenDetails)="onOpenDetails($event)"></app-advertisement-card>
      </div>
    </div>
  </div>
</div>
