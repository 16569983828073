<h1 mat-dialog-title>
  <span *ngIf="data.uuid">{{'common.user_details' | translate}}</span>
</h1>
<div mat-dialog-content>

  <!-- <div class="d-flex justify-content-center">
    <ngx-avatar name="{report.author?.first_name} {report.author?.last_name}" gravatarId="{report.author?.username}"
      [src]="data.avatardocument_object?.file" size="100" [round]="true">
    </ngx-avatar>
  </div>
  <br> -->
  
  <div class="row">
    <mat-form-field appearance="outline" class="col-6">
      <mat-label>{{'common.first_name' | translate}}</mat-label>
      <input matInput [(ngModel)]="data.first_name" readonly>
    </mat-form-field>
    <mat-form-field appearance="outline" class="col-6">
      <mat-label>{{'common.last_name' | translate}}</mat-label>
      <input matInput [(ngModel)]="data.last_name" readonly>
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label translate>common.mail</mat-label>
    <input matInput [(ngModel)]="data.email" readonly>
  </mat-form-field>

  <mat-form-field appearance="outline" style="width: 100%">
    <mat-label>{{'common.phone' | translate}}</mat-label>
    <input matInput [(ngModel)]="data.phone" readonly>
  </mat-form-field>

  <div class="d-flex justify-content-center" *ngIf="!!data.last_login">
    <small>Letztes Login {{data.last_login | date: 'yyyy-MM-dd HH:mm'}}</small>
  </div>
  <!-- <pre>{{data | json}}</pre> -->

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
  <button mat-button color="secondary" mat-dialog-close="">{{'common.close' | translate}}</button>
</div>