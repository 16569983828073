import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { IDetailedUser, UsersService, IUser } from 'projects/webapp/app/services/users.service';
import { ActivatedRoute } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { IShipment } from 'projects/webapp/app/services/shipments.service';
import { ToastrService } from 'ngx-toastr';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  userUUID: string | null = null;
  user: IDetailedUser | null= null;
  userShipments: IShipment [] = [];

  constructor(
    public usersService: UsersService,
    public carrierShipmentsService: CarrierShipmentsService,
    private title: Title,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
  ) {
    this.translateService.get('common.user_details').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    this.route.params.subscribe(params => {
      if (!!params && params['userUUID']) {
        this.userUUID = params['userUUID'];
        this.refresh();
      }
    })
  }

  async refresh() {
    if (this.userUUID) {
      try {
        this.spinnerService.show();
        this.user = await lastValueFrom(this.usersService.getOrgUser(this.userUUID));
        this.userShipments = await lastValueFrom(this.carrierShipmentsService.getShipmentsAssigned({userUUID: this.userUUID}));
      } catch (error) {
        console.error(error);
      } finally {
        this.spinnerService.hide();
      }
    }
  }

  getFullName(user: IUser) {
    return `${user.first_name} ${user.last_name}`
  }

  async onFormSubmit(user: IDetailedUser) {
    if (this.userUUID){
      try {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.user_updated'));

        await this.usersService.updateUser(this.userUUID, user).toPromise();
        this.toastrService.success(successMessage)
        this.refresh();
      } catch (error) {
        this.toastrService.error(JSON.stringify(error))
      }
    }
  }

}
