import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';
import { ShipmentImage } from '../interfaces/shipment.interface';



@Injectable({
  providedIn: 'root'
})
export class AdminShipmentsService {


  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getShipmentImages(shipmentUUID: string): Observable<ShipmentImage[]> {
    return this.http.get<ShipmentImage[]>(this._urls.ADMIN_IMAGES_SHIPMENTS_BY_UUID(shipmentUUID))
  }

  public addShipmentImage(shipmentUUID: string, data: any): Observable<any> {
    return this.http.post<any>(this._urls.ADMIN_IMAGES_SHIPMENTS_BY_UUID(shipmentUUID), data)
  }

  public getShipmentTransaction(shipmentUUID: string): Observable<any> {
    return this.http.get<any>(this._urls.ADMIN_PRICE_SHIPMENTS_BY_UUID(shipmentUUID))
  }
}
