import { Component, OnInit } from '@angular/core';
import { Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShipmentsService } from '../../services/shipments.service';
import { lastValueFrom } from 'rxjs';
import { ImageType } from '../../interfaces/shipment.interface';
import { AdminShipmentsService } from '../../services/admin-shipments.service';
import { PanelType } from '../../interfaces/panel-types.interface';
import { CarrierShipmentsService } from '../../services/carrier-shipments.service';

export interface IShipmentAddImageData {
  panelType: PanelType,
  type: ImageType,
  showType: boolean,
  shipmentUUID: string
}

@Component({
  selector: 'app-shipment-add-image-modal',
  templateUrl: './shipment-add-image-modal.component.html',
  styleUrls: ['./shipment-add-image-modal.component.scss']
})
export class ShipmentAddImageModalComponent implements OnInit {
  isLoading = false;

  imageType = ImageType

  constructor(
    public dialogRef: MatDialogRef<ShipmentAddImageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IShipmentAddImageData,
    private carrierShipmentsService: CarrierShipmentsService,
    private adminShipmentsService: AdminShipmentsService,
  ) { }

  async ngOnInit() {}

  async onFileSelected(event: any) {
    console.log('onFileSelected');
    try {
      this.isLoading = true;
      const file: File = event.target.files[0];
      if (file && this.data.shipmentUUID) {
        const fileName = file.name;
        const formData = new FormData();
        formData.append("file", file);
        formData.append('name', fileName);
        formData.append('type', `${this.data.type}`);
        if (this.data.panelType === 'carrier') {
          const result = await lastValueFrom(this.carrierShipmentsService.addShipmentImage(this.data.shipmentUUID, formData));
          console.log(result);
        }
        if (this.data.panelType === 'admin') {
          const result = await lastValueFrom(this.adminShipmentsService.addShipmentImage(this.data.shipmentUUID, formData));
          console.log(result);
        }
        this.isLoading = false;
        if (this.dialogRef) {
          this.dialogRef.close(true)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false;
    }
  }
}
