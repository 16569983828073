import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { VehicleEditorComponent } from 'projects/webapp/app/components/vehicle-editor/vehicle-editor.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { FleetService, IVehicle } from 'projects/webapp/app/services/fleet.service';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss']
})
export class FleetComponent implements OnInit {


  vehicles: IVehicle[] = []

  constructor(
    public authService: AuthService,
    public fleetService: FleetService,
    public spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    private title: Title,
    private translate: TranslateService,
  ) {
    this.translate.get('common.my_fleet').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.vehicles = await lastValueFrom(this.fleetService.getVehicles())
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

  async onCreateNewVehicle() {
    const data: IVehicle = {
      number: '',
      model: '',
      max_weight: 3500,
      max_ground_spaces: 10,
      description: ''
    }
    this.dialog.open(VehicleEditorComponent, {
      data,
      width: '90vw',
    }).afterClosed().subscribe(async val => {
      if (!!val) {
        try {
          this.spinnerService.show()
          await lastValueFrom(this.fleetService.createVehicle(
            val
          ))
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    })
  }

  onEditVehicle(vehicle: IVehicle) {
    this.dialog.open(VehicleEditorComponent, {
      data: vehicle,
      width: '90vw',
    }).afterClosed().subscribe(async val => {
      if (!!val && vehicle.uuid) {
        try {
          this.spinnerService.show()
          await lastValueFrom(this.fleetService.updateVehicle(vehicle.uuid,
            val
          ))
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    });
  }

  async onDeleteVehicle(vehicle: IVehicle) {
    if (vehicle.uuid && confirm(`Wollen Sie wirklich das Fahrzeug ${vehicle.number} löschen`)) {
      try {
        this.spinnerService.show()
        await lastValueFrom(this.fleetService.deleteVehicle(vehicle.uuid))
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide()
        this.refresh()
      }

    }
  }

}
