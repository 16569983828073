import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable({ providedIn: 'root' })
  @Pipe({ name: 'shipmentNumberPipe' })
export class ShipmentNumberPipe implements PipeTransform {
  constructor() { }

  transform(value: any): string {
      value = '' + (value || '');
    // return !!value ? `${value.slice(0, 2)}-${value.slice(2, 6)}-${value.slice(6, 10)}-${value.slice(10)}` : '';
    return !!value ? `${value.slice(0, 6)}-${value.slice(6, 10)}-${value.slice(10)}` : '';
    }
}
