import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AdvertisementDetailsComponent } from 'projects/webapp/app/components/advertisement-details/advertisement-details.component';
import { AdvertisementsService, IAdvertisement } from 'projects/webapp/app/services/advertisement.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdminAdvertisementComponent implements OnInit {

  ads: IAdvertisement[] = []
  isLoading = false;

  constructor(
    private title: Title,
    private advertisementsService: AdvertisementsService,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) { }

  async ngOnInit() {
    this.translate.get('carrier_dashboard.ads.main_title').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
    this.refresh();
  }

  async refresh() {
    try {
      this.isLoading = true
      this.ads = await lastValueFrom(this.advertisementsService.getAds({}))
      console.log(this.ads)
    } catch (error) {
      console.error(error)
    } finally {
      this.isLoading = false
    }
  }

  onOpenDetails(ad: IAdvertisement) {
    this.dialog.open(AdvertisementDetailsComponent, {
      data: ad,
      width: '90vw',
    })
  }
}
