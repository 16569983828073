import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';


@Injectable({
  providedIn: 'root'
})
export class ExportService {

  public httpOptions = {
    // important to access the headers
    observe: 'response' as 'body',
    responseType: 'arraybuffer' as 'json',
    // headers: new HttpHeaders().append(ALLOWED_TO_REFRESH_HEADER_NAME, 'true'),
  };

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public exportShipments(data: any): Observable<any> {
    return this.http.post<any>(this._urls.CARRIER_EXPORT_SHIPMENTS, data, this.httpOptions)
  }

 
}
