<h1 mat-dialog-title>
    <span>{{'common.dispatch' | translate}}</span>
</h1>
<div mat-dialog-content>
    <div class="mt-2">
        <mat-form-field appearance="outline" style="width: 100%">
            <mat-label>{{'common.status' | translate}}</mat-label>
            <mat-select [(ngModel)]="data.mode">

                <mat-option [value]="shipmentTaskStatus.PICKUP_VIA_HUB"
                    *ngIf="[currentLocationStatus.AT_PICKUP_SITE, currentLocationStatus.AT_PICKUP_TRUCK].includes(data.current_loc)">
                    {{shipmentTaskStatus.PICKUP_VIA_HUB | shipmentTaskStatusPipe}}
                </mat-option>

                <mat-option [value]="shipmentTaskStatus.PICKUP_AND_DELIVERY_DIRECTLY"
                    *ngIf="[currentLocationStatus.AT_PICKUP_SITE, currentLocationStatus.AT_PICKUP_TRUCK].includes(data.current_loc)">
                    {{shipmentTaskStatus.PICKUP_AND_DELIVERY_DIRECTLY | shipmentTaskStatusPipe}}
                </mat-option>

                <mat-option [value]="shipmentTaskStatus.DELIVERY_VIA_HUB"
                    *ngIf="[currentLocationStatus.AT_HUB, currentLocationStatus.AT_DELIVERY_TRUCK].includes(data.current_loc)">
                    {{shipmentTaskStatus.DELIVERY_VIA_HUB | shipmentTaskStatusPipe}}
                </mat-option>

                <mat-option [value]="shipmentTaskStatus.DELIVERY_DIRECTLY"
                    *ngIf="[currentLocationStatus.AT_HUB, currentLocationStatus.AT_DELIVERY_TRUCK].includes(data.current_loc)">
                    {{shipmentTaskStatus.DELIVERY_DIRECTLY | shipmentTaskStatusPipe}}
                </mat-option>

            </mat-select>
        </mat-form-field>


        <div *ngIf="[shipmentTaskStatus.PICKUP_VIA_HUB, shipmentTaskStatus.DELIVERY_VIA_HUB].includes(data.mode)">
            <app-hub-selector (onSelectedHub)="onSelectedHub($event)"></app-hub-selector>
        </div>

        <app-user-selector [selectedUser]="data.user" (onSelectedUser)="onSelectedUser($event)"></app-user-selector>
    </div>

    <!-- <pre>{{data | json}}</pre> -->

</div>
<div mat-dialog-actions style="display: flex; justify-content: space-evenly">
    <button mat-button color="secondary" mat-dialog-close="">{{'common.close' | translate}}</button>
    <button mat-button color="primary" cdkFocusInitial [mat-dialog-close]="data">{{'common.save' | translate}}</button>
</div>
