import { Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { catchError, map, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { NgxSpinnerService } from 'ngx-spinner';
import * as Sentry from '@sentry/angular-ivy';
import { UrlConstantsService } from 'projects/webapp/app/services/url-constants.service';


export interface IAuthTokens {
  access: string;
  refresh?: string;
  accepted_terms?: boolean;
}


export enum OrganizationType {
  SHIPPER = 1,
  CARRIER = 2,
  BOTH = 3,
}
export interface IDecodedAccessToken {
  exp: number;
  jti: string;
  name: string;
  email: string;

  organization_id: number;
  organization_name: string;
  organization_type_preference: OrganizationType
  token_type: string;
  user_id: number;
  uuid: string;
  platform_admin?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  public decodedAccessToken$: Observable<IDecodedAccessToken>;
  private _accessToken = 'accessToken';
  private _refreshToken = 'refreshToken';
  private _decodedAccessToken$: BehaviorSubject<IDecodedAccessToken>;
  logout$ = new BehaviorSubject<boolean>(false);
  private _autoRefreshTokensTimeoutId: number | any;
  private _afterLoginPath = 'after-login-link';

  constructor(
    private router: Router,
    private http: HttpClient,
    private spinnerService: NgxSpinnerService,
    private urls: UrlConstantsService,
    @Optional() private angularFireAnalytics: AngularFireAnalytics,
  ) {
    const t = this._getDecodedAccessToken() as IDecodedAccessToken
    this._decodedAccessToken$ = new BehaviorSubject<IDecodedAccessToken>(t);
    this.decodedAccessToken$ = this._decodedAccessToken$.asObservable();
  }

  public clearAuthToken(): void {
    console.log('clearAuthToken');
    localStorage.removeItem(this._accessToken);
    localStorage.removeItem(this._refreshToken);
    const t = this._getDecodedAccessToken(null) as any
    this._decodedAccessToken$.next(t);
  }

  public setAfterLoginPath(path: string): void {
    sessionStorage.setItem(this._afterLoginPath, path);
  }

  public logout(keepAfterLoginPath: boolean = false, afterLogoutPage: string = '/') {
    console.log(`going to log out '${keepAfterLoginPath}', '${afterLogoutPage}'`);
    try {
      this.logout$.next(true);
    } catch (error) {
      console.error(error);
    }

    if (this.isAuthenticatedUser()) {
      this.angularFireAnalytics?.logEvent('user_logout');
    }

    if (this.isAuthenticatedPlatformAdmin()) {
      this.angularFireAnalytics?.logEvent('admin_logout');
    }

    localStorage.clear();

    this.clearAuthToken();
    Sentry.configureScope((scope) => scope.setUser(null));
    // this.angularFireAnalytics.setUserId(null);
    if (keepAfterLoginPath) { this.setAfterLoginPath(this.router.url); }
    // this.router.navigate([afterLogoutPage]);
    this.spinnerService.hide()
  }

  public putTokenLocalStorage(token: string): void {
    window.localStorage.removeItem('token');
    window.localStorage.setItem('token', token);
  }

  public saveAuthToken(tokens: IAuthTokens): void {
    console.log('saving tokens');
    localStorage.setItem(this._accessToken, tokens.access);
    if (!!tokens.refresh) { localStorage.setItem(this._refreshToken, tokens.refresh); }

    const t = this._getDecodedAccessToken(tokens.access)
    if (!!t) { this._decodedAccessToken$.next(t); }
    this._autoRefreshTokens();
  }

  public getUserId(): number | undefined {
    return this._getDecodedAccessToken()?.user_id;
  }

  public getUserUUID(): string | undefined {
    return this._getDecodedAccessToken()?.uuid;
  }

  public getUsername(): string | undefined {
    return this._getDecodedAccessToken()?.name;
  }

  public getUserEmail(): string | undefined {
    return this._getDecodedAccessToken()?.email;
  }

  public getCurrentOrganizationName(): string | undefined {
    return this._getDecodedAccessToken()?.organization_name;
  }

  public getCurrentOrganizationId(): number | undefined {
    return this._getDecodedAccessToken()?.organization_id;
  }

  public hasOrganizationTypePreference(): OrganizationType | undefined {
    return this._getDecodedAccessToken()?.organization_type_preference;
  }

  public isAuthenticated(): boolean {
    if (this.isExpired()) {
      this.clearAuthToken();
      return false;
    }
    return !!this._getDecodedAccessToken()?.user_id;
  }

  public isAuthenticatedPlatformAdmin(): boolean {
    if (this.isExpired()) {
      this.clearAuthToken();
      return false;
    }
    return !!this._getDecodedAccessToken()?.platform_admin;
  }

  public isAuthenticatedUser(): boolean {
    if (this.isExpired()) {
      this.clearAuthToken();
      return false;
    }
    const result = this._getDecodedAccessToken() != null;
    return result;
  }

  public getExpiration(): number {
    return this._getDecodedAccessToken()?.exp || 0;
  }

  public isExpired(): boolean {
    const e = this._getDecodedAccessToken()?.exp
    if (this._getDecodedAccessToken() && !!e) {
      const exp = e * 1000;
      const now = Date.now();
      if (exp - now > 0) {
        return false;
      }
      return true;
    }
    return true;
  }

  public refreshToken(): Observable<IAuthTokens> {
    console.log('refreshToken');
    return this.http
      .post<IAuthTokens>(
        this.urls.USER_REFRESH_TOKENS
        , { refresh: this.getRefreshToken() })
      .pipe(tap((tokens) => this.saveAuthToken(tokens)));
  }

  private _autoRefreshTokens(): void {
    if (!this.isAuthenticated()) return;

    const expirationPeriod = this.getExpiration() * 1000 - Date.now();
    clearTimeout(this._autoRefreshTokensTimeoutId);
    this._autoRefreshTokensTimeoutId = setTimeout(() => this.refreshToken().subscribe(), expirationPeriod);
  }

  public getAccessToken(): string | null {
    return localStorage.getItem(this._accessToken);
  }

  public getRefreshToken(): string | null {
    return localStorage.getItem(this._refreshToken);
  }

  private _getDecodedAccessToken(accessToken: string | null = this.getAccessToken()): IDecodedAccessToken | null {
    try {
      if (typeof (accessToken) == 'string') {
        return JSON.parse(atob(accessToken.split('.')[1]));
      } else {
        return null;
      }
    } catch {
      return null;
    }
  }

  public loginUser(username: string, password: string): Observable<boolean> {
    this.angularFireAnalytics?.logEvent(`user_try_login`);
    return this.http
      .post<any>(this.urls.USER_LOGIN_EMAIL, { username, password })
      .pipe(
        tap(
          (tokens) => {
            Sentry.setUser({ email: username });
            this.saveAuthToken(tokens);

            this.angularFireAnalytics?.logEvent(`user_login`, {
            });

          },
          (error) => {
            console.warn(error);
            // this._toastr.error(
            //   this._translate.instant('errors.could_not_log_in'),
            //   this._translate.instant('common.error')
            // );
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

  public checkIfEmailAvailable(email: string) {
    return this.http
      .get<any>(this.urls.CHECK_NEW_EMAIL, { params: { email } })
  }

  public registerUser(formValue: any) {
    this.angularFireAnalytics?.logEvent(`user_try_register`);
    return this.http
      .post<any>(this.urls.USER_REGISTER_EMAIL, formValue)
      .pipe(
        tap(
          (tokens) => {
            Sentry.setUser({ email: formValue.email });
            this.saveAuthToken(tokens);

            this.angularFireAnalytics?.logEvent(`user_register`, {
            });

          },
          (error) => {
            console.warn(error);

          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

  public forgotPassword(formValue: any): Observable<boolean> {
    this.angularFireAnalytics?.logEvent(`user_try_forget_password`);
    return this.http
      .post<any>(this.urls.USER_FORGET_PASSWORD, formValue)
      .pipe(
        tap(
          (tokens) => {
            this.angularFireAnalytics?.logEvent(`user_forget_password`);
          },
          (error) => {
            console.warn(error);
            // this._toastr.error(
            //   this._translate.instant('errors.could_not_log_in'),
            //   this._translate.instant('common.error')
            // );
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

  public resetPassword(formValue: any): Observable<boolean> {
    this.angularFireAnalytics?.logEvent(`user_try_forget_password`);
    return this.http
      .post<any>(this.urls.USER_RESET_PASSWORD, formValue)
      .pipe(
        tap(
          (tokens) => {
            this.angularFireAnalytics?.logEvent(`user_forget_password`);
          },
          (error) => {
            console.warn(error);
            // this._toastr.error(
            //   this._translate.instant('errors.could_not_log_in'),
            //   this._translate.instant('common.error')
            // );
          }
        ),
        map((_) => true),
        catchError((_) => of(false))
      );
  }

  public verifyEmail(token: string): Observable<boolean> {
    this.angularFireAnalytics?.logEvent(`user_validate_email`);
    return this.http
      .post<any>(this.urls.USER_VERIFY_EMAIL, { token })
      .pipe(
        tap(
          (tokens) => {
            this.saveAuthToken(tokens);
            Sentry.setUser({ email: this.getUserEmail() });
          },
          (error) => {
            console.error(error);
          }
        ),
        map((_) => true),
        catchError((error) => {
          console.error(error)
          return of(false)
        })
      );
  }

}
