import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { UrlConstantsService } from './url-constants.service';


export enum EventType {
  UNKNOWN = 0,

  LOGIN = 1,
  RESET_PASSWORD = 2,
  
  CREATE_PROJECT = 3,
  CREATE_TASK = 4,
  CREATE_REPORT = 5,
  UPDATE_PROJECT = 6,
  UPDATE_TASK = 7,
  UPDATE_REPORT = 8,
  DELETE_PROJECT = 9,
  DELETE_TASK = 10,
  DELETE_REPORT = 11,

  CREATE_REPORT_DOCUMENT = 12,
  DELETE_REPORT_DOCUMENT = 13,

  CREATE_PROJECT_DOCUMENT = 14,
  DELETE_PROJECT_DOCUMENT = 15,

  UPDATE_AVATAR = 100,
  UPLOAD_REPORT_DOCUMENT = 101,

  UPDATE_ORGANIZATION = 200,
  UPDATE_PROJECT_SHARING = 201,
}


export interface IEvent {
  id: number;
  uuid: string;
  created_at: string,
  updated_at: string,
  type: EventType,
  author:  number,
  entity_uuid: string | null, 
  author_object: {
    "id": number,
    "uuid": string,
    "first_name": string,
    "last_name": string
  }
}



@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(
    private http: HttpClient,
    private _urls: UrlConstantsService,
  ) {
  }

  public getLastActivityEvents(): Observable<IEvent[]> {
    return this.http.get<IEvent[]>(this._urls.EVENTS);
  }

}
