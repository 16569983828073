import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FilteredListComponent } from 'projects/webapp/app/components/filtered-list/filtered-list.component';
import { IFilterProperty } from 'projects/webapp/app/components/filtered-tags/filter.interface';
import { TourEditorModalComponent } from 'projects/webapp/app/components/tour-editor-modal/tour-editor-modal.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { IDetailedTour, TourService, TourStatus } from 'projects/webapp/app/services/tour.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tours-archive',
  templateUrl: './tours-archive.component.html',
  styleUrls: ['./tours-archive.component.scss']
})
export class ToursArchiveComponent extends FilteredListComponent implements OnInit {

  isLoading = false;
  tourStatus = TourStatus
  onlyForThisDriver = false;

  override items: IDetailedTour[] = []
  override filterProperties: IFilterProperty[] = []

  constructor(
    public carrierShipmentsService: CarrierShipmentsService,
    public tourService: TourService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private authService: AuthService,
    public toastrService: ToastrService,
    public spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
  ) {
    super()
  }

  async ngOnInit() {
    await this.refresh();
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.items = await lastValueFrom(this.tourService.getTours());

      this.filteredItems = this.items;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  async onEdit(tour: IDetailedTour) {
    this.dialog.open(TourEditorModalComponent, {
      data: tour,
      width: '90vw',
    }).afterClosed().subscribe(async val => {
      if (!!val && tour.uuid) {
        try {
          this.spinnerService.show()
          await lastValueFrom(this.tourService.updateTour(tour.uuid,
            val
          ))
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    });
  }

  async onDelete(tour: IDetailedTour) {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_tour_deletion', { id: tour.id }));

    if (tour.uuid && confirm(confirmMessage)) {
      try {
        this.spinnerService.show()
        this.isLoading = true;
        this.items = await lastValueFrom(this.tourService.deleteTour(tour.uuid))
        this.refresh()
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.spinnerService.hide()
      }
    }
  }

  async duplicate() {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_items_duplication', { selectedItems: this.selectedItems.length }));

    if (confirm(confirmMessage)) {
      let hasError = false;
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        const data: IDetailedTour = {
          date: element.date,
          status: this.tourStatus.IN_PROGRESS,
          driver: element.driver,
          weight: 0,
          ground_spaces: 0,
          turnover: 0,
          profit: 0,
          is_optimized: false,
          number_of_stops: 0,
          driving_duration: 0,
          driving_distance: 0,
          vehicle: element.vehicle
        }
        try {
          this.spinnerService.show()
          await lastValueFrom(this.tourService.createTour(
            data
          ))
        } catch (error) {
          console.error(error)
          hasError = true;
        } finally {
          this.spinnerService.hide()
        }
      }
      if (!hasError) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.tour_duplicated'));

        this.toastrService.success(successMessage)
      }
      this.refresh();
      this.selectedItems = [];
    }

  }
}
