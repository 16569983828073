import { IShipment } from "../services/shipments.service";


export interface IShipperMarketShipment extends Partial<IShipment> {
  market_price: number;
  views: number
  end_date: string;
}

export interface ICarrierMarketShipment extends Partial<IShipment> {
  orderer: {
    "name": string;
  };
  market_price: number;
  total_pallet_places: number
  end_date: string;
}


export enum ShipmentTransactionKind {
  UNKNOWN = 0,
  AUCTION = 3,
  BUYER_SET = 4,
  SELLER_SET = 5,
  OWN_CLIENT = 6,
}


export interface IShipmentTransaction {
  surcharges: any;
  invoice_object: {
    "id": number,
    "uuid": string,
    "number": string
  };
  "id": number,
  "uuid": string,

  total_price_with_vat: number;
  total_vat: number;
  "base_price": number,
  "total_price": number,
  "options": {
    name: string,
    price: number,
  }[],
  kind: ShipmentTransactionKind,
  "invoice": number,
  "ready_to_invoice": true,
  "buyer_name": string,
  "buyer_id": number,
  "buyer_uuid": string,
  "seller_name": string,
  "seller_id": number,
  "seller_uuid": string
}


export interface IDetailedShipment extends IShipment {
  custom_number?: string;
  pickup_crane_needed: boolean;
  delivery_crane_needed: boolean;

  buyer_object: any;

}


export interface ICarrierShipment extends IDetailedShipment {
meta_data: any;
  buyer_price: IShipmentTransaction
  can_carrier_change_price: boolean;
  can_sell_shipment: boolean;
  total_ground_spaces?: number

  assigned_tour?: number
  assigned_tour_object?: any;
}

export interface IAssignedTourObject {
  "id": number,
  "uuid": string,
  "name": string,
  "date": string,
  "vehicle_number": string,
}

export interface IDispatchShipment extends ICarrierShipment {
  assigned_tour_object?: IAssignedTourObject
}


export enum ImageType {
  PICKUP = 2,
  DELIVERY = 3,
  GENERAL = 4
}

export interface ShipmentImage {
  type: ImageType;
  "id": number,
  "uuid": string,
  "file": string,
  "shipment": string,
  "thumbnail": string | null,
  "name": string
}

