import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { UsersService } from 'projects/webapp/app/services/users.service';

@Component({
  selector: 'app-empties',
  templateUrl: './empties.component.html',
  styleUrls: ['./empties.component.scss']
})
export class EmptiesComponent implements OnInit {

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public dialog: MatDialog,
  ) {
  }

  async ngOnInit() {
  }
}
