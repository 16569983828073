<div *ngIf="isLoading">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<div *ngIf="!isLoading && !siteKey" class="mt-5 p-4 w-100 text-center">
    <div class="alert alert-danger" role="alert">
        SiteKey not found. Please contact our support for further information.
    </div>
</div>

<div *ngIf="!isLoading && siteKey">
    <div class="container">

        <h1 class="text-center mb-5">{{'common.track_shipment' | translate}}</h1>

        <div class="row">
            <div class="col-12">
                <div class="mb-5">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title d-flex justify-content-between">
                                <span>
                                    {{'carrier_dashboard.shipment_search' | translate}}
                                </span>
                            </h5>
                            <form [formGroup]="shipmentSearchForm">
                                <div>
                                    <div class="mb-3">
                                        <div class="input-group">
                                            <input type="text" placeholder="Sendungsnummer, Abholreferenz, Zustellreferenz etc." class="form-control"
                                                formControlName="search">
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

        </div>
        <div *ngIf="shipmentSearchStatus ==='not_found'" class="alert alert-warning">
            {{'carrier_dashboard.shipment-search.no_shipment' | translate}}
        </div>

        <div>
            <button class="btn btn-primary w-100" (click)="search()">
                {{'common.search' | translate}}
            </button>
        </div>


        <div *ngIf="shipmentSearchStatus ==='found'">
            <!-- <pre>{{shipment | json}}</pre> -->
            <app-shipment-tracking [trackingUUID]="shipment.tracking_uuid"></app-shipment-tracking>
        </div>

        <!-- <pre>{{shipmentSearchForm.value | json}}</pre> -->

    </div>

    <br><br>
    <div>
        <div class="pt-6 text-center" *ngIf="tenantName">Licensed to {{tenantName}}</div>

        <div class="text-center">Powered by <a href="https://cargoflow.ch/"
                style="text-decoration: underline;" target="_blank">Cargoflow</a></div>

        <div class="text-center">
            <small class="text-center">v{{versions.date | date: 'yyyyMMdd'}}-{{versions.hash}}</small>
        </div>

    </div>
    <br>

</div>