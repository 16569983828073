<div class="kachel" *ngIf="advertisement">

  <div class="alert alert-info mt-4" *ngIf="!advertisement.is_active">(nicht aktiv, nur für Sie sichtbar)</div>

  <div class="client-art-wrapper">
    <div class="w-layout-layout quick-stack wf-layout-layout">
      <div id="w-node-_09487106-a2bd-a1d2-08d9-44e23eaade1a-d2dd3ff5" class="w-layout-cell">
        <div class="aufraggeber-wrapper">
          <!-- <h3 class="heading-grau">{{'common.consignor' | translate}}</h3> -->
          <h3 class="heading-bold" *ngIf="advertisement.title">
            {{advertisement.title}}
          </h3>
        </div>
      </div>
    </div>

    <div class="w-layout-layout quick-stack wf-layout-layout">
      <div class="abholung">
        <h3 class="heading-grau">{{advertisement.description}}</h3>
      </div>
    </div>
    <div class="w-layout-layout quick-stack wf-layout-layout" *ngIf="advertisement.location">
      <div class="abholung">
        <h3 class="heading-grau">{{'common.where' | translate}}: {{advertisement.location}}</h3>
      </div>
    </div>
    <div class="w-layout-layout quick-stack wf-layout-layout" *ngIf="advertisement.tags">
      <div class="abholung">
        <h3 class="heading-grau">{{advertisement.tags}}</h3>
      </div>
    </div>

    <div class="row" *ngIf="images">
      <div *ngFor="let image of images" class="col-xs-6 col-sm-6 col-md-4 col-lg-3">
        <div class="w-100" style="border: 1px solid black; border-radius: 5px; padding: 5px; margin: 2px">
          <a [href]="image.file" target="_blank">
            <img [src]="image.file" width="100%" />
          </a>
          <!-- <small style="word-break: break-all;">{{image.name}}</small> -->
        </div>
      </div>
    </div>

    <div class="w-layout-layout quick-stack wf-layout-layout">
      <small style="color: grey;">{{'common.published' | translate}} {{advertisement.updated_at | humanDatePipe}}</small>
      <a [routerLink]="[]" (click)="openDetails()" class="btn-primary yellow w-100">{{'common.information' | translate}}</a>
      <a [routerLink]="[]" (click)="openEdit()"
        *ngIf="authService.getCurrentOrganizationId() == advertisement.organization && allowEdit"
        class="btn-primary green w-100">{{'common.edit' | translate}}</a>
    </div>
  </div>
</div>

<!-- <pre>{{advertisement | json}}</pre> -->
