import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FilteredListComponent } from 'projects/webapp/app/components/filtered-list/filtered-list.component';
import { IFilterProperty } from 'projects/webapp/app/components/filtered-tags/filter.interface';
import { TourDatePickerModalComponent } from 'projects/webapp/app/components/tour-date-picker-modal/tour-date-picker-modal.component';
import { TourEditorModalComponent } from 'projects/webapp/app/components/tour-editor-modal/tour-editor-modal.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { CarrierShipmentsService } from 'projects/webapp/app/services/carrier-shipments.service';
import { FleetService } from 'projects/webapp/app/services/fleet.service';
import { IDetailedTour, ITour, TourService, TourStatus } from 'projects/webapp/app/services/tour.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-tour-management',
  templateUrl: './tour-management.component.html',
  styleUrls: ['./tour-management.component.scss']
})
export class TourManagementComponent extends FilteredListComponent implements OnInit {

  isLoading = false;
  tourStatus = TourStatus
  onlyForThisDriver = false;

  override items: IDetailedTour[] = []
  override filterProperties: IFilterProperty[] = []

  constructor(
    public carrierShipmentsService: CarrierShipmentsService,
    public tourService: TourService,
    public fleetService: FleetService,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    private authService: AuthService,
    public toastrService: ToastrService,
    public spinnerService: NgxSpinnerService,
    private translateService: TranslateService,
  ) {
    super()
  }

  async ngOnInit() {
    await this.refresh();
    this.buildFilters();
  }

  buildFilters() {
    const stateFilter: IFilterProperty = {
      title: 'Status',
      key: 'status',
      operators: [
        {
          key: 'operator',
          title: '=',
          value: true,
        },
        {
          key: 'operator',
          title: '!=',
          value: false,
        },
      ],
      values: [
        {
          key: 'values',
          title: 'In Bearbeitung',
          value: this.tourStatus.IN_PROGRESS
        },
        {
          key: 'values',
          title: 'Geplant',
          value: this.tourStatus.PLANNED
        },
        {
          key: 'values',
          title: 'Abgeschlossen',
          value: this.tourStatus.CLOSED,
        },
      ]
    }
    this.filterProperties.push(stateFilter)
  }

  async refresh() {
    try {
      this.spinnerService.show()
      this.isLoading = true;
      this.onlyForThisDriver = this.route.snapshot.data['onlyForMe'];
      if (this.onlyForThisDriver) {
        this.items = await lastValueFrom(this.tourService.getMyTours(this.authService.getUserUUID()));
      } else {
        this.items = await lastValueFrom(this.tourService.getOpenTours());
      }

      this.filteredItems = this.items;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
      this.spinnerService.hide()
    }
  }

  createToursForVehicles(){
    const data = {
      date: new Date(),
    }
    this.dialog.open(TourDatePickerModalComponent, {
      data,
      width: '90vw',
    }).afterClosed().subscribe(async (val: Partial<ITour>) => {
      if (!!val) {
        try {
          this.spinnerService.show();
          const possibleVehicles = await lastValueFrom(this.fleetService.getVehicles())
          for (let index = 0; index < possibleVehicles.length; index++) {
            const vehicle = possibleVehicles[index];
            const data: any = {
              date: val.date.toISOString().split('T')[0],
              weight: 0,
              ground_spaces: 0,
              turnover: 0,
              profit: 0,
              number_of_stops: 0,
              driving_duration: 0,
              driving_distance: 0,
              vehicle: vehicle.id,
            }

            await lastValueFrom(this.tourService.createTour(
              data
            ))
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    });
  }

  async add() {
    const data: ITour = {
      date: new Date(),
      weight: 0,
      ground_spaces: 0,
      turnover: 0,
      profit: 0,
      number_of_stops: 0,
      driving_duration: 0,
      driving_distance: 0,
    }
    this.dialog.open(TourEditorModalComponent, {
      data,
      width: '90vw',
    }).afterClosed().subscribe(async (val: ITour) => {
      if (!!val) {
        const data = { ...val, date: val.date.toISOString().split('T')[0] }
        try {
          this.spinnerService.show()
          await lastValueFrom(this.tourService.createTour(
            data
          ))
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    });
  }

  async onEdit(tour: IDetailedTour) {
    this.dialog.open(TourEditorModalComponent, {
      data: tour,
      width: '90vw',
    }).afterClosed().subscribe(async val => {
      if (!!val && tour.uuid) {
        try {
          this.spinnerService.show()
          await lastValueFrom(this.tourService.updateTour(tour.uuid,
            val
          ))
        } catch (error) {
          console.error(error)
        } finally {
          this.spinnerService.hide()
          this.refresh()
        }
      }
    });
  }

  async onDelete(tour: IDetailedTour) {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_tour_deletion', { id: tour.id }));

    if (tour.uuid && confirm(confirmMessage)) {
      try {
        this.spinnerService.show()
        this.isLoading = true;
        this.items = await lastValueFrom(this.tourService.deleteTour(tour.uuid))
        this.refresh()
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.spinnerService.hide()
      }
    }
  }

  async duplicate() {
    const confirmMessage = await lastValueFrom(this.translateService.get('toastr.confirm_items_duplication', { selectedItems: this.selectedItems.length }));

    if (confirm(confirmMessage)) {
      let hasError = false;
      for (let index = 0; index < this.selectedItems.length; index++) {
        const element = this.selectedItems[index];
        const data: IDetailedTour = {
          date: element.date,
          status: this.tourStatus.IN_PROGRESS,
          driver: element.driver,
          weight: 0,
          ground_spaces: 0,
          turnover: 0,
          profit: 0,
          is_optimized: false,
          number_of_stops: 0,
          driving_duration: 0,
          driving_distance: 0,
          vehicle: element.vehicle
        }
        try {
          this.spinnerService.show()
          await lastValueFrom(this.tourService.createTour(
            data
          ))
        } catch (error) {
          console.error(error)
          hasError = true;
        } finally {
          this.spinnerService.hide()
        }
      }
      if (!hasError) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.tour_duplicated'));

        this.toastrService.success(successMessage)
      }
      this.refresh();
      this.selectedItems = [];
    }

  }
}
