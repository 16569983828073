<mat-form-field appearance="outline" style="width: 100%" *ngIf="!!possibleTours && possibleTours.length > 0">
    <mat-label>{{'common.tour' | translate}}</mat-label>
    <input type="text" matInput [(ngModel)]="selectedTour" [matAutocomplete]="auto" [formControl]="userFormControl">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getTitle.bind(this)"
        (optionSelected)='selected($event.option.value)'>
        <mat-option *ngFor="let option of filteredTours" [value]="option">
            {{getOptionName(option)}}
        </mat-option>
    </mat-autocomplete>
</mat-form-field>

<div class="alert alert-info" role="alert" style="width: 100%" *ngIf="!possibleTours || possibleTours.length == 0">
    {{'common.no_tours_yet' | translate}} <a routerLink="/main/carrier/tours">{{'common.tours_management' | translate}}</a> {{'common.create' | translate}}
</div>
