<mat-card class="mt-3">
    <mat-card-content>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <h5>{{'common.shipment_history' | translate}}</h5>
        </div>

        <div *ngIf="events">
            <ul class="co-simple-list">
                <div class="alert alert-light" *ngFor="let event of events">
                    <span *ngIf="event.author_object">{{event.author_object.first_name}}
                        {{event.author_object.last_name}}</span>
                    {{event.updated_at | humanDatePipe}}: {{event.type | shipmentEventTypePipe}}
                </div>
            </ul>

        </div>
    </mat-card-content>
</mat-card>