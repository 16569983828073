import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { HubEditorComponent } from 'projects/webapp/app/components/hub-editor/hub-editor.component';
import { AuthService } from 'projects/webapp/app/services/auth.service';
import { ClientsService } from 'projects/webapp/app/services/clients.service';
import { IHub, OrganizationsService } from 'projects/webapp/app/services/organizations.service';
import { UsersService } from 'projects/webapp/app/services/users.service';
import { deepCopy } from 'projects/webapp/app/utils';
import { lastValueFrom } from 'rxjs';


@Component({
  selector: 'app-hubs',
  templateUrl: './hubs.component.html',
  styleUrls: ['./hubs.component.scss']
})
export class HubsComponent implements OnInit {
  hubs: IHub[] = []

  constructor(
    public authService: AuthService,
    public clientService: ClientsService,
    public usersService: UsersService,
    public organizationManagement: OrganizationsService,
    public dialog: MatDialog,
    private title: Title,
    private translate: TranslateService,
  ) {
    this.translate.get('carrier_dashboard.user-editor-hub.main_title').subscribe(val => {
      this.title.setTitle(`Cargoflow: ${val}`);
    });
  }

  async ngOnInit() {
    await this.refresh()
  }

  async refresh() {
    this.hubs = await lastValueFrom(this.organizationManagement.getHubs())
  }

  onAdd() {
    const hub: IHub = {
      name: '',
      address: '',
      location: ''
    }
    this.dialog.open(HubEditorComponent, { data: hub }).afterClosed().subscribe(async val => {
      if (!!val) {
        await lastValueFrom(this.organizationManagement.createHub(val));
        this.refresh()
      }
    })
  }

  onEdit(hub: any) {
    const hubCopy = deepCopy(hub)
    this.dialog.open(HubEditorComponent, { data: hubCopy }).afterClosed().subscribe(async val => {
      if (!!val) {
        await lastValueFrom(this.organizationManagement.updateHub(val.uuid, val));
        this.refresh()
      }
    })
  }

  async onDelete(hub: IHub) {
    if (confirm('Sind Sie sicher, dass Sie dieses Hub löschen wollen?') && !!hub.uuid) {
      hub.is_deleted = true;
      await lastValueFrom(this.organizationManagement.updateHub(hub.uuid, hub));
      this.refresh()
    }
  }

}
