<div class="container-fluid">
    <mat-card class="mt-3">
        <mat-card-content>
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <h1>{{'common.notifications' | translate}}</h1>
            </div>

            <div class="alert alert-info" *ngIf="shipments.length == 0">
                {{'common.no_shipments_in_notifications_list' | translate}}
            </div>


            <table class="table table-striped" style="width: 100%"
                *ngIf="!!shipments && shipments.length > 0">
                <thead>
                    <td>{{'common.number' | translate}}</td>
                    <td>{{'common.from' | translate}}</td>
                    <td>{{'common.to' | translate}}</td>
                    <td></td>
                </thead>

                <tbody>
                    <tr *ngFor="let shipment of shipments">
                        <td>
                            <a routerLink="/main/carrier/shipments/{{shipment.uuid}}">
                                {{shipment.number | shipmentNumberPipe }}
                            </a>
                        </td>

                        <td>
                            {{shipment.pickup_address }}
                            <br>
                            {{shipment.pickup_zip }} {{shipment.pickup_location }}
                            <hr>
                            {{shipment.pickup_date }}
                            <br>
                            ({{shipment.pickup_date | humanDatePipe}})
                        </td>

                        <td>
                            {{shipment.delivery_address }}
                            <br>
                            {{shipment.delivery_zip }} {{shipment.delivery_location }}
                            <hr>
                            {{shipment.delivery_date }}
                            <br>
                            ({{shipment.delivery_date | humanDatePipe}})
                        </td>
                    </tr>
                </tbody>
            </table>
        </mat-card-content>
    </mat-card>
</div>
