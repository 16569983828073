import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IPrice } from 'projects/shared/price.interface';
import { IShipperPrice, TenderPricingModalComponent } from 'projects/webapp/app/components/tender-pricing-modal/tender-pricing-modal.component';
import { IShipment, ShipmentPriceMode } from 'projects/webapp/app/services/shipments.service';
import { ShipperShipmentsService } from 'projects/webapp/app/services/shipper-shipments.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-shipper-shipment-wizard',
  templateUrl: './shipment-wizard.component.html',
  styleUrls: ['./shipment-wizard.component.scss']
})
export class ShipperWizardComponent implements OnInit {

  price: IPrice | null = null;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private shipperShipmentsService: ShipperShipmentsService
  ) { }

  async ngOnInit() { }

  async onShipmentPriceRequest(event: any) {
    console.log('onShipmentPriceRequest')
    console.log(event)
  }

  async onShipmentRequest(shipment: IShipment) {
    console.log('onShipmentRequest')
    console.log(shipment);
    this.dialog.open(TenderPricingModalComponent, { data: shipment }).afterClosed().subscribe(async (val: IShipperPrice) => {

      if (!!val) {
        const data = {
          ...shipment, ...{
            shipper_pricing: val
          }
        }

        await lastValueFrom(this.shipperShipmentsService.createShipment(data));

        if (val.price_mode == ShipmentPriceMode.BUYER_SET){
          this.router.navigate(['/main/shipper/shipment-list'])
        } else {
          this.router.navigate(['/main/shipper/market-place'])
        }
      }
    });

  }


}
