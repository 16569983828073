import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { InvoiceEditModalComponent } from '../invoice-edit-modal/invoice-edit-modal.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { deepCopy, downloadFile } from 'projects/webapp/app/utils';
import { IInvoice, InvoiceStatus } from 'projects/webapp/app/interfaces/invoice.interface';
import { InvoicesService } from 'projects/webapp/app/services/invoices.service';
import { TranslateService } from '@ngx-translate/core';
import { IOrganization } from 'projects/webapp/app/interfaces/organization.interface';
import { OrganizationsService } from 'projects/webapp/app/services/organizations.service';


@Component({
  selector: 'app-accounting-invoice-list',
  templateUrl: './accounting-invoice-list.component.html',
  styleUrls: ['./accounting-invoice-list.component.scss']
})
export class AccountingInvoiceListComponent implements OnInit {
  invoices: IInvoice[] = [];
  isLoading = true;

  @Input() invoiceType = [InvoiceStatus.DRAFT]
  invoiceStatus = InvoiceStatus

  ownOrganization: IOrganization | null = null;

  constructor(
    private invoicesService: InvoicesService,
    public spinnerService: NgxSpinnerService,
    private dialog: MatDialog,
    private router: Router,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private organizationService: OrganizationsService,
  ) {
  }

  async ngOnInit() {
    this.ownOrganization = await this.organizationService.getOwnOrganization().toPromise() as any;
    await this.refresh()
  }

  async refresh() {
    try {
      this.isLoading = true;
      this.spinnerService.show();
      this.invoices = await lastValueFrom(this.invoicesService.getInvoices({ status: this.invoiceType }));
      console.log(this.invoices)
    } catch (error) {
      console.error('error')
    } finally {
      this.spinnerService.hide()
      this.isLoading = false;
    }
  }

  editStatusInvoice(item: IInvoice) {
    const data = deepCopy(item)
    this.dialog.open(InvoiceEditModalComponent, { data: data }).afterClosed().subscribe(async val => {
      if (!!val && item.uuid) {
        const successMessage = await lastValueFrom(this.translateService.get('toastr.invoice_adjusted'));

        await lastValueFrom(this.invoicesService.updateInvoice(item.uuid, val));
        this.refresh();
        this.toastrService.success(successMessage)
      }
    })
  }

  editInvoice(item: IInvoice) {
    this.router.navigate(['/main/carrier/facturation/invoice-editor/' + item.uuid])
  }

  async downloadInvoice(item: IInvoice) {
    if (item.uuid) {
      try {
        this.spinnerService.show()
        const response = await lastValueFrom(this.invoicesService.InvoicePDF(item.uuid));
        const blob = new Blob([response], { 'type': 'application/pdf' });
        downloadFile(blob, `Rechnung_${item.number}.pdf`);
      } catch (error) {
        console.error(error)
      } finally {
        this.spinnerService.hide()
      }
    }
  }

  async deleteInvoice(item: IInvoice) {
    try {
      const confirmationMessage = await lastValueFrom(this.translateService.get('toastr.confirm_invoice_deletion'));

      if (confirm(confirmationMessage) && item.uuid) {
        this.spinnerService.show()
        await lastValueFrom(this.invoicesService.deleteInvoice(item.uuid));
        this.refresh()
      }
    } catch (error) {
      console.error(error)
    } finally {
      this.spinnerService.hide()
    }
  }

}
