import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { versions } from 'projects/versions';
import { EmbedTrackTraceService } from 'projects/webapp/app/services/embed-track-trace.service';
import { EmbedWizardService } from 'projects/webapp/app/services/embed-wizard.service';


@Component({
  selector: 'app-embed-track-trace',
  templateUrl: './track-trace.component.html',
  styleUrls: ['./track-trace.component.scss']
})
export class EmbedTrackTraceComponent implements OnInit {

  public shipmentSearchForm = new FormGroup({
    search: new FormControl(''),
  });

  siteKey = '';
  tenantName = '';
  isLoading = true;
  isLoadingProcessing = false;
  shipmentSearchStatus: 'not_searched' | 'not_found' | 'found' = 'not_searched'
  versions = versions;
  shipment: any = null;

  constructor(
    private route: ActivatedRoute,
    private wizardService: EmbedWizardService,
    private trackTraceService: EmbedTrackTraceService,
  ) { }

  async ngOnInit() {
    this.isLoading = true;
    this.route.queryParams
      .subscribe(async (params) => {
        console.log(params);
        this.siteKey = params['siteKey'];
        console.log(this.siteKey);
        this.isLoading = false;
        if (!!this.siteKey) {
          this.wizardService.siteKey = this.siteKey
          try {
            const response = await lastValueFrom(this.wizardService.verify_sitekey(this.siteKey));
            console.log(response)
            this.tenantName = response.tenant_name
          } catch (error) {
            console.error(error);
          }
        }
      });
  }

  async search() {
    console.log('search')
    this.isLoadingProcessing = true;

    try {
      this.shipment = await lastValueFrom(this.trackTraceService.search_shipment_by_number(this.shipmentSearchForm.value))
      if (!!this.shipment) {
        this.shipmentSearchStatus = 'found'
      }
    } catch (error) {
      console.error(error);
      this.shipmentSearchStatus = 'not_found'
    } finally {
      this.isLoadingProcessing = false;
    }
  }
}
