import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LanguageService } from '../../services/language.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(
    private languageService: LanguageService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authClone = request.clone({ setHeaders: { 'Accept-Language': this.languageService.currentLanguage } });
    return next.handle(authClone);
  }
}
